import { Box, Button, CircularProgress, Stack, Tab, Tabs } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";
import BoxTitle from "../../components/BoxTitle";
import TableComponent from "../../components/TableComponent";
import { getLastApplicationAcceptanceDate } from "../../services/useEndpoint";
import { identity, pickBy } from "lodash";
import { getApplications } from "../SpecialistDeclarations/api";

export enum Status {
  SUCCSESS = "Принята",
  REJECTED = "Отклонена",
  SENT = "Новая",
  REVISION = "На доработку",
}

export type SchoolDeclarationsState = {
  id: number;
  organization_name: string;
  checkbox: boolean;
  created_at: string;
  status: string;
  statusId: string;
  url: string;
  typeOfDeclaration: string;
};

const SchoolDeclarations: FC = () => {
  const navigate = useNavigate();

  const [{ page, per_page }, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    per_page: withDefault(NumberParam, 10),
  });

  const { data: dataDate, isLoading: acceptanceIsLoading } = useQuery(
    ["getLastApplicationAcceptanceDate"],
    getLastApplicationAcceptanceDate,
  );

  const {
    data: applications,
    isSuccess: applicationsIsSuccess,
    isLoading: applicationsIsLoading,
  } = useQuery({
    queryKey: ["applications", page, per_page].filter(Boolean),
    queryFn: ({ signal }) =>
      getApplications({
        signal,
        params: pickBy({ page, per_page }, identity),
      }),
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const handlers = {
    proceedToReport: () => {
      navigate("/school/declarations/create");
    },
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const isLastApplicationAcceptanceDate = async () => {
    const data = await getLastApplicationAcceptanceDate();

    if (data) {
      if (data.application_apply === true) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
  };

  useEffect(() => {
    isLastApplicationAcceptanceDate();
  }, [dataDate, acceptanceIsLoading]);

  return (
    <Stack sx={{ gap: 2.5, py: 5, px: 2.5, maxWidth: "1130px" }}>
      <BoxTitle title={"Мои заявления"}>
        {isButtonDisabled && (
          <Button onClick={handlers.proceedToReport}>Подать заявление</Button>
        )}
      </BoxTitle>

      <Tabs value={tabValue} onChange={handleChange}>
        <Tab label="Активные" sx={{ textTransform: "capitalize" }} />
        {/*<Tab label="Архив" sx={{ textTransform: "capitalize" }} />*/}
      </Tabs>

      {applicationsIsLoading && (
        <Box sx={{ display: "grid", placeItems: "center", p: 5 }}>
          <CircularProgress />
        </Box>
      )}

      {applicationsIsSuccess && (
        <TableComponent
          rows={applications.data}
          rowCount={applications.meta.total}
          page={page}
          perPage={per_page}
          onChangePage={(page) => setQuery({ page })}
          onChangePerPage={(per_page) => setQuery({ per_page, page: 1 })}
        />
      )}
    </Stack>
  );
};

export default SchoolDeclarations;
