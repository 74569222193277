import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { SelectChangeEvent } from "@mui/material/Select";

import {
  CheckListDBFInterface,
  AddConclusionProps,
} from "../../types/modalTypes";

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function AddConclusion({
  id,
  open,
  dbfCheckList,
  dbfConclusion,
  onClose,
  submitConslusion,
}: AddConclusionProps) {
  const [type, setType] = useState<number>(dbfConclusion?.type || 1);
  const [checkList, setCheckList] = useState<CheckListDBFInterface>(
    dbfCheckList || {
      nobd_registration: false,
    },
  );

  const handleChange = (event: SelectChangeEvent<number>) => {
    const value = +event.target.value;

    setType(value);

    if (!dbfCheckList)
      setCheckList({
        ...checkList,
        nobd_registration: value === 2 ? false : true,
      });
  };

  useEffect(() => {
    setType(dbfConclusion?.type === 2 ? 2 : 1);
    setCheckList({
      nobd_registration: dbfConclusion?.type === 2 ? false : true,
    });
  }, [dbfConclusion?.type]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Добавить заключение
        </Typography>

        <Box>
          <InputLabel id="">
            Тип заключения <span style={{ color: "red" }}>*</span>
          </InputLabel>

          <Select
            label="Тип заключения"
            value={type}
            required
            onChange={handleChange}
            displayEmpty
            disabled={Boolean(dbfConclusion)}
            sx={{
              display: "flex",
              marginBottom: "24px",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              ".MuiInputBase-input": {
                justifyContent: "flex-start",
                fontSize: "16px",
              },
            }}
          >
            <MenuItem value={1}>Соответствует</MenuItem>
            <MenuItem value={2}>Не соответствует</MenuItem>
          </Select>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{ textAlign: "center", marginBottom: "32px" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {dbfCheckList ? "Проверочный лист" : "Создание проверочного листа"}
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={checkList.nobd_registration}
                  name="nobd_registration"
                />
              }
              label="Проверка регистрации частной организации образования в НОБД"
            />
          </FormGroup>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              width: "45%",
              color: "#2C53F5",
              border: "1px solid #2C53F5",
              backgroundColor: "#FFF",
            }}
            onClick={onClose}
          >
            Отмена
          </Button>

          <Button
            sx={{ width: "45%", color: "#FFF", backgroundColor: "#EC5654" }}
            onClick={() =>
              submitConslusion(
                id,
                checkList.nobd_registration ? 1 : 2,
                checkList,
              )
            }
          >
            Отправить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
