import styled from "@emotion/styled";
import { Circle, Close, Create } from "@mui/icons-material";
import { Box, Button, Checkbox, IconButton, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import React from "react";
import NoRowsOverlay from "../../../components/NoRowsOverlay";
import Pagination from "../../../components/Pagination/Pagination";
import { TableDateTime } from "../../../components/TableDateTime";
import { fincolors } from "../../../styles/theme";
import { IApplication } from "../../../types/application";
import { STATUS_ID } from "../../../utils/constants";
import {
  calculateDaysLeft,
  getStatusColor,
  getUnassignedStyle,
} from "../../../utils/helpers";

const columns: GridColDef[] = [
  {
    field: "id",
    headerClassName: "super-app-theme--header",
    headerName: "№ ID заявки",
    width: 120,
    editable: false,
    renderCell: (params) => (
      <p style={getUnassignedStyle(params.row)}>{`№ ${params.row.id}`}</p>
    ),
  },
  {
    field: "inner_number",
    headerClassName: "super-app-theme--header",
    headerName: "Вх. номер ФЦ",
    width: 120,
    editable: false,
  },
  {
    field: "school_name",
    headerName: "Название школы",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: true,
  },
  {
    field: "created_at",
    headerName: "Дата поступления",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: true,
    renderCell: (params) => (
      <TableDateTime
        dateTimeString={params.row.created_at}
        params={params.row}
      />
    ),
  },
  {
    field: "review_period",
    headerName: "Срок рассмотрения",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: true,
    renderCell: (params) => {
      const { text, color } = calculateDaysLeft(params.row.review_period, true);

      return (
        <div style={{ whiteSpace: "pre-line" }}>
          <Typography
            sx={{
              color,
              fontSize: 14,
              fontWeight: getUnassignedStyle(params.row),
            }}
          >
            {params.row.review_period}
          </Typography>

          {[
            STATUS_ID.approved,
            STATUS_ID.rejected,
            STATUS_ID.returned,
          ].includes(params.row.status.id) ? null : (
            <Typography
              variant="body2"
              sx={{ color, fontWeight: getUnassignedStyle(params.row) }}
            >
              {text}
            </Typography>
          )}
        </div>
      );
    },
  },
  {
    field: "organization_fields",
    headerName: "Отправитель / БИН",
    headerClassName: "super-app-theme--header",
    width: 130,
    editable: true,
    renderCell: (params) => (
      <Box>
        <Typography
          sx={{
            color: fincolors.text.primary,
            fontSize: 14,
            fontWeight: getUnassignedStyle(params.row),
          }}
        >
          {params.row.organization_fields.organization_name}
        </Typography>
        <Typography
          sx={{
            color: fincolors.text.secondary,
            fontSize: 12,
            fontWeight: getUnassignedStyle(params.row),
          }}
        >
          {params.row.organization_fields.official_number}
        </Typography>
      </Box>
    ),
  },
  {
    field: "displayed_status",
    headerName: "Статус",
    headerClassName: "super-app-theme--header",
    width: 250,
    editable: true,
    renderCell: (params) => (
      <div
        style={{
          borderRadius: "36px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          <Circle
            sx={{
              width: "12px",
              height: "12px",
              color: getStatusColor(params.row.status.id),
              marginRight: "8px",
            }}
          />
          <Typography
            sx={{
              display: "flex",
              whiteSpace: "pre-line",
              fontSize: 12,
              width: 165,
            }}
          >
            {params.row?.displayed_status}
          </Typography>
        </span>
        <Typography
          sx={{
            display: "flex",
            whiteSpace: "pre-line",
            fontSize: 12,
            marginLeft: "20px",
          }}
        >
          {params?.row.status.id === STATUS_ID.onSchoolRework ||
          params?.row.status.id === STATUS_ID.rejected ? null : (
            <span>{params.row.current_executor?.organization_name}</span>
          )}
        </Typography>
      </div>
    ),
  },
  {
    field: "type",
    headerClassName: "super-app-theme--header",
    headerName: "Тип заявки",
    width: 220,
    editable: true,
    renderCell: (params) =>
      params.row.type === 1 ? "Основной перечень" : "Дополнительный перечень",
  },
];

interface StyledDataGridProps {
  page: number;
  perPage: number;
  rows: IApplication[];
  rowCount: number;
  onChangePage(page: number): void;
  onChangePerPage(perPage: number): void;

  selectedRows: GridRowId[];
  isBoxShow: boolean;
  isChecked: boolean;
  onCheckboxChange: () => void;
  handleCellClick: (params: any) => void;
  handleBoxAreaClose: () => void;
  handleSelectionModelChange: (
    rowSelectionModel: GridRowSelectionModel,
    details: any,
  ) => void;
  handleClickSelectExecutors: () => void;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  height: "auto",
  border: "2px solid #DFE2EC",
  borderRadius: "8px",
  overflow: "hidden",
  maxWidth: "100%",
  "& .unassigned": {
    fontWeight: "500",
  },
  "& .MuiDataGrid-root": {
    // border: "2px solid red",
    borderRadius: "8px",
    overflow: "hidden",
    height: "auto",
  },
  "& .MuiDataGrid-overlay": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70px",
  },
  "& .MuiDataGrid-overlayWrapper": {
    display: "inline-block",
    height: "70px",
  },
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-checkboxInput": {
      width: "15px",
      height: "15px",
      "& svg": {
        fill: "#111520",
        width: "15px",
        height: "15px",
        borderRadius: "5px",
      },
    },
  },
  "& .MuiDataGrid-checkboxInput": {
    width: "15px",
    height: "15px",
    "& svg": {
      fill: "#DFE2EC",
      width: "15px",
      height: "15px",
      borderRadius: "5px",
    },
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    backgroundColor: "#FFF",
  },

  "& .Mui-checked": {
    fill: "#DFE2EC",
    width: "15px",
    height: "15px",
    "& svg": {
      fill: "#2C53F5",
    },
  },
  "& .MuiDataGrid-cell": {
    fontSize: "14px",
    padding: "10px",
    backgroundColor: "#FFF",
    height: "40px",
    whiteSpace: "normal",
    overflow: "auto",
    textOverflow: "clip",
    fontWeight: "",
  },

  "& .MuiDataGrid-colCellTitle": {
    color: "#111520",
    fontWeight: "medium",
    backgroundColor: "#DFE2EC",
    height: "40px",
  },

  "& .MuiDataGrid-rowSelected": {
    backgroundColor: "red",
    height: "13px",
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },

  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    overflow: "auto",
    lineHeight: "120%",
    whiteSpace: "normal",
    height: "100%",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiDataGrid-footerContainer": {
    display: "none",
  },
}));

const DirectorDataGrid: React.FC<StyledDataGridProps> = (props) => {
  const {
    page,
    perPage,
    rows,
    rowCount,
    onChangePage,
    onChangePerPage,

    selectedRows,
    isChecked,
    onCheckboxChange,
    handleSelectionModelChange,
    handleCellClick,
    isBoxShow,
    handleBoxAreaClose,
    handleClickSelectExecutors,
  } = props;

  return (
    <>
      {isBoxShow && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            border: "2px solid #DFE2EC",
            minHeight: "45px",
            paddingLeft: "10px",
            backgroundColor: fincolors.primary.main,
          }}
        >
          <Checkbox
            checked={isChecked}
            onChange={onCheckboxChange}
            sx={{
              paddingLeft: "6px",
              "& .MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
                fill: "white",
                borderRadius: "5px",
                borderColor: "white",
                borderWidth: "1px",
                "& svg": {
                  fill: isChecked ? "#2C53F5" : "#DFE2EC",
                },
              },
            }}
          />

          <Box>
            <Button
              variant="outlined"
              disabled={true}
              sx={{
                fontSize: "14px",
                height: "32px",
                padding: "6px, 10px, 6px, 10px",
              }}
              onClick={handleClickSelectExecutors}
              endIcon={<Create />}
            >
              Распределить на исполнителей
            </Button>

            <IconButton color="inherit" onClick={handleBoxAreaClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      )}

      <StyledDataGrid
        getCellClassName={(params) => {
          if (!!params.row.current_executor?.organization_name === false) {
            if (
              params.row.status.id !== STATUS_ID.approved &&
              params.row.status.id !== STATUS_ID.rejected
            ) {
              return "unassigned";
            }
          }
          return "";
        }}
        rows={rows}
        columns={columns}
        disableColumnMenu
        checkboxSelection
        onRowSelectionModelChange={handleSelectionModelChange}
        rowSelectionModel={selectedRows}
        onCellClick={handleCellClick}
        hideFooterSelectedRowCount
        pageSizeOptions={[20, 10]}
        columnHeaderHeight={isBoxShow ? 0 : 45}
        rowCount={rowCount}
        slots={{ noRowsOverlay: NoRowsOverlay }}
      />

      <Pagination
        rowCount={rowCount}
        rowsPerPage={perPage}
        currentPage={page}
        onPageChange={(event, page) => onChangePage(page + 1)}
        onRowsPerPageChange={(event) =>
          onChangePerPage(Number(event.target.value))
        }
      />
    </>
  );
};

export default DirectorDataGrid;
