import { useState, useEffect } from "react";

import { getNotifications } from "../services/useEndpoint";
import { useAppSelector } from "../store/hooks";
import {
  selectCurrentPage,
  selectMailsCurrentPage,
  selectPerPage,
} from "../store/reducers/currentPageSlice";
import { selectFilter } from "../store/reducers/filterSlice";

const useNotificationData = () => {
  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const currentPage = useAppSelector(selectCurrentPage);
  const perPage = useAppSelector(selectPerPage);
  // const mailPage = useAppSelector(selectMailsCurrentPage);
  const filterParams = useAppSelector(selectFilter);

  const getData = () => {
    let params = {
      page: currentPage + 1,
      per_page: perPage,
      // ...filterParams,
    };
    // if (params.date === "") {
    //   delete params.date;
    // }
    // if (params.search === "") {
    //   delete params.search;
    // }
    getNotifications(params)
      .then((res) => {
        if (res) {
          setData(res?.notifications?.data);
          setLastPage(res?.notifications?.last_page);
          setTotalCount(res?.notifications?.total);
        } else {
          console.error(
            "Notifications data failed. Please check your credentials.",
          );
        }
      })
      .catch((error) => {
        console.error(
          "An error occurred during Notifications data download:",
          error,
        );
      });
  };

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      console.error(
        "An error occurred during Notifications data download:",
        error,
      );
    }
  }, [currentPage, perPage]);

  return { data, totalCount, lastPage };
};

export default useNotificationData;
