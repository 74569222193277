import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import BoxCard from "../../../components/BoxCard";
import { DeleteOutline } from "@mui/icons-material";

interface School {
  school_name: string;
  actual_address: string;
  organization_id: number;
  id: number;
}

interface SchoolBlockProps {
  index: number;
  value: School;
  touched?: {
    school_name?: boolean;
    actual_address?: boolean;
  };
  errors?: {
    school_name?: string;
    actual_address?: string;
  };
  editMode: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  deleteSchoolItem:(id:number)=>void
}

const SchoolBlock: React.FC<SchoolBlockProps> = ({
   index,
  value,
  touched,
  errors,
  editMode,
  handleChange,
  deleteSchoolItem
}) => {
  return (
    <Box>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ marginBottom: "12px" }}>
        <Typography sx={{ textTransform: "uppercase"}}>
          Сведения школы {value.school_name}
        </Typography>
        {editMode && <Button variant="text" onClick={()=>{deleteSchoolItem(value.id)}}>
          <DeleteOutline /> Удалить школу
        </Button>}
      </Stack>

      <BoxCard>
        <Stack direction={"row"} gap={2}>
          <TextField
          name={`school_name`}
            value={value.school_name}
            label="Название школы"
            placeholder="Введите название"
            error={
              Boolean(touched?.school_name && errors?.school_name) || false
            }
            helperText={(touched?.school_name && errors?.school_name) || false}
            onChange={handleChange}
            disabled={editMode === false}
          />

          <TextField
            name={`actual_address`}
            value={value.actual_address}
            label="Фактический адрес"
            placeholder="Введите адрес"
            error={
              Boolean(touched?.actual_address && errors?.actual_address) ||
              false
            }
            helperText={
              (touched?.actual_address && errors?.actual_address) || false
            }
            onChange={handleChange}
            disabled={editMode === false}
          />
        </Stack>
      </BoxCard>
    </Box>
  );
};

export default SchoolBlock;
