import { Download, TimelineOutlined } from "@mui/icons-material";
import { Button, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import BoxTitle from "../../components/BoxTitle";
import DeclarationHistory from "../../components/DeclarationHistory";
import LoadingBar from "../../components/LoadingBar";
import { EDS } from "../../components/Modals/EDS";
import ExecutorReworkModal from "../../components/Modals/ExecutorReworkModal";
import RejectionModal from "../../components/Modals/RejectionModal";
import SchoolReworkModal from "../../components/Modals/SchoolReworkModal";
import {
  acceptRequest,
  decline,
  executorRework,
  getDocumentForSign,
  getSchoolDeclarationById,
  getSignersDocument,
  sendToSchoolRework,
  signDocument,
} from "../../services/useEndpoint";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectCurrentUser } from "../../store/reducers/currentUserReducer";
import { setSearchFilter } from "../../store/reducers/filterSlice";
import { CheckListInterface } from "../../types/modalTypes";
import { COMMENTS_TYPES } from "../../utils/constants";
import { downloadFile } from "../../utils/helpers";
import { NcaLayer } from "../../utils/nca-layer";
import CheckListModal from "../SpecialistDeclarationsData/components/CheckListModal";
import MainSpecialistDeclarationInner from "./components/MainSpecialistDeclarationInner";

export default function MainSpecialistsDeclarationsData() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);

  const [EDSopen, setEDSopen] = React.useState(false);

  const [checkListModalOpen, setCheckListModalOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);
  const [reworkSchoolOpen, setReworkSchoolOpen] = useState(false);
  const [reworkExecutorOpen, setReworkExecutorOpen] = useState(false);

  const [data, setData] = useState<any>();
  const [isBoardingSchool, setIsBoardingSchool] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [signersDocumentLoading, setSignersDocumentLoading] = useState(false);
  const [isSign, setIsSign] = useState(false);

  function fetchDeclarationData() {
    getSchoolDeclarationById(id as string)
      .then((data) => {
        setData(data.data);
        setIsBoardingSchool(data.data.boarding_school);
      })
      .catch((error) => {
        toast.error(
          `Произошла ошибка при загрузке данных. Ошибка: ${error.message}`,
        );
      });
  }

  useEffect(() => {
    fetchDeclarationData();
  }, [id]);

  const goToDeclarationsPage = () => {
    navigate("/main-specialist/declarations");
    dispatch(setSearchFilter(""));
  };

  async function handleEDS(base64string: string) {
    try {
      return await NcaLayer.sign(base64string);
    } catch (error) {
      toast.error("При подписании документа произошла ошибка");
    }
  }

  const submitExecutorRework = async (id: number, comment: string) => {
    if (!id) return;

    try {
      setIsLoading(true);

      await executorRework(id, comment);
      toast.success("Заявка была отправлена на доработку исполнителю");
      setReworkExecutorOpen(false);
      fetchDeclarationData();
      goToDeclarationsPage();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Не удалось отправить на доработку исполнителю",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitSchoolRework = async (
    id: number,
    comment: string,
    type: Array<number>,
    checkListprop?: CheckListInterface,
  ) => {
    if (!id) return;

    try {
      setIsLoading(true);

      const base64string = await getDocumentForSign({
        filePath: data.check_list.pdf,
      });
      const signature = await handleEDS(base64string);
      if (!signature) return;

      await signDocument(signature, data.check_list.pdf);
      await sendToSchoolRework(id, comment, type, checkListprop);
      toast.success("Заявка была отправлена директору");
      setReworkSchoolOpen(false);
      fetchDeclarationData();
      goToDeclarationsPage();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Не удалось отправить на доработку школе",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitApprove = async () => {
    try {
      setIsLoading(true);

      const base64string = await getDocumentForSign({
        filePath: data.check_list.pdf,
      });
      const signature = await handleEDS(base64string);
      if (!signature) return;

      await signDocument(signature, data.check_list.pdf);
      await acceptRequest(data.id);
      toast.success("Заявка была отправлена на согласование");
      fetchDeclarationData();
      goToDeclarationsPage();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Не удалось отправить на согласование",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitDecline = async (id: number, type: number[]) => {
    if (!id) return;

    try {
      setIsLoading(true);

      const base64string = await getDocumentForSign({
        filePath: data.check_list.pdf,
      });
      const signature = await handleEDS(base64string);
      if (!signature) return;

      await signDocument(signature, data.check_list.pdf);
      await decline(Number(id), type);
      toast.success("Заявка была отправлена на отказ директору");
      setDeclineOpen(false);
      fetchDeclarationData();
      goToDeclarationsPage();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "Не удалось отправить на отказ",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetSignersDocument = async () => {
    setSignersDocumentLoading(true);

    try {
      const document = await getSignersDocument({
        file_path: data.check_list.pdf,
      });
      if (!document) {
        toast.error("Документ не был подписан");
        return;
      }
      downloadFile(document, "Проверочный лист АПД.pdf", "application/pdf");
      setIsSign(true);
    } catch (error) {
      toast.error("Документ не был подписан");
      return;
    } finally {
      setSignersDocumentLoading(false);
    }
  };

  return (
    <div style={{ padding: "40px 20px", maxWidth: "1130px" }}>
      <BoxTitle
        title={
          "Заявление на размещение гос. образовательного заказа на среднее образование"
        }
      >
        <FormControl fullWidth>
          {user.id === data?.current_executor?.id && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Согласование"
              value={""}
              displayEmpty
              sx={{
                width: "300px",
                backgroundColor: "#2C53F5",
                color: "#FFF",
                fill: "#FFF",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
                ".MuiInputBase-input": {
                  justifyContent: "center",
                },
              }}
            >
              <MenuItem value="" disabled sx={{ display: "none" }}>
                Согласование
              </MenuItem>

              {Boolean(
                data?.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.schoolAprrove,
                ),
              ) && (
                <MenuItem onClick={() => setCheckListModalOpen(true)}>
                  Согласовать
                </MenuItem>
              )}

              <MenuItem onClick={() => setReworkExecutorOpen(true)}>
                На доработку исполнителю
              </MenuItem>

              {Boolean(
                data?.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.schoolRework,
                ),
              ) &&
                data?.reworked !== true && (
                  <MenuItem onClick={() => setReworkSchoolOpen(true)}>
                    На доработку заявителю
                  </MenuItem>
                )}

              {Boolean(
                data?.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.schoolDecline,
                ),
              ) &&
                data?.reworked && (
                  <MenuItem onClick={() => setDeclineOpen(true)}>
                    Отказать
                  </MenuItem>
                )}
            </Select>
          )}
        </FormControl>
      </BoxTitle>

      <DeclarationHistory applicationId={data?.id}>
        {(open) => (
          <Button
            variant={"outlined"}
            size={"small"}
            onClick={open}
            sx={{ mb: 1 }}
            startIcon={
              <TimelineOutlined
                style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
              />
            }
          >
            История
          </Button>
        )}
      </DeclarationHistory>

      {isSign && data?.check_list && data?.check_list?.pdf && (
        <Button
          variant={"outlined"}
          size={"small"}
          onClick={handleGetSignersDocument}
          disabled={signersDocumentLoading}
          sx={{ mb: 2 }}
          startIcon={
            <Download
              style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
            />
          }
        >
          Проверочный лист АПД
        </Button>
      )}

      <MainSpecialistDeclarationInner
        isBoardingSchool={isBoardingSchool}
        data={data}
      />

      <EDS open={EDSopen} submit={() => {}} onClose={() => setEDSopen(false)} />
      <LoadingBar
        open={isLoading}
        title={"Подождите, идет загрузка..."}
        onCancel={() => setIsLoading(false)}
      />

      <RejectionModal
        checkList={data?.check_list}
        commentData={data?.comments}
        id={data?.id}
        open={declineOpen}
        submit={submitDecline}
        onClose={() => setDeclineOpen(false)}
      />

      <ExecutorReworkModal
        commentData={data?.comments}
        id={data?.id}
        open={reworkExecutorOpen}
        submit={submitExecutorRework}
        onClose={() => setReworkExecutorOpen(false)}
      />

      <SchoolReworkModal
        commentData={data?.comments}
        id={data?.id}
        open={reworkSchoolOpen}
        submit={submitSchoolRework}
        checkList={data?.check_list}
        onClose={() => setReworkSchoolOpen(false)}
      />

      <CheckListModal
        id={data?.id}
        open={checkListModalOpen}
        submit={submitApprove}
        onClose={() => setCheckListModalOpen(false)}
      />
    </div>
  );
}
