import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormikErrors, FormikTouched } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import BoxCard from "../../../components/BoxCard";
import MagicTable from "../../../components/MagicTable";
import { getArea, getLocality, getRegion } from "../../../services/useEndpoint";
import { useAppDispatch } from "../../../store/hooks";
import {
  setBoardingCadastralNumber,
  setBoardingEncumbranceDate,
  setBoardingPredictiveContingentDataTable,
  setBoardingSchool,
  setBoardingStudentsDataTable,
  setBoardingTargetValue,
  setCadastralNumber,
  setEncumbranceDate,
  setPredictiveContingentDataTable,
  setStudentsDataTable,
  setTargetValue,
} from "../../../store/reducers/createDeclaration";
import {
  BoardingTableRows,
  SchoolTableRows,
  TableRowsMini,
} from "../../../types/tableRowsTypes";
// import { Location } from "../../../types/types";
import anAxiosApi from "../../../services/axiosApi";
import { BREAKPOINT_DATES } from "../../../utils/constants";
import TableDeclarationMini from "./TableDeclarationMini/TableDeclarationMini";
import TableDeclarationsSchool from "./TableDeclarationSchool/TableDeclarationSchool";

interface Region {
  id: number;
  name: string;
}
interface Area {
  id: number;
  name: string;
}
interface Locality {
  id: number;
  name: string;
  parent_id: null | string;
  kato_id: number;
}
interface FormValues {
  [key: string]: any;
  tuition_fee: number | string;
  organization_name: string;
  city: string;
  boarding_school_region: Region | null;
  boarding_school_area: Area | null;
  boarding_school_locality: Locality | null;
  region: Region | null;
  area: Area | null;
  locality: Locality | null;
  kato_id: number | null;
  street: string;
  house_number: string;
  school_name: string;
  place_count: number;
  exploitation_year: string;
  elementary_education: boolean | number;
  basic_secondary_education: boolean | number;
  basic_general_education: boolean | number;
  integrated_education: boolean | number;
  national_educational_db_registration: string;
  state_procurements_registration: string;
  boarding_school: string;
  student_contingent: string;
  private_organization_exploitation_type: string;
  boarding_school_government_order: number;
  private_organization_place_count: number;
  boarding_school_newly_count: number;
  boarding_school_street: string;
  boarding_school_house_number: string;
  boarding_school_place_count: number;
  boarding_school_exploitation_year: string;
  boarding_school_exploitation_type: string;
  _checkboxGroup?: string;
  license_number: string;
  nobd_id: string;
}

interface FormikProp {
  setFieldValue(arg0: string, isChecked: any): unknown;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
  values: FormValues;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
}
const targetDate = new Date(BREAKPOINT_DATES.school);

interface DeclarationStep2Props {
  formik: FormikProp;
  rowsTable: SchoolTableRows[];
  setRowsTable: (e: SchoolTableRows[]) => void;
  rowsTableAll: SchoolTableRows[];
  setRowsTableAll: (e: SchoolTableRows[]) => void;
  rowsTableTwo: SchoolTableRows[];
  setRowsTableTwo: (e: SchoolTableRows[]) => void;
  rowsTableTwoAll: SchoolTableRows[];
  setRowsTableTwoAll: (e: SchoolTableRows[]) => void;
  boardingRowsTable: BoardingTableRows[];
  setBoardingRowsTable: (e: BoardingTableRows[]) => void;
  boardingRowsTableTwo: BoardingTableRows[];
  setBoardingRowsTableTwo: (e: BoardingTableRows[]) => void;
  rowsTableMini: TableRowsMini[];
  setRowsTableMini: (e: TableRowsMini[]) => void;
  rowsTableMiniTwo: TableRowsMini[];
  setRowsTableMiniTwo: (e: TableRowsMini[]) => void;
  isDisabled: boolean;
  isSubmitted: boolean;
  resetIsSubmitted: () => void;
  isInputDisabled: boolean;
}

const DeclarationStep2: React.FC<DeclarationStep2Props> = ({
  formik,
  rowsTable,
  setRowsTable,
  rowsTableAll,
  setRowsTableAll,
  rowsTableTwo,
  setRowsTableTwo,
  rowsTableTwoAll,
  setRowsTableTwoAll,
  boardingRowsTable,
  setBoardingRowsTable,
  boardingRowsTableTwo,
  setBoardingRowsTableTwo,
  rowsTableMini,
  setRowsTableMini,
  rowsTableMiniTwo,
  setRowsTableMiniTwo,
  isDisabled,
  isSubmitted,
  resetIsSubmitted,
  isInputDisabled,
}) => {
  const [regions, setRegions] = useState<Region[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [locality, setLocality] = useState<Locality[]>([]);
  const [regionsBoarding, setRegionsBoarding] = useState<Region[]>([]);
  const [areasBoarding, setAreasBoarding] = useState<Area[]>([]);
  const [localityBoarding, setLocalityBoarding] = useState<Locality[]>([]);
  const [selectedCity, setSelectedCity] = useState<undefined | string | null>(
    "",
  );
  const [mrp, setMRP] = useState<number | null>(null);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setBoardingStudentsDataTable(boardingRowsTable));
  }, [boardingRowsTable, formik.values.boarding_school]);
  useEffect(() => {
    dispatch(setStudentsDataTable(rowsTableAll));
  }, [rowsTable, rowsTableAll, formik.values.boarding_school]);

  useEffect(() => {
    dispatch(setBoardingPredictiveContingentDataTable(boardingRowsTableTwo));
  }, [boardingRowsTableTwo, formik.values.boarding_school]);
  useEffect(() => {
    dispatch(setPredictiveContingentDataTable(rowsTableTwoAll));
  }, [rowsTableTwo, rowsTableTwoAll, formik.values.boarding_school]);

  useEffect(() => {
    dispatch(setTargetValue(rowsTableMini[0].target_value));
    dispatch(setEncumbranceDate(rowsTableMini[0].encumbrance_date));
    dispatch(setCadastralNumber(rowsTableMini[0].cadastral_number));
  }, [rowsTableMini]);

  useEffect(() => {
    dispatch(setBoardingTargetValue(rowsTableMiniTwo[0].target_value));
    dispatch(setBoardingEncumbranceDate(rowsTableMiniTwo[0].encumbrance_date));
    dispatch(setBoardingCadastralNumber(rowsTableMiniTwo[0].cadastral_number));
  }, [rowsTableMiniTwo]);

  const handleClearInput = (fieldName: string, initialValue = "0") => {
    if (
      formik.values[fieldName] == initialValue ||
      formik.values[fieldName] === ""
    ) {
      formik.setFieldValue(fieldName, "");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const regionData = await getRegion();
        if (regionData) {
          setRegions(regionData.data);
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const regionData = await getRegion();
        if (regionData) {
          setRegionsBoarding(regionData.data);
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, []);
  async function fetchMRP(): Promise<number | null> {
    try {
      const response = (await anAxiosApi("/calc_mrp")).data;
      return response.data[0].mrp_value;
    } catch (error) {
      console.error("Ошибка при получении МРП:", error);
      return null;
    }
  }
  // обработчик для размера родительской платы за обучение
  let notificationShown = false;
  const handleTuitionFeeChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    let { value } = event.target;
    value = value.replace(/\D/g, "");
    const maxMRPValue = mrp !== null ? mrp : await fetchMRP();
    if (maxMRPValue === null) {
      console.error("Не удалось получить значение MRP");
      return;
    }
    const maxTuitionFee = formik.values.integrated_education
      ? 2400 * maxMRPValue
      : 1200 * maxMRPValue;
    const minTuitionFee = 1200 * maxMRPValue;
    if (
      (formik.values.integrated_education && Number(value) > maxTuitionFee) ||
      (!formik.values.integrated_education && Number(value) > minTuitionFee)
    ) {
      // Проверяем, было ли уже показано уведомление
      if (!notificationShown) {
        toast.error(
          'Не корректный ввод значения в поле "Размер родительской платы за обучение, в тенге в год"',
        );
        notificationShown = true;
      }
    } else {
      formik.setFieldValue("tuition_fee", value);
    }
  };

  useEffect(() => {
    async function fetchAndSetMRP() {
      const mrpValue = await fetchMRP();
      setMRP(mrpValue);
    }
    fetchAndSetMRP();
  }, []);

  // @ts-ignore
  return (
    <form id="declarations_step_2" onSubmit={formik.handleSubmit}>
      <Stack direction={"column"} spacing={3}>
        <BoxCard title={"Общие данные"}>
          <Typography
            sx={{
              marginBottom:
                isSubmitted && formik.errors._checkboxGroup ? "0px" : "10px",
            }}
          >
            Поставьте галочку напротив одного или нескольких уровней
          </Typography>
          {isSubmitted && formik.errors._checkboxGroup && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {formik.errors._checkboxGroup}
            </Typography>
          )}
          <Stack direction={"column"} spacing={3}>
            <Grid container spacing={1}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="elementary_education"
                      checked={Boolean(formik.values.elementary_education)}
                      onChange={() => {
                        formik.setFieldValue(
                          "elementary_education",
                          formik.values.elementary_education === 1 ? 0 : 1,
                        );
                        if (formik.values.elementary_education === 0)
                          return formik.setFieldValue(
                            "integrated_education",
                            0,
                          );
                        resetIsSubmitted();
                      }}
                      disabled={isInputDisabled}
                    />
                  }
                  label="Начального образования"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="basic_secondary_education"
                      checked={Boolean(formik.values.basic_secondary_education)}
                      onChange={() => {
                        formik.setFieldValue(
                          "basic_secondary_education",
                          formik.values.basic_secondary_education === 1 ? 0 : 1,
                        );

                        resetIsSubmitted();
                        if (formik.values.basic_secondary_education === 0)
                          return formik.setFieldValue(
                            "integrated_education",
                            0,
                          );
                      }}
                      disabled={isInputDisabled}
                    />
                  }
                  label="Основного среднего образования"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="basic_general_education"
                      checked={Boolean(formik.values.basic_general_education)}
                      onChange={() => {
                        formik.setFieldValue(
                          "basic_general_education",
                          formik.values.basic_general_education === 1 ? 0 : 1,
                        );

                        resetIsSubmitted();

                        if (formik.values.basic_general_education === 0)
                          return formik.setFieldValue(
                            "integrated_education",
                            0,
                          );
                      }}
                      disabled={isInputDisabled}
                    />
                  }
                  label="Общего среднего образования"
                />
              </Grid>
              <Grid item xs={9}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="integrated_education"
                      checked={Boolean(formik.values.integrated_education)}
                      onChange={async () => {
                        formik.setFieldValue(
                          "integrated_education",
                          formik.values.integrated_education === 1 ? 0 : 1,
                        );
                        resetIsSubmitted();
                        const newValue =
                          formik.values.integrated_education === 1 ? 0 : 1;
                        console.log("newValue", newValue);
                        const mrpValue = await fetchMRP();
                        if (mrpValue !== null) {
                          const maxTuitionFee = newValue
                            ? 2400 * mrpValue
                            : 1200 * mrpValue;
                          formik.setFieldValue(
                            "tuition_fee",
                            Math.min(
                              Number(formik.values.tuition_fee),
                              maxTuitionFee,
                            ).toString(),
                          );
                          if (formik.values.integrated_education === 1) {
                            formik.setFieldValue("tuition_fee", "");
                          }
                        } else {
                          console.error("Не удалось получить значение MRP");
                        }

                        if (formik.values.integrated_education === 0) {
                          formik.setFieldValue("elementary_education", 0);
                          formik.setFieldValue("basic_secondary_education", 0);
                          formik.setFieldValue("basic_general_education", 0);
                        }
                      }}
                      disabled={isInputDisabled}
                    />
                  }
                  label="По самостоятельно разработанным интегрированным образовательным программам, прошедшим авторизацию в Орг. Межд. Бак-та или межд. институциональную аккредитацию образования"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  name="tuition_fee"
                  value={formik.values.tuition_fee}
                  label={
                    <span style={{ fontSize: "12px" }}>
                      Размер родительской платы за обучение, тенге в год (макс.{" "}
                      {formik.values.integrated_education ? "2400" : "1200"}{" "}
                      МРП)
                    </span>
                  }
                  sx={{
                    position: "relative",
                    "& label": {
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    },
                  }}
                  placeholder="Размер родительской платы за обучение, тенге в год"
                  error={
                    Boolean(
                      formik?.touched?.tuition_fee &&
                        formik?.errors?.tuition_fee,
                    ) || false
                  }
                  helperText={
                    formik?.touched?.tuition_fee && formik?.errors?.tuition_fee
                      ? formik.errors.tuition_fee
                      : ""
                  }
                  InputProps={{
                    className: formik.errors.tuition_fee
                      ? 'Не корректный ввод значения в поле ""Размер родительской платы за обучение, в тенге в год'
                      : "",
                  }}
                  onChange={handleTuitionFeeChange}
                  onBlur={formik.handleBlur}
                  onClick={() => {
                    handleClearInput("tuition_fee");
                  }}
                  disabled={isInputDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Регистрация в Нац.образовательной базе данных (НОБД)
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.national_educational_db_registration}
                    label="Регистрация в Нац.образовательной базе данных (НОБД)"
                    onChange={(event) => {
                      const { value } = event.target;
                      formik.setFieldValue(
                        "national_educational_db_registration",
                        value,
                      );
                    }}
                    disabled={isInputDisabled}
                  >
                    <MenuItem value={"Есть"}>Есть</MenuItem>
                    <MenuItem value={"Нет"}>Нет</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Регистрация на веб-портале государственных закупок
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.state_procurements_registration}
                    label="Регистрация на веб-портале государственных закупок"
                    onChange={(event) => {
                      const { value } = event.target;
                      formik.setFieldValue(
                        "state_procurements_registration",
                        value,
                      );
                    }}
                    disabled={isInputDisabled}
                  >
                    <MenuItem value={"Есть"}>Есть</MenuItem>
                    <MenuItem value={"Нет"}>Нет</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Наличие интерната при школе
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.boarding_school}
                    label="Наличие интерната при школе"
                    onChange={(event) => {
                      const { value } = event.target;
                      formik.setFieldValue("boarding_school", value);
                      dispatch(setBoardingSchool(value));
                    }}
                    disabled={isInputDisabled}
                  >
                    <MenuItem value={"Есть"}>Есть</MenuItem>
                    <MenuItem value={"Нет"}>Нет</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Фактический контингент учащихся
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.student_contingent}
                    label="Фактический контингент учащихся"
                    onChange={(event) => {
                      const { value } = event.target;
                      console.log(event.target);
                      formik.setFieldValue("student_contingent", value);
                    }}
                    disabled={isInputDisabled}
                  >
                    <MenuItem value={"Городская школа"}>
                      Городская школа
                    </MenuItem>
                    <MenuItem value={"Сельская школа"}>Сельская школа</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4} position={"relative"}>
                <Typography
                  sx={{
                    position: "absolute",
                    top: "60%",
                    translateY: "-60%",
                  }}
                >
                  №
                </Typography>
                <TextField
                  name="license_number"
                  value={formik.values.license_number}
                  label="Номер лицензии (кроме АОО)"
                  placeholder="Введите номер лицензии"
                  error={
                    Boolean(
                      formik?.touched?.license_number &&
                        formik?.errors?.license_number,
                    ) || false
                  }
                  helperText={
                    (formik?.touched?.license_number &&
                      formik?.errors?.license_number) ||
                    false
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isInputDisabled}
                  sx={{ paddingLeft: "40px" }}
                />
              </Grid>
              <Grid item xs={4} position={"relative"}>
                <Typography
                  sx={{
                    position: "absolute",
                    top: "60%",
                    translateY: "-60%",
                  }}
                >
                  ID
                </Typography>
                <TextField
                  name="nobd_id"
                  value={formik.values.nobd_id}
                  label="Номер ID НОБД"
                  placeholder="Введите Номер ID в НОБД"
                  error={Boolean(formik?.errors?.nobd_id) || false}
                  helperText={formik?.errors?.nobd_id || false}
                  sx={{ paddingLeft: "20px" }}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const numericValue = inputValue.replace(/\D/g, "");
                    formik.setFieldValue("nobd_id", numericValue);
                  }}
                  onBlur={formik.handleBlur}
                  disabled={isInputDisabled}
                />
              </Grid>
            </Grid>
          </Stack>
        </BoxCard>

        <BoxCard
          title={"Данные по учащимся"}
          subTitle={"По состоянию на дату подачи заявления:"}
        >
          <TableDeclarationsSchool
            rows={rowsTable}
            setRows={setRowsTable}
            setRowsAll={setRowsTableAll}
            isInputDisabled={isInputDisabled}
            isCurrent={true}
          />
          {/*<Button sx={{ width: '198px', marginTop: '16px' }}>*/}
          {/*  Сохранить*/}
          {/*</Button>*/}
        </BoxCard>
        <BoxCard
          title={""}
          subTitle={
            "Прогнозный контингент учащихся на 1 сентября предстоящего учебного года:"
          }
          sx={{ marginTop: "14px" }}
        >
          <TableDeclarationsSchool
            rows={rowsTableTwo}
            setRows={setRowsTableTwo}
            setRowsAll={setRowsTableTwoAll}
            isInputDisabled={isInputDisabled}
            isCurrent={false}
          />
          {/*<Button sx={{ width: '198px', marginTop: '16px' }}>*/}
          {/*  Сохранить*/}
          {/*</Button>*/}
        </BoxCard>

        {formik.values.boarding_school === "Есть" && (
          <>
            <BoxCard
              title={"Данные по учащимся интернат"}
              subTitle={"По состоянию на дату подачи заявления:"}
            >
              <MagicTable
                rows={boardingRowsTable}
                setRows={setBoardingRowsTable}
                // setRowsAll={setBoardingRowsTableAll}
                isInputDisabled={isInputDisabled}
                isCurrent={true}
              />
              {/*<Button sx={{ width: '198px', marginTop: '16px' }}>*/}
              {/*  Сохранить*/}
              {/*</Button>*/}
            </BoxCard>
            <BoxCard
              title={""}
              subTitle={
                "Прогнозный контингент учащихся на 1 сентября предстоящего учебного года:"
              }
            >
              <MagicTable
                rows={boardingRowsTableTwo}
                setRows={setBoardingRowsTableTwo}
                // setRowsAll={setBoardingRowsTableTwoAll}
                isInputDisabled={isInputDisabled}
                isCurrent={false}
              />
              {/*<Button sx={{ width: '198px', marginTop: '16px' }}>*/}
              {/*  Сохранить*/}
              {/*</Button>*/}
            </BoxCard>
          </>
        )}
        <BoxCard title={"Характеристика объекта организации образования"}>
          <Grid container spacing={1} alignItems={"flex-end"} rowGap={"10px"}>
            <Grid
              item
              xs={3}
              sx={{ position: "relative", marginBottom: "5px" }}
            >
              <Autocomplete
                value={formik.values.region}
                options={regions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    error={
                      Boolean(
                        formik?.touched?.region && formik?.errors?.region,
                      ) || false
                    }
                    label="Регион"
                  />
                )}
                onChange={async (_, option) => {
                  formik.setFieldValue("region", option);
                  formik.setFieldValue("area", null);
                  formik.setFieldValue("locality", null);
                  const AreasData = option && (await getArea(option.id));
                  if (AreasData) setAreas(AreasData.data);
                }}
                disabled={isInputDisabled}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ position: "relative", marginBottom: "5px" }}
            >
              <Autocomplete
                value={formik.values.area}
                options={areas}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    error={
                      Boolean(formik?.touched?.area && formik?.errors?.area) ||
                      false
                    }
                    label="Район"
                  />
                )}
                onChange={async (_, option) => {
                  formik.setFieldValue("area", option);
                  formik.setFieldValue("locality", null);

                  const localityData = option && (await getLocality(option.id));
                  if (localityData) setLocality(localityData.data);
                }}
                disabled={isInputDisabled}
              />
            </Grid>

            <Grid
              item
              xs={3}
              sx={{ position: "relative", marginBottom: "5px" }}
            >
              <Autocomplete
                options={locality}
                getOptionLabel={(option) => option.name}
                value={formik.values.locality}
                onChange={async (_, option) => {
                  formik.setFieldValue("locality", option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Населенный пункт"
                    variant="outlined"
                    error={Boolean(
                      formik?.touched?.locality && formik?.errors?.locality,
                    )}
                    helperText={
                      formik?.touched?.locality && formik?.errors?.locality
                    }
                    disabled={isInputDisabled}
                  />
                )}
                noOptionsText="Нет доступных вариантов"
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ position: "relative", marginBottom: "5px" }}
            >
              <FormControl fullWidth>
                <TextField
                  label="КАТО"
                  variant="outlined"
                  value={formik.values.locality?.kato_id}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="street"
                value={formik.values.street}
                label="Улица/Микрорайон/Жилой массив и т.п"
                placeholder="Улица/Микрорайон/Жилой массив и т.п"
                error={
                  Boolean(formik?.touched?.street && formik?.errors?.street) ||
                  false
                }
                helperText={
                  (formik?.touched?.street && formik?.errors?.street) || false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
                sx={{
                  position: "relative",
                  "& label": {
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  },
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="house_number"
                value={formik.values.house_number}
                label="№ дома/здания"
                placeholder="№ дома/здания"
                error={
                  Boolean(
                    formik?.touched?.house_number &&
                      formik?.errors?.house_number,
                  ) || false
                }
                helperText={
                  (formik?.touched?.house_number &&
                    formik?.errors?.house_number) ||
                  false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
                sx={{
                  position: "relative",
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                name="school_name"
                value={formik.values.school_name}
                label="Наименование школы (без организационно-правовой формы)"
                placeholder="Наименование школы"
                sx={{
                  position: "relative",
                  "& label": {
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  },
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
                error={
                  Boolean(
                    formik?.touched?.school_name && formik?.errors?.school_name,
                  ) || false
                }
                helperText={
                  (formik?.touched?.school_name &&
                    formik?.errors?.school_name) ||
                  false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="place_count"
                value={formik.values.place_count}
                label="Проектная мощность (ученических мест)"
                placeholder="Проектная мощность (ученических мест)"
                error={
                  Boolean(
                    formik?.touched?.place_count && formik?.errors?.place_count,
                  ) || false
                }
                helperText={
                  (formik?.touched?.place_count &&
                    formik?.errors?.place_count) ||
                  false
                }
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const numericValue = inputValue.replace(/\D/g, "");
                  formik.setFieldValue("place_count", numericValue);
                }}
                onBlur={formik.handleBlur}
                onClick={() => {
                  handleClearInput("place_count");
                }}
                disabled={isInputDisabled}
                sx={{
                  position: "relative",
                  "& label": {
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  },
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="exploitation_year"
                value={formik.values.exploitation_year}
                label="Дата ввода в эксплуатацию "
                placeholder="Дата ввода в эксплуатацию "
                id="exploitation_year"
                type="date"
                // defaultValue="2017-05-24"
                InputLabelProps={{
                  shrink: true,
                }}
                error={
                  Boolean(
                    formik?.touched?.exploitation_year &&
                      formik?.errors?.exploitation_year,
                  ) || false
                }
                helperText={
                  (formik?.touched?.exploitation_year &&
                    formik?.errors?.exploitation_year) ||
                  false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
                sx={{
                  position: "relative",
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
              />
            </Grid>
            {new Date(formik.values.exploitation_year) >
              new Date(BREAKPOINT_DATES.school) && (
              <Box sx={{ mt: "10px" }}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="registered_immutability_agreement"
                        checked={Boolean(
                          formik.values.registered_immutability_agreement,
                        )}
                        onChange={() => {
                          formik.setFieldValue(
                            "registered_immutability_agreement",
                            formik.values.registered_immutability_agreement ===
                              1
                              ? 0
                              : 1,
                          );
                        }}
                        disabled={isInputDisabled}
                      />
                    }
                    label="Наличие зарегистрированного Соглашения о неизменности целевого назначения объекта организации среднего образования (А2)"
                  />
                </Grid>
              </Box>
            )}
          </Grid>
        </BoxCard>
        {formik.values.registered_immutability_agreement ? (
          <BoxCard
            title={
              "Заполняется частной организацией образования с вновь введенными ученическими местами в объекте частной организации образования, введенными в эксплуатацию после 20 июля 2018 года"
            }
          >
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={4}>
                <TextField
                  InputProps={{
                    style: {
                      //backgroundColor: "#F4F6FA",
                    },
                  }}
                  sx={{
                    position: "relative",
                    "& .MuiFormHelperText-root": {
                      position: "absolute",
                      bottom: "-20px",
                    },
                  }}
                  name="private_organization_place_count"
                  value={formik.values.private_organization_place_count}
                  label="Количество вновь введенных ученических мест"
                  placeholder="Количество вновь введенных ученических мест"
                  error={
                    Boolean(
                      formik?.touched?.private_organization_place_count &&
                        formik?.errors?.private_organization_place_count,
                    ) || false
                  }
                  helperText={
                    (formik?.touched?.private_organization_place_count &&
                      formik?.errors?.private_organization_place_count) ||
                    false
                  }
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const numericValue = inputValue.replace(/\D/g, "");
                    formik.setFieldValue(
                      "private_organization_place_count",
                      numericValue,
                    );
                  }}
                  onBlur={formik.handleBlur}
                  onClick={() => {
                    handleClearInput("private_organization_place_count");
                  }}
                  disabled={isInputDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    ЧОО введено в эксплуатацию путем
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.private_organization_exploitation_type}
                    label="ЧОО введено в эксплуатацию путем:"
                    onChange={(event) => {
                      const { value } = event.target;
                      formik.setFieldValue(
                        "private_organization_exploitation_type",
                        value,
                      );
                    }}
                    disabled={isInputDisabled}
                  >
                    <MenuItem value={"Строительство"}>Строительство</MenuItem>
                    <MenuItem value={"Реконструкция"}>Реконструкция</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: "24px" }}>
              <Grid item xs={12}>
                <TableDeclarationMini
                  rows={rowsTableMini}
                  setRows={setRowsTableMini}
                  isInputDisabled={isInputDisabled}
                  isSchoolTable={true}
                />
              </Grid>
            </Grid>
          </BoxCard>
        ) : null}
        {formik.values.boarding_school === "Есть" ? (
          <BoxCard title={"Характеристики Интерната"}>
            <Grid container spacing={1} alignItems={"flex-end"} rowGap={"10px"}>
              <Grid item xs={3}>
                <Autocomplete
                  value={formik.values.boarding_school_region}
                  options={regionsBoarding}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      error={Boolean(
                        formik?.touched?.boarding_school_region &&
                          formik?.errors?.boarding_school_region,
                      )}
                      label="Регион"
                    />
                  )}
                  onChange={async (_, option) => {
                    formik.setFieldValue("boarding_school_region", option);
                    formik.setFieldValue("boarding_school_area", null);
                    formik.setFieldValue("boarding_school_locality", null);
                    const areaData = option && (await getArea(option.id));
                    if (areaData) {
                      setAreasBoarding(areaData.data);
                    }
                  }}
                  noOptionsText="Нет доступных вариантов"
                  disabled={isInputDisabled}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  value={formik.values.boarding_school_area}
                  options={areasBoarding}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      error={Boolean(
                        formik?.touched?.boarding_school_area &&
                          formik?.errors?.boarding_school_area,
                      )}
                      label="Район"
                    />
                  )}
                  onChange={async (_, option) => {
                    formik.setFieldValue("boarding_school_area", option);
                    formik.setFieldValue("boarding_school_locality", null);
                    const localityData =
                      option && (await getLocality(option.id));
                    if (localityData) {
                      setLocalityBoarding(localityData.data);
                    }
                  }}
                  disabled={isInputDisabled}
                  noOptionsText="Нет доступных вариантов"
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  value={formik.values.boarding_school_locality}
                  options={localityBoarding}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label="Населенный пункт"
                      variant="outlined"
                      error={Boolean(
                        formik?.touched?.boarding_school_locality &&
                          formik?.errors?.boarding_school_locality,
                      )}
                      helperText={
                        formik?.touched?.boarding_school_locality &&
                        formik?.errors?.boarding_school_locality
                      }
                      disabled={isInputDisabled}
                    />
                  )}
                  onChange={async (_, option) => {
                    formik.setFieldValue("boarding_school_locality", option);
                  }}
                  noOptionsText="Нет доступных вариантов"
                />
              </Grid>

              <Grid
                item
                xs={3}
                sx={{ position: "relative", marginBottom: "5px" }}
              >
                <FormControl fullWidth>
                  <TextField
                    label="КАТО"
                    variant="outlined"
                    value={formik.values.boarding_school_locality?.kato_id}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="boarding_school_street"
                  value={formik.values.boarding_school_street}
                  label="Улица/Микрорайон/Жилой массив и т.п"
                  placeholder="Улица/Микрорайон/Жилой массив и т.п"
                  error={
                    Boolean(
                      formik?.touched?.boarding_school_street &&
                        formik?.errors?.boarding_school_street,
                    ) || false
                  }
                  helperText={
                    (formik?.touched?.boarding_school_street &&
                      formik?.errors?.boarding_school_street) ||
                    false
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isInputDisabled}
                  sx={{
                    position: "relative",
                    "& label": {
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    },
                    "& .MuiFormHelperText-root": {
                      position: "absolute",
                      bottom: "-20px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="boarding_school_house_number"
                  value={formik.values.boarding_school_house_number}
                  label="№ дома/здания"
                  placeholder="№ дома/здания"
                  error={
                    Boolean(
                      formik?.touched?.boarding_school_house_number &&
                        formik?.errors?.boarding_school_house_number,
                    ) || false
                  }
                  helperText={
                    (formik?.touched?.boarding_school_house_number &&
                      formik?.errors?.boarding_school_house_number) ||
                    false
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isInputDisabled}
                  sx={{
                    position: "relative",
                    "& .MuiFormHelperText-root": {
                      position: "absolute",
                      bottom: "-20px",
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <TextField
                  name="boarding_school_street"
                  value={formik.values.boarding_school_street}
                  label="Улица, дом/здание"
                  placeholder="Улица, дом/здание"
                  error={
                    Boolean(
                      formik?.touched?.boarding_school_street &&
                        formik?.errors?.boarding_school_street,
                    ) || false
                  }
                  helperText={
                    (formik?.touched?.boarding_school_street &&
                      formik?.errors?.boarding_school_street) ||
                    false
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isInputDisabled}
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  name="boarding_school_place_count"
                  value={formik.values.boarding_school_place_count}
                  label="Проектная мощность (мест)"
                  placeholder="2700"
                  error={
                    Boolean(
                      formik?.touched?.boarding_school_place_count &&
                        formik?.errors?.boarding_school_place_count,
                    ) || false
                  }
                  helperText={
                    (formik?.touched?.boarding_school_place_count &&
                      formik?.errors?.boarding_school_place_count) ||
                    false
                  }
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const numericValue = inputValue.replace(/\D/g, "");
                    formik.setFieldValue(
                      "boarding_school_place_count",
                      numericValue,
                    );
                  }}
                  onClick={() => {
                    handleClearInput("boarding_school_place_count");
                  }}
                  onBlur={formik.handleBlur}
                  disabled={isInputDisabled}
                  sx={{
                    position: "relative",
                    "& .MuiFormHelperText-root": {
                      position: "absolute",
                      bottom: "-20px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="boarding_school_exploitation_year"
                  value={formik.values.boarding_school_exploitation_year}
                  label="Дата ввода в эксплуатацию "
                  placeholder="Дата ввода в эксплуатацию "
                  id="exploitation_year"
                  type="date"
                  //defaultValue="2017-05-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    Boolean(
                      formik?.touched?.boarding_school_exploitation_year &&
                        formik?.errors?.boarding_school_exploitation_year,
                    ) || false
                  }
                  helperText={
                    (formik?.touched?.boarding_school_exploitation_year &&
                      formik?.errors?.boarding_school_exploitation_year) ||
                    false
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isInputDisabled}
                  sx={{
                    position: "relative",
                    "& .MuiFormHelperText-root": {
                      position: "absolute",
                      bottom: "-20px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Интернат введен в эксплуатацию путем
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.boarding_school_exploitation_type}
                    label="Интернат введен в эксплуатацию путем:"
                    onChange={(event) => {
                      const { value } = event.target;
                      formik.setFieldValue(
                        "boarding_school_exploitation_type",
                        value,
                      );
                    }}
                    disabled={isInputDisabled}
                  >
                    <MenuItem value={"Строительство"}>Строительство</MenuItem>
                    <MenuItem value={"Реконструкция"}>Реконструкция</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {new Date(formik.values.boarding_school_exploitation_year) >
                new Date(BREAKPOINT_DATES.boarding) && (
                <Box sx={{ mt: "10px" }}>
                  <Grid item alignItems={"flex-end"} display={"flex"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="boarding_school_registered_immutability_agreement"
                          checked={Boolean(
                            formik.values
                              .boarding_school_registered_immutability_agreement,
                          )}
                          onChange={() => {
                            formik.setFieldValue(
                              "boarding_school_registered_immutability_agreement",
                              formik.values
                                .boarding_school_registered_immutability_agreement ===
                                1
                                ? 0
                                : 1,
                            );
                          }}
                          disabled={isInputDisabled}
                        />
                      }
                      label="Наличие зарегистрированного Соглашения о неизменности целевого назначения объекта организации среднего образования (А3)"
                    />
                  </Grid>
                </Box>
              )}
            </Grid>
          </BoxCard>
        ) : null}
        {formik.values.boarding_school_registered_immutability_agreement ? (
          <BoxCard
            title={
              "Заполняется частной организацией образования с вновь введенными местами проживания в объекте частной организации образования, введенными в эксплуатацию после 01 ЯНВАРЯ 2020 года"
            }
          >
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={4}>
                <TextField
                  InputProps={{
                    style: {
                      //backgroundColor: "#F4F6FA",
                    },
                  }}
                  sx={{
                    position: "relative",
                    "& .MuiFormHelperText-root": {
                      position: "absolute",
                      bottom: "-20px",
                    },
                  }}
                  name="boarding_school_newly_count"
                  value={formik.values.boarding_school_newly_count}
                  label="Количество вновь введенных мест проживания"
                  placeholder="Количество вновь введенных мест проживания"
                  error={
                    Boolean(
                      formik?.touched?.boarding_school_newly_count &&
                        formik?.errors?.boarding_school_newly_count,
                    ) || false
                  }
                  helperText={
                    (formik?.touched?.boarding_school_newly_count &&
                      formik?.errors?.boarding_school_newly_count) ||
                    false
                  }
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const numericValue = inputValue.replace(/\D/g, "");
                    formik.setFieldValue(
                      "boarding_school_newly_count",
                      Number(numericValue),
                    );
                  }}
                  onBlur={formik.handleBlur}
                  onClick={() => {
                    handleClearInput("boarding_school_newly_count");
                  }}
                  disabled={isInputDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Интернат введен в эксплуатацию путем
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.boarding_school_government_order}
                    label="Интернат введен в эксплуатацию путем"
                    sx={{
                      position: "relative",
                      "& label": {
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      },
                    }}
                    onChange={(event) => {
                      const { value } = event.target;
                      formik.setFieldValue(
                        "boarding_school_government_order",
                        value,
                      );
                    }}
                    disabled={isInputDisabled}
                  >
                    <MenuItem value={0}>Строительство</MenuItem>
                    <MenuItem value={1}>Реконструкция</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: "24px" }}>
              <Grid item xs={12}>
                <TableDeclarationMini
                  rows={rowsTableMiniTwo}
                  setRows={setRowsTableMiniTwo}
                  isInputDisabled={isInputDisabled}
                  isSchoolTable={false}
                />
              </Grid>
            </Grid>
          </BoxCard>
        ) : null}
      </Stack>
    </form>
  );
};

export default DeclarationStep2;
