import { DataArray, PictureAsPdf } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import { toast } from "react-toastify";
import { getSignersDocument } from "../../services/useEndpoint";
import { IApplication } from "../../types/application";
import { downloadFile, extractFileNameAndExtension } from "../../utils/helpers";
import { getDeclarationHistory } from "./api";
import { getColorByStatus } from "./helpers";

interface DeclarationHistoryProps {
  applicationId: IApplication["id"];
  children(onOpen: () => void): React.ReactNode;
}

const DeclarationHistory = ({
  applicationId,
  children,
}: DeclarationHistoryProps) => {
  const [opened, setOpened] = useState(false);

  const {
    data: declarationHistory,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["declarationHistory", applicationId],
    queryFn: ({ signal }) => getDeclarationHistory(applicationId, { signal }),
    enabled: Boolean(applicationId) && opened,
  });

  const donwloadDocumentMutation = useMutation({
    mutationKey: ["getSignersDocument"],
    mutationFn: getSignersDocument,
    onSuccess: (data) => {
      downloadFile(data, "Документ.pdf", "application/pdf");
    },
    onError: () => {
      toast.error("Не удалось скачать документ");
    },
  });

  return (
    <>
      {children(() => setOpened(true))}

      <Drawer
        open={opened}
        anchor="right"
        onClose={() => setOpened(false)}
        sx={{ width: DeclarationHistory.width }}
        PaperProps={{ sx: { width: DeclarationHistory.width } }}
      >
        {isLoading ? (
          <Box sx={{ height: "100vh", display: "grid", placeItems: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {isSuccess && declarationHistory.data?.length ? (
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {declarationHistory.data.map(
                  ({ displayed_status, title, created_at, user, pdf, id }) => {
                    const document = pdf
                      ? extractFileNameAndExtension(pdf)
                      : null;

                    return (
                      <TimelineItem key={id}>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>

                        <TimelineContent>
                          <Chip
                            label={displayed_status}
                            variant="outlined"
                            size="small"
                            sx={{ bgcolor: getColorByStatus(displayed_status) }}
                          />

                          <Typography variant="h5">{title}</Typography>

                          {user && (
                            <Typography>{user.organization_name}</Typography>
                          )}

                          {document && (
                            <Button
                              color="info"
                              variant="text"
                              size="small"
                              startIcon={<PictureAsPdf />}
                              disabled={donwloadDocumentMutation.isLoading}
                              onClick={() =>
                                pdf &&
                                donwloadDocumentMutation.mutate({
                                  file_path: pdf,
                                })
                              }
                            >
                              {document.name}
                            </Button>
                          )}

                          <Typography
                            component="p"
                            variant="caption"
                            color="text.secondary"
                          >
                            {dayjs(created_at).format("DD.MM.YYYY")}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  },
                )}
              </Timeline>
            ) : (
              <Stack
                sx={{ height: "100%", display: "grid", placeItems: "center" }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6">Пусто</Typography>
                  <DataArray />
                </Box>
              </Stack>
            )}
          </>
        )}
      </Drawer>
    </>
  );
};

DeclarationHistory.width = 475;

export default DeclarationHistory;
