import { combineReducers } from "@reduxjs/toolkit";
import currentUserSlice from "./currentUserReducer";
import createDeclarationSlice from "./createDeclaration";
import currentPageReducer from "./currentPageSlice";
import currentDeclarationReducer from "./currentDeclarationSlice";
import filterReducer from "./filterSlice";
import notificationsReducer from "./notificationsSlice";
import validateTablesReducer from "./validateTablesSlice";
import validateCadastralInfoReducer from "./validateCadastralInfoSlice";
export default combineReducers({
  currentUserSlice,
  createDeclarationSlice,
  currentPageReducer,
  currentDeclarationReducer,
  filterReducer,
  notificationsReducer,
  validateTablesReducer,
  validateCadastralInfoReducer,
});
