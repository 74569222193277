import { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";

import {
  getMainSpecialistsConfirm,
  getSpetialistsConfirm,
} from "../../../services/useEndpoint";
import AutoWorkerSelect from "./AutoWorkerSelect";
import { GridRowId } from "@mui/x-data-grid";
import { useAppSelector } from "../../../store/hooks";
import { selectCurrentUser } from "../../../store/reducers/currentUserReducer";
import { getCurrentDepartment } from "../../../utils/helpers";

interface AutoConfirmModalProps {
  open: boolean;
  onClose: () => void;
  applicationIds: GridRowId[] | null;
}

interface ISelectedExecutors {
  specialistId: number | null;
  mainSpecialistId: number | null;
  id: string;
}
const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function AutoConfirmModal({
  open,
  applicationIds,
  onClose,
}: AutoConfirmModalProps) {
  const [specialists, setSpecialists] = useState<[]>([]);
  const [mainSpecialists, setMainSpecialists] = useState<[]>([]);

  const [mainSpecialistsIds, setMainSpecialistsIds] = useState<number[]>([]);
  const [specialistsIds, setSpecialistsIds] = useState<number[]>([]);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [selectedPairs, setSelectedPairs] = useState<Array<ISelectedExecutors>>(
    [{ id: generateUniqueId(), specialistId: null, mainSpecialistId: null }],
  );

  const department: string = useAppSelector(selectCurrentUser).role.code;
  const currentDepartment = getCurrentDepartment(department);

  function generateUniqueId() {
    return Math.random().toString(36).slice(2, 9);
  }

  useEffect(() => {
  
    if (!open) return;
      try {
        getSpetialistsConfirm(currentDepartment)
          .then((data) => {
            if (data) setSpecialists(data.data);
          })
          .catch((error) => {
            console.log("Ошибка при запросе специалистов", error);
          });
      } catch (error) {
        console.log("Ошибка при запросе специалистов", error);
      }
  
      try {
        getMainSpecialistsConfirm(currentDepartment)
          .then((data) => {
            if (data) setMainSpecialists(data.data);
          })
          .catch((error) => {
            console.log("Ошибка при запросе специалистов", error);
          });
      } catch (error) {
        console.log("Ошибка при запросе специалистов", error);
      }
    

   
  }, [open]);

  const addSelectedPair = () => {
    setSelectedPairs((prevPairs) => [
      ...prevPairs,
      { id: generateUniqueId(), specialistId: null, mainSpecialistId: null },
    ]);
  };

  const handleDeleteSelectorPair = (id: string) => {
    setSelectedPairs((prevPairs) => prevPairs.filter((pair) => pair.id !== id));
  };

  const handleSpecialistChange = (
    id: string,
    newSpecialistId: number | null,
  ) => {
    setSelectedPairs((prevPairs) =>
      prevPairs.map((pair) =>
        pair.id === id ? { ...pair, specialistId: newSpecialistId } : pair,
      ),
    );
  };

  const handleMainSpecialistChange = (
    id: string,
    newMainSpecialistId: number | null,
  ) => {
    setSelectedPairs((prevPairs) =>
      prevPairs.map((pair) =>
        pair.id === id
          ? { ...pair, mainSpecialistId: newMainSpecialistId }
          : pair,
      ),
    );
  };

  useEffect(() => {
    const newSpecialistsIds = selectedPairs
      .map((pair) => pair.specialistId)
      .filter((id) => id !== null) as number[];

    const newMainSpecialistsIds = selectedPairs
      .map((pair) => pair.mainSpecialistId)
      .filter((id) => id !== null) as number[];

    setSpecialistsIds(newSpecialistsIds);
    setMainSpecialistsIds(newMainSpecialistsIds);
  }, [selectedPairs]);

  useEffect(() => {
    if (specialistsIds.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [specialistsIds]);

  function handleConfirm() {
    if (selectedPairs) {
      alert(
        `
        выбранные специалисты:  ${specialistsIds},
        выбранные глав специалисты:  ${mainSpecialistsIds},
        id заявок:  ${applicationIds}`,
      );
      onClose();

      setMainSpecialistsIds([]);
      setSpecialistsIds([]);
      setSelectedPairs([
        { id: generateUniqueId(), specialistId: null, mainSpecialistId: null },
      ]);
      setButtonDisabled(true);

      //логика на будущее для отправки формы
      // try {
      //   setSpetialistsConfirm(
      //     selectedExecutors.executor_ids,
      //     selectedExecutors.main_executor_ids,
      //     applicationIds,
      //   )
      //     .then((data) => {
      //       // alert("Вы успешно назначили исполнителя");
      //       setButtonDisabled(true);
      //       toast.success("Исполнители успешно назначены");
      //       navigate("/director/declarations");
      //       onClose();
      //       console.log("Отправлено на подтверждение", data);
      //     })
      //     .catch((error) => {
      //       console.log("Ошибка при запросе специалистов", error);
      //     });
      // } catch (error) {
      //   console.log("Ошибка при запросе специалистов", error);
      // }
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Назначить
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, textAlign: "center" }}
        >
          Выберите из списка на кого назначить заявку
        </Typography>

        {selectedPairs.map((pair, i) => (
          <AutoWorkerSelect
            key={pair.id}
            index={i}
            id={pair.id}
            specialistId={pair.specialistId}
            mainSpecialistId={pair.mainSpecialistId}
            specialists={specialists}
            mainSpecialists={mainSpecialists}
            handleDelete={() => handleDeleteSelectorPair(pair.id)}
            handleSpecialistChange={handleSpecialistChange}
            handleMainSpecialistChange={handleMainSpecialistChange}
          />
        ))}
        <Button
          variant="text"
          sx={{ fontSize: "14px", marginTop: "16px" }}
          onClick={addSelectedPair}
        >
          <Add sx={{ fontSize: "16px" }} /> Добавить исполнителей
        </Button>
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            marginTop: "20px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "space-between",
            gap: "30px",
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              onClose();
              setSelectedPairs([
                {
                  id: generateUniqueId(),
                  specialistId: null,
                  mainSpecialistId: null,
                },
              ]);
            }}
          >
            Отмена
          </Button>
          <Button fullWidth onClick={handleConfirm} disabled={buttonDisabled}>
            Назначить
          </Button>
        </Container>
      </Box>
    </Modal>
  );
}
