import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  type DialogProps,
  DialogTitle,
} from "@mui/material";
import type { PropsWithChildren } from "react";

export interface ConfirmDialogProps extends DialogProps {
  title?: string;
  confirmText?: string;
  disabled?: boolean;
  onClose(): void;
  onConfirm?(): void;
}

const ConfirmDialog = ({
  title,
  confirmText,
  children,
  disabled,
  onClose,
  onConfirm,
  ...props
}: PropsWithChildren<ConfirmDialogProps>) => {
  return (
    <Dialog {...props} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle sx={{ bgcolor: "#2C53F5" }}>
        <b>{title ?? "Подтвердить действие"}</b>
      </DialogTitle>

      <DialogContent>
        {confirmText && <DialogContentText>{confirmText}</DialogContentText>}

        {children}
      </DialogContent>

      <DialogActions>
        {onConfirm && (
          <Button
            variant="contained"
            disabled={disabled}
            onClick={onConfirm}
            fullWidth
          >
            Да
          </Button>
        )}

        <Button
          variant="outlined"
          disabled={disabled}
          onClick={onClose}
          fullWidth
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
