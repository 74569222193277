import { useQuery, UseQueryResult } from "react-query";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { UserInterface } from "../store/reducers/currentUserReducer";
import anAxiosApi from "./axiosApi";
import { toast } from "react-toastify";
import { GetDeclarationsParams } from "../types/types";
import { CheckListInterface, CheckListDBFInterface } from "../types/modalTypes";

type ConfigType = AxiosRequestConfig & {
  url: string;
  data: Record<string, unknown>;
};

export const useApi = (
  url: string,
  config: ConfigType,
): UseQueryResult<any, AxiosError> => {
  const keys = Object.keys(config?.data || {});

  return useQuery({
    queryKey: keys?.length > 0 ? keys : [config.url],
    queryFn: async () => {
      const { data }: AxiosResponse = await axios(config); // Use axios directly here
      return data;
    },
  });
};
interface RegistrationData {
  login: string;
  password: string;
  role_id: string;
  organization_name: string;
  official_number: string;
  email_address: string;
  phone_number: string;
  code: number;
}

interface AuthData {
  user: UserInterface;
  access_token: string;
}

interface sendRegistrationCode {
  email_address: string;
  phone_number: string;
}
interface sendForgotPassword {
  email_address: string;
}
interface checkPasswordCodeState {
  email_address: string;
  code: number;
}

interface SchoolListProps {
  school_name: string;
  actual_address: string;
  organization_id: number;
  id: number;
}

export const registerUser = async (
  formData: RegistrationData,
): Promise<{ success: boolean; data: any }> => {
  try {
    // Make the API request to register the user using axios
    const response: AxiosResponse = await anAxiosApi.post(
      "/auth/register",
      formData,
    );

    // Check if the response indicates successful registration
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      // Registration failed
      return { success: false, data: undefined };
    }
  } catch (error: any) {
    toast.error(error?.message);
    return { success: false, data: undefined };
  }
};

export const sendRegisterCode = async (
  formData: sendRegistrationCode,
): Promise<{ success: boolean; data: any }> => {
  try {
    const response: AxiosResponse = await anAxiosApi.post(
      "/auth/send-register-code",
      formData,
    );
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      // Registration failed
      return { success: false, data: undefined };
    }
  } catch (error: any) {
    toast.error(error?.message);
    return { success: false, data: undefined };
  }
};

export const loginCall = async (
  username: string,
  password: string,
): Promise<AuthData | boolean> => {
  try {
    const { data }: AxiosResponse<AuthData> = await anAxiosApi.post(
      "/auth/login",
      { login: username, password },
    );

    return data;
  } catch (error: any) {
    toast.error(`Ошибка авторизации. Неверно введенные данные`);
    console.log(`Ошибка авторизации. ${error?.message}`);
    return false;
  }
};

// TODO: Пропишите тут тип возвращаемых данных в Promise
export const loginCallByEDS = async (xml: string): Promise<unknown> => {
  try {
    // TODO: Здесь тоже, только в AxiosResponse
    const { data }: AxiosResponse<unknown> = await anAxiosApi.post(
      "/auth/eds-login",
      { xml },
    );

    return data;
  } catch (error: any) {
    toast.error(`Ошибка авторизации. ${error?.message}`);
    return false;
  }
};
export const signByEDS = async (xml: string): Promise<unknown> => {
  try {
    // TODO: Здесь тоже, только в AxiosResponse
    const { data }: AxiosResponse<unknown> = await anAxiosApi.post(
      "/application/eds-sign",
      { xml },
    );

    return data;
  } catch (error: any) {
    toast.error(error?.message);
    return false;
  }
};

export const signSchoolByEDS = async ({
  xml,
  signatory_official_number,
  official_number,
}: Record<string, string>): Promise<unknown> => {
  try {
    // TODO: Здесь тоже, только в AxiosResponse
    const { data }: AxiosResponse<unknown> = await anAxiosApi.post(
      "/application/school-eds-sign",
      { xml, signatory_official_number, official_number },
    );
    return data;
  } catch (error: any) {
    toast.error(error.message);
    return false;
  }
};

export const sendUnForgotPassword = async (
  formData: sendForgotPassword,
): Promise<{ success: boolean; data: any }> => {
  try {
    const response: AxiosResponse = await anAxiosApi.post(
      "/auth/reset-password",
      formData,
    );
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, data: undefined };
    }
  } catch (error: any) {
    toast.error(error?.message);
    return { success: false, data: undefined };
  }
};

export const sendCheckPasswordCode = async (
  formData: checkPasswordCodeState,
) => {
  const response = await anAxiosApi.post("/auth/check-password-code", formData);

  return response.data;
};

export const sendForgotPassword = async (
  formData: sendForgotPassword,
): Promise<{ success: boolean; data: any }> => {
  try {
    const response: AxiosResponse = await anAxiosApi.post(
      "/auth/send-password-code",
      formData,
    );
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, data: undefined };
    }
  } catch (error: any) {
    toast.error("Выбранный адрес электронной почты недействителен");
    // toast.error(error?.message);
    return { success: false, data: undefined };
  }
};

export const getSchools = async (params: GetDeclarationsParams) => {
  const queryString = new URLSearchParams(params as any).toString();

  try {
    const { data } = await anAxiosApi.get(`/application?${queryString}`);
    return data;
  } catch (error: any) {
    toast.error(error?.message);
    return null;
  }
};

export const getFilteredDeclarations = async (
  filter: string,
  value: string | number,
) => {
  try {
    const { data } = await anAxiosApi.get(`/application?${filter}=${value}`);
    return data;
  } catch (error: any) {
    toast.error(error?.message);
    return false;
  }
};

export const getSchoolDeclarationById = async (id: string) => {
  try {
    const { data } = await anAxiosApi.get(`/application/${id}`);

    return data;
  } catch (error: any) {
    toast.error(error?.message);
    return false;
  }
};

export const getCurrentUser = async (id: number) => {
  try {
    const { data } = await anAxiosApi.get(`/user/${id}`);
    return data;
  } catch (error: any) {
    toast.error(error?.message);
    return false;
  }
};

export const createSchoolDeclaration = async (formData: FormData) => {
  const { data } = await anAxiosApi.post(`/application`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const previewSchoolDeclaration = async (formData: FormData) => {
  try {
    const { data } = await anAxiosApi.post(
      `/application/preview-pdf`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      },
    );
    return data;
  } catch (error: any) {
    toast.error(error?.message);
    return false;
  }
};

export const getSpecialistData = async () => {
  try {
    const { data } = await anAxiosApi.get(`/application`);
    return data;
  } catch (error: any) {
    toast.error(error?.message);
  }
};

export const getSpetialistsConfirm = async (departmentName: string) => {
  try {
    const { data } = await anAxiosApi.get(
      `/application/${departmentName}/get-executors`,
    );
    return data;
  } catch (error: any) {
    toast.error(error?.message);
  }
};

export const getMainSpecialistsConfirm = async (departmentName: string) => {
  try {
    // console.log(formData)
    const { data } = await anAxiosApi.get(
      `/application/${departmentName}/get-main-executors`,
    );
    return data;
  } catch (error: any) {
    toast.error(error?.message);
  }
};

export const setSpetialistsConfirm = async (
  executor_id: number,
  main_executor_id: number | null,
  applicationId: number,
  departmentName: string,
) => {
  const { data } = await anAxiosApi.post(
    `/application/${departmentName}/${applicationId}/set-executors`,
    { executor_id, main_executor_id },
  );
  return data;
};

export const getSchoolDeclarations = async () => {
  try {
    const { data } = await anAxiosApi.get(`/application`);
    return data;
  } catch (error: any) {
    toast.error(error?.message);
  }
};

export const getSchoolDeclarationsOne = async (id: number) => {
  try {
    // console.log(formData)
    const { data } = await anAxiosApi.get(`/application/${id}`);

    return data;
  } catch (error: any) {
    toast.error(error?.message);
  }
};

//получение типов причин для доработки/отказа

export const getRemarkTypes = async () => {
  try {
    const { data } = await anAxiosApi.get(`/application/remark-types`);
    return data;
  } catch (error: any) {
    toast.error(error?.message);
  }
};

// Принятие заявления
export const acceptRequest = async (id: number) => {
  const { data } = await anAxiosApi.post(`/application/${id}/accept`);
  return data;
};

export const sendToSchoolRework = async (
  id: number,
  comment: string,
  remark_type_ids: Array<number>,
  check_list?: CheckListInterface,
) => {
  const { data } = await anAxiosApi.post(`/application/${id}/school-rework`, {
    comment,
    remark_type_ids,
    check_list,
  });
  return data;
};

export const sendToSchoolReworkDBF = async (
  id: number,
  comment: string,
  remark_type_ids: Array<number>,
  check_list: CheckListDBFInterface,
) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}/school-rework-dbf`,
    {
      comment,
      remark_type_ids,
      check_list,
    },
  );
  return data;
};

export const decline = async (id: number, remark_type_ids: Array<number>) => {
  const { data } = await anAxiosApi.post(`/application/${id}/decline`, {
    remark_type_ids,
  });
  return data;
};

export const addDBFConclusion = async (id: number, type: number) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}/set-dbf-conclusion`,
    { type },
  );
  return data;
};

export const postApproveConclusion = async (id: number) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}/set-dbf-conclusion`,
  );
  return data;
};

export const approveDecline = async (id: number, data: { pdf: string }) => {
  const response = await anAxiosApi.post(
    `/application/${id}/approve-decline`,
    data,
  );
  return response.data;
};

export const approveSchoolRework = async (
  id: number,
  data: { pdf: string },
) => {
  const response = await anAxiosApi.post(
    `/application/${id}/approve-school-rework`,
    data,
  );
  return response.data;
};

export const executorRework = async (id: number, comment: string) => {
  const { data } = await anAxiosApi.post(`/application/${id}/executor-rework`, {
    comment,
  });
  return data;
};

export const getRevisionFile = async (id: number) => {
  try {
    const { data } = await anAxiosApi.get(
      `/application/${id}/get-school-rework-pdf`,
      {
        responseType: "blob",
      },
    );
    return data;
  } catch (error: any) {
    toast.error(error?.message);
  }
};

export const getDeclineFile = async (id: number) => {
  try {
    const { data } = await anAxiosApi.get(
      `/application/${id}/get-decline-pdf`,
      {
        responseType: "blob",
      },
    );
    return data;
  } catch (error: any) {
    toast.error(error?.message);
  }
};

//Изменение заявления
export const editDeclaration = async (id: number, formData: FormData) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}?_method=PUT`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return data;
};

//изменить данные профиля организации
export const editSchoolProfile = async (id: number, formData: FormData) => {
  try {
    const { data } = await anAxiosApi.post(`/user/${id}?_method=PUT`, formData);
    toast.success("Данные успешно изменены");
    return { success: true, data };
  } catch (error: any) {
    toast.error(error.message);
    return { success: false };
  }
};

//изменить логин и пароль
export const updateCredentials = async (id: number, formData: FormData) => {
  try {
    const { data } = await anAxiosApi.post(
      `/user/${id}/update-credentials?_method=PUT`,
      formData,
    );
    toast.success("Данные успешно изменены");
    return { success: true, data };
  } catch (error: any) {
    toast.error("Данные не получилось изменить");
    return { success: false };
  }
};

//Получить список школ
export const getSchoolsList = async () => {
  try {
    const { data } = await anAxiosApi.get(`/school`);
    return data;
  } catch (error: any) {
    toast.error("Не получилось загрузить список школ");
  }
};

//Добавить школу
export const addSchool = async (formData: FormData) => {
  try {
    const { data } = await anAxiosApi.post(`/school`, formData);
    return data;
  } catch (error: any) {
    toast.error("Не получилось добавить школу");
  }
};

//Изменить школу
export const changeSchoolData = async (value: SchoolListProps) => {
  try {
    const { data } = await anAxiosApi.post(
      `/school/${value.id}?_method=PUT`,
      value,
    );
    return data;
  } catch (error: any) {
    toast.error("Не получилось изменить школу");
  }
};

//Удалить школу
export const deleteSchool = async (id: number) => {
  try {
    const { data } = await anAxiosApi.delete(`/school/${id}`);
    return data;
  } catch (error: any) {
    toast.error("Не получилось удалить школу");
  }
};

//получить список исходящих писем
export const getOutgoingMails = async (page: number) => {
  const { data } = await anAxiosApi.get(
    `/application/outgoing-mails?page=${page}`,
  );
  return data;
};

//получить список входящих писем
export const getIncomingMails = async (config?: AxiosRequestConfig) => {
  const { data } = await anAxiosApi.get("/application/incoming-mails", config);
  return data;
};

//эндпоинты для получения региона, города, района, округа
export const getRegion = async () => {
  const { data } = await anAxiosApi.get(`/region`);
  return data;
};

export const getArea = async (parentId: number) => {
  const { data } = await anAxiosApi.get(`/area?parent_id=${parentId}`);
  return data;
};

export const getDistrict = async (parentId: number) => {
  const { data } = await anAxiosApi.get(`/district?parent_id=${parentId}`);
  return data;
};

export const getLocality = async (parentId: number) => {
  const { data } = await anAxiosApi.get(`/locality?parent_id=${parentId}`);
  return data;
};

export const getNotifications = async (params: GetDeclarationsParams | any) => {
  const queryString = new URLSearchParams(params as any).toString();

  try {
    const { data } = await anAxiosApi.get(`/notifications?${queryString}`);
    return data;
  } catch (error: any) {
    toast.error(
      "Ошибка при загрузке уведомлений. Пожалуйста, попробуйте еще раз позже.",
    );
    return null;
  }
};

export const getNotificationsOne = async (id: number) => {
  try {
    const { data } = await anAxiosApi.get(`/notifications/${id}`);
    return data;
  } catch (error: any) {
    toast.error("Ошибка при загрузке уведомления.");
  }
};

export const postViewNotifications = async (ids: number) => {
  const { data } = await anAxiosApi.post(`/view-notifications`, ids);
  return data;
};

export const revokeApplications = async (
  id: number,
  data: { return_pdf: string },
) => {
  try {
    const response = await anAxiosApi.post(`/application/${id}/return`, data);
    return response.data;
  } catch (error: any) {
    toast.error(
      "Ошибка при отзыве заявления. Пожалуйста, попробуйте еще раз позже.",
    );
  }
};

export const getRevokeApplicationsDocument = async (id: number) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}/preview-return-pdf`,
    {},
    { responseType: "blob" },
  );
  return data;
};

export const fetchAutoAssignmentSetting = async (type: string) => {
  try {
    const { data } = await anAxiosApi.get(`/settings?name=${type}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const toggleAutoAssignment = async (department: string) => {
  try {
    const { data } = await anAxiosApi.post("/settings/auto-assignment", {
      department: department,
    });
    return data;
  } catch (error) {
    return error;
  }
};

// архивация всех текущих заявлений, в тело метода ничего не нужно передавать
export const postArchieve = async () => {
  try {
    const { data } = await anAxiosApi.post(`/application/archive-applications`);
    return data;
  } catch (error) {
    toast.error("Не получилось отправить в архив. Попробуйте позже");
    return error;
  }
};

//получение заявлений из архива
export const getArchive = async () => {
  try {
    const { data } = await anAxiosApi.get(`/application/get-archive`);
    return data;
  } catch (error: any) {
    toast.error("Не удалось получить архив заявлений.");
  }
};

export const getArchiveByYear = async (year: number) => {
  try {
    const { data } = await anAxiosApi.get(
      `/application/get-archive?year=${year}`,
    );
    return data;
  } catch (error: any) {
    toast.error(`Не удалось получить архив заявлений за ${year}. `);
    return null;
  }
};

// Функция для получения последней записи даты приема заявлений
export const getLastApplicationAcceptanceDate = async () => {
  try {
    const response = await anAxiosApi.get(`/application-acceptance`);
    return response.data;
  } catch (error) {
    toast.error("Ошибка при получении последней записи даты приема заявлений");
    throw error;
  }
};

// Функция для добавления записи приема заявлений
export const storeApplicationAcceptance = async (
  type: number,
  date_from: string,
  date_to: string,
) => {
  try {
    const response = await anAxiosApi.post(`/store-application-acceptance`, {
      type,
      date_from,
      date_to,
    });
    return response.data;
  } catch (error) {
    toast.error("Ошибка при добавлении записи приема заявлений");
    throw error;
  }
};

// Функция для отмены приема заявки
export const cancelApplicationAcceptance = async (id: number) => {
  try {
    const response = await anAxiosApi.post(
      `/cancel-application-acceptance/${id}`,
    );
    return response.data;
  } catch (error) {
    toast.error("Ошибка при отмене приема заявки");
    throw error;
  }
};

//Для получения кол-ва заявок по статусам для бокового меню
export const getCountDeclarationsStatus = async () => {
  try {
    const { data } = await anAxiosApi.get(`/application/status-counts`);
    return data;
  } catch (error) {
    toast.error(
      "Ошибка при получении количества заявок по статусам для бокового меню",
    );
    throw error;
  }
};

// Для получения документа для подписания
export const getDocumentForSign = async ({
  filePath,
}: {
  filePath: string;
}) => {
  const { data } = await anAxiosApi.post("/get-document-for-sign", {
    filePath,
  });
  return data;
};

export const getSignersDocument = async ({
  file_path,
}: {
  file_path: string;
}) => {
  const { data } = await anAxiosApi.post(
    "/signers-document",
    { file_path },
    { responseType: "blob" },
  );
  return data;
};

// подписание пришедших данных с эндпоинта получения документа и передача подписи и пути до подписываемого файла
export const signDocument = async (signature: string, file_path: string) => {
  try {
    const response = await anAxiosApi.post("/sign", {
      signature,
      file_path,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// для сохранения документа заявления перед подписанием
export const storeApplicationPdf = async (formData: FormData) => {
  try {
    const { data } = await anAxiosApi.post(
      `/application/store-application-pdf`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return data;
  } catch (error) {
    console.error("Error signing document:", error);
    throw error;
  }
};

// добавление чек-листа у специалиста АПД
export const checkList = async (
  id: number,
  checkListState?: CheckListInterface,
) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}/check-list`,
    checkListState,
  );
  return data;
};

// добавление чек-листа у специалиста ДБФ
export const saveDBFcheckList = async (
  id: number,
  pdf: string,
  checkListDBFState: CheckListDBFInterface,
) => {
  const { data } = await anAxiosApi.post(`/application/${id}/dbf-check-list`, {
    ...checkListDBFState,
    pdf,
  });
  return data;
};

// обновленное подписание проверочных листов APD при создании
export const storeCheckListPdf = async (
  id: number,
  checkListState?: CheckListInterface,
) => {
  try {
    const { data } = await anAxiosApi.post(
      `/application/${id}/store-check-list-pdf`,
      checkListState,
    );
    return data;
  } catch (error) {
    console.error("Error signing document:", error);
    throw error;
  }
};

//
export const storeDbfCheckListPdf = async (
  id: number,
  checkListDBFState: CheckListDBFInterface,
) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}/store-dbf-check-list-pdf`,
    checkListDBFState,
  );
  return data;
};

// сохранение письма об отказе
export const storeDeclineCheckListPdf = async (id: number) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}/store-decline-pdf`,
  );
  return data;
};

// подписание отзыва заявления
export const storeReturnPdf = async (id: number) => {
  const { data } = await anAxiosApi.post(`/application/${id}/store-return-pdf`);
  return data;
};

// подписание заявления на доработку
export const storeSchoolReworkPdf = async (id: number) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}/store-school-rework-pdf`,
  );
  return data;
};

// подписание заявления на доработку
export const storeSchoolDeclinePdf = async (id: number) => {
  const { data } = await anAxiosApi.post(
    `/application/${id}/store-decline-pdf`,
  );
  return data;
};
