import { Box, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

import BoxTitle from "../../components/BoxTitle";
import TableMails from "./components/TableMails";
import { getIncomingMails } from "./api";

const IncomingMails = () => {
  const [{ page, per_page }, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    per_page: withDefault(NumberParam, 10),
  });

  const {
    data: incomingMails,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["incomingMails", page, per_page].filter(Boolean),
    queryFn: ({ signal }) =>
      getIncomingMails({ signal, params: { page, per_page } }),
  });

  return (
    <Box sx={{ py: 5, px: 2.5, maxWidth: "1130px" }}>
      <Box sx={{ mb: 2.5 }}>
        <BoxTitle title={"Журнал входящих писем"} />
      </Box>

      {isLoading && (
        <Box sx={{ display: "grid", placeItems: "center", p: 5 }}>
          <CircularProgress />
        </Box>
      )}

      {isSuccess && (
        <TableMails
          rows={incomingMails.data}
          rowCount={incomingMails.meta.total}
          page={page}
          perPage={per_page}
          onChangePage={(page) => setQuery({ page })}
          onChangePerPage={(per_page) => setQuery({ per_page, page: 1 })}
        />
      )}
    </Box>
  );
};

export default IncomingMails;
