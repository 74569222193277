import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ArticleOutlined,
  DescriptionOutlined,
  ForwardToInboxOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MarkEmailUnreadOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { fincolors } from "../styles/theme";

import {
  getCountDeclarationsStatus,
  postArchieve,
} from "../services/useEndpoint";
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { useApplicationsQueryParams } from "../hooks/useApplicationsQueryParams";
import { selectCurrentUser } from "../store/reducers/currentUserReducer";
import {
  setClearFilter,
  setOverdueFilter,
  setStatusFilter,
} from "../store/reducers/filterSlice";
import { ROLES } from "../utils/constants";

type ListItemType = {
  label: string;
  value: string;
  params: AxiosRequestConfig["params"];
};

const role = Number(Cookies.get("role"));

interface MenuItem {
  path: string;
  text: string;
  icon: React.ReactElement;
  statusList?: ListItemType[];
}

interface AdminMenuProps {
  state: number;
}

const AdminMenuItem: React.FC<
  MenuItem & {
    statusCounts: { status: string; status_count: number }[];
  }
> = ({ path, text, icon, statusList, statusCounts }) => {
  const match = useMatch({ path: "/admin" + path, end: path.length === 1 });
  const location = useLocation();
  const navigate = useNavigate();
  const [isSpecialist, setIsSpecialist] = useState(false);
  const [isMainSpecialist, setIsMainSpecialist] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const dispatch = useAppDispatch();
  const blueColor = fincolors.primary.main;
  const iconStyle = isListOpen ? { color: blueColor } : { color: "white" };

  const [, setQuery] = useApplicationsQueryParams();

  useEffect(() => {
    let currentPath = location.pathname;
    if (currentPath.startsWith("/specialist/")) {
      setIsSpecialist(true);
    }
    if (currentPath.startsWith("/main-specialist/")) {
      setIsMainSpecialist(true);
    }
  }, [location.pathname]);

  const onClickHandle = ({ overdue, status }: AxiosRequestConfig["params"]) => {
    dispatch(overdue ? setOverdueFilter(overdue) : setStatusFilter(status));

    navigate({
      pathname: isSpecialist
        ? "/specialist" + path
        : isMainSpecialist
          ? "/main-specialist" + path
          : "/director" + path,
    });
    setQuery({ status, overdue });
  };

  return (
    <>
      <ListItem
        sx={{
          backgroundColor: isListOpen ? fincolors.secondary.main : "",
          border: 1,
          borderBottom: "none",
          borderColor: isListOpen ? blueColor : "transparent",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* <ListItemButton to={'/admin' + path} component={Link} selected={Boolean(match)}> */}
        <ListItemButton
          disabled={
            !(
              path === "/declarations" ||
              path === "/outgoing-mails" ||
              path === "/incoming-mails" ||
              path === "/declarations/enumeration"
            )
          }
          to={
            isSpecialist
              ? "/specialist" + path
              : isMainSpecialist
                ? "/main-specialist" + path
                : "/director" + path
          }
          component={Link}
          selected={Boolean(match)}
          onClick={() => {
            dispatch(setClearFilter());
          }}
        >
          <ListItemIcon sx={iconStyle}>{icon}</ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              color: isListOpen ? blueColor : fincolors.primary.contrastText,
            }}
            primary={text}
          />
        </ListItemButton>
        {statusList && (
          <Button
            variant="text"
            sx={{ justifyContent: "flex-end" }}
            onClick={() => {
              setIsListOpen(!isListOpen);
            }}
          >
            {isListOpen ? (
              <KeyboardArrowUp sx={{ fill: blueColor }} />
            ) : (
              <KeyboardArrowDown
                sx={{ fill: fincolors.primary.contrastText }}
              />
            )}
          </Button>
        )}
      </ListItem>
      {isListOpen && (
        <Box
          sx={{
            backgroundColor: fincolors.secondary.main,
            display: "flex",
            flexDirection: "column",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            borderRight: 1,
            borderBottom: 1,
            borderLeft: 1,
            borderColor: blueColor,
          }}
        >
          {statusList?.map((item) => (
            <Button
              variant="text"
              sx={{
                color: fincolors.text.primary,
                fontWeight: 500,
                textAlign: "start",
                justifyContent: "space-between",
                display: "flex",
                minHeight: "45px",
                paddingLeft: "54px",
                paddingRight: "16px",
              }}
              key={item.label}
              onClick={() => onClickHandle(item.params)}
            >
              {item.label}
              <Typography
                sx={{
                  flexShrink: 0,
                  minWidth: "24px",
                  textAlign: "center",
                  color: blueColor,
                }}
              >
                {statusCounts.find(({ status }) => status === item.value)
                  ?.status_count || 0}
              </Typography>
            </Button>
          ))}
        </Box>
      )}
    </>
  );
};

const handleArchiveButtonClick = async () => {
  const confirmed = window.confirm(
    "Вы уверены, что хотите добавить заявления в архив?",
  );
  if (confirmed) {
    const senData = await postArchieve();
    if (senData) {
      toast.success("Успешно добавлены заявления в архив!");
    }
  }
};

const AdminMenu: React.FC<AdminMenuProps> = React.memo(() => {
  const [statusCounts, setStatusCounts] = useState<
    {
      status: string;
      status_count: number;
    }[]
  >([]);
  const user = useAppSelector(selectCurrentUser);

  const statusListItems: ListItemType[] = [
    { label: "Поступившие", value: "new", params: { status: 1 } },
    {
      label: "На доработке у исполнителя",
      value: "executor_rework",
      params: { status: 5 },
    },
    { label: "На согласовании", value: "agreement", params: { status: 2 } },
    { label: "На исполнении", value: "execution", params: { status: 3 } },

    ...([
      ROLES.directorApd.id,
      ROLES.specialistApd.id,
      ROLES.mainSpecialistApd.id,
    ].includes(user.role.id)
      ? [{ label: "Автоотказ", value: "overdue", params: { overdue: true } }]
      : []),

    {
      label: "На доработке у школ",
      value: "school_rework",
      params: { status: 4 },
    },
    { label: "Принятые", value: "accepted", params: { status: 6 } },

    ...([
      ROLES.directorDbf.id,
      ROLES.specialistDbf.id,
      ROLES.mainSpecialistDbf.id,
    ].includes(user.role.id)
      ? [{ label: "Заключено", value: "dbf_finished", params: { status: 10 } }]
      : []),

    { label: "Отклоненные", value: "declined", params: { status: 7 } },
  ];

  const menuItems: MenuItem[] = [
    {
      path: "/declarations",
      text: "Заявления",
      icon: <DescriptionOutlined />,
      statusList: statusListItems,
    },
    {
      path: "/outgoing-mails",
      text: "Исходящие письма",
      icon: <ForwardToInboxOutlined />,
    },
    {
      path: "/incoming-mails",
      text: "Входящие письма",
      icon: <MarkEmailUnreadOutlined />,
    },
    {
      path: "/declarations/enumeration",
      text: "Перечень школ",
      icon: <ArticleOutlined />,
    },
    {
      path: "",
      text: "Управление уведомлениями",
      icon: <DescriptionOutlined />,
    },
    { path: "", text: "CMS", icon: <DescriptionOutlined /> },
    {
      path: "",
      text: "Управление пользователями",
      icon: <DescriptionOutlined />,
    },
    {
      path: "",
      text: "Процессы",
      icon: <DescriptionOutlined />,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCountDeclarationsStatus();
        setStatusCounts(data);
      } catch (error) {
        console.error("Error fetching declaration status counts:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <List
      disablePadding={true}
      sx={{
        width: "310px",
        display: "flex",
        flexDirection: "column",
        // gap: "20px",
        backgroundColor: "#2C53F5",
        paddingTop: "40px",
      }}
    >
      {menuItems.map((item) => (
        <AdminMenuItem statusCounts={statusCounts} key={item.text} {...item} />
      ))}

      {role === ROLES.directorApd.id ? (
        <Button sx={{ mt: 15 }} onClick={handleArchiveButtonClick}>
          Добавить в архив
        </Button>
      ) : null}
    </List>
  );
});
export default AdminMenu;
