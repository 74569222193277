import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { SelectChangeEvent } from "@mui/material/Select";

import { getRemarkTypes } from "../../services/useEndpoint";
import {
  CheckListInterface,
  RejectModalProps,
  remarkType,
} from "../../types/modalTypes";

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const style_new = {
  maxHeight: "40vh",
  overflowY: "auto",
};

const selectRenderValueStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "98%",
};

export default function RejectionModal({
  id,
  open,
  checkList,
  onClose,
  submit,
  commentData,
}: RejectModalProps) {
  const initialRemarkTypeIds = commentData?.length
    ? commentData[0].remark_type_ids
    : [];

  const [selectedOptionsIds, setSelectedOptionsIds] =
    useState<any[]>(initialRemarkTypeIds);
  const [remarkTypes, setRemarkTypes] = useState<remarkType[]>();

  const [checkListState, setCheckListState] = useState<CheckListInterface>({
    form_correspondence: false,
    document_completeness: false,
    license_check: false,
    name_bin_correspondence: false,
    gz_members_registration: false,
    government_educational_order: false,
    preliminary_agreement: false,
    immutability_agreement: false,
  });

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const value = event.target.value;
    setSelectedOptionsIds(
      typeof value === "string"
        ? value.split(",").map((str) => parseInt(str, 10))
        : value,
    );
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckListState({
      ...checkListState,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    if (commentData?.length > 0) {
      const currentData = commentData[0];

      setSelectedOptionsIds(
        !!currentData.remark_type_ids
          ? currentData.remark_type_ids?.map((remarkType) => remarkType.id)
          : [],
      );
    }

    if (checkList) setCheckListState(checkList);
  }, [checkList, commentData]);

  useEffect(() => {
    if (!open) return;
    try {
      getRemarkTypes()
        .then((data) => {
          if (data) {
            setRemarkTypes(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          На отказ заявителю
        </Typography>
        <Box sx={{ marginBottom: "32px" }}>
          <Box>
            <InputLabel id="">Тип замечаний</InputLabel>
            <Select
              label="Тип замечаний"
              value={selectedOptionsIds}
              multiple
              required
              disabled={Boolean(checkList)}
              onChange={handleChange}
              renderValue={(selected) => (
                <Box sx={selectRenderValueStyle}>
                  {selected
                    .map(
                      (id) =>
                        remarkTypes?.find((type) => type.id === id)?.short_text,
                    )
                    .filter((name) => name)
                    .join(", ") || "Выберите из списка"}
                </Box>
              )}
              displayEmpty
              sx={{
                display: "flex",
                marginBottom: "24px",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiInputBase-input": {
                  justifyContent: "flex-start",
                  fontSize: "16px",
                },
              }}
            >
              {remarkTypes &&
                remarkTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox
                      checked={selectedOptionsIds.includes(option.id)}
                    />
                    <ListItemText primary={option.short_text} />
                  </MenuItem>
                ))}
            </Select>
          </Box>

          <Box sx={style_new}>
            <Typography
              sx={{ textAlign: "center", marginBottom: "32px" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {checkList ? "Проверочный лист" : "Создание проверочного листа"}
            </Typography>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={Boolean(checkList)}
                    checked={checkListState.form_correspondence}
                    onChange={handleCheckboxChange}
                    name="form_correspondence"
                  />
                }
                label="Соответствие заявления форме, согласно приложению 3 и (или) 3-1 (при наличии) к Правилам"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={Boolean(checkList)}
                    checked={checkListState.document_completeness}
                    onChange={handleCheckboxChange}
                    name="document_completeness"
                  />
                }
                label="Полнота представленных документов согласно пунктам 56 и 56-1 Правил"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={Boolean(checkList)}
                    checked={checkListState.license_check}
                    onChange={handleCheckboxChange}
                    name="license_check"
                  />
                }
                label="Проверка лицензии частной организации образования на занятие образовательной деятельностью на сайте www.elicense.kz"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={Boolean(checkList)}
                    checked={checkListState.name_bin_correspondence}
                    onChange={handleCheckboxChange}
                    name="name_bin_correspondence"
                  />
                }
                label="Соответствие наименования частной организации образования и БИН на сайте www.kgd.gov.kz и справке о государственной регистрации (перерегистрации) юридического лица (при наличии)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={Boolean(checkList)}
                    checked={checkListState.gz_members_registration}
                    onChange={handleCheckboxChange}
                    name="gz_members_registration"
                  />
                }
                label="Проверка регистрации частной организации образования на веб-портале в Реестре участников ГЗ"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={Boolean(checkList)}
                    checked={checkListState.government_educational_order}
                    onChange={handleCheckboxChange}
                    name="government_educational_order"
                  />
                }
                label="Установление факта получения частной организацией образования государственного образовательного заказа на среднее образование ранее в Реестре договоров веб-портала и в предыдущих Перечнях по объекту, указанному в заявлении"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={Boolean(checkList)}
                    checked={checkListState.preliminary_agreement}
                    onChange={handleCheckboxChange}
                    name="preliminary_agreement"
                  />
                }
                label="Действие предварительного договора на дату подачи заявления частной организации образования"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={Boolean(checkList)}
                    checked={checkListState.immutability_agreement}
                    onChange={handleCheckboxChange}
                    name="immutability_agreement"
                  />
                }
                label="Соглашения о неизменности целевого назначения с отметкой о произведенной регистрации регистрирующим органом в правовом кадастре обременения права на изменение целевого назначения объекта организации образования"
              />
            </FormGroup>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              width: "45%",
              color: "#2C53F5",
              border: "1px solid #2C53F5",
              backgroundColor: "#FFF",
            }}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            sx={{
              width: "45%",
              color: "#FFF",
              backgroundColor: "#EC5654",
            }}
            onClick={() => submit(id, selectedOptionsIds, checkListState)}
            disabled={!selectedOptionsIds?.length}
          >
            Отказать
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
