export enum EOutgoingMailType {
  REWORK = 4,
  APPROVE = 6,
  DECLINE = 7,
}

export interface IOutgoingMail {
  id: string;
  send_date: string;
  organization_name: string;
  mail_type: EOutgoingMailType;
  pdf: string;
  rework_period: string;
}
