import {
  Autocomplete,
  Button,
  FormControlProps,
  Grid,
  MenuItem,
  Stack,
  StackProps,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Form, Formik, useFormikContext } from "formik";
import { omit, pick } from "lodash";
import { useApplicationsQueryParams } from "../hooks/useApplicationsQueryParams";
import anAxiosApi from "../services/axiosApi";
import {
  IApplication,
  IApplicationStatus,
  IExecutor,
} from "../types/application";
import { AsyncAutocomplete } from "./AsyncAutocomplete";

const APPLCAITON_STATUSES = [
  { name: "Поступившие", status: 1 },
  { name: "На доработке у исполнителя", status: 5 },
  { name: "На согласовании", status: 2 },
  { name: "На исполнении", status: 3 },
  { name: "На доработке у школ", status: 4 },
  { name: "Принятые", status: 6 },
  { name: "Отклоненные", status: 7 },
];

const APPLICATION_TYPES = [
  { name: "Основной перечень", type: "main" },
  { name: "Дополнительный перечень", type: "additional" },
];

interface DeclarationsFiltersValues {
  date: string;
  status: IApplicationStatus | "";
  type: "main" | "additional";
  official_number: string;
  review_days_count: number;
  id: IApplication["id"] | null;
  current_executor: IExecutor | null;
}

interface DeclarationsFiltersProps extends StackProps {
  disabled?: boolean;
}

const DeclarationsFilters = ({
  disabled,
  ...props
}: DeclarationsFiltersProps) => {
  const [values, setQuery] = useApplicationsQueryParams();

  function handleSubmit(values: DeclarationsFiltersValues) {
    setQuery({
      page: 1,
      date: values.date,
      status: String(values.status),
      type: values.type,
      official_number: values.official_number,
      review_days_count: values.review_days_count,
      id: values.id,
      current_executor: values.current_executor
        ? pick(values.current_executor, ["organization_name", "id"])
        : null,
    });
  }

  function handleReset() {
    setQuery({
      page: 1,
      date: null,
      status: undefined,
      type: undefined,
      official_number: undefined,
      review_days_count: undefined,
      id: null,
      current_executor: null,
    });
  }

  return (
    <Formik
      initialValues={
        omit(values, [
          "page",
          "per_page",
          "search",
        ]) as DeclarationsFiltersValues
      }
      onSubmit={handleSubmit}
    >
      {({ resetForm }) => (
        <Stack component={Form} {...props}>
          <DeclarationsFiltersFormControls disabled={disabled} />

          <Stack direction="row" gap={1} sx={{ justifyContent: "flex-end" }}>
            <Button
              size="small"
              type="reset"
              variant="outlined"
              color="error"
              disabled={disabled}
              onClick={() => {
                resetForm();
                handleReset();
              }}
            >
              Очистить
            </Button>

            <Button
              size="small"
              type="submit"
              variant="contained"
              disabled={disabled}
            >
              Применить
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  );
};

const DeclarationsFiltersFormControls = ({
  disabled,
}: Pick<FormControlProps, "disabled">) => {
  const { values, setFieldValue, handleChange } =
    useFormikContext<DeclarationsFiltersValues>();

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <DatePicker
          label="Дата"
          value={values.date ? dayjs(values.date) : null}
          onChange={(date) => setFieldValue("date", date)}
          disabled={disabled}
          slotProps={{
            textField: {
              name: "date",
              fullWidth: true,
            },
          }}
        />
      </Grid>

      <Grid item lg={3} md={4} sm={6} xs={12}>
        <TextField
          select
          label="Статус"
          value={values.status}
          onChange={(event) => setFieldValue("status", event.target.value)}
          disabled={disabled}
          fullWidth
        >
          {APPLCAITON_STATUSES.map((status) => (
            <MenuItem key={status.status} value={status.status}>
              {status.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item lg={3} md={4} sm={6} xs={12}>
        <TextField
          select
          label="Тип"
          value={values.type}
          onChange={(event) => setFieldValue("type", event.target.value)}
          disabled={disabled}
          fullWidth
        >
          {APPLICATION_TYPES.map((type) => (
            <MenuItem key={type.type} value={type.type}>
              {type.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item lg={3} md={4} sm={6} xs={12}>
        <TextField
          name="id"
          label="ID Заявления"
          value={values.id}
          onChange={handleChange}
          disabled={disabled}
          fullWidth
        />
      </Grid>

      <Grid item lg={3} md={4} sm={6} xs={12}>
        <TextField
          name="official_number"
          label="БИН"
          value={values.official_number}
          onChange={handleChange}
          disabled={disabled}
          fullWidth
        />
      </Grid>

      <Grid item lg={3} md={4} sm={6} xs={12}>
        <TextField
          type="number"
          name="review_days_count"
          label="Срок рассмотрения"
          value={values.review_days_count}
          onChange={handleChange}
          disabled={disabled}
          fullWidth
        />
      </Grid>

      <Grid item lg={3} md={4} sm={6} xs={12}>
        {/* @ts-ignore */}
        <AsyncAutocomplete
          useQueryProps={({ open }) => ({
            queryKey: ["useGetExecutors"].filter(Boolean),
            queryFn: async ({ signal }) => {
              const executors = (
                await anAxiosApi.get("/application/apd/get-executors", {
                  signal,
                })
              ).data;
              const mainExecutors = (
                await anAxiosApi.get("/application/apd/get-main-executors", {
                  signal,
                })
              ).data;

              return [...executors.data, ...mainExecutors.data];
            },
            enabled: open,
          })}
        >
          {({ data: executors, AutocompleteProps, TextFieldProps }) => (
            <Autocomplete
              {...AutocompleteProps}
              value={values.current_executor}
              options={executors ?? []}
              onChange={(_, option) =>
                setFieldValue("current_executor", option)
              }
              getOptionLabel={(option) => option.organization_name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={disabled}
              renderInput={(props) => (
                <TextField
                  {...TextFieldProps(props)}
                  label="Исполнитель"
                  name="current_executor"
                  fullWidth
                />
              )}
            />
          )}
        </AsyncAutocomplete>
      </Grid>
    </Grid>
  );
};

export default DeclarationsFilters;
