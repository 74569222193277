import { Stack, TextField } from "@mui/material";
import BoxCard from "../../../components/BoxCard";
import { ChangeEvent, FC } from "react";
import { DetailsValues } from "../StudentProfile";

// import cl from './HomePage.module.scss';

interface Props {
  values: DetailsValues;
  touched: {
    official_number?: boolean;
    phone_number?: boolean;
    // official_address?: boolean;
    // actual_address?: boolean;
    // ceo_fullname?: boolean;
    // ceo_official_number?: boolean;
    email_address?: boolean;
    // postcode?: boolean;
    organization_name?: boolean;
  };
  errors: {
    official_number?: string;
    phone_number?: string;
    // official_address?: string;
    // actual_address?: string;
    // ceo_fullname?: string;
    // ceo_official_number?: string;
    email_address?: string;
    // postcode?: string;
    organization_name?: string;
  };
  editMode: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  // changeEditMode:()=>void;
}

const OrganizationDetails: FC<Props> = ({
  values,
  touched,
  errors,
  editMode,
  handleChange,
  // changeEditMode
}) => {
  //обработчики для полей БИН и ИИН
  const handleNumberChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = evt.target;
    if (/^[0-9]*$/.test(value)) {
      handleChange(evt);
    }
  };

  return (
    <BoxCard title={"Личные данные"}>
      <Stack
        direction={"row"}
        sx={{
          flexWrap: "wrap",
          "> *": { width: "calc(50% - 12px) !important" },
        }}
        gap={"24px"}
      >
        <TextField
          name="organization_name"
          value={values.organization_name}
          sx={{ width: "calc(50% - 12px)", marginBottom: "24px" }}
          label="ФИО"
          placeholder="Введите ФИО"
          error={
            Boolean(touched.organization_name && errors.organization_name) ||
            false
          }
          helperText={
            (touched.organization_name && errors.organization_name) || false
          }
          onChange={handleChange}
          disabled={!editMode}
        />
        <TextField
          name="official_number"
          value={values.official_number}
          sx={{ width: "auto" }}
          label="ИИН"
          placeholder="ИИН"
          inputProps={{
            maxLength: 12,
          }}
          error={
            Boolean(touched.official_number && errors.official_number) || false
          }
          helperText={
            (touched.official_number && errors.official_number) || false
          }
          onChange={handleNumberChange}
          disabled={!editMode}
        />
        <TextField
          name="phone_number"
          value={values.phone_number}
          sx={{ width: "auto" }}
          label="Телефон"
          placeholder="Введите телефон"
          error={Boolean(touched.phone_number && errors.phone_number) || false}
          helperText={(touched.phone_number && errors.phone_number) || false}
          onChange={handleChange}
          disabled={!editMode}
        />
        {/* <TextField
          name="official_address"
          value={values.official_address}
          sx={{ width: "auto" }}
          label="Юридический адрес"
          placeholder="Введите адрес"
          error={
            Boolean(touched.official_address && errors.official_address) ||
            false
          }
          helperText={
            (touched.official_address && errors.official_address) || false
          }
          onChange={handleChange}
          disabled={!editMode}
        />
        <TextField
          name="actual_address"
          value={values.actual_address}
          sx={{ width: "auto" }}
          label="Фактический адрес"
          placeholder="Введите адрес"
          error={
            Boolean(touched.actual_address && errors.actual_address) || false
          }
          helperText={
            (touched.actual_address && errors.actual_address) || false
          }
          onChange={handleChange}
          disabled={!editMode}
        /> */}

        <TextField
          name="email_address"
          value={values.email_address}
          sx={{ width: "auto" }}
          label="Электронная почта"
          placeholder="Введите электронную почту"
          error={
            Boolean(touched.email_address && errors.email_address) || false
          }
          helperText={(touched.email_address && errors.email_address) || false}
          onChange={handleChange}
          disabled={!editMode}
        />
        {/* <TextField
          name="postcode"
          value={values.postcode}
          sx={{ width: "auto" }}
          label="Почтовый адрес"
          placeholder="Введите почтовый адрес"
          error={Boolean(touched.postcode && errors.postcode) || false}
          helperText={(touched.postcode && errors.postcode) || false}
          onChange={handleChange}
          disabled={!editMode}
        />

        <TextField
          name="ceo_fullname"
          value={values.ceo_fullname}
          sx={{ width: "auto" }}
          label="ФИО директора"
          placeholder="Введите данные"
          error={Boolean(touched.ceo_fullname && errors.ceo_fullname) || false}
          helperText={(touched.ceo_fullname && errors.ceo_fullname) || false}
          onChange={handleChange}
          disabled={!editMode}
        />
        <TextField
          name="ceo_official_number"
          value={values.ceo_official_number}
          sx={{ width: "auto" }}
          label="ИНН директора"
          placeholder="Введите ИНН"
          inputProps={{
            maxLength: 12,
          }}
          error={
            Boolean(
              touched.ceo_official_number && errors.ceo_official_number,
            ) || false
          }
          helperText={
            (touched.ceo_official_number && errors.ceo_official_number) || false
          }
          onChange={handleNumberChange}
          disabled={!editMode}
        /> */}
      </Stack>
    </BoxCard>
  );
};

export default OrganizationDetails;
