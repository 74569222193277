import { AxiosRequestConfig } from "axios";
import anAxiosApi from "../../services/axiosApi";
import { Meta } from "../../types/types";
import { IApplication } from "../../types/application";

export const getApplications = async (config?: AxiosRequestConfig) => {
  const { data } = await anAxiosApi.get<{ data: IApplication[]; meta: Meta }>(
    "/application",
    config,
  );
  return data;
};

export const getArchiveApplications = async (config?: AxiosRequestConfig) => {
  const { data } = await anAxiosApi.get<{ data: IApplication[]; meta: Meta }>(
    "/application/get-archive",
    config,
  );
  return data;
};
