import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export interface ICurrentPage {
  currentPage: number;
  mailsCurrentPage: number;
  perPage: number;
}
const initialState: ICurrentPage = {
  currentPage: 0,
  mailsCurrentPage: 0,
  perPage: 0,
};

export const currentPageSlice = createSlice({
  name: "currentpage",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setMailsCurrentPage: (state, action) => {
      state.mailsCurrentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
  },
});

export const selectCurrentPage = (state: RootState) =>
  state.currentPageReducer.currentPage;

export const selectMailsCurrentPage = (state: RootState) =>
  state.currentPageReducer.mailsCurrentPage;

export const selectPerPage = (state: RootState) =>
  state.currentPageReducer.perPage;
export const { setCurrentPage, setMailsCurrentPage, setPerPage } =
  currentPageSlice.actions;
export default currentPageSlice.reducer;
