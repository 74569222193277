import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { Button, TextField } from "@mui/material";
import { TableRowsMini } from "../../../../types/tableRowsTypes";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectIsBoardingTouched,
  selectIsBoardingValid,
  selectIsSchoolTouched,
  selectIsSchoolValid,
  setIsBoardingTouched,
  setIsBoardingValid,
  setIsSchoolTouched,
  setIsSchoolValid,
} from "../../../../store/reducers/validateCadastralInfoSlice";
import IMask from "imask/holder";
import { InputMask } from "imask/esm/index";
import CustomEditableCell from "./CustomEditableCell";

export interface RowData {
  name: string;
  date: string;
  nomer: string;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  height: "auto",
  width: "100%",
  border: "1px solid #BFC5D9",
  borderRadius: "8px",
  overflow: "hidden",
  "& .MuiDataGrid-cell": {
    lineHeight: "unset !important",
    maxHeight: "none !important",
    whiteSpace: "normal",
    padding: 0,
    border: "1px solid #BFC5D9",
  },
  "& .MuiDataGrid-columnHeaders": {
    color: "#111520",
    fontWeight: "medium",
    backgroundColor: "#F1F3F9",
    height: "40px",
    "& .MuiButtonBase-root": {
      display: "none",
    },
  },
  "& .MuiDataGrid-renderingZone": {
    maxHeight: "none !important",
  },
  "& .MuiDataGrid-row": {
    width: "100%",
    maxHeight: "none !important",
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    overflow: "auto",
    lineHeight: "120%",
    whiteSpace: "normal",
    height: "100%",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },
}));

interface TableProps {
  rows: GridRowsProp;
  onRowSelected: (selectedRows: RowData[]) => void;
}
const placeholder = {
  target_value: "Введите целевое назначение",
  encumbrance_date: "Введите дату обременения",
  cadastral_number: "Введите кадастровый номер",
};

const BoardingColumns: GridColDef[] = [
  {
    field: "target_value",
    headerName:
      "Целевое назначение (согласно Кадастровому техническому паспорту)",
    flex: 1,
    editable: false,
    disableColumnMenu: false,
    renderCell: CustomEditableCell,
  },
  {
    field: "encumbrance_date",
    headerName: "Дата регистрации обременения",
    flex: 1,
    editable: false,
    disableColumnMenu: false,
    renderCell: CustomEditableCell,
  },
  {
    field: "cadastral_number",
    headerName: "Кадастровый номер (А3) с литером",
    flex: 1,
    editable: false,
    disableColumnMenu: false,
    renderCell: CustomEditableCell,
  },
];

const SchoolColumns: GridColDef[] = [
  {
    field: "target_value",
    headerName:
      "Целевое назначение (согласно Кадастровому техническому паспорту)",
    flex: 1,
    editable: false,
    disableColumnMenu: false,
    renderCell: CustomEditableCell,
  },
  {
    field: "encumbrance_date",
    headerName: "Дата регистрации обременения",
    flex: 1,
    editable: false,
    disableColumnMenu: false,
    renderCell: CustomEditableCell,
  },
  {
    field: "cadastral_number",
    headerName: "Кадастровый номер (А2) с литером",
    flex: 1,
    editable: false,
    disableColumnMenu: false,
    renderCell: CustomEditableCell,
  },
];

export interface TableDeclarationMiniProps {
  rows: TableRowsMini[];
  setRows: (e: TableRowsMini[]) => void;
  isInputDisabled?: boolean;
  isSchoolTable?: boolean;
}

const TableDeclarationMini: React.FC<TableDeclarationMiniProps> = ({
  rows,
  setRows,
  isInputDisabled,
  isSchoolTable,
}) => {
  const dispatch = useAppDispatch();
  const [invalidRows, setInvalidRows] = React.useState<
    Array<{ field: string; id: number }>
  >([]);
  const handleCellValueChange = (id: number, field: string, newValue: any) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: newValue };
      }
      return row;
    });
    setRows(updatedRows);
  };
  const columns = isSchoolTable ? SchoolColumns : BoardingColumns;

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <StyledDataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
          renderCell: (params) => (
            <CustomEditableCell
              {...params}
              placeholder={placeholder}
              disabled={isInputDisabled}
              onCellValueChange={handleCellValueChange}
              isSchoolTable={isSchoolTable}
              setInvalidRows={setInvalidRows}
              invalidRows={invalidRows}
            />
          ),
        }))}
        hideFooter={true}
        hideFooterPagination={true}
        disableColumnMenu={true}
        disableColumnSelector={true}
      />
      {!isInputDisabled && (
        <Button
          sx={{
            mt: "25px",
          }}
          onClick={() => {
            if (rows.length < 15) {
              const newRow = rows.concat({
                cadastral_number: "",
                encumbrance_date: "",
                id: rows.length + 1,
                target_value: "",
              });
              setRows(newRow);
              dispatch(
                isSchoolTable
                  ? setIsSchoolTouched(false)
                  : setIsBoardingTouched(false),
              );
            } else {
              console.log("Достигнут лимит в 15 строк");
            }
          }}
        >
          Добавить строку
        </Button>
      )}
    </div>
  );
};

export default TableDeclarationMini;
