import { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";

import { AsyncAutocompleteProps } from "./types";
import useAsyncAutocomplete from "./useAsyncAutocomplete";
import { EAutocompleteTextVariants } from "./constants";

const AsyncAutocomplete = <
  Config,
  Value,
  Result = Config,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  useQueryProps,
  children,
}: AsyncAutocompleteProps<
  Config,
  Value,
  Result,
  Multiple,
  DisableClearable,
  FreeSolo
>) => {
  const {
    useQueryProps: _useQueryProps,
    handleClose,
    handleOpen,
    setQuery,
  } = useAsyncAutocomplete(useQueryProps);
  const useQueryResult = useQuery(_useQueryProps);

  return children({
    ...useQueryResult,
    AutocompleteProps: {
      autoHighlight: true,
      loading: useQueryResult.isLoading,
      loadingText: EAutocompleteTextVariants.Loading,
      noOptionsText: useQueryResult.isError
        ? EAutocompleteTextVariants.Error
        : !((useQueryResult.data || []) as Value[]).length
          ? EAutocompleteTextVariants.Empty
          : EAutocompleteTextVariants.EnterValue,
      onInputChange: (_, value, reason) => {
        reason === "input" && setQuery(value ? value : null);
      },
      onOpen: handleOpen,
      onClose: handleClose,
    },
    TextFieldProps: (params) => ({
      ...params,
      InputProps: {
        ...params.InputProps,
        endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
      },
    }),
  });
};

export default AsyncAutocomplete;
