import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";

import { ApproveModalProps, CheckListInterface } from "../../types/modalTypes";

const style = {
  position: "absolute",
  zIndex: 5555,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  maxHeight: "100vh",
  p: 4,
};

const style_new = {
  maxHeight: "40vh",
  overflowY: "auto",
};

export default function ApproveModalCheckList({
  open,
  onClose,
  id,
  submit,
}: ApproveModalProps) {
  const [checkListState, setCheckListState] = useState<CheckListInterface>({
    form_correspondence: false,
    document_completeness: false,
    license_check: false,
    name_bin_correspondence: false,
    gz_members_registration: false,
    government_educational_order: false,
    preliminary_agreement: false,
    immutability_agreement: false,
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckListState({
      ...checkListState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ marginBottom: "32px" }}>
          <Box>
            <Box sx={style_new}>
              <Typography
                sx={{ textAlign: "center", marginBottom: "32px" }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Создание проверочного листа
              </Typography>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkListState.form_correspondence}
                      onChange={handleCheckboxChange}
                      name="form_correspondence"
                    />
                  }
                  label="Соответствие заявления форме, согласно приложению 3 и (или) 3-1 (при наличии) к Правилам"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkListState.document_completeness}
                      onChange={handleCheckboxChange}
                      name="document_completeness"
                    />
                  }
                  label="Полнота представленных документов согласно пунктам 56 и 56-1 Правил"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkListState.license_check}
                      onChange={handleCheckboxChange}
                      name="license_check"
                    />
                  }
                  label="Проверка лицензии частной организации образования на занятие образовательной деятельностью на сайте www.elicense.kz"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkListState.name_bin_correspondence}
                      onChange={handleCheckboxChange}
                      name="name_bin_correspondence"
                    />
                  }
                  label="Соответствие наименования частной организации образования и БИН на сайте www.kgd.gov.kz и справке о государственной регистрации (перерегистрации) юридического лица (при наличии)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkListState.gz_members_registration}
                      onChange={handleCheckboxChange}
                      name="gz_members_registration"
                    />
                  }
                  label="Проверка регистрации частной организации образования на веб-портале в Реестре участников ГЗ"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkListState.government_educational_order}
                      onChange={handleCheckboxChange}
                      name="government_educational_order"
                    />
                  }
                  label="Установление факта получения частной организацией образования государственного образовательного заказа на среднее образование ранее в Реестре договоров веб-портала и в предыдущих Перечнях по объекту, указанному в заявлении"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkListState.preliminary_agreement}
                      onChange={handleCheckboxChange}
                      name="preliminary_agreement"
                    />
                  }
                  label="Действие предварительного договора на дату подачи заявления частной организации образования"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkListState.immutability_agreement}
                      onChange={handleCheckboxChange}
                      name="immutability_agreement"
                    />
                  }
                  label="Соглашения о неизменности целевого назначения с отметкой о произведенной регистрации регистрирующим органом в правовом кадастре обременения права на изменение целевого назначения объекта организации образования"
                />
              </FormGroup>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              width: "45%",
              // color: '#2C53F5',
              // border: '1px solid #2C53F5',
              // backgroundColor: '#FFF',
            }}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            onClick={() => submit(id, checkListState)}
            sx={{
              width: "45%",
              // color: '#2C53F5',
              // border: '1px solid #2C53F5',
            }}
          >
            Отправить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
