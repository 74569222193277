// @ts-nocheck

import { Download, TimelineOutlined } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material/";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BoxCard from "../../components/BoxCard";
import BoxTitle from "../../components/BoxTitle";
import Comments from "../../components/Comments";
import CommentsConclusion from "../../components/CommentsConclusion";
import DeclarationHistory from "../../components/DeclarationHistory";
import FileDownloadField from "../../components/FileDownloadField";
import LoadingBar from "../../components/LoadingBar";
import { EDS } from "../../components/Modals/EDS";
import ExecutorReworkModal from "../../components/Modals/ExecutorReworkModal";
import TableBoardingData from "../../components/TableBoardingData";
import {
  acceptRequest,
  approveDecline,
  approveSchoolRework,
  executorRework,
  getDeclineFile,
  getDocumentForSign,
  getRevisionFile,
  getRevokeApplicationsDocument,
  getSchoolDeclarationById,
  getSignersDocument,
  signDocument,
  storeSchoolDeclinePdf,
  storeSchoolReworkPdf,
} from "../../services/useEndpoint";
import { useAppSelector } from "../../store/hooks";
import { selectCurrentUser } from "../../store/reducers/currentUserReducer";
import {
  CheckListInterface,
  CommentInterface,
  remarkType,
} from "../../types/modalTypes";
import {
  BREAKPOINT_DATES,
  COMMENTS_TYPES,
  EXECUTOR_TYPES,
} from "../../utils/constants";
import { downloadFile } from "../../utils/helpers";
import { NcaLayer } from "../../utils/nca-layer";
import { TableRowsMini } from "../SchoolDeclarationCreate/components/DeclarationStep2";
import TableDeclarationMini from "../SchoolDeclarationCreate/components/TableDeclarationMini/TableDeclarationMini";
import CheckListModal from "../SpecialistDeclarationsData/components/CheckListModal";
import ConfirmationModal from "./components/ComfirmationModal";
import FilePreview from "./components/FilePreview";
import PreviewDocumentDialog from "./components/PreviewDocumentDialog";
import PupilsTable from "./components/PupilsTable";

const StyledTypography = styled(Typography)({
  fontSize: "14px",
  lineHeight: "1.5em",
  marginBottom: "10px",
  marginTop: "10px",
});

const rows: TableRowsMini[] = [
  {
    id: 1,
    target_value: "",
    encumbrance_date: "",
    cadastral_number: "",
  },
];

export default function DeclarationsData() {
  const user = useAppSelector(selectCurrentUser);

  const [isBoardingSchool, setIsBoardingSchool] = useState("");
  const [declaration, setDeclarations] = useState();
  const [contingentDataTable, setContingentDataTable] = useState();
  const [tableDeclarationsMini, setTableDeclarationsMini] = useState(rows);
  const [tableDeclarationsMiniTwo, setTableDeclarationsMiniTwo] =
    useState(rows);
  const [predictiveContingentDataTable, setPredictiveContingentDataTable] =
    useState();
  const [boardingContingentDataTable, setBoardingContingentDataTable] =
    useState();
  const [
    boardingPredictiveContingentDataTable,
    setBoardingPredictiveContingentDataTable,
  ] = useState();
  const currentUserId = useAppSelector(selectCurrentUser).id;
  const [EDSopen, setEDSopen] = useState(false);

  const [, setIsApplicationDownloading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [, setMainSpecialistComment] = useState<{
    names: string[];
    comment: string;
  }>({ names: [], comment: "" });

  const [specialistComment, setSpecialistComment] = useState<{
    names: string[];
    comment: string;
  }>({ names: [], comment: "" });
  const [pdfUrl] = useState();
  const [loadingPrewiewFile] = useState(false);
  const [isDecline] = useState(false);
  const [isRevision] = useState(false);

  const [PDFLoading, setPDFLoading] = useState(false);
  const [checkListPDFLoading, setCheckListPDFLoading] = useState(false);
  const [DBFCheckListPDFLoading, setDBFCheckListPDFLoading] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [checkListModalOpen, setCheckListModalOpen] = useState(false);
  const [executorReworkModalOpen, setExecutorReworkModalOpen] = useState(false);

  const [schoolDeclinePdf, setSchoolDeclinePdf] = useState<File | null>(null);
  const [previewSchoolDeclineOpened, setOpenedPreviewSchoolDecline] =
    useState(false);

  const [schoolReworkPdf, setSchoolReworkPdf] = useState<File | null>(null);
  const [previewSchoolReworkOpened, setOpenedPreviewSchoolRework] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const mainSpecialistComment = declaration?.data.comments?.find(
      (item: CommentInterface) =>
        item.executor_type === EXECUTOR_TYPES.mainSpecialist,
    );
    if (mainSpecialistComment) {
      const names = mainSpecialistComment.remark_type_ids?.map(
        (item: remarkType) => item.short_text,
      );
      setMainSpecialistComment({
        names,
        comment: mainSpecialistComment.comment,
      });
    }
    const specialistComment = declaration?.data.comments?.find(
      (item: CommentInterface) =>
        item.executor_type === EXECUTOR_TYPES.specialist,
    );
    if (specialistComment) {
      const names = specialistComment.remark_type_ids?.map(
        (item: remarkType) => item.short_text,
      );
      setSpecialistComment({
        names,
        comment: specialistComment.comment,
      });
    }
  }, [declaration?.data?.comments]);

  const handleDownloadDBFCheckListPDF = () => {
    setDBFCheckListPDFLoading(true);

    getSignersDocument({ file_path: declaration?.data?.dbf_check_list?.pdf })
      .then((data) => {
        downloadFile(data, "Проверочный лист ДБФ1.pdf", "application/pdf");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setDBFCheckListPDFLoading(false);
      });
  };

  const handleDownloadCheckListPDF = () => {
    setCheckListPDFLoading(true);

    getSignersDocument({ file_path: declaration?.data.check_list.pdf })
      .then((data) => {
        downloadFile(data, "Проверочный лист АПД.pdf", "application/pdf");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setCheckListPDFLoading(false);
      });
  };

  const handleDownloadPDF = () => {
    setPDFLoading(true);

    getSignersDocument({ file_path: declaration?.data?.pdf })
      .then((data) => {
        downloadFile(data, "Заявление.pdf", "application/pdf");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setPDFLoading(false);
      });
  };

  function fetchDeclarationData() {
    getSchoolDeclarationById(id)
      .then((data) => {
        if (data) {
          setDeclarations(data);
          setContingentDataTable(JSON.parse(data.data.students_data_table));
          setPredictiveContingentDataTable(
            JSON.parse(data.data.predictive_contingent_data_table),
          );
          setBoardingContingentDataTable(
            JSON.parse(data.data.boarding_school_students_data_table),
          );
          setBoardingPredictiveContingentDataTable(
            JSON.parse(
              data.data.boarding_school_predictive_contingent_data_table,
            ),
          );
          setTableDeclarationsMini(JSON.parse(data?.data?.cadastral_table));
          setTableDeclarationsMiniTwo(
            JSON.parse(data?.data?.boarding_school_cadastral_table),
          );
          setIsBoardingSchool(data?.data?.boarding_school);
          console.log(data?.data?.boarding_school);
        } else {
          console.log("School data failed. Please check your credentials.");
        }
      })
      .catch((error) => {
        console.error("An error occurred during school data download:", error);
      });
  }

  useEffect(() => {
    fetchDeclarationData();
  }, []);

  async function handleEDS(base64string: string) {
    try {
      return await NcaLayer.sign(base64string);
    } catch (error) {
      toast.error("При подписании документа произошла ошибка");
    }
  }

  const submitApprove = async (
    id: number | null,
    comment: string,
    type: Array<number>,
    checkListprop: CheckListInterface,
  ) => {
    if (!id) return;

    setIsLoading(true);

    try {
      const base64string = await getDocumentForSign({
        filePath: declaration?.data?.check_list?.pdf,
      });

      const signature = await handleEDS(base64string);
      if (!signature) return;

      await signDocument(signature, declaration?.data?.check_list?.pdf);
      toast.success("Проверочный лист успешно подписан!");

      await acceptRequest(id);

      toast.success("Заявление успешно согласовано");
      fetchDeclarationData();
      navigate("/director/declarations");
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "Не удалось согласовать заявление",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitRevision = async (
    id: number,
    comment: string,
    types: Array<number>,
  ) => {
    setIsLoading(true);

    try {
      await executorRework(id, comment, types);
      toast.success("Заявление возвращено на доработку специалисту");
      setExecutorReworkModalOpen(false);

      navigate("/director/declarations");
      fetchDeclarationData();
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Ошибка при отправке на доработку",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const previewSchoolRework = async () => {
    setIsLoading(true);

    try {
      const base64string = await getDocumentForSign({
        filePath: declaration?.data.check_list.pdf,
      });
      const signature = await handleEDS(base64string);
      if (!signature) return;

      await signDocument(signature, declaration?.data.check_list.pdf);
      toast.success("Проверочный лист успешно подписан!");
      const pdfFile = await getRevisionFile(declaration?.data.id);

      setSchoolReworkPdf(pdfFile);
      setOpenedPreviewSchoolRework(true);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Не удалось отправить на доработку",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const previewSchoolDecline = async () => {
    setIsLoading(true);

    try {
      const base64string = await getDocumentForSign({
        filePath: declaration?.data.check_list.pdf,
      });
      const signature = await handleEDS(base64string);
      if (!signature) return;

      await signDocument(signature, declaration?.data.check_list.pdf);
      toast.success("Проверочный лист успешно подписан!");
      const pdfFile = await getDeclineFile(declaration?.data.id);

      setSchoolDeclinePdf(pdfFile);
      setOpenedPreviewSchoolDecline(true);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Не удалось отказать");
    } finally {
      setIsLoading(false);
    }
  };

  const submitSchoolRework = async () => {
    setIsLoading(true);

    try {
      const response = await storeSchoolReworkPdf(declaration?.data.id);

      const signature = await handleEDS(response.base64_content);
      if (!signature) return;

      await signDocument(signature, response.file_path);
      await approveSchoolRework(declaration?.data.id, {
        pdf: response.file_path,
      });

      setOpenedPreviewSchoolRework(false);
      setSchoolReworkPdf(null);
      toast.success("Заявка была отправлена на доработку школе");
      fetchDeclarationData();
      navigate("/director/declarations");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Не удалось отправить на доработку",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitSchoolDecline = async () => {
    setIsLoading(true);

    try {
      const response = await storeSchoolDeclinePdf(declaration?.data.id);

      const signature = await handleEDS(response.base64_content);
      if (!signature) return;

      await signDocument(signature, response.file_path);
      await approveDecline(declaration?.data.id, { pdf: response.file_path });

      setOpenedPreviewSchoolDecline(false);
      setSchoolDeclinePdf(null);
      toast.success("Заявка была отказана");
      fetchDeclarationData();
      navigate("/director/declarations");
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Не удалось отказать");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadApplication = async () => {
    setIsApplicationDownloading(true);

    getRevokeApplicationsDocument(id)
      .then((res) => {
        const data = new Blob([res], { type: "application/pdf" });
        const pdfURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement("a");
        tempLink.href = pdfURL;
        tempLink.download = "document.pdf";
        document.body.appendChild(tempLink);
        tempLink.click();
        window.URL.revokeObjectURL(pdfURL);
        tempLink.remove();
      })
      .catch(() => {
        toast.error("Ошибка при скачивания заявления");
      })
      .finally(() => {
        setIsApplicationDownloading(false);
      });
  };

  return (
    <div style={{ padding: "40px 20px", maxWidth: "1130px" }}>
      <BoxTitle
        title={
          "Заявление на размещение гос. образовательного заказа на среднее образование"
        }
      >
        <FormControl fullWidth>
          {declaration?.data?.status.id !== 4 &&
            declaration?.data?.status.id !== 6 &&
            declaration?.data?.status.id !== 7 &&
            declaration?.data?.status.id !== 9 && (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={""}
                displayEmpty
                sx={{
                  width: "300px",
                  backgroundColor: "#2C53F5",
                  color: "#FFF",
                  fill: "#FFF",
                  ".MuiOutlinedInput-notchedOutline": {
                    //borderColor: "rgba(228, 219, 233, 0.25)",
                    borderColor: "transparent !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    // borderColor: "rgba(228, 219, 233, 0.25)",
                    borderColor: "transparent !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    // borderColor: "rgba(228, 219, 233, 0.25)",
                    borderColor: "transparent !important",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "white !important",
                  },
                  ".MuiInputBase-input": {
                    justifyContent: "center",
                  },
                }}
              >
                <MenuItem value="" disabled sx={{ display: "none" }}>
                  {declaration?.data?.status.id === 1
                    ? "Назначение исполнителя"
                    : "Согласовать"}
                </MenuItem>

                <MenuItem onClick={() => setConfirmationModalOpen(true)}>
                  Назначение исполнителя
                </MenuItem>

                {user.id === declaration?.data?.current_executor?.id && (
                  <>
                    {Boolean(
                      declaration?.data?.comments.find(
                        (comment: any) =>
                          comment.type === COMMENTS_TYPES.schoolAprrove,
                      ),
                    ) && (
                      <MenuItem onClick={() => setCheckListModalOpen(true)}>
                        Согласовать
                      </MenuItem>
                    )}

                    <MenuItem onClick={() => setExecutorReworkModalOpen(true)}>
                      На доработку исполнителю
                    </MenuItem>

                    {Boolean(
                      declaration?.data?.comments.find(
                        (comment: any) =>
                          comment.type === COMMENTS_TYPES.schoolRework,
                      ),
                    ) &&
                      declaration?.data?.reworked !== true && (
                        <MenuItem
                          disabled={isLoading}
                          onClick={previewSchoolRework}
                        >
                          Отправить заявителю
                        </MenuItem>
                      )}
                  </>
                )}

                {Boolean(
                  declaration?.data?.comments.find(
                    (comment: any) =>
                      comment.type === COMMENTS_TYPES.schoolDecline,
                  ),
                ) &&
                  declaration?.data?.reworked && (
                    <MenuItem
                      disabled={isLoading}
                      onClick={previewSchoolDecline}
                    >
                      Отказать заявителю
                    </MenuItem>
                  )}
              </Select>
            )}

          {(isDecline || isRevision) && (
            <Button
              sx={{ minWidth: "200px", mt: 3 }}
              onClick={() => setEDSopen(true)}
            >
              Подписать
            </Button>
          )}
        </FormControl>
      </BoxTitle>

      <Stack sx={{ mb: 1, gap: 1, alignItems: "flex-start" }}>
        <Button
          variant={"outlined"}
          size={"small"}
          onClick={handleDownloadPDF}
          disabled={PDFLoading}
          startIcon={
            <Download
              style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
            />
          }
        >
          Скачать заявление
        </Button>

        <DeclarationHistory applicationId={declaration?.data?.id}>
          {(open) => (
            <Button
              variant={"outlined"}
              size={"small"}
              onClick={open}
              startIcon={
                <TimelineOutlined
                  style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
                />
              }
            >
              История
            </Button>
          )}
        </DeclarationHistory>

        {declaration?.data?.dbf_check_list &&
          declaration?.data?.dbf_check_list?.pdf && (
            <Button
              variant={"outlined"}
              size={"small"}
              onClick={handleDownloadDBFCheckListPDF}
              disabled={DBFCheckListPDFLoading}
              startIcon={
                <Download
                  style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
                />
              }
            >
              Проверочный лист ДБФ1
            </Button>
          )}

        {declaration?.data?.check_list &&
          declaration?.data?.check_list?.pdf && (
            <Button
              variant={"outlined"}
              size={"small"}
              onClick={handleDownloadCheckListPDF}
              disabled={checkListPDFLoading}
              startIcon={
                <Download
                  style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
                />
              }
            >
              Проверочный лист АПД
            </Button>
          )}
      </Stack>

      {pdfUrl && <FilePreview isLoading={loadingPrewiewFile} pdfUrl={pdfUrl} />}

      {declaration?.data?.status?.id === 9 && (
        <Button
          startIcon={
            <DownloadIcon
              style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
            />
          }
          variant={"outlined"}
          size={"small"}
          onClick={handleDownloadApplication}
        >
          Письмо на отзыв
        </Button>
      )}

      {declaration?.data?.dbf_finished && declaration?.data?.dbf_conclusion && (
        <Stack sx={{ mb: 1 }}>
          <CommentsConclusion
            title="Заключение ДБФ1:"
            type={declaration?.data?.dbf_conclusion.type}
          />
        </Stack>
      )}

      {declaration?.data && (
        <>
          {Boolean(declaration?.data?.comments?.length) && (
            <>
              {(() => {
                const comment = declaration?.data.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.executorRework,
                );

                if (!comment) return null;

                return (
                  <Comments
                    title={
                      comment.executor_type === EXECUTOR_TYPES.mainSpecialist
                        ? "Замечания и комментарии главного специалиста:"
                        : "Замечания и комментарии директора:"
                    }
                    remarkTypes={[]}
                    comment={comment.comment}
                  />
                );
              })()}

              {(() => {
                const comment = declaration?.data.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.schoolRework,
                );

                if (!comment) return null;

                return (
                  <Comments
                    title="Замечания и комментарии специалиста:"
                    remarkTypes={comment.remark_type_ids?.map(
                      (remarkType: any) => remarkType.short_text,
                    )}
                    comment={comment.comment}
                  />
                );
              })()}

              {(() => {
                const comment = declaration?.data.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.schoolDecline,
                );

                if (!comment) return null;

                return (
                  <Comments
                    title="Замечания и комментарии специалиста:"
                    remarkTypes={comment.remark_type_ids?.map(
                      (remarkType: any) => remarkType.short_text,
                    )}
                    comment={comment.comment}
                  />
                );
              })()}

              {(() => {
                const comment = declaration?.data.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.schoolAprrove,
                );

                if (!comment) return null;

                return (
                  <Comments
                    title="Замечания и комментарии специалиста:"
                    remarkTypes={[]}
                    comment={comment.comment}
                  />
                );
              })()}
            </>
          )}
        </>
      )}

      <Stack direction={"column"} spacing={3} sx={{ marginTop: "32px" }}>
        <BoxCard title={"Сведения об организации"}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                name="region"
                value={declaration?.data.organization.organization_name}
                label="Наименование частной организации"
                placeholder="Наименование частной организации"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="city"
                value={declaration?.data.organization.official_number}
                label="БИН"
                placeholder="БИН"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="area_and_street"
                value={declaration?.data.organization.official_address}
                label="Юридический адрес"
                placeholder="Юридический адрес"
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="signatory_name"
                value={declaration?.data.signatory_name}
                label="ФИО подписанта (руководитель или представитель по доверенности)"
                placeholder="ФИО подписанта (руководитель или представитель по доверенности)"
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="signatory_name"
                value={declaration?.data.signatory_official_number}
                label="ИИН подписанта"
                placeholder="ИИН подписанта"
                disabled
              />
            </Grid>

            {declaration?.data.signatory_pdf && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  component="a"
                  href={declaration?.data.signatory_pdf}
                  target="_blank"
                  download
                  rel="noreferrer"
                  size="small"
                  endIcon={<DownloadIcon />}
                >
                  Скачать доверенность
                </Button>
              </Grid>
            )}
          </Grid>
        </BoxCard>
        <BoxCard title={"Общие данные"}>
          <Grid container spacing={1} sx={{ marginBottom: "10px" }}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="elementary_education"
                    checked={Boolean(declaration?.data.elementary_education)}
                  />
                }
                label="Начального образования"
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="basic_secondary_education"
                    checked={Boolean(
                      declaration?.data.basic_secondary_education,
                    )}
                  />
                }
                label="Основного среднего образования"
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="basic_general_education"
                    checked={Boolean(declaration?.data.basic_general_education)}
                  />
                }
                label="Общего среднего образования"
                disabled={true}
              />
            </Grid>
            <Grid item xs={9}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="integrated_education"
                    checked={Boolean(declaration?.data.integrated_education)}
                  />
                }
                label="По самостоятельно разработанным интегрированным образовательным программам, прошедшим авторизацию в Орг. Межд. Бак-та или межд. институциональную аккредитацию образования"
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                name="tuition_fee"
                value={declaration?.data.tuition_fee}
                label={
                  <span style={{ whiteSpace: "pre-line" }}>
                    Размер родительской платы за обучение, тенге в год
                  </span>
                }
                placeholder="Размер родительской платы за обучение, тенге в год"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="national_educational_db_registration"
                value={declaration?.data.national_educational_db_registration}
                label="Регистрация в Нац.образовательной базе данных (НОБД)"
                placeholder="Регистрация в Нац.образовательной базе данных (НОБД)"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="state_procurements_registration"
                value={declaration?.data.state_procurements_registration}
                label="Регистрация на веб-портале государственных закупок"
                placeholder="Регистрация на веб-портале государственных закупок"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="boarding_school"
                value={declaration?.data.boarding_school}
                label="Наличие интерната при школе"
                disabled
                placeholder="Наличие интерната при школе"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="student_contingent"
                value={declaration?.data.student_contingent}
                label="Фактический контингент учащихся"
                disabled
                placeholder="Фактический контингент учащихся"
              />
            </Grid>
            <Grid item xs={4} position={"relative"}>
              <Typography
                sx={{
                  position: "absolute",
                  top: "50%",
                  translateY: "-50%",
                }}
              >
                №
              </Typography>
              <TextField
                name="license_number"
                value={declaration?.data?.license_number}
                label="Номер лицензии (кроме АОО)"
                disabled
                placeholder="Номер лицензии (кроме АОО)"
                sx={{ paddingLeft: "20px" }}
              />
            </Grid>
            <Grid item xs={4} position={"relative"}>
              <Typography
                sx={{
                  position: "absolute",
                  top: "50%",
                  translateY: "-50%",
                }}
              >
                ID
              </Typography>
              <TextField
                name="nobd_id"
                value={declaration?.data?.nobd_id}
                label="Номер ID НОБД"
                disabled
                placeholder="Номер ID НОБД"
                sx={{ paddingLeft: "20px" }}
              />
            </Grid>
          </Grid>
        </BoxCard>

        <BoxCard title="данные по учащимся">
          <Box
            sx={{ backgroundColor: "white", borderRadius: "10px" }}
            // p={3}
            // mt={2}
          >
            <Typography
              component="h3"
              fontSize={"14px"}
              fontWeight={500}
              mb={2}
            >
              По состоянию на дату подачи заявления:
            </Typography>
            {contingentDataTable && <PupilsTable rows={contingentDataTable} />}
            {/*{contingentDataTable && <PupilsTable rows={contingentDataTable} />}*/}
          </Box>
        </BoxCard>

        <BoxCard
          sx={{ backgroundColor: "white", borderRadius: "10px" }}
          // p={3}
          // mt={2}
        >
          <Typography component="h3" fontSize={"14px"} fontWeight={500} mb={2}>
            Прогнозный контингент учащихся на 1 сентября предстоящего учебного
            года:
          </Typography>
          {predictiveContingentDataTable && (
            <PupilsTable rows={predictiveContingentDataTable} />
          )}
          {/*{predictiveContingentDataTable && (*/}
          {/*  <PupilsTable rows={predictiveContingentDataTable} />*/}
          {/*)}*/}
        </BoxCard>

        {isBoardingSchool === "Есть" && (
          <>
            <BoxCard title="Данные по учащимся интернат">
              <Box
                sx={{ backgroundColor: "white", borderRadius: "10px" }}
                // p={3}
                // mt={2}
              >
                <Typography
                  component="h3"
                  fontSize={"14px"}
                  fontWeight={500}
                  mb={2}
                >
                  По состоянию на дату подачи заявления:
                </Typography>
                <TableBoardingData rows={boardingContingentDataTable} />
                {/*{contingentDataTable && <PupilsTable rows={contingentDataTable} />}*/}
              </Box>
            </BoxCard>

            <BoxCard
              sx={{ backgroundColor: "white", borderRadius: "10px" }}
              // p={3}
              // mt={2}
            >
              <Typography
                component="h3"
                fontSize={"14px"}
                fontWeight={500}
                mb={2}
              >
                Прогнозный контингент учащихся на 1 сентября предстоящего
                учебного года:
              </Typography>
              <TableBoardingData rows={boardingPredictiveContingentDataTable} />

              {/*{predictiveContingentDataTable && (*/}
              {/*  <PupilsTable rows={predictiveContingentDataTable} />*/}
              {/*)}*/}
            </BoxCard>
          </>
        )}

        <BoxCard title={"Характеристики объекта"}>
          <Grid container spacing={1}>
            {declaration?.data.region && (
              <Grid item xs={4}>
                <TextField
                  name="region"
                  value={declaration?.data?.region?.name}
                  label="Регион"
                  placeholder="Регион"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}
            {declaration?.data.area && (
              <Grid item xs={4}>
                <TextField
                  name="area"
                  value={declaration?.data?.area?.name}
                  label="Район"
                  placeholder="Район"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}
            {declaration?.data?.district && (
              <Grid item xs={4}>
                <TextField
                  name="district"
                  value={declaration?.data?.district?.name}
                  label="Округ"
                  placeholder="Округ"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}

            <Grid item xs={4}>
              <TextField
                name="locality"
                value={declaration?.data?.locality?.name}
                label="Населенный пункт"
                placeholder="Населенный пункт"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="street"
                value={declaration?.data.street}
                label="Улица/Микрорайон/Жилой массив и т.п."
                placeholder="Улица/Микрорайон/Жилой массив и т.п."
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="house_number"
                value={declaration?.data?.house_number}
                label="№ дома/здания"
                placeholder="№ дома/здания"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="school_name"
                value={declaration?.data.school_name}
                label="Наименование школы"
                disabled
                placeholder="Наименование школы"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="place_count"
                value={declaration?.data.place_count}
                label="Проектная мощность (ученических мест)"
                disabled
                placeholder="Проектная мощность (ученических мест)"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="exploitation_year"
                value={declaration?.data.exploitation_year}
                label="Дата ввода в эксплуатацию "
                placeholder="Дата ввода в эксплуатацию "
                id="exploitation_year"
                type="date"
                disabled
                //defaultValue="2017-05-24"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="registered_immutability_agreement"
                    checked={Boolean(
                      declaration?.data.registered_immutability_agreement,
                    )}
                  />
                }
                label="Наличие зарегистрированного Соглашения о неизменности целевого назначения объекта организации среднего образования (А2)"
                disabled={true}
              />
            </Grid>
          </Grid>
        </BoxCard>
        {new Date(BREAKPOINT_DATES.school) <
          new Date(declaration?.data?.exploitation_year) && (
          <BoxCard
            title={
              "Заполняется частной организацией образования с вновь введенными ученическими местами в объекте частной организации образования, введенными в эксплуатацию после 20 июля 2018 года"
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  name="private_organization_place_count"
                  value={declaration?.data.private_organization_place_count}
                  label="Количество вновь введенных ученических мест"
                  placeholder="Количество вновь введенных ученических мест"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="private_organization_exploitation_type"
                  value={
                    declaration?.data.private_organization_exploitation_type
                  }
                  label="ЧОО введено в эксплуатацию путем"
                  placeholder="ЧОО введено в эксплуатацию путем"
                  disabled
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  name="school_name"
                  value={declaration?.data.school_name}
                  label="Наименование школы"
                  disabled
                  placeholder="Наименование школы"
                />
              </Grid>
            </Grid>
          </BoxCard>
        )}
        {new Date(BREAKPOINT_DATES.school) <
          new Date(declaration?.data?.exploitation_year) &&
          declaration?.data.private_organization_exploitation_type && (
            <BoxCard title={""}>
              <TableDeclarationMini
                rows={tableDeclarationsMini}
                setRows={setTableDeclarationsMini}
                isSchoolTable={true}
                isInputDisabled={true}
              />
            </BoxCard>
          )}
        {isBoardingSchool === "Есть" && (
          <>
            <BoxCard title={"Характеристики Интерната"}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    name="private_organization_place_live_count"
                    value={
                      declaration?.data.private_organization_place_live_count
                    }
                    label="Количество вновь введенных мест проживания"
                    placeholder="Количество вновь введенных мест проживания"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="private_organization_exploitation_type"
                    value={
                      declaration?.data
                        .private_organization_exploitation_live_type
                    }
                    label="Ранее государственный образовательный заказ на вновь введенные места проживания
                    в объекте частной организации образования, введенные в эксплуатацию после 01 января 2020 года"
                    placeholder="Ранее государственный образовательный заказ на вновь введенные места проживания
                    в объекте частной организации образования, введенные в эксплуатацию после 01 января 2020 года"
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_region"
                    value={declaration?.data?.boarding_school_region?.name}
                    label="Регион"
                    placeholder="Регион"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {declaration?.data.boarding_school_area && (
                  <Grid item xs={4}>
                    <TextField
                      name="boarding_school_area"
                      value={declaration?.data?.boarding_school_area?.name}
                      label="Район"
                      placeholder="Район"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_district"
                    value={declaration?.data?.boarding_school_district?.name}
                    label="Округ"
                    placeholder="Округ"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_locality"
                    value={declaration?.data?.boarding_school_locality?.name}
                    label="Населенный пункт"
                    placeholder="Населенный пункт"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_street"
                    value={declaration?.data.boarding_school_street}
                    label="Улица/Микрорайон/Жилой массив и т.п."
                    placeholder="Улица/Микрорайон/Жилой массив и т.п."
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="house_number"
                    value={declaration?.data?.boarding_school_house_number}
                    label="№ дома/здания"
                    placeholder="№ дома/здания"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_place_count"
                    value={declaration?.data.boarding_school_place_count}
                    label="Проектная мощность (ученических мест)"
                    disabled
                    placeholder="Проектная мощность (ученических мест)"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="exploitation_year"
                    value={declaration?.data.boarding_school_exploitation_year}
                    label="Дата ввода в эксплуатацию "
                    placeholder="Дата ввода в эксплуатацию "
                    id="boarding_school_exploitation_year"
                    type="date"
                    disabled
                    // defaultValue="2017-05-24"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_exploitation_type"
                    value={declaration?.data.boarding_school_exploitation_type}
                    label="Интернат введен в эксплуатацию путем"
                    placeholder="Интернат введен в эксплуатацию путем"
                    id="boarding_school_exploitation_type"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                {new Date(declaration?.data.boarding_school_exploitation_year) >
                new Date(BREAKPOINT_DATES.boarding) ? (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="boarding_school_registered_immutability_agreement"
                          checked={Boolean(
                            declaration?.data
                              .boarding_school_registered_immutability_agreement,
                          )}
                        />
                      }
                      label="Наличие зарегистрированного Соглашения о неизменности целевого назначения объекта организации среднего образования (А3)"
                      disabled={true}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </BoxCard>

            {
              // new Date(declaration?.data.boarding_school_exploitation_year) >
              // new Date(BREAKPOINT_DATES.boarding)
              declaration?.data
                .boarding_school_registered_immutability_agreement ? (
                //   ||
                // declaration?.data.boarding_school === "Eсть"
                <BoxCard title={""}>
                  <TableDeclarationMini
                    rows={tableDeclarationsMiniTwo}
                    setRows={setTableDeclarationsMiniTwo}
                    isSchoolTable={false}
                    isInputDisabled={true}
                  />
                </BoxCard>
              ) : null
            }
          </>
        )}

        {
          // declaration?.data.education_licence !== "null" &&
          //   declaration?.data.legal_entity_certificate !== "null" &&
          //   declaration?.data.bank_certificate !== "null" &&

          <BoxCard title={"Документы"} sx={{ maxWidth: "100%" }}>
            <Grid item xs={4} sx={{ width: "400px" }}>
              <TextField
                name="private_organization_government_order"
                value={
                  declaration?.data.private_organization_government_order
                    ? "Размещался"
                    : "Не размещался"
                }
                label="Ранее государственный образовательный заказ:"
                disabled
                placeholder="Не размещался"
                sx={{
                  "& .MuiInputLabel-root": {
                    overflow: "auto",
                    whiteSpace: "normal",
                    textOverflow: "clip",
                  },
                }}
              />
            </Grid>

            {declaration?.data.education_licence !== null && (
              <Box sx={{ marginBottom: "10px" }}>
                <StyledTypography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  Копия лицензии на занятие образовательной деятельностью по
                  общеобразовательным учебным программам начального, основного
                  среднего, общего среднего образования, выданной по форме,
                  утвержденной приказом Министра национальной экономики
                  Республики Казахстан от 6 января 2015 года № 3.
                </StyledTypography>
                <FileDownloadField
                  fileName="Копия лицензии на"
                  fileUrl={declaration?.data.education_licence}
                  fileDownloadName="Лицензия_образ_деятельность"
                />
              </Box>
            )}

            {declaration?.data.legal_entity_certificate &&
              // declaration?.data.legal_entity_certificate !== "null" &&
              declaration?.data.legal_entity_certificate !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography sx={{ fontWeight: "500" }}>
                    Справка о государственной регистрации (перерегистрации)
                    юридического лица
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Справка о гос регистр."
                    fileUrl={declaration?.data.legal_entity_certificate}
                    fileDownloadName="Спр_гос_регистрации"
                  />
                </Box>
              )}
            {declaration?.data.tuition_fee_statement &&
              // declaration?.data.tuition_fee_statement !== "null" &&
              declaration?.data.tuition_fee_statement !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography sx={{ fontWeight: "500" }}>
                    Копия приказа руководителя частной организации образования
                    или лица исполняющего его обязанности об утверждении размера
                    родительской платы за обучение в частной организации
                    образования (при наличии родительской оплаты)
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Копия приказа руководителя"
                    fileUrl={declaration?.data.tuition_fee_statement}
                    fileDownloadName="Приказ_о_род_оплате"
                  />
                </Box>
              )}
            {declaration?.data.bank_certificate &&
              // declaration?.data.bank_certificate !== "null" &&
              declaration?.data.bank_certificate !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography
                    sx={{
                      fontWeight: "500",
                    }}
                  >
                    Справка о наличии счета в банке второго уровня Республики
                    Казахстан
                  </StyledTypography>
                  <FileDownloadField
                    sx={{}}
                    fileName="Справка о наличии счета в банке"
                    fileUrl={declaration?.data.bank_certificate}
                    fileDownloadName="Справка_счет_в_банке"
                  />
                </Box>
              )}

            {declaration?.data.anti_terrorism_measures &&
              declaration?.data.anti_terrorism_measures !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography
                    sx={{
                      fontWeight: "500",
                    }}
                  >
                    Документ, подтверждающий соблюдение требований
                    антитеррористической защиты в соответствии с Инструкцией
                  </StyledTypography>
                  <FileDownloadField
                    sx={{}}
                    fileName="Документ, подтверждающий соблюдение требований антитеррористической защиты в соответствии с Инструкцией"
                    fileUrl={declaration?.data.anti_terrorism_measures}
                    fileDownloadName="Документ_антитеррористической_защиты"
                  />
                </Box>
              )}

            {((declaration?.data.preliminary_agreement &&
              declaration?.data.preliminary_agreement !== null) ||
              (declaration?.data.project_documentation &&
                declaration?.data.project_documentation !== null) ||
              (declaration?.data.exploitation_act &&
                declaration?.data.exploitation_act !== null) ||
              (declaration?.data.condition_conclusion &&
                declaration?.data.condition_conclusion !== null) ||
              (declaration?.data.real_estate_certificate &&
                declaration?.data.real_estate_certificate !== null) ||
              (declaration?.data.cadastral_information &&
                declaration?.data.cadastral_information !== null)) && (
              <>
                <StyledTypography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  Для частных организаций образования с вновь введенными
                  ученическими местами и (или) местами для проживания в объектах
                  частных организаций среднего образования, предусмотренными
                  Методикой. При этом предельный срок эксплуатации здания с
                  вновь введенными путем реконструкции ученическими местами в
                  объектах частных организаций среднего образования,
                  предусмотренными Методикой, составляет 30 (тридцать) лет на
                  дату первоначальной подачи заявления на данный объект:
                </StyledTypography>
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography
                  //sx={{ fontSize: "14px", marginBottom: "10px", lineHeight: "1.5em" }}
                  >
                    Копия действующего предварительного договора, заключенного
                    между оператором и частной организацией образования по
                    утвержденной оператором форме
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Пред договор"
                    fileUrl={declaration?.data.preliminary_agreement}
                    fileDownloadName="Пред_договор"
                  />
                  {declaration?.data.immutability_agreement && (
                    <FileDownloadField
                      fileName="Соглашение о неизменности"
                      fileUrl={declaration?.data.immutability_agreement}
                      fileDownloadName="Соглашение о неизменности"
                    />
                  )}
                </Box>
              </>
            )}
            {declaration?.data.project_documentation &&
              // declaration?.data.project_documentation !== "null" &&
              declaration?.data.project_documentation !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography
                  // sx={{ fontSize: "14px", marginBottom: "10px",lineHeight: "1.5em" }}
                  >
                    Проектная (проектно-сметная) документация на объект частной
                    организации образования, разработанная и утвержденная в
                    соответствии со статьей 60 Закона об архитектурной
                    деятельности, либо судебный акт о праве собственности на
                    объект
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Проектная (проектно-сметная) документация…"
                    fileUrl={declaration?.data.project_documentation}
                    fileDownloadName="ПСД"
                  />
                </Box>
              )}
            {declaration?.data.exploitation_act &&
              // declaration?.data.exploitation_act !== "null" &&
              declaration?.data.exploitation_act !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography>
                    Акт приемки построенного объекта в эксплуатацию,
                    подтверждающий дату ввода объекта в эксплуатацию в
                    соответствии с пунктом 4 статьи 73 Закона об архитектурной
                    деятельности
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Акт приемки построенного объекта в эксплуатацию…"
                    fileUrl={declaration?.data.exploitation_act}
                    fileDownloadName="Акт_приемки_в_эксплуатацию"
                  />
                </Box>
              )}
            {declaration?.data.condition_conclusion &&
              // declaration?.data.condition_conclusion !== "null" &&
              declaration?.data.condition_conclusion !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography>
                    Заключение о состоянии подлежащего реконструкции здания в
                    соответствии с пунктом 4 статьей 34-4 Закона об
                    архитектурной деятельности, при реконструкции
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Заключение о состоянии "
                    fileUrl={declaration?.data.condition_conclusion}
                    fileDownloadName="Закл_о_состоянии_подлеж_реконстр_здания"
                  />
                </Box>
              )}
            {declaration?.data.real_estate_certificate &&
              // declaration?.data.real_estate_certificate !== "null" &&
              declaration?.data.real_estate_certificate !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography>
                    Сведения о зарегистрированных правах (обременениях) на
                    недвижимое имущество и его технических характеристиках с
                    зарегистрированным обременением права на изменение целевого
                    назначения объекта частной организации среднего образования,
                    указанного в заявлении и сведениях об интернатной
                    организации (при наличии), на срок не менее двадцати лет
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Сведения о зарегистрированных"
                    fileUrl={declaration?.data.real_estate_certificate}
                    fileDownloadName="Сведения_об_обременении"
                  />
                </Box>
              )}
            {declaration?.data.cadastral_information &&
              // declaration?.data.cadastral_information !== "null" &&
              declaration?.data.cadastral_information !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography>
                    Сведения из правового кадастра о наличии либо отсутствии
                    зарегистрированных прав на объект частной организации
                    образования, указанный в заявлении и (или) сведениях об
                    интернатной организации (при наличии), в том числе о виде
                    права, правообладателе, основании возникновения права, а
                    также об основании прекращения права (при наличии)
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Справка Сведения из кадастра"
                    fileUrl={declaration?.data.cadastral_information}
                    fileDownloadName="Сведения_о_зарегистрированных_правах"
                  />
                </Box>
              )}

            {declaration?.data.integrated_education_realization_documents &&
              // declaration?.data.integrated_education_realization_documents !==
              // "null" &&
              declaration?.data.integrated_education_realization_documents !==
                null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography sx={{ fontWeight: "500" }}>
                    Документы, подтверждающие реализацию самостоятельно
                    разработанных интегрированных образовательных программ,
                    прохождение авторизации в Организации Международного
                    Бакалавриата или международную институциональную
                    аккредитацию - для частных организаций образования,
                    реализующих самостоятельно разработанные интегрированные
                    образовательные программы, прошедших авторизацию в
                    Организации Международного Бакалавриата или международную
                    институциональную аккредитацию
                  </StyledTypography>
                  {JSON.parse(
                    declaration?.data
                      .integrated_education_realization_documents,
                  ).map((el: string) => (
                    <FileDownloadField
                      fileName="Документы, подтверждающие"
                      fileUrl={el}
                      fileDownloadName="Сведения_самостоят_разраб_ОП"
                    />
                  ))}
                </Box>
              )}
          </BoxCard>
        }
      </Stack>

      <ConfirmationModal
        applicationId={declaration?.data.id}
        executorId={declaration?.data.executor_id}
        mainExecutorid={declaration?.data.main_executor_id}
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      />

      <ExecutorReworkModal
        commentData={declaration?.data.comment}
        id={declaration?.data.id}
        open={executorReworkModalOpen}
        submit={submitRevision}
        onClose={() => setExecutorReworkModalOpen(false)}
      />

      <CheckListModal
        id={declaration?.data.id}
        open={checkListModalOpen}
        onClose={() => setCheckListModalOpen(false)}
        submit={(id, comment, type, checkListState) =>
          submitApprove(id, comment, type, checkListState)
        }
      />

      <EDS open={EDSopen} submit={() => {}} onClose={() => setEDSopen(false)} />
      <LoadingBar
        open={isLoading}
        title={"Подождите, идет загрузка..."}
        onCancel={() => setIsLoading(false)}
      />

      <PreviewDocumentDialog
        open={previewSchoolReworkOpened}
        maxWidth="lg"
        fullWidth
        title='Предпросмотр документа "Заявление на доработку"'
        file={schoolReworkPdf}
        disabled={isLoading}
        onClose={() => setOpenedPreviewSchoolRework(false)}
        onSubmit={submitSchoolRework}
      />

      <PreviewDocumentDialog
        open={previewSchoolDeclineOpened}
        maxWidth="lg"
        fullWidth
        title='Предпросмотр документа "Заявление на отказ"'
        file={schoolDeclinePdf}
        disabled={isLoading}
        onClose={() => setOpenedPreviewSchoolDecline(false)}
        onSubmit={submitSchoolDecline}
      />
    </div>
  );
}
