import { MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import BoxTitle from "../../components/BoxTitle";
import LoadingBar from "../../components/LoadingBar";
import AddConclusion from "../../components/Modals/AddConclusion";
import { EDS } from "../../components/Modals/EDS";
import ExecutorReworkModal from "../../components/Modals/ExecutorReworkModal";
import {
  addDBFConclusion,
  executorRework,
  getDocumentForSign,
  getSchoolDeclarationById,
  signDocument,
} from "../../services/useEndpoint";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectCurrentUser } from "../../store/reducers/currentUserReducer";
import { setSearchFilter } from "../../store/reducers/filterSlice";
import { NcaLayer } from "../../utils/nca-layer";
import MainSpecDeclarationInnerDBF from "./components/MainSpecDeclarationInnerDBF";

export default function MainSpecDeclarationsDataDBF() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const [data, setData] = useState<any>();

  const [EDSopen, setEDSopen] = React.useState(false);

  const [reworkExecutorOpen, setRevisionExecutorOpen] = useState(false);
  const [addDBFConclusionOpen, setAddDBFConclusionOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isBoardingSchool, setIsBoardingSchool] = useState("");

  function fetchDeclarationData() {
    getSchoolDeclarationById(id as string)
      .then((data) => {
        setData(data.data);
        setIsBoardingSchool(data.data.boarding_school);
      })
      .catch((error) => {
        toast.error(
          `Произошла ошибка при загрузке данных. Ошибка: ${error.message}`,
        );
      });
  }

  useEffect(() => {
    fetchDeclarationData();
  }, []);

  const goToDeclarationsPage = () => {
    navigate("/main-specialist/declarations");
    dispatch(setSearchFilter(""));
  };

  async function handleEDS(base64string: string) {
    try {
      return await NcaLayer.sign(base64string);
    } catch (error) {
      toast.error("При подписании документа произошла ошибка");
    }
  }

  const submitExecutorRework = async (id: number, comment: string) => {
    if (!id) return;

    try {
      setIsLoading(true);

      await executorRework(id, comment);

      toast.success("Заявка была отправлена на доработку исполнителю.");
      setRevisionExecutorOpen(false);
      fetchDeclarationData();
      goToDeclarationsPage();
    } catch (error: any) {
      toast.error(
        `Произошла ошибка при отправке заявления на доработку. Ошибка: ${error.message}`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const submitAddDBFConclusion = async (id: number, type: number) => {
    try {
      setIsLoading(true);
      const base64string = await getDocumentForSign({
        filePath: data?.dbf_check_list?.pdf,
      });
      const signature = await handleEDS(base64string);

      if (!signature) return;

      await signDocument(signature, data?.dbf_check_list?.pdf);
      await addDBFConclusion(id, type);

      toast.success("Заключение было отправлено директору.");
      setAddDBFConclusionOpen(false);
      fetchDeclarationData();
      goToDeclarationsPage();
    } catch (error: any) {
      toast.error(`Не удалось отправить заключение.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ padding: "40px 20px", maxWidth: "1130px" }}>
      <BoxTitle
        title={
          "Заявление на размещение гос. образовательного заказа на среднее образование"
        }
      >
        <FormControl fullWidth>
          {user.id === data?.current_executor?.id && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Согласование"
              value={""}
              displayEmpty
              sx={{
                width: "300px",
                backgroundColor: "#2C53F5",
                color: "#FFF",
                fill: "#FFF",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
                ".MuiInputBase-input": {
                  justifyContent: "center",
                },
              }}
            >
              <MenuItem value="" disabled sx={{ display: "none" }}>
                Добавить заключение
              </MenuItem>

              <MenuItem onClick={() => setAddDBFConclusionOpen(true)}>
                Добавить заключение
              </MenuItem>

              <MenuItem onClick={() => setRevisionExecutorOpen(true)}>
                На доработку исполнителю
              </MenuItem>
            </Select>
          )}
        </FormControl>
      </BoxTitle>

      <MainSpecDeclarationInnerDBF
        isBoardingSchool={isBoardingSchool}
        data={data}
      />

      <EDS open={EDSopen} submit={() => {}} onClose={() => setEDSopen(false)} />
      <LoadingBar
        open={isLoading}
        title={"Подождите, идет загрузка..."}
        onCancel={() => setIsLoading(false)}
      />

      <AddConclusion
        id={data?.id}
        dbfCheckList={data?.dbf_check_list}
        dbfConclusion={data?.dbf_conclusion}
        open={addDBFConclusionOpen}
        submitConslusion={submitAddDBFConclusion}
        onClose={() => setAddDBFConclusionOpen(false)}
      />

      <ExecutorReworkModal
        commentData={data?.comments}
        id={data?.id}
        open={reworkExecutorOpen}
        submit={submitExecutorRework}
        onClose={() => setRevisionExecutorOpen(false)}
      />
    </div>
  );
}
