import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import "dayjs/locale/ru";

import { store } from "./store/store";
import BaseStyles from "./styles/BasicStyles";
import theme from "./styles/theme";

import App from "./App";
import GlobalContext from "./contexts/GlobalContext";
import ModalContext from "./contexts/ModalContext";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
      },
    },
    queryCache: new QueryCache({
      onError: (err) => {
        const error = err as Error;
        const msg = error.message;
        console.error("[useQuery] global err:\n", msg, err);
      },
    }),
  });

  root.render(
    <StrictMode>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ru"
                >
                  <GlobalContext>
                    <ModalContext>
                      <CssBaseline />
                      <BaseStyles />

                      <App />
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        theme="light"
                      />
                    </ModalContext>
                  </GlobalContext>
                </LocalizationProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </Provider>
        </QueryParamProvider>
      </BrowserRouter>
    </StrictMode>,
  );
} else {
  console.error("Root element with ID 'root' not found.");
}
