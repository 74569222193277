import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  isSchoolValid: true,
  isBoardingValid: true,
  isSchoolTouched: false,
  isBoardingTouched: false,
};
const validateCadastralInfoSlice = createSlice({
  name: "validateCadastralInfo",
  initialState,
  reducers: {
    setIsSchoolValid: (state, action) => {
      state.isSchoolValid = action.payload;
    },
    setIsBoardingValid: (state, action) => {
      state.isBoardingValid = action.payload;
    },
    setIsSchoolTouched: (state, action) => {
      state.isSchoolTouched = action.payload;
    },
    setIsBoardingTouched: (state, action) => {
      state.isBoardingTouched = action.payload;
    },
  },
});

export const selectIsSchoolValid = (state: RootState) =>
  state.validateCadastralInfoReducer.isSchoolValid;
export const selectIsBoardingValid = (state: RootState) =>
  state.validateCadastralInfoReducer.isSchoolValid;
export const selectIsSchoolTouched = (state: RootState) =>
  state.validateCadastralInfoReducer.isSchoolTouched;
  export const selectIsBoardingTouched = (state: RootState) =>
  state.validateCadastralInfoReducer.isBoardingTouched;

export const {
  setIsSchoolValid,
  setIsBoardingValid,
  setIsSchoolTouched,
  setIsBoardingTouched,
} = validateCadastralInfoSlice.actions;

export default validateCadastralInfoSlice.reducer;
