import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

interface PreviewDocumentDialogProps
  extends Omit<DialogProps, "title" | "onSubmit"> {
  title: React.ReactNode;
  file: File | null;
  disabled: boolean;
  onSubmit(): void;
}

const PreviewDocumentDialog = ({
  title,
  file,
  disabled,
  onSubmit,
  ...props
}: PreviewDocumentDialogProps) => {
  const preview = file ? URL.createObjectURL(file) : "";

  return (
    <Dialog {...props}>
      <DialogTitle sx={{ color: "inherit" }}>{title}</DialogTitle>

      <DialogContent>
        <Box
          component="iframe"
          title={preview}
          src={preview}
          sx={{ width: "100%", height: "100vh" }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => props.onClose?.({}, "backdropClick")}
          color="error"
          variant="outlined"
          disabled={disabled}
        >
          Закрыть
        </Button>

        <Button
          type="submit"
          onClick={onSubmit}
          color="primary"
          disabled={disabled}
        >
          Подписать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDocumentDialog;
