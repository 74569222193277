import { Box } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid";

export default function NoRowsOverlay() {
  return (
    <GridOverlay>
      <Box
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Ничего не найдено
      </Box>
    </GridOverlay>
  );
}
