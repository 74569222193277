import { Circle } from "@mui/icons-material";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getNotifications } from "../../../../services/useEndpoint";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectNotifications,
  selectShowAllNotifications,
  setNotifications,
} from "../../../../store/reducers/notificationsSlice";
import theme, { fincolors } from "../../../../styles/theme";
import { NotificationDate } from "../../../../types/modalTypes";

const schoolNotifications: NotificationDate[] = [
  {
    id: 194,
    applicationId: 1,
    title: `Заявка №194 согласована`,
    text: "Перейти к заявке",
    date: "07.01.2023",
    isUnread: true,
    url: "/school/declarations/194",
  },
  {
    id: 193,
    applicationId: 2,
    title: `Заявка №193 принята в работу`,
    text: "Перейти к заявке",
    date: "07.01.2023",
    isUnread: true,
    url: "/school/declarations/193",
  },
  {
    id: 177,
    applicationId: 3,
    title: `Вам отказано по заявке №177 `,
    text: "Перейти к заявке",
    date: "07.01.2023",
    isUnread: false,
    url: "/school/declarations/177",
  },
  {
    id: null,
    applicationId: 4,
    title: `Открыт прием заявок на подушевое финансирование`,
    text: "Перейти к объявлению",
    date: "07.01.2023",
    isUnread: false,
    url: "https://fincenter.kz/private-schools/ads",
  },
];
const NotificationsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data } = useQuery(["notificationsQuery"], () => getNotifications(1));
  const notifications = useAppSelector(selectNotifications);
  const location = useLocation();
  // const [notifications, setNotifications] = useState<any[] | null>(null);
  useEffect(() => {
    let notificationsData: any[] = [];

    if (data) {
      data?.notifications?.data?.map((el: any) => {
        const pathname = location.pathname;
        const parts = pathname.split("/");
        const address = parts[1];
        const date = el.updated_at
          .replace(/^(\d{4}-\d{2}-\d{2}).*$/, "$1")
          .split("-")
          .reverse()
          .join("-");
        notificationsData.push({
          id: el.id,
          applicationId: el.user_id,
          title: el.short_text,
          text: "Перейти к объявлению",
          date: date,
          isUnread: el.seen,
          url: `/${address}/declarations/${el.application_id}`,
        });
      });
      dispatch(setNotifications(notificationsData));
    }
  }, [data]);

  // useEffect(() => {
  //   if (data) {
  //     setNotifications(data.notifications);
  //   }
  // }, [data]);
  const showAllNotifications = useAppSelector(selectShowAllNotifications);
  const displayedNotifications = showAllNotifications
    ? notifications
    : notifications.slice(0, 2);

  if (displayedNotifications.length === 0) {
    return (
      <div style={{ margin: "0 auto", padding: "30px 0" }}>Уведомлений нет</div>
    );
  }
  console.log("data == ", data);
  console.log("displayedNotifications == ", displayedNotifications);
  return (
    <Stack
      direction="column"
      sx={{
        maxHeight: "300px",
        overflowY: "scroll",
      }}
    >
      {displayedNotifications &&
        displayedNotifications.map((notification) => (
          <Link
            href={notification.url}
            underline="none"
            sx={{
              paddingY: "15px",
              cursor: "pointer",
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderColor: fincolors.other.borderColor,
              paddingX: "31px",
              //minHeight: "72px",
              "&:not(:last-child)": {
                marginBottom: "-1px",
              },
            }}
          >
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  sx={{
                    fontSize: theme.typography.h5,
                    color: fincolors.text.primary,
                    wordWrap: "break-word",
                    position: "relative",
                    width: "70%",
                  }}
                >
                  {!notification.isUnread && (
                    <Circle
                      sx={{
                        fontSize: "8px",
                        color: fincolors.primary.main,
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "-15px",
                      }}
                    />
                  )}
                  {notification.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: fincolors.text.disabled,
                    marginLeft: "20px",
                  }}
                >
                  {notification.date}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  color: fincolors.text.secondary,
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              >
                {notification.text}
              </Typography>
            </Box>
          </Link>
        ))}
    </Stack>
  );
};

export default NotificationsList;
