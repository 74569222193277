import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Field, useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import * as yup from "yup";
import { registerUser, sendRegisterCode } from "../../../services/useEndpoint";
import { ROLES, form_msg } from "../../../utils/constants";
import { useModalContext } from "../../../contexts/ModalContext";
import IMask from "imask";
import Cookies from "js-cookie";
import { setAllCurrentUserData } from "../../../store/reducers/currentUserReducer";
import { useAppDispatch } from "../../../store/hooks";
import { toast } from "react-toastify";
import RegistrationCode from "./RegistrationCode";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type RegistrationFormProps = {
  handlers: {
    nextStep: (arg?: string) => void;
    backStep: () => void;
  };
};

const RegistrationForm: React.FC<RegistrationFormProps> = ({ handlers }) => {
  const navigate = useNavigate();
  const { setOpen } = useModalContext();
  const phoneMask = IMask.createMask({
    mask: "+7 (000) 000-00-00",
  });
  const dispatch = useAppDispatch();
  const [openRegistrationCode, setOpenRegistrationCode] = useState(false);
  const [isPasswordView, setIsPasswordView] = useState<boolean>(false);
  const [isRepeatPasswordView, setIsRepeatPasswordView] =
    useState<boolean>(false);
  const [currentLabels, serCurrentLabels] = useState<{
    role_id: number;
    organization_name: string;
    official_number: string;
  }>();
  const labels = [
    //labels для школы
    {
      role_id: ROLES.school.id,
      organization_name: "Наименование частной организации",
      official_number: "БИН",
    },
    //labels для инвестора
    {
      role_id: ROLES.investor.id,
      organization_name: "Название юридического лица/ИП/ФИО",
      official_number: "БИН/ИИН",
    },
    {
      role_id: ROLES.student.id,
      organization_name: "ФИО",
      official_number: "ИИН",
    },
  ];

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
      repeatPassword: "",
      role_id: "1",
      organization_name: "",
      official_number: "",
      email_address: "",
      phone_number: "",
    },
    validationSchema: yup.object().shape({
      login: yup.string().required(form_msg.REQUIRED),
      password: yup
        .string()
        .required(form_msg.REQUIRED)
        .min(8, form_msg.MIN_LENGTH(8))
        .matches(
          form_msg.PASSWORD_REG_EXP,
          'В числе символов пароля обязательно должны присутствовать буквы в верхнем и нижнем регистрах, цифры и спецсимволы ("№%:?*@#$%^ и другие).',
        ),
      repeatPassword: yup
        .string()
        .test("passwords-match", "Пароли должны совпадать", function (value) {
          return this.parent.password === value;
        }),
      role_id: yup.string().required(form_msg.REQUIRED),
      organization_name: yup.string().required(form_msg.REQUIRED),
      official_number: yup
        .string()
        .required(form_msg.REQUIRED)
        .min(12, form_msg.MIN_LENGTH(12)),
      email_address: yup
        .string()
        .email(form_msg.EMAIL)
        .required(form_msg.REQUIRED),
      phone_number: yup.string().required(form_msg.REQUIRED),
    }),
    validateOnChange: true,
    async onSubmit(values, { resetForm }) {
      try {
        // Call the registerUser function with the form values
        const { success, data } = await sendRegisterCode({
          phone_number: values.phone_number,
          email_address: values.email_address,
        });
        if (success) {
          setOpenRegistrationCode(true);
          Cookies.set("token", "Bearer " + data.access_token);
          //@ts-ignore
          Cookies.set("currentUserId", data.user.id);
          dispatch(setAllCurrentUserData(data.user));

          //@ts-ignore
          Cookies.set("role", data.user.role.id);
          console.log("Registration successful!");

          navigate("/school/declarations");
          setOpen(false);
        } else {
          toast.error("Пользователь c такими данными уже существует");
        }
      } catch (error) {
        console.error("An error occurred during registration:", error);
      }
      // resetForm();
    },
  });

  const choseLabels = (role: number) => {
    const newLabels = labels.find((labelObj) => labelObj.role_id === role);
    serCurrentLabels(newLabels);
  };

  useEffect(() => {
    choseLabels(Number(formik.values.role_id));
  }, [formik.values.role_id]);

  const { values, errors, touched, handleChange, handleSubmit } = formik;

  const handleNumberChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = evt.target;
    if (/^[0-9]*$/.test(value)) {
      formik.setFieldValue(name, value);
    }
  };

  const restartRegistrationCode = async () => {
    try {
      const { success, data } = await sendRegisterCode({
        phone_number: values.phone_number,
        email_address: values.email_address,
      });
      if (success) {
        Cookies.set("token", "Bearer " + data.access_token);
        //@ts-ignore
        Cookies.set("currentUserId", data.user.id);
        dispatch(setAllCurrentUserData(data.user));
        //@ts-ignore
        Cookies.set("role", data.user.role.id);
        console.log("Registration successful!");

        navigate("/school/declarations");
        setOpen(false);
      } else {
        toast.error("Пользователь c такими данными уже существует");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const changePhoneNumber = () => {
    setOpenRegistrationCode(false);
  };
  const sendRequestToServer = async (code: number) => {
    try {
      const { success, data } = await registerUser({ ...values, code });
      if (success) {
        Cookies.set("token", "Bearer " + data.access_token);
        //@ts-ignore
        Cookies.set("currentUserId", data.user.id);
        dispatch(setAllCurrentUserData(data.user));
        //@ts-ignore
        Cookies.set("role", data.user.role.id);
        console.log("Registration successful!");

        navigate("/school/declarations");
        setOpen(false);
        toast.success("Активация прошла успешно");
      } else {
        toast.error("Пользователь c такими данными уже существует");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h2" component="h2" sx={{ mb: 2 }}>
        Регистрация
      </Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          component="span"
          sx={{ mb: "12px", textTransform: "uppercase" }}
        >
          Данные для регистрации
        </Typography>
        <Card variant="outlined" sx={{ p: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="role_id"
                value={values.role_id}
                sx={{ flexBasis: "100%" }}
                label="Выберите роль"
                error={Boolean(touched.role_id && errors.role_id) || false}
                helperText={(touched.role_id && errors.role_id) || false}
                onChange={(event) => {
                  // Convert the role_id value to an integer based on the selected option
                  const { value } = event.target;
                  // const roleIdValue = value === "school" ? 0 : 1;
                  handleChange({
                    target: {
                      name: "role_id",
                      // value: roleIdValue,
                      value: value,
                    },
                  });
                }}
                select
              >
                <MenuItem value={ROLES.school.id}>Школа</MenuItem>
                {/* <MenuItem value={ROLES.investor.id}>Инвестор</MenuItem>
                <MenuItem value={ROLES.student.id}>Студент</MenuItem> */}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="organization_name"
                value={values.organization_name}
                //sx={{ width: "auto", flexGrow: 1 }}
                // label="Наименование частной организации"
                label={currentLabels?.organization_name}
                placeholder="Введите название"
                error={
                  Boolean(
                    touched.organization_name && errors.organization_name,
                  ) || false
                }
                helperText={
                  (touched.organization_name && errors.organization_name) ||
                  false
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="official_number"
                value={values.official_number}
                //sx={{ width: "auto", flexGrow: 1 }}
                label={currentLabels?.official_number}
                placeholder="БИН"
                error={
                  Boolean(touched.official_number && errors.official_number) ||
                  false
                }
                helperText={
                  (touched.official_number && errors.official_number) || false
                }
                inputProps={{
                  maxLength: 12,
                }}
                onChange={handleNumberChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="email_address"
                value={values.email_address}
                //sx={{ width: "auto", flexGrow: 2 }}
                label="Электронная почта"
                placeholder="Введите электронную почту"
                error={
                  Boolean(touched.email_address && errors.email_address) ||
                  false
                }
                helperText={
                  (touched.email_address && errors.email_address) || false
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="phone_number"
                value={values.phone_number}
                //sx={{ width: "auto", flexGrow: 2 }}
                label="Номер телефона"
                defaultValue={"+7"}
                placeholder="Номер телефона"
                error={
                  Boolean(touched.phone_number && errors.phone_number) || false
                }
                helperText={
                  (touched.phone_number && errors.phone_number) || false
                }
                onChange={(event) => {
                  phoneMask.resolve(event.target.value as string);
                  handleChange({
                    target: {
                      name: "phone_number",
                      value: phoneMask.value,
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
        </Card>

        <Typography
          variant="h5"
          component="span"
          sx={{ mt: "24px", mb: "12px", textTransform: "uppercase" }}
        >
          Логин и пароль
        </Typography>
        <Card variant="outlined" sx={{ p: "24px" }}>
          <Stack direction="row" gap={2}>
            <TextField
              name="login"
              value={values.login}
              label="Логин"
              placeholder="Введите логин"
              error={Boolean(touched.login && errors.login) || false}
              helperText={(touched.login && errors.login) || false}
              onChange={handleChange}
            />

            <TextField
              name="password"
              value={values.password}
              label="Пароль"
              placeholder="Введите пароль"
              error={Boolean(touched.password && errors.password) || false}
              helperText={(touched.password && errors.password) || false}
              onChange={handleChange}
              type={isPasswordView ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={() => setIsPasswordView(!isPasswordView)}
                    variant="text"
                  >
                    {!isPasswordView ? <Visibility /> : <VisibilityOff />}
                  </Button>
                ),
              }}
            />
            <TextField
              name="repeatPassword"
              value={values.repeatPassword}
              label="Повторите пароль"
              placeholder="Повторите пароль"
              error={
                Boolean(touched.repeatPassword && errors.repeatPassword) ||
                false
              }
              helperText={
                (touched.repeatPassword && errors.repeatPassword) || false
              }
              onChange={handleChange}
              type={isRepeatPasswordView ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={() =>
                      setIsRepeatPasswordView(!isRepeatPasswordView)
                    }
                    variant="text"
                  >
                    {!isRepeatPasswordView ? <Visibility /> : <VisibilityOff />}
                  </Button>
                ),
              }}
            />
          </Stack>
        </Card>

        <Stack direction="row" gap={2} sx={{ mt: "32px", width: "100%" }}>
          <Button
            variant="outlined"
            sx={{ flex: 1 }}
            onClick={handlers.backStep}
          >
            Отмена
          </Button>
          <Button sx={{ flex: 1 }} type="submit">
            Зарегистрироваться
          </Button>
        </Stack>
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontSize: "13px" }}>
            Телефон Службы поддержки
          </Typography>
          <Typography sx={{ fontSize: "13px" }}>+7 775 128 61 88</Typography>
        </Box>
      </Box>
      <RegistrationCode
        open={openRegistrationCode}
        emailAddress={values?.email_address}
        changePhoneNumber={changePhoneNumber}
        restartRegistrationCode={restartRegistrationCode}
        sendRequestToServer={(code: number) => sendRequestToServer(code)}
      />
    </form>
  );
};

export default RegistrationForm;
