import BoxTitle from "../../components/BoxTitle";
import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import SchoolBlock from "./components/SchoolBlock";
import { useNavigate } from "react-router-dom";
import { Add, ModeEditOutline } from "@mui/icons-material";
import AddSchoolBlock from "./components/AddSchoolBlock";
import {
  addSchool,
  changeSchoolData,
  deleteSchool,
  getSchoolsList,
} from "../../services/useEndpoint";
import { useAppSelector } from "../../store/hooks";
import { selectCurrentUser } from "../../store/reducers/currentUserReducer";
import { form_msg } from "../../utils/constants";

interface SchoolValues {
  school_name: string;
  actual_address: string;
  // organization_id?:number
  // id?:number
}

interface SchoolListProps {
  school_name: string;
  actual_address: string;
  organization_id: number;
  id: number;
}

const SchoolList: React.FC = (props) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const [schoolList, setSchoolList] = useState<Array<SchoolListProps>>([]);
  const [updatedSchools, setUpdatedSchools] = useState<Array<SchoolListProps>>(
    [],
  );
  const [originalSchoolList, setOriginalSchoolList] = useState<
    Array<SchoolListProps>
  >([]);

  const [editMode, setEditMode] = useState(false);
  const [addSchoolMode, setAddSchoolMode] = useState(false);
  const navigate = useNavigate();

  const formikAllSchools = useFormik<SchoolListProps[]>({
    initialValues: schoolList,
    enableReinitialize: true,
    validationSchema: yup.array().of(
      yup.object().shape({
        school_name: yup.string().required(form_msg.REQUIRED),
        actual_address: yup.string().required(form_msg.REQUIRED),
      }),
    ),
    onSubmit(values, { resetForm }) {
      handlers.saveNewListData();
      resetForm();
      // try {
      //   deletedSchoolsId.forEach((schoolId) => {
      //     deleteSchool(schoolId);
      //   });
      // } catch (error) {
      //   console.error("Ошибка при удалении школы:", error);
      // }
    },
  });

  const getSchoolsListData = () => {
    try {
      getSchoolsList().then((data) => {
        const sortedSchools = [...data.data].sort((a, b) => a.id - b.id);
        setSchoolList(sortedSchools);
        setOriginalSchoolList(sortedSchools);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSchoolsListData();
  }, []);

  const formikNewSchool = useFormik<SchoolValues>({
    initialValues: {
      school_name: "",
      actual_address: "",
    },
    onSubmit: (values, { resetForm }) => {
      const newSchoolFormData = handlers.createFormData(values, currentUser.id);
      try {
        addSchool(newSchoolFormData).then(() => getSchoolsListData());
      } catch (error) {
        console.error("Ошибка при добавлении школы:", error);
      }
      resetForm();
      setAddSchoolMode(!addSchoolMode);
    },
    validationSchema: yup.object().shape({
      school_name: yup.string().required(form_msg.REQUIRED),
      actual_address: yup.string().required(form_msg.REQUIRED),
    }),
  });

  const handlers = {
    //handlers для изменения данных
    changeEditMode: () => {
      if (editMode) {
        setSchoolList(originalSchoolList);
      }
      setEditMode(!editMode);
      setUpdatedSchools([]);
    },

    handleSchoolChange: (
      id: number,
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { name, value } = event.target;

      setSchoolList((currentList) =>
        currentList.map((school) =>
          school.id === id ? { ...school, [name]: value } : school,
        ),
      );
      setUpdatedSchools((currentUpdatedSchools) => {
        const existingSchool = currentUpdatedSchools.find(
          (school) => school.id === id,
        );
        if (existingSchool) {
          return currentUpdatedSchools.map((school) =>
            school.id === id ? { ...school, [name]: value } : school,
          );
        } else {
          const updatedSchool = schoolList.find((school) => school.id === id);
          return updatedSchool
            ? [...currentUpdatedSchools, { ...updatedSchool, [name]: value }]
            : currentUpdatedSchools;
        }
      });
    },
    saveNewListData: () => {
      setEditMode(false);
      updatedSchools.forEach((school) => {
        changeSchoolData(school);
      });
      setUpdatedSchools([]);
      setOriginalSchoolList(schoolList);
    },

    //handlers для  добавления новых школ
    cancelAddSchool: () => {
      formikNewSchool.resetForm({
        values: { school_name: "", actual_address: "" },
      });
      setAddSchoolMode(false);
    },

    createFormData: (values: SchoolValues, userId: number): FormData => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof SchoolValues];
        if (value !== null && value !== undefined) {
          formData.append(key, value.toString());
        }
      });
      formData.append("organization_id", userId.toString());
      return formData;
    },

    //handlers для удаления школы
    deleteSchoolItem: (id: number) => {
      const currentSchool = schoolList.find((school) => school.id === id);
      if (
        window.confirm(
          `Данные школы ${currentSchool?.school_name} удалятся. Вы уверены, что хотите продолжить?`,
        )
      ) {
        deleteSchool(id).then(() => {
          setSchoolList((currentList) =>
            currentList.filter((school) => school.id !== id),
          );
          setOriginalSchoolList((currentList) =>
            currentList.filter((school) => school.id !== id),
          );
        });
      }
    },

    //handlers для навигации
    navigateToCreate: () => {
      navigate("/school/declarations/create");
    },
  };

  return (
    <>
      <BoxTitle title={"Мои школы"}>
        {editMode === false ? (
          <>
            <Button onClick={handlers.changeEditMode} variant="outlined">
              <ModeEditOutline />
            </Button>
            <Button
              sx={{ width: "245px" }}
              onClick={() => {
                handlers.navigateToCreate();
              }}
            >
              Подать заявление
            </Button>
          </>
        ) : (
          <>
            <Button variant="outlined" onClick={handlers.changeEditMode}>
              Отменить
            </Button>
            <Button
              onClick={() => {
                formikAllSchools.handleSubmit();
              }}
            >
              Сохранить
            </Button>
          </>
        )}
      </BoxTitle>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            maxWidth: "189px",
            height: "36px",
            justifyContent: "space-between",
          }}
          onClick={() => {
            setAddSchoolMode(true);
          }}
        >
          <Add />
          Добавить школу
        </Button>
      </Box>
      {addSchoolMode ? (
        <>
          <AddSchoolBlock
            // index={values.schools.length + 1}
            values={formikNewSchool.values}
            touched={formikNewSchool.touched}
            errors={formikNewSchool.errors}
            handleChange={formikNewSchool.handleChange}
            resetForm={() => {
              formikNewSchool.resetForm({
                values: {
                  school_name: "",
                  actual_address: "",
                },
              });
            }}
          />
          <Box>
            <Button
              variant="outlined"
              sx={{ height: "36px", width: "189px", marginRight: "24px" }}
              onClick={handlers.cancelAddSchool}
            >
              Отменить
            </Button>
            <Button
              sx={{ height: "36px", width: "189px" }}
              onClick={() => {
                formikNewSchool.handleSubmit();
              }}
            >
              Добавить
            </Button>
          </Box>
        </>
      ) : null}
      {formikAllSchools.values.map((school: SchoolListProps, index: number) => (
        <SchoolBlock
          key={school.id}
          // id={school.id}
          index={index}
          value={school}
          touched={formikAllSchools.touched[index] || {}}
          errors={formikAllSchools.errors[index] || {}}
          editMode={editMode}
          handleChange={(event) =>
            handlers.handleSchoolChange(school.id, event)
          }
          deleteSchoolItem={() => {
            handlers.deleteSchoolItem(school.id);
          }}
          //  handleSubmit={formikAllSchools.handleSubmit}
        />
      ))}
    </>
  );
};

export default SchoolList;
