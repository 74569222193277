import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { createSvgIcon } from "@mui/material";
import axios from "axios";
import anAxiosApi from "../services/axiosApi";
import { string } from "yup";

interface FileDownloadFieldProps {
  fileName: string;
  fileUrl: string;
  fileDownloadName?: string;
}
export const CustomDocIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
  >
    <path
      d="M2.00065 0.666016C1.55862 0.666016 1.1347 0.84161 0.82214 1.15417C0.509579 1.46673 0.333984 1.89065 0.333984 2.33268V15.666C0.333984 16.108 0.509579 16.532 0.82214 16.8445C1.1347 17.1571 1.55862 17.3327 2.00065 17.3327H12.0007C12.4427 17.3327 12.8666 17.1571 13.1792 16.8445C13.4917 16.532 13.6673 16.108 13.6673 15.666V5.66602L8.66732 0.666016H2.00065ZM2.00065 2.33268H7.83398V6.49935H12.0007V15.666H2.00065V2.33268ZM3.66732 8.99935V10.666H10.334V8.99935H3.66732ZM3.66732 12.3327V13.9993H7.83398V12.3327H3.66732Z"
      fill="#2C53F5"
    />
  </svg>,
  "CustomDocIcon",
);

const FileDownloadField: React.FC<FileDownloadFieldProps> = ({
  fileName,
  fileDownloadName,
  fileUrl,
}) => {
  const handleDownload = async () => {
    const fileFormat: string = fileUrl.includes(".")
      ? fileUrl.split(".").pop()!.toLowerCase()
      : "";

    function getData() {
      fetch(fileUrl, {
        method: "GET",
        headers: {
          "Content-type": "application/octet-stream",
        },
      })
        .then((res) => res.blob())
        .then((data) => {
          let url = URL.createObjectURL(data);
          const link = document.createElement("a");
          //
          link.setAttribute("href", url);
          if (!!fileDownloadName === true) {
            link.setAttribute("download", `${fileDownloadName}.${fileFormat}`);
          } else {
            link.setAttribute("download", `${fileName}.${fileFormat}`);
          }
          link.style.display = "none";
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        });
    }
    getData();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<CustomDocIcon />}
      onClick={() => handleDownload()}
      sx={[
        {
          width: "400px",
          overflow: "hidden",
          backgroundColor: "#FFF",
          whiteSspace: "nowrap",
          border: "1px solid #DFE2EC",
          color: "#2C53F5",
        },
        { "&:hover": { color: "#FFF", path: { fill: "#FFF" } } },
      ]}
    >
      Скачать {fileName}
    </Button>
  );
};

export default FileDownloadField;
