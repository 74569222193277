import React, { useState } from "react";
import { Modal, Typography, Box, Button } from "@mui/material";


const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  onClose: () => void;
  submit: () => void;
};
const EDSPortal = ({ open, onClose, submit }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Подписание
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, textAlign: "center", mb: 2 }}
        >
          Подпишите заявку с помощью ЭЦП
        </Typography>
        {/*<Box sx={{ width: "80%", justifyContent: "center" }}>*/}
        <Button onClick={submit} variant="contained" sx={{ width: "100%" }}>
          Выбрать сертификат
        </Button>

      </Box>
    </Modal>
  );
};

export default EDSPortal
