//@ts-nocheck

import { Box, Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BoxTitle from "../../components/BoxTitle";
import LoadingBar from "../../components/LoadingBar";
import { EDS } from "../../components/Modals/EDS";
import {
  createSchoolDeclaration,
  previewSchoolDeclaration,
  signDocument,
  storeApplicationPdf,
} from "../../services/useEndpoint";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectBoardingPredictiveContingentDataTable,
  selectBoardingStudentsDataTable,
  selectPredictiveContingentDataTable,
  selectStudentsDataTable,
} from "../../store/reducers/createDeclaration";
import {
  selectCurrentUser,
  selectCurrentUserId,
} from "../../store/reducers/currentUserReducer";
import {
  setIsBoardingTouched,
  setIsBoardingValid,
  setIsSchoolTouched,
  setIsSchoolValid,
} from "../../store/reducers/validateCadastralInfoSlice";
import {
  selectInvalidCurrentRows,
  selectInvalidPredictiveRows,
} from "../../store/reducers/validateTablesSlice";
import { fincolors } from "../../styles/theme";
import {
  BREAKPOINT_DATES,
  CADASTRAL_NUMBER_REG_EXP,
} from "../../utils/constants";
import { NcaLayer } from "../../utils/nca-layer";
import {
  BoardingTableRows,
  BoardingTableRowsTwo,
} from "./boardingSchoolTableRows";
import DeclarationStep1 from "./components/DeclarationStep1";
import DeclarationStep2, { TableRowsMini } from "./components/DeclarationStep2";
import DeclarationStep3 from "./components/DeclarationStep3";
import DeclarationStep4 from "./components/DeclarationStep4";
import SuccsessModal from "./components/SuccessModal";
import {
  validationSchemaStep1,
  validationSchemaStep2,
  validationSchemaStep3,
} from "./schoolDeclarationYupSchema";
import { SchoolTableRows, SchoolTableRowsTwo } from "./schoolTableRows";

const steps = ["Данные по организации", "Данные по школе", "Документы"];

// TODO: Вынести валидацию в отдельный файл
// TODO: Раздробить компонент
const rows: TableRowsMini[] = [
  {
    id: 1,
    target_value: "",
    encumbrance_date: "",
    cadastral_number: "",
  },
];
const SchoolDeclarationCreate: React.FC = (props) => {
  const dispatch = useAppDispatch();
  const [rowsTable, setRowsTable] = useState(SchoolTableRows);
  const [rowsTableAll, setRowsTableAll] = useState(SchoolTableRows);
  const [rowsTableTwo, setRowsTableTwo] = useState(SchoolTableRowsTwo);
  const [rowsTableTwoAll, setRowsTableTwoAll] = useState(SchoolTableRowsTwo);
  const [boardingRowsTable, setBoardingRowsTable] = useState(BoardingTableRows);

  const [boardingRowsTableTwo, setBoardingRowsTableTwo] =
    useState(BoardingTableRowsTwo);
  const [rowsTableMini, setRowsTableMini] = useState(rows);
  const [rowsTableMiniTwo, setRowsTableMiniTwo] = useState(rows);
  const [aCurrentStep, setCurrentStep] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [pdf, setPdf] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isStep2, setIsStep2] = useState(false);
  const [isStep3, setIsStep3] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [EDSopen, setEDSopen] = useState(false);
  const [declarationsData, setDeclarationsData] = useState(null);
  const [loadingPrewiewFile, setLoadingPreviewFile] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();
  const [signatoryFileUrl, setSignatoryFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useAppSelector(selectCurrentUser);
  const invalidCurrentRows = useAppSelector(selectInvalidCurrentRows);
  const invalidPredictiveRows = useAppSelector(selectInvalidPredictiveRows);

  useEffect(() => {
    if (currentUser.id) {
      formikStep1.setValues({
        official_number: currentUser.official_number,
        official_address: currentUser.official_address,
        email_address: currentUser.email_address,
        phone_number: currentUser.phone_number,
        actual_address: currentUser.postcode,
        organization_name: currentUser.organization_name,
        signatory_name: "",
        signatory_official_number: "",
      });
    }
  }, [currentUser.id]);
  const handleClose = () => {
    setOpen(false);

    navigate("/school/declarations");
  };
  const navigate = useNavigate();

  const predictiveContingentDataTable = useAppSelector(
    selectPredictiveContingentDataTable,
  );
  const boardingPredictiveContingentDataTable = useAppSelector(
    selectBoardingPredictiveContingentDataTable,
  );
  const studentsDataTable = useAppSelector(selectStudentsDataTable);
  const boardingStudentsDataTable = useAppSelector(
    selectBoardingStudentsDataTable,
  );
  const currentUserId = useAppSelector(selectCurrentUserId);

  async function handleEDS(base64string: string) {
    try {
      return await NcaLayer.sign(base64string);
    } catch (error) {
      toast.error("При подписании документа произошла ошибка");
    }
  }

  const postApplications = async () => {
    if (!declarationsData) return;

    setIsLoading(true);

    try {
      const newData = new FormData();

      for (let [key, value] of declarationsData.entries()) {
        newData.append(key, value);
      }

      const response = await storeApplicationPdf(declarationsData);
      declarationsData.append("pdf", response.file_path);

      const signature = await handleEDS(response.base64_content);
      if (!signature) return;
      await signDocument(signature, response.file_path);

      await createSchoolDeclaration(declarationsData);

      toast.success("Процесс прошел успешно, заявление отправлено");
      setDeclarationsData(null);
      navigate("school/declarations/");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          "Произошла ошибка при отправке данных",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formikStep1 = useFormik({
    initialValues: {
      signatory_name: "",
      signatory_official_number: "",
      organization_name: "",
      official_number: "",
      official_address: "",
      actual_address: "",
      email_address: "",
      phone_number: "",
      signatory_pdf: null,
    },
    validationSchema: validationSchemaStep1,
    validateOnChange: true,
  });

  const formikStep2 = useFormik({
    initialValues: {
      tuition_fee: 0,
      region: null,
      area: null,
      district: null,
      locality: null,
      boarding_school_region: null,
      boarding_school_area: null,
      boarding_school_locality: null,
      street: "",
      house_number: "",
      school_name: "",
      place_count: 0,
      exploitation_year: "",
      elementary_education: 0,
      basic_secondary_education: 0,
      basic_general_education: 0,
      integrated_education: 0,
      national_educational_db_registration: "Нет",
      state_procurements_registration: "Нет",
      boarding_school: "Нет",
      student_contingent: "Городская школа",
      boarding_school_government_order: 0,
      boarding_school_newly_count: 0,
      private_organization_exploitation_type: "Строительство",
      //private_organization_government_order: "Нет",
      private_organization_place_count: 0,
      // private_organization_exploitation_year: "",
      // boarding_school_region: "",
      // boarding_school_city: "",
      // boarding_school_area: "",
      // boarding_school_district: "",
      boarding_school_street: "",
      boarding_school_house_number: "",
      boarding_school_place_count: 0,
      boarding_school_exploitation_year: "",
      boarding_school_exploitation_type: "Строительство",
      license_number: "",
      registered_immutability_agreement: 0,
      boarding_school_registered_immutability_agreement: 0,
      nobd_id: "",
    },
    validationSchema: validationSchemaStep2,
  });

  const formikStep3 = useFormik({
    initialValues: {
      email: "",
      private_organization_government_order: 1,
      education_licence: null,
      legal_entity_certificate: null,
      bank_certificate: null,
      preliminary_agreement: null,
      immutability_agreement: null,
      real_estate_certificate: null,
      project_documentation: null,
      exploitation_act: null,
      condition_conclusion: null,
      cadastral_information: null,
      tuition_fee_statement: null,
      integrated_education_realization_documents: null,
      status_id: 1,
      boarding_school_preliminary_agreement: null,
      boarding_school_immutability_agreement: null,
      boarding_school_project_documentation: null,
      boarding_school_exploitation_act: null,
      boarding_school_condition_conclusion: null,
      boarding_school_real_estate_certificate: null,
      boarding_school_cadastral_information: null,
      anti_terrorism_measures: null,
    },
    validationSchema: validationSchemaStep3,
  });

  //useEffect'ы для сброса значений и состояния touched в таблицах для ячеек с условным отображением
  useEffect(() => {
    // formikStep2.setFieldValue("private_organization_exploitation_year", "");
    formikStep2.setFieldValue("private_organization_place_count", 0);
    formikStep2.setFieldTouched("private_organization_place_count", false);
    // formikStep2.setFieldTouched(
    //   "private_organization_exploitation_year",
    //   false,
    // );
  }, [formikStep2.values.exploitation_year]);

  useEffect(() => {
    formikStep3.setFieldValue(
      "integrated_education",
      formikStep2.values.integrated_education,
    );
  }, [formikStep2.values.integrated_education]);

  useEffect(() => {
    formikStep3.setFieldValue(
      "registered_immutability_agreement",
      formikStep2.values.registered_immutability_agreement,
    );
  }, [formikStep2.values.registered_immutability_agreement]);

  useEffect(() => {
    formikStep3.setFieldValue(
      "boarding_school_registered_immutability_agreement",
      formikStep2.values.boarding_school_registered_immutability_agreement,
    );
  }, [formikStep2.values.boarding_school_registered_immutability_agreement]);

  useEffect(() => {
    formikStep3.setFieldValue(
      "private_organization_exploitation_type",
      formikStep2.values.private_organization_exploitation_type,
    );
  }, [formikStep2.values.private_organization_exploitation_type]);

  useEffect(() => {
    formikStep3.setFieldValue(
      "boarding_school_exploitation_type",
      formikStep2.values.boarding_school_exploitation_type,
    );
  }, [formikStep2.values.boarding_school_exploitation_type]);

  useEffect(() => {
    const fields = [
      "education_licence",
      "bank_certificate",
      "preliminary_agreement",
      "project_documentation",
      "exploitation_act",
      "condition_conclusion",
      "real_estate_certificate",
      "cadastral_information",
      "integrated_education_realization_documents",
      "anti_terrorism_measures",
    ];
    fields.forEach((field) => {
      formikStep3.setFieldTouched(field, false);
      formikStep3.setFieldValue(field, null);
    });
  }, [formikStep3.values.private_organization_government_order]);

  useEffect(() => {
    const fieldsValue = [
      // "boarding_school_city",
      // "boarding_school_area",
      // "boarding_school_street",
      // "boarding_school_district",
      "boarding_school_house_number",
      "boarding_school_exploitation_year",
    ];
    fieldsValue.forEach((field) => {
      formikStep2.setFieldValue(field, "");
    });
    formikStep2.setFieldValue("boarding_school_place_count", 0);
    formikStep2.setFieldValue(
      "boarding_school_exploitation_type",
      "Строительство",
    );
    formikStep2.setFieldValue(
      "boarding_school_registered_immutability_agreement",
      0,
    );

    // formikStep2.setFieldValue("boarding_school_region", "Акмолинская область");
    const fieldsTouched = [
      // "boarding_school_region",
      // "boarding_school_city",
      // "boarding_school_area",
      // "boarding_school_street",
      "boarding_school_house_number",
      // "boarding_school_district",
      "boarding_school_exploitation_year",
      "boarding_school_place_count",
      "boarding_school_exploitation_type",
    ];
    fieldsTouched.forEach((field) => {
      formikStep2.setFieldTouched(field, false);
    });
    setRowsTableMiniTwo(rows);
    setBoardingRowsTable(BoardingTableRows);
    setBoardingRowsTableTwo(BoardingTableRowsTwo);
  }, [formikStep2.values.boarding_school]);

  useEffect(() => {
    if (
      new Date(formikStep2?.values?.exploitation_year) <
      new Date(BREAKPOINT_DATES.school)
    ) {
      setRowsTableMini(rows);
    }
  }, [formikStep2.values.exploitation_year]);

  useEffect(() => {
    if (
      new Date(formikStep2.values.boarding_school_exploitation_year) <
      new Date(BREAKPOINT_DATES.boarding)
    ) {
      formikStep2.setFieldValue(
        "boarding_school_registered_immutability_agreement",
        0,
      );
      setRowsTableMiniTwo(rows);
    }
  }, [formikStep2.values.boarding_school_exploitation_year]);

  useEffect(() => {
    setRowsTableMiniTwo(rows);
  }, [formikStep2.values.boarding_school_registered_immutability_agreement]);

  // const postSchoolDeclarations = async (declarationFormData) => {
  //   try {
  //     const { data } = await createSchoolDeclaration(declarationFormData);
  //     console.log("dataq", data);
  //     setPdfData(data.pdf);
  //     //setEDSopen(true);
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   // await createSchoolDeclaration(declarationFormData)
  //   //   .then((data) => {
  //   //     if (data) {
  //   //       setIsLoading(false);
  //   //       toast.success("Процесс прошел успешно, заявление отправлено");
  //   //       setDeclarationsData(null);
  //   //       // navigate("school/declarations/");
  //   //       setTimeout(() => {
  //   //         window.open(data.data.pdf, "_blank");
  //   //       }, 1000);
  //   //     }
  //   //     // else {
  //   //     //   console.log("Data send failed. Please check your credentials.");
  //   //     // }
  //   //   })
  //   //   .catch((error) => {
  //   //     setIsLoading(false);
  //   //     toast.error(`Произошла ошибка при отправке данных.  ${error.message}`);
  //   //   });
  //   // } catch (error) {
  //   //   toast.error("Произошла попытка при отправке данных. ", error.message);
  //   // }
  // };

  const handlers = {
    closeCreation: () => {
      if (
        !window.confirm(
          "Все заполненные данные удалятся. Вы уверены, что хотите продолжить?",
        )
      )
        return false;
      setCurrentStep(0);
      navigate("/school/declarations");
    },
    nextStep: (forceStep?: number) => {
      const step = forceStep ? forceStep : aCurrentStep + 1;
      setCurrentStep(step);
    },
    touchAllFields: (form) => {
      form.setTouched(
        Object.keys(form.initialValues).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {}),
      );
    },
    validateAndHandleStep: async (currentForm) => {
      const errors = await currentForm.validateForm();

      if (Object.keys(errors).length) {
        toast.error("Пожалуйста, заполните все обязательные поля.");
        handlers.touchAllFields(currentForm);
        setIsSubmitted(!!errors._checkboxGroup);

        return;
      }

      if (currentForm === formikStep1) {
        setIsStep2(true);
        handlers.nextStep();
        return;
      }
      if (currentForm === formikStep2) {
        setIsStep3(true);
        //правка с приоритетом 2
        // if (
        //   currentForm.values.national_educational_db_registration === "Нет" ||
        //   currentForm.values.state_procurements_registration === "Нет" ||
        //   !currentForm.values.license_number
        // ) {
        //   toast.error(
        //     "Без регистрации в НОБД, на веб-портале и без лицензии на образовательную деятельность подать заявление на размещение государственного заказа невозможно!",
        //   );
        //   return
        // }
        if (
          formikStep2.values.national_educational_db_registration === "Нет" ||
          formikStep2.values.state_procurements_registration === "Нет" ||
          !formikStep2.values.nobd_id
        ) {
          toast.error(
            "Без регистрации в НОБД, на веб-портале и без лицензии на образовательную деятельность подать заявление на размещение государственного заказа невозможно!",
          );
          return;
        }
        if (
          Object.keys(invalidCurrentRows).length !== 0 ||
          Object.keys(invalidPredictiveRows).length !== 0
        ) {
          toast.error(
            "Сумма учащихся интернат не может быть больше числа учащихся в образовательном учреждении",
          );
          return;
        }
        if (
          // new Date(BREAKPOINT_DATES.school) <
          // new Date(formikStep2.values.exploitation_year)
          formikStep2.values.registered_immutability_agreement
        ) {
          const filteredRows = rowsTableMini.filter(
            (row) => row.id === 1 || !!row.cadastral_number,
          );
          const invalidRows = filteredRows.filter(
            (row) =>
              !CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) ||
              // &&  row.id === 1
              (CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) &&
                (!row.target_value || !row.encumbrance_date)),
          );
          setRowsTableMini(filteredRows);
          if (invalidRows.length) {
            toast.error("Пожалуйста, заполните обязательные поля.");
            dispatch(setIsSchoolValid(false));
            dispatch(setIsSchoolTouched(true));

            return;
          }
        }

        if (
          // new Date(BREAKPOINT_DATES.boarding) <
          // new Date(formikStep2.values.boarding_school_exploitation_year)
          formikStep2.values.boarding_school_registered_immutability_agreement
        ) {
          const filteredRows = rowsTableMiniTwo.filter(
            (row) => row.id === 1 || !!row.cadastral_number,
          );
          const invalidRows = filteredRows.filter(
            (row) =>
              !CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) ||
              // &&  row.id === 1
              (CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) &&
                (!row.target_value || !row.encumbrance_date)),
          );
          setRowsTableMiniTwo(filteredRows);
          if (invalidRows.length) {
            toast.error("Пожалуйста, заполните обязательные поля.");
            dispatch(setIsBoardingValid(false));
            dispatch(setIsBoardingTouched(true));

            return;
          }
        }

        handlers.nextStep();
        dispatch(setIsSchoolTouched(false));
        dispatch(setIsBoardingTouched(false));
      }
    },
    validateOnStep3: () => {
      formikStep3.validateForm().then((errors) => {
        if (Object.keys(errors).length) {
          toast.error("Пожалуйста, загрузите обязательные файлы.");
          formikStep3.setTouched(
            Object.keys(formikStep3.initialValues).reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {}),
          );
        } else {
          handlers.nextStep();
          const declarationFormData = new FormData();

          //данные с первой формы "Данные по организации", aCurrentStep = 0
          if (formikStep1.values.signatory_pdf) {
            declarationFormData.append(
              "signatory_pdf",
              formikStep1.values.signatory_pdf,
            );
          } else {
            declarationFormData.append("signatory_pdf", "");
          }

          declarationFormData.append(
            "signatory_official_number",
            formikStep1.values.signatory_official_number,
          );
          declarationFormData.append(
            "signatory_name",
            formikStep1.values.signatory_name,
          );
          declarationFormData.append(
            "organization_fields[organization_name]",
            formikStep1.values.organization_name,
          );
          declarationFormData.append(
            "organization_fields[official_number]",
            formikStep1.values.official_number,
          );
          declarationFormData.append(
            "organization_fields[official_address]",
            formikStep1.values.official_address,
          );
          declarationFormData.append(
            "organization_fields[postcode]",
            formikStep1.values.actual_address,
          );
          declarationFormData.append(
            "organization_fields[email_address]",
            formikStep1.values.email_address,
          );
          declarationFormData.append(
            "organization_fields[phone_number]",
            formikStep1.values.phone_number,
          );
          declarationFormData.append("organization_id", currentUserId);
          declarationFormData.append(
            "tuition_fee",
            formikStep2.values.tuition_fee,
          );
          declarationFormData.append("region_id", formikStep2.values.region.id);
          declarationFormData.append("area_id", formikStep2.values.area.id);
          declarationFormData.append(
            "locality_id",
            formikStep2.values.locality.id,
          );

          declarationFormData.append("street", formikStep2.values.street);
          declarationFormData.append(
            "house_number",
            formikStep2.values.house_number,
          );
          declarationFormData.append(
            "school_name",
            formikStep2.values.school_name,
          );
          declarationFormData.append(
            "place_count",
            formikStep2.values.place_count,
          );
          declarationFormData.append(
            "exploitation_year",
            formikStep2.values.exploitation_year,
          );
          declarationFormData.append(
            "registered_immutability_agreement",
            formikStep2.values.registered_immutability_agreement,
          );
          declarationFormData.append(
            "elementary_education",
            formikStep2.values.elementary_education,
          );
          declarationFormData.append(
            "basic_secondary_education",
            formikStep2.values.basic_secondary_education,
          );
          declarationFormData.append(
            "basic_general_education",
            formikStep2.values.basic_general_education,
          );
          declarationFormData.append(
            "integrated_education",
            formikStep2.values.integrated_education,
          );
          declarationFormData.append(
            "national_educational_db_registration",
            formikStep2.values.national_educational_db_registration,
          );
          declarationFormData.append(
            "state_procurements_registration",
            formikStep2.values.state_procurements_registration,
          );
          declarationFormData.append(
            "boarding_school",
            formikStep2.values.boarding_school,
          );
          declarationFormData.append(
            "boarding_school_registered_immutability_agreement",
            formikStep2.values
              .boarding_school_registered_immutability_agreement,
          );

          declarationFormData.append(
            "student_contingent",
            formikStep2.values.student_contingent,
          );
          declarationFormData.append(
            "students_data_table",
            JSON.stringify(studentsDataTable),
          );
          declarationFormData.append(
            "predictive_contingent_data_table",
            JSON.stringify(predictiveContingentDataTable),
          );
          declarationFormData.append(
            "boarding_school_students_data_table",
            JSON.stringify(boardingStudentsDataTable),
          );
          declarationFormData.append(
            "boarding_school_predictive_contingent_data_table",
            JSON.stringify(boardingPredictiveContingentDataTable),
          );
          declarationFormData.append(
            "private_organization_exploitation_type",
            formikStep2.values.private_organization_exploitation_type,
          );
          declarationFormData.append(
            "boarding_school_government_order",
            formikStep2.values.boarding_school_government_order,
          );
          // declarationFormData.append(
          //   "private_organization_government_order",
          //   formikStep2.values.private_organization_government_order,
          // );
          declarationFormData.append(
            "private_organization_place_count",
            formikStep2.values.private_organization_place_count,
          );
          declarationFormData.append(
            "boarding_school_newly_count",
            Number(formikStep2.values.boarding_school_newly_count),
          );
          console.log(
            "formikStep2.values.boarding_school_newly_count",
            Number(formikStep2.values.boarding_school_newly_count),
          );

          declarationFormData.append(
            "cadastral_table",
            JSON.stringify(rowsTableMini),
          );
          declarationFormData.append(
            "boarding_school_cadastral_table",
            JSON.stringify(rowsTableMiniTwo),
          );
          declarationFormData.append(
            "license_number",
            formikStep2.values.license_number,
          );
          declarationFormData.append("nobd_id", formikStep2.values.nobd_id);
          //данные с третьей формы "Документы", aCurrentStep = 2
          declarationFormData.append(
            "private_organization_government_order",
            formikStep3.values.private_organization_government_order,
          );
          // if (
          //   new Date(formikStep2.values.exploitation_year) <
          //   new Date(BREAKPOINT_DATES.school)
          // ) {
          //   declarationFormData.append(
          //     "private_organization_government_order",
          //     "",
          //   );
          // } else {
          //   declarationFormData.append(
          //     "private_organization_government_order",
          //     formikStep3.values.private_organization_government_order,
          //   );
          // }
          declarationFormData.append(
            "education_licence",
            formikStep3.values.education_licence !== null
              ? formikStep3.values.education_licence
              : "",
          ); //file

          declarationFormData.append(
            "legal_entity_certificate",
            formikStep3.values.legal_entity_certificate !== null
              ? formikStep3.values.legal_entity_certificate
              : "",
          ); // file

          declarationFormData.append(
            "bank_certificate",
            formikStep3.values.bank_certificate !== null
              ? formikStep3.values.bank_certificate
              : "",
          ); // file

          declarationFormData.append(
            "anti_terrorism_measures",
            formikStep3.values.anti_terrorism_measures !== null
              ? formikStep3.values.anti_terrorism_measures
              : "",
          );

          declarationFormData.append(
            "preliminary_agreement",
            formikStep3.values.preliminary_agreement !== null
              ? formikStep3.values.preliminary_agreement
              : "",
          ); // file

          declarationFormData.append(
            "boarding_school_preliminary_agreement",
            formikStep3.values.boarding_school_preliminary_agreement !== null
              ? formikStep3.values.boarding_school_preliminary_agreement
              : "",
          ); // file

          declarationFormData.append(
            "immutability_agreement",
            formikStep3.values.immutability_agreement !== null
              ? formikStep3.values.immutability_agreement
              : "",
          ); // file

          declarationFormData.append(
            "boarding_school_immutability_agreement",
            formikStep3.values.boarding_school_immutability_agreement !== null
              ? formikStep3.values.boarding_school_immutability_agreement
              : "",
          ); // file

          declarationFormData.append(
            "real_estate_certificate",
            formikStep3.values.real_estate_certificate != null
              ? formikStep3.values.real_estate_certificate
              : "",
          ); // file

          declarationFormData.append(
            "boarding_school_project_documentation",
            formikStep3.values.boarding_school_project_documentation != null
              ? formikStep3.values.boarding_school_project_documentation
              : "",
          ); // file

          declarationFormData.append(
            "project_documentation",
            formikStep3.values.project_documentation !== null
              ? formikStep3.values.project_documentation
              : "",
          ); // file

          declarationFormData.append(
            "exploitation_act",
            formikStep3.values.exploitation_act !== null
              ? formikStep3.values.exploitation_act
              : "",
          ); //file

          declarationFormData.append(
            "boarding_school_exploitation_act",
            formikStep3.values.boarding_school_exploitation_act !== null
              ? formikStep3.values.boarding_school_exploitation_act
              : "",
          ); //file

          declarationFormData.append(
            "condition_conclusion",
            formikStep3.values.condition_conclusion !== null
              ? formikStep3.values.condition_conclusion
              : "",
          ); //file

          declarationFormData.append(
            "boarding_school_condition_conclusion",
            formikStep3.values.boarding_school_condition_conclusion !== null
              ? formikStep3.values.boarding_school_condition_conclusion
              : "",
          ); //file

          declarationFormData.append(
            "boarding_school_real_estate_certificate",
            formikStep3.values.boarding_school_real_estate_certificate !== null
              ? formikStep3.values.boarding_school_real_estate_certificate
              : "",
          ); //file

          declarationFormData.append(
            "cadastral_information",
            formikStep3.values.cadastral_information !== null
              ? formikStep3.values.cadastral_information
              : "",
          ); //file

          declarationFormData.append(
            "boarding_school_cadastral_information",
            formikStep3.values.boarding_school_cadastral_information !== null
              ? formikStep3.values.boarding_school_cadastral_information
              : "",
          ); //file

          declarationFormData.append(
            "tuition_fee_statement",
            formikStep3.values.tuition_fee_statement !== null
              ? formikStep3.values.tuition_fee_statement
              : "",
          ); //file
          if (
            formikStep3.values.integrated_education_realization_documents &&
            formikStep3.values.integrated_education_realization_documents.length
          ) {
            Array.from(
              formikStep3.values.integrated_education_realization_documents,
            ).forEach((el) => {
              declarationFormData.append(
                "integrated_education_realization_documents[]",
                el,
              );
            });
          }
          // declarationFormData.append(
          //   "integrated_education_realization_documents[]",
          //   values.integrated_education_realization_documents,
          // );
          declarationFormData.append("status_id", formikStep3.values.status_id);
          console.log(
            "formikStep2.values.boarding_school",
            formikStep2.values.boarding_school,
          );
          if (formikStep2.values.boarding_school !== "Нет") {
            if (formikStep2.values.boarding_school_region) {
              declarationFormData.append(
                "boarding_school_region_id",
                formikStep2.values.boarding_school_region.id,
              );
            }
            if (formikStep2.values.boarding_school_area) {
              declarationFormData.append(
                "boarding_school_area_id",
                formikStep2.values.boarding_school_area.id,
              );
            }
            if (formikStep2.values.boarding_school_locality) {
              declarationFormData.append(
                "boarding_school_locality_id",
                formikStep2.values.boarding_school_locality.id,
              );
            }
          }

          // declarationFormData.append(
          //   "boarding_school_region",
          //   formikStep2.values.boarding_school_region,
          // );
          // declarationFormData.append(
          //   "boarding_school_city",
          //   formikStep2.values.boarding_school_city,
          // );
          // declarationFormData.append(
          //   "boarding_school_area",
          //   formikStep2.values.boarding_school_area,
          // );
          // declarationFormData.append(
          //   "boarding_school_district",
          //   formikStep2.values.boarding_school_district,
          // );
          declarationFormData.append(
            "boarding_school_street",
            formikStep2.values.boarding_school_street,
          );
          declarationFormData.append(
            "boarding_school_house_number",
            formikStep2.values.boarding_school_house_number,
          );

          declarationFormData.append(
            "boarding_school_place_count",
            formikStep2.values.boarding_school_place_count,
          );
          declarationFormData.append(
            "boarding_school_exploitation_year",
            formikStep2.values.boarding_school_exploitation_year,
          );
          declarationFormData.append(
            "boarding_school_exploitation_type",
            formikStep2.values.boarding_school_exploitation_type,
          );
          setDeclarationsData(declarationFormData);
          console.log("DATAA  declarationFormData = ", declarationFormData);
          setLoadingPreviewFile(true);

          try {
            previewSchoolDeclaration(declarationFormData).then((res) => {
              if (res) {
                toast.info(
                  "Если PDF файл не отображается, проверьте параметры отображения файлов в настройках браузера",
                );
                console.log("res previewSchoolDeclaration = ", res);
                const data = new Blob([res], { type: "application/pdf" });
                setPdfUrl(window.URL.createObjectURL(data));
                setLoadingPreviewFile(false);
              } else {
                console.log("Data send failed. Please check your credentials.");
              }
            });
          } catch (error) {
            console.log(error);
            setLoadingPreviewFile(false);
          }
          // setEDSopen(true);
        }
      });
    },
  };

  const onClickCurrentPage = (index) => {
    console.log("index", index);
    if (index === 1 && isStep2 === true) {
      setCurrentStep(index);
    }
    if (index === 2 && isStep3 === true) {
      setCurrentStep(index);
    }
    if (index === 0) {
      setCurrentStep(index);
    }
  };
  const resetIsSubmitted = () => {
    setIsSubmitted(false);
  };

  useEffect(() => {
    let newSchoolRows = SchoolTableRows;
    let newSchoolRowsTwo = SchoolTableRowsTwo;
    let newBoardingSchoolRows = BoardingTableRows;
    let newBoardingSchoolRowsTwo = BoardingTableRowsTwo;
    if (formikStep2.values.elementary_education === 0) {
      newSchoolRows = newSchoolRows.filter((elem) => elem.id !== 1);
      newSchoolRowsTwo = newSchoolRowsTwo.filter((elem) => elem.id !== 1);
      newBoardingSchoolRows = newBoardingSchoolRows.filter(
        (elem) => elem.id !== 1,
      );
      newBoardingSchoolRowsTwo = newBoardingSchoolRowsTwo.filter(
        (elem) => elem.id !== 1,
      );
    }
    if (formikStep2.values.basic_secondary_education === 0) {
      newSchoolRows = newSchoolRows.filter((elem) => elem.id !== 2);
      newSchoolRowsTwo = newSchoolRowsTwo.filter((elem) => elem.id !== 2);
      newBoardingSchoolRows = newBoardingSchoolRows.filter(
        (elem) => elem.id !== 2,
      );
      newBoardingSchoolRowsTwo = newBoardingSchoolRowsTwo.filter(
        (elem) => elem.id !== 2,
      );
    }
    if (formikStep2.values.basic_general_education === 0) {
      newSchoolRows = newSchoolRows.filter((elem) => elem.id !== 3);
      newSchoolRowsTwo = newSchoolRowsTwo.filter((elem) => elem.id !== 3);
      newBoardingSchoolRows = newBoardingSchoolRows.filter(
        (elem) => elem.id !== 3,
      );
      newBoardingSchoolRowsTwo = newBoardingSchoolRowsTwo.filter(
        (elem) => elem.id !== 3,
      );
    }

    if (formikStep2.values.integrated_education === 0) {
      newSchoolRows = newSchoolRows.filter(
        (elem) => elem.id !== 5 && elem.id !== 6 && elem.id !== 7,
      );
      newSchoolRowsTwo = newSchoolRowsTwo.filter(
        (elem) => elem.id !== 5 && elem.id !== 6 && elem.id !== 7,
      );
      newBoardingSchoolRows = newBoardingSchoolRows.filter(
        (elem) => elem.id !== 5 && elem.id !== 6 && elem.id !== 7,
      );
      newBoardingSchoolRowsTwo = newBoardingSchoolRowsTwo.filter(
        (elem) => elem.id !== 5 && elem.id !== 6 && elem.id !== 7,
      );
    }
    setRowsTable(newSchoolRows);
    setRowsTableAll(newSchoolRows);
    setRowsTableTwo(newSchoolRowsTwo);
    setRowsTableTwoAll(newSchoolRowsTwo);
    setBoardingRowsTable(newBoardingSchoolRows);
    setBoardingRowsTableTwo(newBoardingSchoolRowsTwo);
  }, [
    formikStep2.values.elementary_education,
    formikStep2.values.basic_secondary_education,
    formikStep2.values.basic_general_education,
    formikStep2.values.boarding_school,
    formikStep2.values.integrated_education,
  ]);

  return (
    <>
      <Box>
        <Button
          variant="text"
          startIcon={<span>{"<"}</span>}
          onClick={() => handlers.closeCreation()}
        >
          Назад к заявлениям
        </Button>
      </Box>

      <BoxTitle
        title={
          "Заявление на размещение гос. образовательного заказа на среднее образование"
        }
      >
        {(aCurrentStep === 0 || aCurrentStep === 1) && (
          <Button
            sx={{ minWidth: "200px" }}
            form={`declarations_step_${1 + aCurrentStep}`}
            //  type="submit"
            onClick={() => {
              if (aCurrentStep === 0) {
                handlers.validateAndHandleStep(formikStep1);
              }
              if (aCurrentStep === 1) {
                handlers.validateAndHandleStep(formikStep2);
              }
            }}
          >
            Продолжить
          </Button>
        )}

        {aCurrentStep === 2 && (
          <Button
            sx={{ minWidth: "200px", whiteSpace: "nowrap" }}
            form="declarations_step_3"
            //type="submit"
            onClick={() => {
              handlers.validateOnStep3();
            }}
          >
            Сформировать заявление
          </Button>
        )}

        {aCurrentStep === 3 && (
          <Button
            sx={{ minWidth: "200px", whiteSpace: "nowrap" }}
            form="declarations_step_3"
            type="submit"
            onClick={postApplications}
            disabled={loadingPrewiewFile}
          >
            Отправить
          </Button>
        )}
      </BoxTitle>

      <Stack
        direction={"row"}
        sx={{
          background: fincolors.background.paper,
          borderRadius: "10px",
        }}
      >
        {steps.map((label, index) => {
          const isMe = index === +aCurrentStep;
          return (
            <Button
              key={index}
              sx={{
                width: "calc(100% / 3)",
                color: isMe ? "" : fincolors.text.primary,
                background: isMe ? "" : fincolors.background.paper,
                fontWeight: isMe ? 500 : 400,
              }}
              onClick={() => onClickCurrentPage(index)}
            >
              {label}
            </Button>
          );
        })}
      </Stack>

      {aCurrentStep === 0 ? (
        <DeclarationStep1
          formik={formikStep1}
          isDisabled={isDisabled}
          isInputDisabled={false}
          signatoryFileUrl={signatoryFileUrl}
          setSignatoryFileUrl={setSignatoryFileUrl}
          isCreation={true}
        />
      ) : aCurrentStep === 1 ? (
        <DeclarationStep2
          formik={formikStep2}
          rowsTable={rowsTable}
          setRowsTable={setRowsTable}
          rowsTableAll={rowsTableAll}
          setRowsTableAll={setRowsTableAll}
          rowsTableTwo={rowsTableTwo}
          setRowsTableTwo={setRowsTableTwo}
          rowsTableTwoAll={rowsTableTwoAll}
          setRowsTableTwoAll={setRowsTableTwoAll}
          boardingRowsTable={boardingRowsTable}
          setBoardingRowsTable={setBoardingRowsTable}
          // boardingRowsTableAll={boardingRowsTableAll}
          // setBoardingRowsTableAll={setBoardingRowsTableAll}
          boardingRowsTableTwo={boardingRowsTableTwo}
          setBoardingRowsTableTwo={setBoardingRowsTableTwo}
          // boardingRowsTableTwoAll={boardingRowsTableTwoAll}
          // setBoardingRowsTableTwoAll={setBoardingRowsTableTwoAll}
          rowsTableMini={rowsTableMini}
          setRowsTableMini={setRowsTableMini}
          rowsTableMiniTwo={rowsTableMiniTwo}
          setRowsTableMiniTwo={setRowsTableMiniTwo}
          isDisabled={isDisabled}
          isSubmitted={isSubmitted}
          resetIsSubmitted={resetIsSubmitted}
        />
      ) : aCurrentStep === 2 ? (
        <DeclarationStep3
          formik={formikStep3}
          formik2Values={formikStep2.values}
          isDisabled={isDisabled}
          isCreation={true}
          isInputDisabled={false}
        />
      ) : aCurrentStep === 3 ? (
        <DeclarationStep4 pdfUrl={pdfUrl} isLoading={loadingPrewiewFile} />
      ) : null}
      <BoxTitle>
        {(aCurrentStep === 0 || aCurrentStep === 1) && (
          <Button
            sx={{ minWidth: "200px" }}
            form={`declarations_step_${1 + aCurrentStep}`}
            //  type="submit"
            onClick={() => {
              if (aCurrentStep === 0) {
                handlers.validateAndHandleStep(formikStep1);
              }
              if (aCurrentStep === 1) {
                handlers.validateAndHandleStep(formikStep2);
              }
            }}
          >
            Продолжить
          </Button>
        )}
        {aCurrentStep === 2 && (
          <Button
            sx={{ minWidth: "200px", whiteSpace: "nowrap" }}
            form="declarations_step_3"
            //type="submit"
            onClick={() => {
              handlers.validateOnStep3();
            }}
          >
            Сформировать заявление
          </Button>
        )}
        {aCurrentStep === 3 && (
          <Button
            sx={{ minWidth: "200px", whiteSpace: "nowrap" }}
            form="declarations_step_3"
            type="submit"
            // onClick={() => setEDSopen(true)}
            onClick={() => postApplications()}
            disabled={loadingPrewiewFile}
          >
            Отправить
          </Button>
        )}
      </BoxTitle>

      <SuccsessModal open={open} pdf={pdf} onClose={handleClose} />

      <EDS open={EDSopen} submit={() => {}} onClose={() => setEDSopen(false)} />

      <LoadingBar
        open={isLoading}
        title={"Подождите, идет загрузка..."}
        onCancel={() => setIsLoading(false)}
      />
    </>
  );
};

export default SchoolDeclarationCreate;
