import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";

type Spetialist = {
  actual_address: null | string;
  ceo_fullname: null | string;
  ceo_official_number: null | string;
  created_at: Date | string;
  email_address: string;
  id: number;
  login: string;
  official_address: null | string;
  official_number: string;
  organization_name: string;
  phone_number: string;
  postcode: null | string;
};

export default function WorkerSelect({
  spetialists,
  disableButton,
  mainSpecialists,
  executorId,
  mainExecutorid,
}: {
  disableButton: (executor_id: number, main_executor_id: number | null) => void;
  spetialists: Spetialist[];
  mainSpecialists: Spetialist[];
  executorId: number | null;
  mainExecutorid: number | null;
}) {
  const [spetialistId, setSpetialistId] = useState<number | null>(executorId);
  const [mainSpetialistId, setMainSpetialistId] = useState<number | null>(
    mainExecutorid,
  );

  const handleSpetialistId = (event: SelectChangeEvent) => {
    const newValue = Number(event.target.value);
    setSpetialistId(newValue);
  };

  const handleMainSpetialistId = (event: SelectChangeEvent) => {
    const newValue = Number(event.target.value);
    setMainSpetialistId(newValue ?? null);
  };
  useEffect(() => {
    if (spetialistId) {
      disableButton(spetialistId, mainSpetialistId);
    }
  }, [spetialistId, mainSpetialistId]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ marginTop: "30px" }} fullWidth>
        <InputLabel id="spelialist-select">Специалист</InputLabel>
        <Select
          labelId="spelialist-select"
          id="spelialist-select"
          value={
            spetialists
              .find((item) => item.id === spetialistId)
              ?.id.toString() || ""
          }
          label="Spetialist"
          onChange={handleSpetialistId}
        >
          {spetialists &&
            spetialists.map((item) => {
              return (
                <MenuItem value={item.id}>{item.organization_name}</MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <FormControl sx={{ marginTop: "30px" }} fullWidth>
        <InputLabel id="main-spetialist-select">
          Главный специалист (не обязательно)
        </InputLabel>
        <Select
          labelId="main-spetialist-select"
          id="main-spetialist-select"
          value={
            mainSpecialists
              .find((item) => item.id === mainSpetialistId)
              ?.id.toString() || ""
          }
          label="Main Spetialis"
          onChange={handleMainSpetialistId}
        >
          {mainSpecialists &&
            mainSpecialists.map((item) => {
              return (
                <MenuItem value={item.id}>{item.organization_name}</MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
}
