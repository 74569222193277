import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  selectBoardingPredictiveContingentDataTable,
  selectBoardingSchool,
  selectBoardingStudentsDataTable,
  selectPredictiveContingentDataTable,
  selectStudentsDataTable,
} from "../store/reducers/createDeclaration";
import { BoardingTableRows, SchoolTableRows } from "../types/tableRowsTypes";
import {
  setInvalidCurrentRows,
  setInvalidPredictiveRows,
} from "../store/reducers/validateTablesSlice";
export interface CheckCellsValuesParams {
  isCurrent: boolean;
}

interface ValidationErrors {
  [key: number]: string[];
}

const validatePair = (
  first: number,
  second: number,
  third: number,
  id: number,
) => {
  if (id === 4) return true;
  return first >= second + third;
};

export const useCheckCellsValues = (isCurrent: boolean) => {
  const [allErrors, setAllErrors] = useState({});
  const dispatch = useAppDispatch();
  const studentsDataTable = useAppSelector(
    isCurrent ? selectStudentsDataTable : selectPredictiveContingentDataTable,
  );
  const boardingStudentsDataTable = useAppSelector(
    isCurrent
      ? selectBoardingStudentsDataTable
      : selectBoardingPredictiveContingentDataTable,
  );
  const boardingSchool = useAppSelector(selectBoardingSchool);

  const validateAllTables = () => {
    const newErrors: ValidationErrors = {};
    studentsDataTable?.forEach((schoolRow) => {
      const boardingRow = boardingStudentsDataTable?.find(
        (row) => row.id === schoolRow.id,
      );
      if (boardingRow && schoolRow.id !== undefined) {
        const { invalidFields } = checkValues(
          schoolRow,
          boardingRow,
          schoolRow.id,
        );
        if (invalidFields.length > 0) {
          newErrors[schoolRow.id] = invalidFields;
        }
      }
    });
    setAllErrors(newErrors);
    if (isCurrent) {
      dispatch(setInvalidCurrentRows(newErrors));
    } else {
      dispatch(setInvalidPredictiveRows(newErrors));
    }
  };
  useEffect(() => {
    // Проверяем все строки при изменении данных таблиц
    validateAllTables();
  }, [studentsDataTable, boardingStudentsDataTable, isCurrent]);

  useEffect(() => {
    // Проверяем состояние boardingSchool и обновляем валидацию
    if (boardingSchool !== "Есть") {
      dispatch(setInvalidCurrentRows({}));
      dispatch(setInvalidPredictiveRows({}));
      setAllErrors({})
    } 
  }, [boardingSchool]);

  const checkValues = (
    schoolRow: SchoolTableRows,
    boardingRow: BoardingTableRows,
    id: number,
  ) => {
    // Создаем объект для хранения результатов проверки каждой пары
    const validationResult = {
      classes: true,
      all_pupils: true,
      classSchool: true,
      classSummStudents: true,
      common_pupils: validatePair(
        Number(schoolRow.common_pupils),
        Number(boardingRow.normativClassBoarding),
        Number(boardingRow.normativClassDaily),
        id,
      ),
      normativClassBoarding: validatePair(
        Number(schoolRow.common_pupils),
        Number(boardingRow.normativClassBoarding),
        Number(boardingRow.normativClassDaily),
        id,
      ),
      normativClassDaily: validatePair(
        Number(schoolRow.common_pupils),
        Number(boardingRow.normativClassBoarding),
        Number(boardingRow.normativClassDaily),
        id,
      ),
      correction_pupils: validatePair(
        Number(schoolRow.correction_pupils),
        Number(boardingRow.correctiveClassBoarding),
        Number(boardingRow.correctiveClassDaily),
        id,
      ),
      correctiveClassBoarding: validatePair(
        Number(schoolRow.correction_pupils),
        Number(boardingRow.correctiveClassBoarding),
        Number(boardingRow.correctiveClassDaily),
        id,
      ),
      correctiveClassDaily: validatePair(
        Number(schoolRow.correction_pupils),
        Number(boardingRow.correctiveClassBoarding),
        Number(boardingRow.correctiveClassDaily),
        id,
      ),
      home_pupils: validatePair(
        Number(schoolRow.home_pupils),
        Number(boardingRow.homeSchoolersDaily),
        0,
        id,
      ),
      homeSchoolersDaily: validatePair(
        Number(schoolRow.home_pupils),
        Number(boardingRow.homeSchoolersDaily),
        0,
        id,
      ),
      special_pupils: validatePair(
        Number(schoolRow.special_pupils),
        Number(boardingRow.specialClassBoarding),
        Number(boardingRow.specialClassDaily),
        id,
      ),
      specialClassBoarding: validatePair(
        Number(schoolRow.special_pupils),
        Number(boardingRow.specialClassBoarding),
        Number(boardingRow.specialClassDaily),
        id,
      ),
      specialClassDaily: validatePair(
        Number(schoolRow.special_pupils),
        Number(boardingRow.specialClassBoarding),
        Number(boardingRow.specialClassDaily),
        id,
      ),
    };
    const invalidFields = Object.entries(validationResult)
      .filter(([_, isValid]) => !isValid)
      .map(([field]) => field);
    return {
      validationResult,
      invalidFields,
    };
  };

  return { checkValues, allErrors };
};
