import { Box, Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Cookies from "js-cookie";
import { ROLES } from "../../utils/constants";

interface ApproveModalProps {
  open: boolean;
  onClose: () => void;
  submit: () => void;
}

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function ApproveModal({
  open,
  onClose,
  submit,
}: ApproveModalProps) {
  const role = Cookies.get("role");

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Согласование заявки
        </Typography>

        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, textAlign: "center", mb: 2 }}
        >
          {Number(role) === ROLES.directorApd.id
            ? "Заявление соответствует всем требованиям, подписать ЭЦП"
            : "В связи с соответствием всех требований, нужно подписать ЭЦП"}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button sx={{ width: "45%" }} onClick={onClose}>
            Отмена
          </Button>
          <Button sx={{ width: "45%" }} onClick={submit}>
            Подписать ЭЦП
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
