import { AxiosRequestConfig } from "axios";
import anAxiosApi from "../../services/axiosApi";
import { Meta } from "../../types/types";
import { IOutgoingMail } from "./types";

export const getOutgoingMails = async (config?: AxiosRequestConfig) => {
  const { data } = await anAxiosApi.get<{ data: IOutgoingMail[]; meta: Meta }>(
    "/application/outgoing-mails",
    config,
  );
  return data;
};
