import { createSlice } from "@reduxjs/toolkit";
import { GetDeclarationsParams } from "../../types/types";
import { RootState } from "../store";

interface InitialState {
  filterParams: GetDeclarationsParams;
  orderBy: string;
  orderDirection: string;
}
const initialState: InitialState = {
  filterParams: {},
  orderBy: "created_at",
  orderDirection: "desc",
};

export const setFilterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setStatusFilter: (state, action) => {
      state.filterParams.status_id = action.payload;
    },
    setOverdueFilter: (state, action) => {
      delete state.filterParams.status_id;
      state.filterParams.overdue = action.payload;
    },
    setTypeFilter: (state, action) => {
      state.filterParams.type = action.payload;
    },
    setDateFilter: (state, action) => {
      state.filterParams.date = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.filterParams.search = action.payload;
    },
    setSpecialistFilter: (state, action) => {
      state.filterParams.current_executor_id = action.payload;
    },
    setApplicationId: (state, action) => {
      state.filterParams.id = action.payload;
    },
    setOfficialNumber: (state, action) => {
      state.filterParams.official_number = action.payload;
    },
    setReviewDaysCount: (state, action) => {
      state.filterParams.review_days_count = action.payload;
    },
    setClearFilter: (state) => {
      state.filterParams = {};
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setOrderDirection: (state, action) => {
      state.orderDirection = action.payload;
    },
  },
});

export const selectFilter = (state: RootState) =>
  state.filterReducer.filterParams;

export const selectSortOrderBy = (state: RootState) =>
  state.filterReducer.orderBy;

export const selectSortOrderDirection = (state: RootState) =>
  state.filterReducer.orderDirection;

export const {
  setStatusFilter,
  setOverdueFilter,
  setTypeFilter,
  setDateFilter,
  setSearchFilter,
  setClearFilter,
  setApplicationId,
  setOfficialNumber,
  setReviewDaysCount,
  setSpecialistFilter,
  setOrderBy,
  setOrderDirection,
} = setFilterSlice.actions;
export default setFilterSlice.reducer;
