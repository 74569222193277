import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";

const FilePreview = ({
  pdfUrl,
  isLoading,
}: {
  pdfUrl: string;
  isLoading: boolean;
}) => {
  useEffect(() => {
    return () => {
      if (pdfUrl) {
        window.URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);
  return (
    <>
      <Typography textTransform="uppercase">документ</Typography>
      <Box position="relative" height="1000px">
        {isLoading && (
          <CircularProgress
            size={68}
            sx={{
              color: "primary.main",
              position: "absolute",
              top: "40px",
              left: "50%",
              marginTop: "-34px",
              marginLeft: "-34px",
            }}
          />
        )}
        {!isLoading && pdfUrl && (
          <iframe
            src={pdfUrl}
            style={{ width: "100%", height: "1000px" }}
            title="preview file"
          />
        )}
      </Box>
    </>
  );
};

export default FilePreview;
