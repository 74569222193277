import { Link, useMatch } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  ApartmentOutlined,
  ImportContactsOutlined,
  FileCopyOutlined,
  MarkEmailUnreadOutlined,
  Mail,
} from "@mui/icons-material";
import React from "react";

interface MenuItem {
  path: string;
  text: string;
  icon: React.ReactElement;
}

const menuItems: MenuItem[] = [
  { path: "/profile", text: "Профиль", icon: <ApartmentOutlined /> },
  // { path: "/schools", text: "Мои школы", icon: <ImportContactsOutlined /> },
  { path: "/declarations", text: "Мои заявления", icon: <FileCopyOutlined /> },
  {
    path: "/notifications",
    text: "Уведомления",
    icon: <MarkEmailUnreadOutlined />,
  },
  // { path: "/financing", text: "Уведомления", icon: <Mail /> },
];

const SideMenuItem: React.FC<MenuItem> = ({ path, text, icon }) => {
  const match = useMatch({ path: "/school" + path, end: path.length === 1 });

  return (
    <ListItem disablePadding>
      <ListItemButton
        //disabled={path !== "/declarations"}
        to={"/school" + path}
        component={Link}
        selected={Boolean(match)}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

const SideMenu: React.FC = () => {
  return (
    <List
      sx={{
        width: "310px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {menuItems.map((item) => (
        <SideMenuItem key={item.path} {...item} />
      ))}
      <Box sx={{ mt: 2, marginLeft: 2 }}>
        <Typography>Телефон Службы поддержки</Typography>
        <Typography>+7 775 128 61 88</Typography>
      </Box>
    </List>
  );
};

export default SideMenu;
