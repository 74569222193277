import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
const initialState:any = {
  currentDeclarations: [],
};

export const currentDeclarationSlice = createSlice({
  name: "currentDeclaration",
  initialState,
  reducers: {
    setCurrentDeclarations: (state, action) => {
      state.currentDeclarations = action.payload;
    },
  },
});

export const selectCurrentDeclaration = (state: RootState) =>
  state.currentDeclarationReducer.currentDeclarations;

export const { setCurrentDeclarations } = currentDeclarationSlice.actions;
export default currentDeclarationSlice.reducer;
