import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";

import { Download } from "@mui/icons-material";
import { toast } from "react-toastify";
import BoxCard from "../../../components/BoxCard";
import Comments from "../../../components/Comments";
import CommentsConclusion from "../../../components/CommentsConclusion";
import FileDownloadField from "../../../components/FileDownloadField";
import TableBoardingData from "../../../components/TableBoardingData";
import { getSignersDocument } from "../../../services/useEndpoint";
import { TableRowsMini } from "../../../types/tableRowsTypes";
import {
  BREAKPOINT_DATES,
  COMMENTS_TYPES,
  EXECUTOR_TYPES,
} from "../../../utils/constants";
import { downloadFile } from "../../../utils/helpers";
import PupilsTable from "../../DeclarationsData/components/PupilsTable";
import TableDeclarationMini from "../../SchoolDeclarationCreate/components/TableDeclarationMini/TableDeclarationMini";

const StyledTypography = styled(Typography)({
  fontSize: "14px",
  lineHeight: "1.5em",
  marginBottom: "10px",
  marginTop: "10px",
});

export default function MainSpecialistDeclarationInner({
  data,
  isBoardingSchool,
}: {
  data: any;
  isBoardingSchool: string;
}) {
  const rows: TableRowsMini[] = [
    {
      id: 1,
      target_value: "",
      encumbrance_date: "",
      cadastral_number: "",
    },
  ];

  const studentsData =
    data?.students_data_table && JSON.parse(data?.students_data_table);

  const predictiveContingentData =
    data?.predictive_contingent_data_table &&
    JSON.parse(data?.predictive_contingent_data_table);

  const boardingStudentsData =
    data?.boarding_school_students_data_table &&
    JSON.parse(data?.boarding_school_students_data_table);

  const boardingPredictiveContingentData =
    data?.boarding_school_predictive_contingent_data_table &&
    JSON.parse(data?.boarding_school_predictive_contingent_data_table);

  const tableDeclarationsMini =
    data?.cadastral_table && JSON.parse(data?.cadastral_table);
  const tableDeclarationsMiniTwo =
    data?.boarding_school_cadastral_table &&
    JSON.parse(data?.boarding_school_cadastral_table);

  const [, setTableDeclarationsMini] = useState<TableRowsMini[]>(rows);
  const [, setTableDeclarationsMiniTwo] = useState<TableRowsMini[]>(rows);

  const [PDFLoading, setPDFLoading] = useState(false);
  const [checkListPDFLoading, setCheckListPDFLoading] = useState(false);
  const [DBFCheckListPDFLoading, setDBFCheckListPDFLoading] = useState(false);

  const handleDownloadDBFCheckListPDF = () => {
    setDBFCheckListPDFLoading(true);

    getSignersDocument({ file_path: data?.dbf_check_list?.pdf })
      .then((data) => {
        downloadFile(data, "Проверочный лист ДБФ1.pdf", "application/pdf");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setDBFCheckListPDFLoading(false);
      });
  };

  const handleDownloadCheckListPDF = () => {
    setCheckListPDFLoading(true);

    getSignersDocument({ file_path: data.check_list.pdf })
      .then((data) => {
        downloadFile(data, "Проверочный лист АПД.pdf", "application/pdf");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setCheckListPDFLoading(false);
      });
  };

  const handleDownloadPDF = () => {
    setPDFLoading(true);

    getSignersDocument({ file_path: data?.pdf })
      .then((data) => {
        downloadFile(data, "Заявление.pdf", "application/pdf");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setPDFLoading(false);
      });
  };

  return (
    <>
      <Stack sx={{ mb: 1, gap: 1, alignItems: "flex-start" }}>
        <Button
          variant={"outlined"}
          size={"small"}
          onClick={handleDownloadPDF}
          disabled={PDFLoading}
          startIcon={
            <Download
              style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
            />
          }
        >
          Скачать заявление
        </Button>

        {data?.dbf_check_list && data?.dbf_check_list?.pdf && (
          <Button
            variant={"outlined"}
            size={"small"}
            onClick={handleDownloadDBFCheckListPDF}
            disabled={DBFCheckListPDFLoading}
            startIcon={
              <Download
                style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
              />
            }
          >
            Проверочный лист ДБФ1
          </Button>
        )}

        {data?.check_list && data?.check_list?.pdf && (
          <Button
            variant={"outlined"}
            size={"small"}
            onClick={handleDownloadCheckListPDF}
            disabled={checkListPDFLoading}
            startIcon={
              <Download
                style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
              />
            }
          >
            Проверочный лист АПД
          </Button>
        )}
      </Stack>

      {data?.dbf_finished && data?.dbf_conclusion && (
        <Stack sx={{ mb: 1 }}>
          <CommentsConclusion
            title="Заключение ДБФ1:"
            type={data?.dbf_conclusion.type}
          />
        </Stack>
      )}

      {data && (
        <>
          {Boolean(data?.comments?.length) && (
            <>
              {(() => {
                const comment = data.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.executorRework,
                );

                if (!comment) return null;

                return (
                  <Comments
                    title={
                      comment.executor_type === EXECUTOR_TYPES.mainSpecialist
                        ? "Замечания и комментарии главного специалиста:"
                        : "Замечания и комментарии директора:"
                    }
                    remarkTypes={[]}
                    comment={comment.comment}
                  />
                );
              })()}

              {(() => {
                const comment = data.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.schoolRework,
                );

                if (!comment) return null;

                return (
                  <Comments
                    title="Замечания и комментарии специалиста:"
                    remarkTypes={comment.remark_type_ids?.map(
                      (remarkType: any) => remarkType.short_text,
                    )}
                    comment={comment.comment}
                  />
                );
              })()}

              {(() => {
                const comment = data.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.schoolDecline,
                );

                if (!comment) return null;

                return (
                  <Comments
                    title="Замечания и комментарии специалиста:"
                    remarkTypes={comment.remark_type_ids?.map(
                      (remarkType: any) => remarkType.short_text,
                    )}
                    comment={comment.comment}
                  />
                );
              })()}

              {(() => {
                const comment = data.comments.find(
                  (comment: any) =>
                    comment.type === COMMENTS_TYPES.schoolAprrove,
                );

                if (!comment) return null;

                return (
                  <Comments
                    title="Замечания и комментарии специалиста:"
                    remarkTypes={[]}
                    comment={comment.comment}
                  />
                );
              })()}
            </>
          )}
        </>
      )}

      <Stack direction={"column"} spacing={3} sx={{ marginTop: "32px" }}>
        <BoxCard title={"Сведения об организации"}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                name="region"
                value={data?.organization.organization_name}
                label="Наименование частной организации"
                placeholder="Наименование частной организации"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="city"
                value={data?.organization.official_number}
                label="БИН"
                placeholder="БИН"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="area_and_street"
                value={data?.organization.official_address}
                label="Юридический адрес"
                placeholder="Юридический адрес"
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="signatory_name"
                value={data?.signatory_name}
                label="ФИО подписанта (руководитель или представитель по доверенности)"
                placeholder="ФИО подписанта (руководитель или представитель по доверенности)"
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="signatory_name"
                value={data?.signatory_official_number}
                label="ИИН подписанта"
                placeholder="ИИН подписанта"
                disabled
              />
            </Grid>

            {data?.signatory_pdf && (
              <Grid item xs={12}>
                <FileDownloadField
                  fileName="Доверенность"
                  fileUrl={data?.signatory_pdf}
                  fileDownloadName="Доверенность"
                />
              </Grid>
            )}
          </Grid>
        </BoxCard>
        <BoxCard title={"Общие данные"}>
          <Grid container spacing={1} sx={{ marginBottom: "10px" }}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="elementary_education"
                    checked={Boolean(data?.elementary_education)}
                  />
                }
                label="Начального образования"
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="basic_secondary_education"
                    checked={Boolean(data?.basic_secondary_education)}
                  />
                }
                label="Основного среднего образования"
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="basic_general_education"
                    checked={Boolean(data?.basic_general_education)}
                  />
                }
                label="Общего среднего образования"
                disabled={true}
              />
            </Grid>
            <Grid item xs={9}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="integrated_education"
                    checked={Boolean(data?.integrated_education)}
                  />
                }
                label="По самостоятельно разработанным интегрированным образовательным программам, прошедшим авторизацию в Орг. Межд. Бак-та или межд. институциональную аккредитацию образования"
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                name="tuition_fee"
                value={data?.tuition_fee}
                label="Размер родительской платы за обучение, тенге в год"
                placeholder="Размер родительской платы за обучение, тенге в год"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="national_educational_db_registration"
                value={data?.national_educational_db_registration}
                label="Регистрация в Нац.образовательной базе данных (НОБД)"
                placeholder="Регистрация в Нац.образовательной базе данных (НОБД)"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="state_procurements_registration"
                value={data?.state_procurements_registration}
                label="Регистрация на веб-портале государственных закупок"
                placeholder="Регистрация на веб-портале государственных закупок"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="boarding_school"
                value={data?.boarding_school}
                label="Наличие интерната при школе"
                disabled
                placeholder="Наличие интерната при школе"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="student_contingent"
                value={data?.student_contingent}
                label="Фактический контингент учащихся"
                disabled
                placeholder="Фактический контингент учащихся"
              />
            </Grid>
            <Grid item xs={4} position={"relative"}>
              <Typography
                sx={{
                  position: "absolute",
                  top: "50%",
                  translateY: "-50%",
                }}
              >
                №
              </Typography>
              <TextField
                name="license_number"
                value={data?.license_number}
                label="Номер лицензии (кроме АОО)"
                disabled
                placeholder="Номер лицензии (кроме АОО)"
                sx={{ paddingLeft: "20px" }}
              />
            </Grid>
            <Grid item xs={4} position={"relative"}>
              <Typography
                sx={{
                  position: "absolute",
                  top: "50%",
                  translateY: "-50%",
                }}
              >
                ID
              </Typography>
              <TextField
                name="nobd_id"
                value={data?.nobd_id}
                label="Номер ID НОБД"
                disabled
                placeholder="Номер ID НОБД"
                sx={{ paddingLeft: "20px" }}
              />
            </Grid>
          </Grid>
        </BoxCard>

        <BoxCard title="Данные по учащимся">
          <Box
            sx={{ backgroundColor: "white", borderRadius: "10px" }}
            // p={3}
            // mt={2}
          >
            <Typography
              component="h3"
              fontSize={"14px"}
              fontWeight={500}
              mb={2}
            >
              По состоянию на дату подачи заявления:
            </Typography>
            {isBoardingSchool && <PupilsTable rows={studentsData} />}
          </Box>
        </BoxCard>

        <BoxCard
          sx={{ backgroundColor: "white", borderRadius: "10px" }}
          // p={3}
          // mt={2}
        >
          <Typography component="h3" fontSize={"14px"} fontWeight={500} mb={2}>
            Прогнозный контингент учащихся на 1 сентября предстоящего учебного
            года:
          </Typography>
          {isBoardingSchool && <PupilsTable rows={predictiveContingentData} />}
        </BoxCard>

        {isBoardingSchool === "Есть" && (
          <>
            <BoxCard title="Данные по учащимся интернат">
              <Box sx={{ backgroundColor: "white", borderRadius: "10px" }}>
                <Typography
                  component="h3"
                  fontSize={"14px"}
                  fontWeight={500}
                  mb={2}
                >
                  По состоянию на дату подачи заявления:
                </Typography>
                <TableBoardingData rows={boardingStudentsData} />
              </Box>
            </BoxCard>

            <BoxCard
              sx={{ backgroundColor: "white", borderRadius: "10px" }}
              // p={3}
              // mt={2}
            >
              <Typography
                component="h3"
                fontSize={"14px"}
                fontWeight={500}
                mb={2}
              >
                Прогнозный контингент учащихся на 1 сентября предстоящего
                учебного года:
              </Typography>
              <TableBoardingData rows={boardingPredictiveContingentData} />
            </BoxCard>
          </>
        )}

        <BoxCard title={"Характеристики объекта "}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                name="region"
                value={data?.region.name}
                label="Регион"
                placeholder="Регион"
                disabled
              />
            </Grid>
            {data?.area && (
              <Grid item xs={4}>
                <TextField
                  name="area"
                  value={data?.area.name}
                  label="Район"
                  placeholder="Район"
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <TextField
                name="city"
                value={data?.district.name}
                label="Округ"
                placeholder="Округ"
                disabled
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="city"
                value={data?.locality.name}
                label="Населенный пункт"
                placeholder="Населенный пункт"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="street"
                value={data?.street}
                label="Улица/Микрорайон/Жилой массив и т.п."
                placeholder="Улица/Микрорайон/Жилой массив и т.п."
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="house_number"
                value={data?.house_number}
                label="№ дома/здания"
                placeholder="№ дома/здания"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="school_name"
                value={data?.school_name}
                label="Наименование школы"
                disabled
                placeholder="Наименование школы"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="place_count"
                value={data?.place_count}
                label="Проектная мощность (ученических мест)"
                disabled
                placeholder="Проектная мощность (ученических мест)"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="exploitation_year"
                value={data?.exploitation_year}
                label="Дата ввода в эксплуатацию "
                // placeholder="Дата ввода в эксплуатацию "
                id="exploitation_year"
                type="date"
                disabled
                // defaultValue="2017-05-24"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="registered_immutability_agreement"
                    checked={Boolean(data?.registered_immutability_agreement)}
                  />
                }
                label="Наличие зарегистрированного Соглашения о неизменности целевого назначения объекта организации среднего образования (А2)"
                disabled={true}
              />
            </Grid>
          </Grid>
        </BoxCard>
        {new Date(BREAKPOINT_DATES.school) <
          new Date(data?.exploitation_year) && (
          <BoxCard
            title={
              "Заполняется частной организацией образования с вновь введенными ученическими местами в объекте частной организации образования, введенными в эксплуатацию после 20 июля 2018 года"
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  name="private_organization_place_count"
                  value={data?.private_organization_place_count}
                  label="Количество вновь введенных ученических мест"
                  placeholder="Количество вновь введенных ученических мест"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="private_organization_exploitation_type"
                  value={data?.private_organization_exploitation_type}
                  label="ЧОО введено в эксплуатацию путем "
                  placeholder="ЧОО введено в эксплуатацию путем "
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="school_name"
                  value={data?.school_name}
                  label="Наименование школы"
                  disabled
                  placeholder="Наименование школы"
                />
              </Grid>
            </Grid>
          </BoxCard>
        )}
        {new Date(BREAKPOINT_DATES.school) <
          new Date(data?.exploitation_year) &&
          data.private_organization_exploitation_type && (
            <BoxCard title={""}>
              <TableDeclarationMini
                isInputDisabled={true}
                rows={tableDeclarationsMini}
                setRows={setTableDeclarationsMini}
                isSchoolTable={true}
              />
            </BoxCard>
          )}
        {isBoardingSchool === "Есть" && (
          <>
            <BoxCard title={"Характеристики Интерната"}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_region"
                    value={data?.boarding_school_region?.name}
                    label="Регион"
                    placeholder="Регион"
                    disabled
                  />
                </Grid>
                {data?.boarding_school_area && (
                  <Grid item xs={4}>
                    <TextField
                      name="boarding_school_area"
                      value={data?.boarding_school_area?.name}
                      label="Район"
                      placeholder="Район"
                      disabled
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_city"
                    value={data?.boarding_school_district?.name}
                    label="Округ"
                    placeholder="Округ"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_city"
                    value={data?.boarding_school_locality?.name}
                    label="Населенный пункт"
                    placeholder="Населенный пункт"
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_street"
                    value={data?.boarding_school_street}
                    label="Улица/Микрорайон/Жилой массив и т.п."
                    placeholder="Улица/Микрорайон/Жилой массив и т.п."
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="house_number"
                    value={data?.boarding_school_house_number}
                    label="№ дома/здания"
                    placeholder="№ дома/здания"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_place_count"
                    value={data?.boarding_school_place_count}
                    label="Проектная мощность (ученических мест)"
                    disabled
                    placeholder="Проектная мощность (ученических мест)"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="exploitation_year"
                    value={data?.boarding_school_exploitation_year}
                    label="Дата ввода в эксплуатацию "
                    placeholder="Дата ввода в эксплуатацию "
                    id="boarding_school_exploitation_year"
                    type="date"
                    disabled
                    //defaultValue="2017-05-24"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="boarding_school_exploitation_type"
                    value={data?.boarding_school_exploitation_type}
                    label="Интернат введен в эксплуатацию путем"
                    placeholder="Интернат введен в эксплуатацию путем"
                    id="boarding_school_exploitation_type"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {new Date(data?.boarding_school_exploitation_year) >
                new Date(BREAKPOINT_DATES.boarding) ? (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="boarding_school_registered_immutability_agreement"
                          checked={Boolean(
                            data?.boarding_school_registered_immutability_agreement,
                          )}
                        />
                      }
                      label="Наличие зарегистрированного Соглашения о неизменности целевого назначения объекта организации среднего образования (А3)"
                      disabled={true}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </BoxCard>
            {
              // new Date(data?.boarding_school_exploitation_year) >
              // new Date(BREAKPOINT_DATES.boarding)
              data?.boarding_school_registered_immutability_agreement ? (
                // || data?.boarding_school === "Eсть"
                <BoxCard title={""}>
                  <TableDeclarationMini
                    isInputDisabled={true}
                    rows={tableDeclarationsMiniTwo}
                    setRows={setTableDeclarationsMiniTwo}
                    isSchoolTable={false}
                  />
                </BoxCard>
              ) : null
            }
          </>
        )}
        {
          // data?.education_licence !== "null" &&
          //   data?.legal_entity_certificate !== "null" &&
          //   data?.bank_certificate !== "null" &&
          <BoxCard title={"Документы"} sx={{ maxWidth: "100%" }}>
            <Grid item xs={4} sx={{ width: "400px" }}>
              <TextField
                name="private_organization_government_order"
                value={
                  data?.private_organization_government_order
                    ? "Размещался"
                    : "Не размещался"
                }
                label="Ранее государственный образовательный заказ:"
                disabled
                placeholder="Не размещался"
                sx={{
                  "& .MuiInputLabel-root": {
                    overflow: "auto",
                    whiteSpace: "normal",
                    textOverflow: "clip",
                  },
                }}
              />
            </Grid>
            {
              // data?.education_licence !== "null" &&
              data?.education_licence !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography sx={{ fontWeight: "500" }}>
                    Копия лицензии на занятие образовательной деятельностью по
                    общеобразовательным учебным программам начального, основного
                    среднего, общего среднего образования, выданной по форме,
                    утвержденной приказом Министра национальной экономики
                    Республики Казахстан от 6 января 2015 года № 3.
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Копия лицензии"
                    fileUrl={data?.education_licence}
                    fileDownloadName="Лицензия_образ_деятельность"
                  />
                </Box>
              )
            }
            {
              // data?.legal_entity_certificate !== "null" &&
              data?.legal_entity_certificate !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography sx={{ fontWeight: "500" }}>
                    Справка о государственной регистрации (перерегистрации)
                    юридического лица
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Справка о государственной регистрации"
                    fileUrl={data?.legal_entity_certificate}
                    fileDownloadName="Спр_гос_регистрации"
                  />
                </Box>
              )
            }
            {data?.anti_terrorism_measures &&
              data?.anti_terrorism_measures !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography
                    sx={{
                      fontWeight: "500",
                    }}
                  >
                    Документ, подтверждающий соблюдение требований
                    антитеррористической защиты в соответствии с Инструкцией
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Документ, подтверждающий соблюдение требований антитеррористической защиты в соответствии с Инструкцией"
                    fileUrl={data?.anti_terrorism_measures}
                    fileDownloadName="Документ_антитеррористической_защиты"
                  />
                </Box>
              )}
            {data?.bank_certificate !== "null" &&
              data?.bank_certificate !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography sx={{ fontWeight: "500" }}>
                    Справка о наличии счета в банке второго уровня Республики
                    Казахстан
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Справка о наличии счета"
                    fileUrl={data?.bank_certificate}
                    fileDownloadName="Справка_счет_в_банке"
                  />
                </Box>
              )}
            {((data?.preliminary_agreement &&
              data?.preliminary_agreement !== null) ||
              (data?.project_documentation &&
                data?.project_documentation !== null) ||
              (data?.exploitation_act && data?.exploitation_act !== null) ||
              (data?.condition_conclusion &&
                data?.condition_conclusion !== null) ||
              (data?.real_estate_certificate &&
                data?.real_estate_certificate !== null) ||
              (data?.cadastral_information &&
                data?.cadastral_information !== null)) && (
              <>
                <StyledTypography sx={{ fontWeight: "500" }}>
                  Для частных организаций образования с вновь введенными
                  ученическими местами и (или) местами для проживания в объектах
                  частных организаций среднего образования, предусмотренными
                  Методикой. При этом предельный срок эксплуатации здания с
                  вновь введенными путем реконструкции ученическими местами в
                  объектах частных организаций среднего образования,
                  предусмотренными Методикой, составляет 30 (тридцать) лет на
                  дату первоначальной подачи заявления на данный объект:
                </StyledTypography>
                {data?.preliminary_agreement &&
                  // data?.preliminary_agreement !== "null" &&
                  data?.preliminary_agreement !== null && (
                    <Box sx={{ marginBottom: "10px" }}>
                      <StyledTypography>
                        Копия действующего предварительного договора,
                        заключенного между оператором и частной организацией
                        образования по утвержденной оператором форме
                      </StyledTypography>
                      <FileDownloadField
                        fileName="Пред договор"
                        fileUrl={data?.preliminary_agreement}
                        fileDownloadName="Пред_договор"
                      />
                      {data?.immutability_agreement && (
                        <FileDownloadField
                          fileName="Соглашение о неизменности"
                          fileUrl={data?.immutability_agreement}
                          fileDownloadName="Соглашение о неизменности"
                        />
                      )}
                    </Box>
                  )}
              </>
            )}
            {
              // data?.project_documentation !== "null" &&
              data?.project_documentation !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography>
                    Проектная (проектно-сметная) документация на объект частной
                    организации образования, разработанная и утвержденная в
                    соответствии со статьей 60 Закона об архитектурной
                    деятельности, либо судебный акт о праве собственности на
                    объект
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Проектная (проектно-сметная) документация"
                    fileUrl={data?.project_documentation}
                    fileDownloadName="ПСД"
                  />
                </Box>
              )
            }
            {
              // data?.exploitation_act !== "null" &&
              data?.exploitation_act !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography>
                    Акт приемки построенного объекта в эксплуатацию,
                    подтверждающий дату ввода объекта в эксплуатацию в
                    соответствии с пунктом 4 статьи 73 Закона об архитектурной
                    деятельности
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Акт приемки"
                    fileUrl={data?.exploitation_act}
                    fileDownloadName="Акт_приемки_в_эксплуатацию"
                  />
                </Box>
              )
            }
            {
              // data?.condition_conclusion !== "null" &&
              data?.condition_conclusion !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography>
                    Заключение о состоянии подлежащего реконструкции здания в
                    соответствии с пунктом 4 статьей 34-4 Закона об
                    архитектурной деятельности, при реконструкции
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Заключение о состоянии"
                    fileUrl={data?.condition_conclusion}
                    fileDownloadName="Закл_о_состоянии_подлеж_реконстр_здания"
                  />
                </Box>
              )
            }
            {
              // data?.real_estate_certificate !== "null" &&
              data?.real_estate_certificate !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography>
                    Сведения о зарегистрированных правах (обременениях) на
                    недвижимое имущество и его технических характеристиках с
                    зарегистрированным обременением права на изменение целевого
                    назначения объекта частной организации среднего образования,
                    указанного в заявлении и сведениях об интернатной
                    организации (при наличии), на срок не менее двадцати лет
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Сведения о зарегистрированных правах"
                    fileUrl={data?.real_estate_certificate}
                    fileDownloadName="Сведения_об_обременении"
                  />
                </Box>
              )
            }
            {
              // data?.cadastral_information !== "null" &&
              data?.cadastral_information !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography>
                    Сведения из правового кадастра о наличии либо отсутствии
                    зарегистрированных прав на объект частной организации
                    образования, указанный в заявлении и (или) сведениях об
                    интернатной организации (при наличии), в том числе о виде
                    права, правообладателе, основании возникновения права, а
                    также об основании прекращения права (при наличии)
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Справка Сведения из правового кадастра"
                    fileUrl={data?.cadastral_information}
                    fileDownloadName="Сведения_о_зарегистрированных_правах"
                  />
                </Box>
              )
            }
            {
              // data?.tuition_fee_statement !== "null" &&
              data?.tuition_fee_statement !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography sx={{ fontWeight: "500" }}>
                    Копия приказа руководителя частной организации образования
                    или лица исполняющего его обязанности об утверждении размера
                    родительской платы за обучение в частной организации
                    образования (при наличии родительской оплаты)
                  </StyledTypography>
                  <FileDownloadField
                    fileName="Копия приказа руководителя"
                    fileUrl={data?.tuition_fee_statement}
                    fileDownloadName="Приказ_о_род_оплате"
                  />
                </Box>
              )
            }
            {
              // data?.integrated_education_realization_documents !== "null" &&
              data?.integrated_education_realization_documents !== null && (
                <Box sx={{ marginBottom: "10px" }}>
                  <StyledTypography sx={{ fontWeight: "500" }}>
                    Документы, подтверждающие реализацию самостоятельно
                    разработанных интегрированных образовательных программ,
                    прохождение авторизации в Организации Международного
                    Бакалавриата или международную институциональную
                    аккредитацию - для частных организаций образования,
                    реализующих самостоятельно разработанные интегрированные
                    образовательные программы, прошедших авторизацию в
                    Организации Международного Бакалавриата или международную
                    институциональную аккредитацию
                  </StyledTypography>
                  {data?.integrated_education_realization_documents &&
                    JSON.parse(
                      data?.integrated_education_realization_documents,
                    ).map((el: string) => (
                      <FileDownloadField
                        fileName="Документы, подтверждающие"
                        fileUrl={el}
                        fileDownloadName="Сведения_самостоят_разраб_ОП"
                      />
                    ))}
                </Box>
              )
            }
          </BoxCard>
        }
      </Stack>
    </>
  );
}
