import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { Document } from "react-pdf";
import { Page } from "react-pdf";

interface ConfirmationModal {
  open: boolean;
  onClose: () => void;
  pdf: any;
}

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function SuccsessModal({
  open,
  onClose,
  pdf,
}: ConfirmationModal) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Заявка успешно сформирована и направлена исполнителю
        </Typography>
        {/*<Document file={pdf}>*/}
        {/*  <Page pageNumber={1} />*/}
        {/*</Document>*/}
      </Box>
    </Modal>
  );
}
