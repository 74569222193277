import Cookies from "js-cookie";
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import AdminLayout from "../layouts/AdminLayout";
import IdleLayout from "../layouts/IdleLayout";
import InvestorLayout from "../layouts/InvestorLayout";
import SchoolLayout from "../layouts/SchoolLayout";
import StudentLayout from "../layouts/StudentLayout";
import AuthPage from "../pages/Auth/AuthPage";
import Declarations from "../pages/Declarations/Declarations";
import DeclarationsData from "../pages/DeclarationsData/DeclarationsData";
import DeclarationsDataDBF from "../pages/DeclarationsData/DeclarationsDataDBF";
import IncomingMails from "../pages/IncomingMails/Mails";
import MainSpecialistDeclarations from "../pages/MainSpecialistsDeclarations/MainSpecialistDeclarations";
import MainSpecDeclarationsDataDBF from "../pages/MainSpecialistsDeclarationsData/MainSpecDeclarationsDataDBF";
import MainSpecialistsDeclarationsData from "../pages/MainSpecialistsDeclarationsData/MainSpecialistsDeclarationsData";
import Notifications from "../pages/Notifications/Notifications";
import OutgoingMails from "../pages/OutgoingMails/Mails";
import SchoolProfile from "../pages/Profile/SchoolProfile";
import SchoolDeclarationCreate from "../pages/SchoolDeclarationCreate/SchoolDeclarationCreate";
import SchoolDeclarations from "../pages/SchoolDeclarations/SchoolDeclarations";
import SchoolDeclarationsOne from "../pages/SchoolDeclarationsOne/SchoolDeclarationsOne";
import SchoolList from "../pages/SchoolList/SchoolList";
import SpecialistDeclaration from "../pages/SpecialistDeclarations/SpecialistDeclarations";
import SpecialistDeclarationData from "../pages/SpecialistDeclarationsData/SpecialistDeclarationsData";
import SpecialistDeclarationDataDBF from "../pages/SpecialistDeclarationsData/SpecialistDeclarationsDataDBF";
import StudentProfile from "../pages/StudentProfile/StudentProfile";
import StudentRequests from "../pages/StudentRequests/StudentRequests";
import { ROLES } from "../utils/constants";
import { redirectByRole } from "../utils/helpers";
import DeclarationsEnumeration from "../pages/DeclarationsEnumeration";

const PrivateRoute = ({
  access,
  navigateTo,
}: {
  access: boolean;
  navigateTo: string;
}) => {
  return <>{access ? <Outlet /> : <Navigate to={navigateTo} />}</>;
};
const Routers: React.FC = () => {
  const role = Number(Cookies.get("role"));
  const isAuthorized = !!Cookies.get("token");

  const isDirector =
    isAuthorized && [ROLES.directorApd.id, ROLES.directorDbf.id].includes(role);
  const isMainSpecialist =
    isAuthorized &&
    [ROLES.mainSpecialistApd.id, ROLES.mainSpecialistDbf.id].includes(role);
  const isSchool = isAuthorized && role === ROLES.school.id;
  const isSpecialist =
    isAuthorized &&
    [ROLES.specialistApd.id, ROLES.specialistDbf.id].includes(role);
  const isInvestor = isAuthorized && role === ROLES.investor.id;
  const isStudent = isAuthorized && role === ROLES.student.id;
  const getLayoutComponent = () => {
    if (isSchool) return <SchoolLayout />;
    if (isDirector || isSpecialist || isMainSpecialist) return <AdminLayout />;
    if (isInvestor) return <InvestorLayout />;
    if (isStudent) return <StudentLayout />;
    return null;
  };
  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthorized ? getLayoutComponent() : <Navigate to="/auth/sign-in" />
        }
      />

      <Route path="/auth/*" element={<IdleLayout />}>
        <Route index element={<AuthPage state={undefined} />} />
        <Route path="sign-in" element={<AuthPage state="" />} />
        <Route path="sign-up" element={<AuthPage state="register" />} />
        <Route
          path="restore-password"
          element={<AuthPage state="passrecovery" />}
        />
        <Route path="*" element={<Navigate to="sign-in" replace />} />
      </Route>

      <Route
        element={
          <PrivateRoute
            access={role === ROLES.school.id}
            navigateTo={redirectByRole(role)}
          />
        }
      >
        <Route path="/school/*" element={<SchoolLayout />}>
          <Route path="profile" element={<SchoolProfile />} />
          <Route path="schools" element={<SchoolList />} />
          <Route path="declarations/:id" element={<SchoolDeclarationsOne />} />
          <Route path="declarations" element={<SchoolDeclarations />} />
          <Route
            path="declarations/create"
            element={<SchoolDeclarationCreate />}
          />
          <Route path="notifications" element={<Notifications />} />
          <Route path="financing" element={<SchoolProfile />} />
          <Route path="*" element={<Navigate to="declarations" />} />
        </Route>
      </Route>

      <Route
        element={
          <PrivateRoute
            access={role === ROLES.student.id}
            navigateTo={redirectByRole(role)}
          />
        }
      >
        <Route path="/student/*" element={<StudentLayout />}>
          <Route path="profile" element={<StudentProfile />} />
          <Route path="requests" element={<StudentRequests />} />
        </Route>
      </Route>

      <Route
        element={
          <PrivateRoute
            access={role === ROLES.directorDbf.id || role === 3}
            navigateTo={redirectByRole(role)}
          />
        }
      >
        <Route path="/director/*" element={<AdminLayout />}>
          <Route path="declarations" element={<Declarations />} />
          {role === 3 && (
            <Route path="declarations/:id" element={<DeclarationsData />} />
          )}
          {role === ROLES.directorDbf.id && (
            <Route path="declarations/:id" element={<DeclarationsDataDBF />} />
          )}

          <Route path="incoming-mails" element={<IncomingMails />} />
          <Route path="outgoing-mails" element={<OutgoingMails />} />
          <Route
            path="declarations/enumeration"
            element={<DeclarationsEnumeration />}
          />
          <Route path="*" element={<Navigate to="declarations" replace />} />
        </Route>
      </Route>

      <Route
        element={
          <PrivateRoute
            access={role === 4 || role === ROLES.specialistDbf.id}
            navigateTo={redirectByRole(role)}
          />
        }
      >
        <Route path="specialist/*" element={<AdminLayout />}>
          <Route path="declarations" element={<SpecialistDeclaration />} />
          {role === 4 && (
            <Route
              path="declarations/:id"
              element={<SpecialistDeclarationData />}
            />
          )}
          {role === ROLES.specialistDbf.id && (
            <Route
              path="declarations/:id"
              element={<SpecialistDeclarationDataDBF />}
            />
          )}

          <Route path="incoming-mails" element={<IncomingMails />} />
          <Route path="outgoing-mails" element={<OutgoingMails />} />
          <Route
            path="declarations/enumeration"
            element={<DeclarationsEnumeration />}
          />
          <Route path="*" element={<Navigate to="declarations" replace />} />
        </Route>
      </Route>

      <Route
        element={
          <PrivateRoute
            access={role === 5 || role === ROLES.mainSpecialistDbf.id}
            navigateTo={redirectByRole(role)}
          />
        }
      >
        <Route path="main-specialist/*" element={<AdminLayout />}>
          <Route path="declarations" element={<MainSpecialistDeclarations />} />
          {role === 5 && (
            <Route
              path="declarations/:id"
              element={<MainSpecialistsDeclarationsData />}
            />
          )}
          {role === ROLES.mainSpecialistDbf.id && (
            <Route
              path="declarations/:id"
              element={<MainSpecDeclarationsDataDBF />}
            />
          )}

          <Route path="incoming-mails" element={<IncomingMails />} />
          <Route path="outgoing-mails" element={<OutgoingMails />} />
          <Route
            path="declarations/enumeration"
            element={<DeclarationsEnumeration />}
          />
          <Route path="*" element={<Navigate to="declarations" replace />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Routers;
