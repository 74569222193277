export const numbersDeclension = (number: number, words: string[]) =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];

// export const CADASTRAL_NUMBER_REG_EXP =
//   /^\d{2}:\d{3}:\d{3}:\d{3}:[\dA-Za-z\u0400-\u04FF]+(\/[\dA-Za-z\u0400-\u04FF]*)?$/;
export const CADASTRAL_NUMBER_REG_EXP = /.*/;

export const form_msg = {
  REQUIRED: "Пожалуйста, заполните это поле",
  EMAIL: "Пожалуйста, введите корректный e-mail",
  PHONE: "Пожалуйста, введите корректный номер телефона",
  MIN_LENGTH: (length: number) =>
    `Минимальная длина - ${length} ${numbersDeclension(length, [
      "символ",
      "символа",
      "символов",
    ])}`,
  PASSWORD_MISMATCH: "Пароли не совпадают",
  PASSWORD_REG_EXP:
    /^(?=.*[A-ZА-Я])(?=.*[a-zа-я])(?=.*\d)(?=.*[№%:?*@#$%^&+=`~!"'.,/;^{}]).*$/,
  SELECT_FROM_LIST: "Пожалуйста, выберите из списка",
  SELECT_MIN_FROM_LIST: (
    count: number = 1,
    words: string[] = ["элемент", "элемента", "элементов"],
  ) =>
    `Пожалуйста, выберите из списка не менее ${count} ${numbersDeclension(
      count,
      words,
    )}`,
  SELECT_DATE: "Пожалуйста, выберите дату",
  SELECT_YEAR: "Пожалуйста, выберите год",
  LARGE_FILE: "Вес файла не должен превышать 30мб",
  UNSUPPORTED_FILE_TYPE: "Недопустимый формат файла",
  SELECT_AVATAR: "Пожалуйста, выберите аватар",
  SELECT_FILE: "Пожалуйста, выберите файл",
  INVALID_IP: "Пожалуйста, введите корректный IP-адрес",
  INCORRECT_IP: "IP-адрес должен быть меньше или равен 255",
  LARGE_TEXT: (length: number = 255) =>
    `Максимальная длина текста - ${length} ${numbersDeclension(length, [
      "символ",
      "символа",
      "символов",
    ])}`,
};

export const ROLES = {
  school: {
    id: 1,
    path: "/school",
  },
  investor: {
    id: 9,
    path: "/investor",
  },
  student: {
    id: 10,
    path: "/student",
  },
  directorApd: {
    id: 3,
    path: "/director",
  },
  specialistApd: {
    id: 4,
    path: "/specialist",
  },
  mainSpecialistApd: {
    id: 5,
    path: "/main-specialist",
  },
  directorDbf: {
    id: 6,
    path: "/director",
  },
  mainSpecialistDbf: {
    id: 8,
    path: "/main-specialist",
  },
  specialistDbf: {
    id: 7,
    path: "/specialist",
  },
};

export const DEPARTMENT_DIRECTOR_CODE = {
  apd: "apd_director",
  dbf: "dbf_director",
};

export const COMMENTS_TYPES = {
  schoolRework: "school_rework",
  executorRework: "executor_rework",
  schoolDecline: "declined",
  schoolAprrove: "accepted",
};

export const EXECUTOR_TYPES = {
  mainSpecialist: "main_specialist",
  specialist: "specialist",
  director: "director",
};

export const BREAKPOINT_DATES = {
  school: "2018-07-20",
  boarding: "2020-01-01",
};

export const STUDENT_REQUEST_TYPES = {
  loanDebt: "loan_debt",
  workOff: "work_off",
  debtStatus: "debt_status",
  creditStatus: "credit_status",
};

export const STATUS_ID = {
  new: 1,
  onApproval: 2,
  onExecution: 3,
  onSchoolRework: 4,
  onExecutorRework: 5,
  approved: 6,
  rejected: 7,
  returned: 9,
};
