import { CheckCircle, Create } from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { fincolors } from "../styles/theme";

interface CommentsProps {
  title: string;
  remarkTypes?: Array<string> | null;
  comment?: string;
  isMainSpecialist?: boolean;
  typeConclusion?: number;
}

const Comments: React.FC<CommentsProps> = ({
  title,
  remarkTypes,
  typeConclusion,
  comment,
  isMainSpecialist,
}) => {
  return (
    <Box
      sx={{
        width: "auto",
        minHeight: "50px",
        maxWidth: "912px",
        // borderRadius: "10px",
        // padding: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          //  mb: 2,
          p: 2,
          bgcolor: isMainSpecialist
            ? "rgba(96, 73, 121, 0.25)"
            : "rgba(150, 54, 53, 0.25)",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <Create
          width={3}
          // color={isMainSpecialist ? "error" : "#4d0064"}
          sx={{ mr: 1, color: isMainSpecialist ? "#604979" : "#963635" }}
        />
        <Typography fontWeight={500}>{title}</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: fincolors.primary.contrastText,
          p: 2,
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderColor: fincolors.other.tableBorderColor,
          borderWidth: "1px",
          borderTopWidth: 0,
          borderStyle: "solid",
        }}
      >
        {remarkTypes?.length ? (
          <>
            <Typography
              sx={{
                color: fincolors.other.tableBorderColor,
              }}
            >
              Замечания:
            </Typography>
            <Box
              sx={{
                borderColor: fincolors.other.tableBorderColor,
                borderRadius: "10px",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              {remarkTypes &&
                remarkTypes.map((type, index) => (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        p: 2,
                      }}
                    >
                      <Typography
                        key={index}
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        {type}
                      </Typography>
                      <CheckCircle
                        // color={isMainSpecialist ? "error" : "primary"}
                        sx={{
                          mr: 1,
                          color: isMainSpecialist ? "#4d0064" : "#800000",
                        }}
                      />
                    </Stack>
                    {index !== remarkTypes.length - 1 && (
                      <Divider
                        sx={{ borderColor: fincolors.other.tableBorderColor }}
                      />
                    )}
                  </>
                ))}
            </Box>
          </>
        ) : null}

        {comment && (
          <>
            <Typography
              sx={{
                mt: remarkTypes?.length ? 2 : "",
                color: fincolors.other.tableBorderColor,
              }}
            >
              Комментарий:
            </Typography>
            <Typography>{comment}</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Comments;
