import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { createApplicationEnumeration } from "../api";

export interface CreateEnumerationDialogProps
  extends Omit<DialogProps, "onClose" | "open" | "children"> {
  children(
    onOpen: () => void,
    mutationResult: UseCreateEnumerationResult,
  ): React.ReactNode;
  onCreate?(): void;
}

const CreateEnumerationDialog = ({
  onCreate,
  children,
  ...props
}: CreateEnumerationDialogProps) => {
  const [opened, setOpened] = useState(false);

  const createEnumerationMutation = useCreateEnumeration(() =>
    setOpened(false),
  );

  return (
    <>
      {children(() => setOpened(true), createEnumerationMutation)}

      <Dialog
        {...props}
        open={opened}
        onClose={() => setOpened(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle sx={{ bgcolor: "#2C53F5" }}>
          <b>Сформировать перечень</b>
        </DialogTitle>

        <Formik
          initialValues={{
            list_date: dayjs(),
          }}
          validationSchema={yup.object().shape({
            list_date: yup.date().required("Обязательное поле"),
          })}
          onSubmit={({ list_date }) =>
            createEnumerationMutation.mutate({
              list_date: dayjs(list_date).format("YYYY-MM-DD"),
            })
          }
        >
          {({ values, touched, errors, setFieldValue }) => (
            <Form>
              <DialogContent>
                <DatePicker
                  label="Дата перечня"
                  value={values.list_date}
                  format="DD.MM.YYYY"
                  onChange={(date) => setFieldValue("list_date", date)}
                  disableFuture
                  disabled={createEnumerationMutation.isLoading}
                  slotProps={{
                    textField: {
                      error: touched.list_date && Boolean(errors.list_date),
                      helperText: touched.list_date
                        ? errors.list_date && String(errors.list_date)
                        : "",
                      fullWidth: true,
                    },
                  }}
                />
              </DialogContent>

              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={createEnumerationMutation.isLoading}
                  onClick={onCreate}
                  fullWidth
                >
                  Сформировать
                </Button>

                <Button
                  variant="outlined"
                  disabled={createEnumerationMutation.isLoading}
                  onClick={() => setOpened(false)}
                  fullWidth
                >
                  Отмена
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

type UseCreateEnumerationResult = ReturnType<typeof useCreateEnumeration>;
function useCreateEnumeration(onCreate: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["createEnumeration"],
    mutationFn: createApplicationEnumeration,
    onSuccess: async () => {
      toast.success("Перечень успешно сформирован");
      await queryClient.invalidateQueries(["declarations-enumeration"]);

      onCreate?.();
    },
    onError: () => {
      toast.error("Не удалось сформировать перечень");
    },
  });
}

export default CreateEnumerationDialog;
