import { Stack, TextField } from "@mui/material";
import BoxCard from "../../../components/BoxCard";
import { ChangeEvent } from "react";

interface School {
  school_name: string;
  actual_address: string;
}


interface AddSchoolProp {
    values: {
        school_name: string;
        actual_address: string;
    };
    touched: {
        school_name?: boolean;
        actual_address?: boolean;
    };
    errors: {
        school_name?: string;
        actual_address?: string;
    };
    // editMode: number;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    resetForm: () => void;

}

const AddSchoolBlock: React.FC<AddSchoolProp> = ({
    values,
    touched,
    errors,
    handleChange,

}) => {

  return (
    <BoxCard title={"Новая школа"}>
      <Stack direction={"row"} gap={2}>
        <TextField
          name={`school_name`}
          value={values.school_name}
          label="Название школы"
          placeholder="Введите название"
          error={Boolean(touched?.school_name && errors.school_name) || false}
          helperText={(touched?.school_name && errors.school_name) || false}
          onChange={handleChange}
          //   disabled={editMode === 0}
        />

        <TextField
          name={`actual_address`}
          value={values.actual_address}
          label="Фактический адрес"
          placeholder="Введите адрес"
          error={Boolean(touched?.actual_address && errors.actual_address) || false}
          helperText={(touched?.actual_address&& errors.actual_address) || false}
          onChange={handleChange}
          //   disabled={editMode === 0}
        />
      </Stack>
    </BoxCard>
  );
};

export default AddSchoolBlock;
