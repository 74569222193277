interface SchoolTableRows {
  id: number;
  classes: string;
  all_pupils: number;
  common_pupils: number;
  special_pupils: number;
  correction_pupils: number;
  home_pupils: number;
}

export const SchoolTableRows: SchoolTableRows[] = [
  {
    id: 1,
    classes: "1-4",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 2,
    classes: "5-9",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 3,
    classes: "10-11",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },

  {
    id: 5,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 6,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 7,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
];

export const SchoolTableRowsTwo: SchoolTableRows[] = [
  {
    id: 1,
    classes: "1-4",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 2,
    classes: "5-9",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 3,
    classes: "10-11",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 5,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 6,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 7,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
];
