import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Modal,
  Stack,
} from "@mui/material";
import { useState, useEffect } from "react";
import { CheckListProps, CheckListInterface } from "../../../types/modalTypes";
import { getSchoolDeclarationById } from "../../../services/useEndpoint";
import * as React from "react";

const style = {
  position: "absolute",
  zIndex: 555,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "auto",
};

export default function CheckListModal({
  open,
  onClose,
  submit,
  id,
}: CheckListProps) {
  const [data, setData] = useState<any>();
  const [checkListState, setCheckListState] = useState<CheckListInterface>({
    form_correspondence: false,
    document_completeness: false,
    license_check: false,
    name_bin_correspondence: false,
    gz_members_registration: false,
    government_educational_order: false,
    preliminary_agreement: false,
    immutability_agreement: false,
  });

  useEffect(() => {
    if (open) {
      getSchoolDeclarationById(id).then((data) => {
        setData(data?.data);
        setCheckListState(data?.data?.check_list);
      });
    }
  }, [open, id]);

  const handleSubmit = () => {
    submit(Number(id), []);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center", marginBottom: "32px" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Проверочный лист
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkListState?.form_correspondence}
                disabled
              />
            }
            label="Соответствие заявления форме, согласно приложению 3 и (или) 3-1 (при наличии) к Правилам"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkListState?.document_completeness}
                disabled
              />
            }
            label="Полнота представленных документов согласно пунктам 56 и 56-1 Правил"
          />
          <FormControlLabel
            control={
              <Checkbox checked={checkListState?.license_check} disabled />
            }
            label="Проверка лицензии частной организации образования на занятие образовательной деятельностью на сайте www.elicense.kz"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkListState?.name_bin_correspondence}
                disabled
              />
            }
            label="Соответствие наименования частной организации образования и БИН на сайте www.kgd.gov.kz и справке о государственной регистрации (перерегистрации) юридического лица (при наличии)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkListState?.gz_members_registration}
                disabled
              />
            }
            label="Проверка регистрации частной организации образования на веб-портале в Реестре участников ГЗ"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkListState?.government_educational_order}
                disabled
              />
            }
            label="Установление факта получения частной организацией образования государственного образовательного заказа на среднее образование ранее в Реестре договоров веб-портала и в предыдущих Перечнях по объекту, указанному в заявлении"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkListState?.preliminary_agreement}
                disabled
              />
            }
            label="Действие предварительного договора на дату подачи заявления частной организации образования"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkListState?.immutability_agreement}
                disabled
              />
            }
            label="Соглашения о неизменности целевого назначения с отметкой о произведенной регистрации регистрирующим органом в правовом кадастре обременения права на изменение целевого назначения объекта организации образования"
          />
        </FormGroup>

        <Stack
          direction="row"
          sx={{ justifyContent: "flex-end", gap: 1, mt: 2 }}
        >
          <Button onClick={onClose} color="error" variant="outlined">
            Отмена
          </Button>

          <Button onClick={handleSubmit} variant="contained">
            Подписать
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
