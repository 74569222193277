import styled from "@emotion/styled";
import {
  DeleteOutlineOutlined,
  DownloadOutlined,
  DriveFileRenameOutlineOutlined,
} from "@mui/icons-material";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import NoRowsOverlay from "../../../components/NoRowsOverlay";
import Pagination from "../../../components/Pagination/Pagination";
import { getSignersDocument } from "../../../services/useEndpoint";
import { downloadFile } from "../../../utils/helpers";
import { applicationEnumerationsMap } from "../constants";
import { EApplicationEnumerationStatus } from "../enums";
import { getStatusColor, getStatusIcon } from "../helpers";
import { IApplicationEnumeration } from "../types";
import DeleteEnumerationAction from "./DeleteEnumeration";
import SignEnumeration from "./SignEnumeration";

interface ITable {
  page: number;
  perPage: number;
  rows: IApplicationEnumeration[];
  rowCount: number;
  onChangePage(page: number): void;
  onChangePerPage(perPage: number): void;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  height: "auto",
  border: "2px solid #DFE2EC",
  borderRadius: "8px",
  overflow: "hidden",
  "& .MuiDataGrid-root": {
    borderRadius: "8px",
    overflow: "hidden",
    height: "auto",
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    backgroundColor: "#FFF",
  },
  "& .MuiDataGrid-overlay": {
    display: "none",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "14px",
    padding: "10px",
    height: "auto",
    overflow: "auto",
    textOverflow: "clip",
  },
  "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
    whiteSpace: "normal",
    height: "auto",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-row:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-colCellTitle": {
    color: "#111520",
    fontWeight: "medium",
    backgroundColor: "#DFE2EC",
    height: "40px",
  },

  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    overflow: "auto",
    lineHeight: "120%",
    whiteSpace: "normal",
    height: "100%",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },
}));

const ApplicationsEnumerationTable: React.FC<ITable> = ({
  page,
  perPage,
  rows,
  rowCount,
  onChangePage,
  onChangePerPage,
}) => {
  const donwloadDocumentMutation = useMutation({
    mutationKey: ["getSignersDocument"],
    mutationFn: getSignersDocument,
    onSuccess: (data) => {
      downloadFile(data, "Исходящее письмо.pdf", "application/pdf");
    },
    onError: () => {
      toast.error("Не удалось скачать документ");
    },
  });

  return (
    <>
      <StyledDataGrid
        rows={rows}
        columns={[
          {
            field: "name",
            headerName: "Перечень",
            headerClassName: "super-app-theme--header",
            width: 500,
            editable: false,
            renderCell: (params) => params.row.name,
          },
          {
            field: "status_id",
            headerName: "Статус",
            headerClassName: "super-app-theme--header",
            width: 180,
            editable: false,
            renderCell: (params) => {
              const status = applicationEnumerationsMap.get(
                params.row.status_id,
              );
              const Icon = getStatusIcon(
                params.row.status_id as EApplicationEnumerationStatus,
              );

              if (!status) return "—";

              return (
                <Chip
                  sx={{
                    bgcolor: getStatusColor(
                      params.row.status_id as EApplicationEnumerationStatus,
                    ),
                  }}
                  icon={<Icon />}
                  label={status}
                />
              );
            },
          },
          {
            field: "list_date",
            headerName: "Дата перечня",
            headerClassName: "super-app-theme--header",
            width: 120,
            editable: false,
            renderCell: (params) => params.row.list_date,
          },
          {
            field: "created_at",
            headerName: "Дата формирования",
            headerClassName: "super-app-theme--header",
            width: 120,
            editable: false,
            renderCell: (params) =>
              dayjs(params.row.created_at).format("DD.MM.YYYY"),
          },
          {
            field: "actions",
            headerName: "Действия",
            headerClassName: "super-app-theme--header",
            width: 160,
            editable: false,
            renderCell: (params) => {
              return (
                <Stack direction="row" gap={1}>
                  {Boolean(params.row.link) && (
                    <>
                      <Tooltip title="Скачать документ">
                        <IconButton
                          aria-label="Скачать документ"
                          size="small"
                          disabled={donwloadDocumentMutation.isLoading}
                          onClick={() =>
                            donwloadDocumentMutation.mutate({
                              file_path: params.row.link,
                            })
                          }
                        >
                          <DownloadOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <SignEnumeration
                        enumerationId={params.row.id}
                        filePath={params.row.link}
                      >
                        {(signEnumerationMutation) => (
                          <Tooltip title="Подписать">
                            <IconButton
                              aria-label="Подписать"
                              size="small"
                              onClick={() => signEnumerationMutation.mutate()}
                              disabled={signEnumerationMutation.isLoading}
                            >
                              <DriveFileRenameOutlineOutlined fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </SignEnumeration>
                    </>
                  )}

                  <DeleteEnumerationAction enumerationId={params.row.id}>
                    {(onOpen) => (
                      <Tooltip title="Удалить">
                        <IconButton
                          aria-label="Удалить"
                          size="small"
                          onClick={onOpen}
                        >
                          <DeleteOutlineOutlined
                            fontSize="small"
                            color="error"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </DeleteEnumerationAction>
                </Stack>
              );
            },
          },
        ]}
        disableColumnMenu
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
        pageSizeOptions={[20, 10]}
        slots={{ noRowsOverlay: NoRowsOverlay }}
      />

      <Pagination
        rowCount={rowCount}
        rowsPerPage={perPage}
        currentPage={page}
        onPageChange={(event, page) => onChangePage(page + 1)}
        onRowsPerPageChange={(event) =>
          onChangePerPage(Number(event.target.value))
        }
      />
    </>
  );
};

export default ApplicationsEnumerationTable;
