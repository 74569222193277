import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridRowId,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { Circle, Close, Create, Height } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  createSvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  selectCurrentPage,
  setCurrentPage,
  setPerPage,
} from "../store/reducers/currentPageSlice";
import Pagination from "./Pagination/Pagination";
import { string } from "yup";
import { fincolors } from "../styles/theme";
import TableDateTimeNotifications from "./TableDateTimeNotifications";
import NoRowsOverlay from "./NoRowsOverlay";
import { getNotificationsOne } from "../services/useEndpoint";
import ModalNotificationsById from "./Modals/ModalNotificationsById/ModalNotificationsById";
export interface RowData {
  id: number;
  checkbox: boolean;
  application_id: number;
  short_text: string;
  created_at: string;
  full_text: string;
}
const columns: GridColDef[] = [
  {
    field: "application_id",
    headerClassName: "super-app-theme--header",
    headerName: "ID заявки",
    width: 100,
    editable: false,
  },
  {
    field: "short_text",
    headerName: "Тема",
    headerClassName: "super-app-theme--header",
    width: 650,
    editable: true,
    renderCell: (params) => (
      <div style={{ fontWeight: params.row.seen ? "bold" : "normal" }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "created_at",
    headerName: "Дата",
    headerClassName: "super-app-theme--header",
    width: 180,
    editable: false,
    renderCell: (params) => (
      <TableDateTimeNotifications dateTimeString={params.row.created_at} />
    ),
  },
];

interface StyledDataGridProps {
  rows: RowData[];
  rowCount: number;
  selectedRows?: GridRowId[];
  isBoxShow?: boolean;
  isChecked?: boolean;
  onCheckboxChange?: () => void;
  handleCellClick?: (params: any) => void;
  handleBoxAreaClose?: () => void;
  handleSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: any,
  ) => void;
  handleClickSelectExecutors?: () => void;
}

const NotificationsDataGrid: React.FC<StyledDataGridProps> = (props) => {
  const [selectedRow, setSelectedRow] = useState<RowData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCellClickId = async (params: any) => {
    const { field, row } = params;
    if (field === "application_id" || field === "short_text") {
      try {
        setSelectedRow(row);
        setIsModalOpen(true);
      } catch (error) {
        console.error("Error handling cell click:", error);
      }
    }
  };

  const {
    rows,
    selectedRows,
    isChecked,
    onCheckboxChange,
    handleSelectionModelChange,
    handleCellClick,
    isBoxShow,
    handleBoxAreaClose,
    rowCount,
    handleClickSelectExecutors,
  } = props;

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    height: "auto",
    border: "2px solid #DFE2EC",
    borderRadius: isBoxShow ? "0px" : "8px",
    overflow: "hidden",
    maxWidth: "100%",
    "& .unassigned": {
      fontWeight: "500",
    },
    "& .MuiDataGrid-root": {
      // border: "2px solid red",
      borderRadius: "8px",
      overflow: "hidden",
      height: "auto",
    },
    "& .MuiDataGrid-overlay": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "60px",
    },
    "& .MuiDataGrid-overlayWrapper": {
      display: "inline-block",
      height: "60px",
    },
    "& .MuiDataGrid-columnHeaders": {
      "& .MuiDataGrid-checkboxInput": {
        width: "15px",
        height: "15px",
        "& svg": {
          fill: "#111520",
          width: "15px",
          height: "15px",
          borderRadius: "5px",
        },
      },
    },
    "& .MuiDataGrid-checkboxInput": {
      width: "15px",
      height: "15px",
      "& svg": {
        fill: "#DFE2EC",
        width: "15px",
        height: "15px",
        borderRadius: "5px",
      },
    },
    "& .MuiDataGrid-virtualScrollerContent": {
      backgroundColor: "#FFF",
    },

    "& .Mui-checked": {
      fill: "#DFE2EC",
      width: "15px",
      height: "15px",
      "& svg": {
        fill: "#2C53F5",
      },
    },
    "& .MuiDataGrid-cell": {
      fontSize: "14px",
      padding: "10px",
      backgroundColor: "#FFF",
      height: "40px",
      whiteSpace: "normal",
      overflow: "auto",
      textOverflow: "clip",
      fontWeight: "",
    },

    "& .MuiDataGrid-colCellTitle": {
      color: "#111520",
      fontWeight: "medium",
      backgroundColor: "#DFE2EC",
      height: "40px",
    },

    "& .MuiDataGrid-rowSelected": {
      backgroundColor: "red",
      height: "13px",
    },
    "& .MuiDataGrid-columnHeaderTitleContainerContent": {
      height: "100%",
    },

    "& .MuiDataGrid-columnHeaderTitle": {
      textOverflow: "clip",
      overflow: "auto",
      lineHeight: "120%",
      whiteSpace: "normal",
      height: "100%",
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-footerContainer": {
      display: "none",
    },
  }));

  const dispatch = useAppDispatch();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 20,
    page: 0,
  });
  useEffect(() => {
    dispatch(setPerPage(paginationModel.pageSize));
  }, [paginationModel]);

  useEffect(() => {
    dispatch(setCurrentPage(paginationModel.page));
  }, [paginationModel.page]);
  return (
    <>
      {isBoxShow && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            border: "2px solid #DFE2EC",
            minHeight: "45px",
            paddingLeft: "10px",
            backgroundColor: fincolors.primary.main,
          }}
        >
          <Checkbox
            checked={isChecked}
            onChange={onCheckboxChange}
            sx={{
              paddingLeft: "6px",
              "& .MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
                fill: "white",
                borderRadius: "5px",
                borderColor: "white",
                borderWidth: "1px",
                "& svg": {
                  fill: isChecked ? "#2C53F5" : "#DFE2EC",
                },
              },
            }}
          />
          <Box>
            <Button
              variant="outlined"
              disabled={true}
              sx={{
                fontSize: "14px",
                height: "32px",
                padding: "6px, 10px, 6px, 10px",
              }}
              onClick={handleClickSelectExecutors}
            >
              Распределить на исполнителей <Create />
            </Button>
            <Button
              sx={{
                fontSize: "14px",
                height: "32px",
                padding: "6px, 10px, 6px, 10px",
                background: "none",
              }}
              onClick={handleBoxAreaClose}
            >
              <Close />
            </Button>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Tab
          label="Новых писем"
          sx={{ color: "#373C49", fontWeight: "400", fontSize: "12px" }}
        />
        <Pagination
          rowCount={rowCount}
          rowsPerPage={paginationModel.pageSize}
          currentPage={paginationModel.page + 1}
          onPageChange={(evt, page) =>
            setPaginationModel({ ...paginationModel, page: page })
          }
          onRowsPerPageChange={(evt) => {
            setPaginationModel({
              ...paginationModel,
              pageSize: Number(evt.target.value),
            });
          }}
        />
      </Box>

      <StyledDataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        checkboxSelection
        onRowSelectionModelChange={handleSelectionModelChange}
        rowSelectionModel={selectedRows}
        onCellClick={handleCellClickId}
        hideFooterSelectedRowCount
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        paginationMode="server"
        pageSizeOptions={[20, 10]}
        columnHeaderHeight={isBoxShow ? 0 : 45}
        rowCount={rowCount}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
      />
      {isModalOpen && selectedRow && (
        <ModalNotificationsById
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          id={selectedRow.application_id}
          fullText={selectedRow.full_text}
        />
      )}
    </>
  );
};

export default NotificationsDataGrid;
