import { EDeclarationHistoryStatus } from "./enums";

export function getColorByStatus(status: EDeclarationHistoryStatus) {
  switch (status) {
    case EDeclarationHistoryStatus["Новое"]:
      return "#80DEEA";
    case EDeclarationHistoryStatus["Принято"]:
      return "#A5D6A7";
    case EDeclarationHistoryStatus["Отправлено на доработку"]:
      return "#FFF59D";
    case EDeclarationHistoryStatus["Отклонено"]:
      return "#EF9A9A";
    case EDeclarationHistoryStatus["Отозвано"]:
      return "#CFD8DC";
    case EDeclarationHistoryStatus["На исполнении"]:
      return "#90CAF9";
    case EDeclarationHistoryStatus["На согласовании"]:
      return "#81D4FA";
    case EDeclarationHistoryStatus["На доработке у исполнителя"]:
      return "#FFE082";
    default:
      return "#ECEFF1";
  }
}
