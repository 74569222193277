import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  invalidCurrentRows: {},
  invalidPredictiveRows: {},
};
const validateTablesSlice = createSlice({
  name: "validateTables",
  initialState,
  reducers: {
    setInvalidCurrentRows: (state, action) => {
      state.invalidCurrentRows = action.payload;
    },
    setInvalidPredictiveRows: (state, action) => {
      state.invalidPredictiveRows = action.payload;
    },
  },
});

export const selectInvalidCurrentRows = (state: RootState) =>
  state.validateTablesReducer.invalidCurrentRows;
  export const selectInvalidPredictiveRows = (state: RootState) =>
  state.validateTablesReducer.invalidPredictiveRows;
export const {setInvalidCurrentRows, setInvalidPredictiveRows } =
  validateTablesSlice.actions;

export default validateTablesSlice.reducer;
