import { Box, Button, Container, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getMainSpecialistsConfirm,
  getSpetialistsConfirm,
  setSpetialistsConfirm,
} from "../../../services/useEndpoint";
import { useAppSelector } from "../../../store/hooks";
import { selectCurrentUser } from "../../../store/reducers/currentUserReducer";
import { getCurrentDepartment } from "../../../utils/helpers";
import WorkerSelect from "./WorkerSelect";

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  applicationId: number;
  executorId?: string;
  mainExecutorid?: string;
}

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function ConfirmationModal({
  open,
  applicationId,
  onClose,
  executorId,
  mainExecutorid,
}: ConfirmationModalProps) {
  const [spetialists, setSpetialists] = useState<[]>([]);
  const [executorsMain, setExecutorsMain] = useState<{
    executor_id: number;
    main_executor_id: number | null;
  } | null>(null);

  const [mainSpetialists, setMainSpetialists] = useState<[]>([]);
  const [selectedExecutors, setSelectedExecutors] = useState<{
    executor_id: number;
    main_executor_id: number | null;
  }>();

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const navigate = useNavigate();

  const department = useAppSelector(selectCurrentUser).role.code;

  const currentDepartment = getCurrentDepartment(department);

  function handleConfirm() {
    if (!selectedExecutors) return;

    setSpetialistsConfirm(
      selectedExecutors.executor_id,
      selectedExecutors.main_executor_id,
      applicationId,
      currentDepartment,
    )
      .then((data) => {
        setButtonDisabled(true);
        toast.success("Заявление направлено исполнителю!");
        navigate("/director/declarations");
        onClose();
      })
      .catch((error) => {
        toast.error("Ошибка при назначении исполнителя");
      });
  }

  function handleDisable(executorId: number, mainExecutorId: number | null) {
    setSelectedExecutors({
      executor_id: executorId,
      main_executor_id: mainExecutorId,
    });
    if (executorId) {
      setButtonDisabled(false);
    }
  }

  useEffect(() => {
    if (!open && !executorsMain) return;
    try {
      getSpetialistsConfirm(currentDepartment)
        .then((data) => {
          if (data) setSpetialists(data.data);
        })
        .catch((error) => {
          console.log("Ошибка при запросе специалистов", error);
        });
    } catch (error) {
      console.log("Ошибка при запросе специалистов", error);
    }

    try {
      getMainSpecialistsConfirm(currentDepartment)
        .then((data) => {
          if (data) {
            setMainSpetialists(data.data);
          }
        })
        .catch((error) => {
          console.log("Ошибка при запросе специалистов", error);
        });
    } catch (error) {
      console.log("Ошибка при запросе специалистов", error);
    }
  }, [open, executorsMain]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Назначить
        </Typography>

        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, textAlign: "center" }}
        >
          Выберите из списка на кого назначить заявку
        </Typography>

        <WorkerSelect
          spetialists={spetialists}
          mainSpecialists={mainSpetialists}
          executorId={Number(executorId) ? Number(executorId) : null}
          mainExecutorid={
            Number(mainExecutorid) ? Number(mainExecutorid) : null
          }
          disableButton={handleDisable}
        />

        <Container
          disableGutters
          maxWidth={false}
          sx={{
            marginTop: "20px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "space-between",
            gap: "30px",
          }}
        >
          <Button fullWidth variant="outlined" onClick={onClose}>
            Отмена
          </Button>

          <Button fullWidth onClick={handleConfirm} disabled={buttonDisabled}>
            Назначить
          </Button>
        </Container>
      </Box>
    </Modal>
  );
}
