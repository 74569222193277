import React from "react";
import { Typography, Box } from "@mui/material";

const TableDateTimeNotifications = ({ dateTimeString }: { dateTimeString: string }) => {
    const formatDateTime = (dateTimeString: string) => {
        const dateObj = new Date(dateTimeString);
        const date = dateObj.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        const time = dateObj.toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
        });
        return { date, time };
    };

    const { date, time } = formatDateTime(dateTimeString);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column',  }}>
            <Typography sx={{color: '#373C49', fontSize: '12px'}}>
                {date}
            </Typography>
            <Typography sx={{color: '#373C49', fontSize: '12px'}}>
                {time}
            </Typography>
        </Box>
    );
};

export default TableDateTimeNotifications;
