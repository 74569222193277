import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fincolors } from '../../../styles/theme';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: fincolors.background.default,
    padding: '5px'
    // flex: 1
    // color: theme.palette.common.white,
  },
  // [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
  // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// function createData(
//   name: string,
//   calories: number,
//   fat: number,
//   carbs: number,
//   protein: number,
//   dada: number
// ) {
//   return { name, calories, fat, carbs, protein };
// }

interface RowTablePupilsInterface {
  classes: string | number,
  common_pupils: string | number,
  special_pupils: string | number,
  correction_pupils: string | number,
  home_pupils: string | number,
  all_pupils: string | number,
}

interface PupilsPropsInterface {
  rows: RowTablePupilsInterface[];
}

// const rows: RowTablePupilsInterface[] = [
//   {classes: '1 - 4', all_pupils: '34', common_pupils: '22', special_pupils: '4', correction_pupils: '4', home_pupils: '6'},
//   {classes: '5 - 9', all_pupils: '34', common_pupils: '22', special_pupils: '4', correction_pupils: '4', home_pupils: '6'},
//   {classes: '10 - 11', all_pupils: '34', common_pupils: '22', special_pupils: '4', correction_pupils: '4', home_pupils: '6'},
//   {classes: 'Итого', all_pupils: '34', common_pupils: '22', special_pupils: '4', correction_pupils: '4', home_pupils: '6'},
// ];

const PupilsTable: React.FC<PupilsPropsInterface> = (rows) => {
  const [renderRows, setRenderRows] = useState<RowTablePupilsInterface[] | undefined>();

  useEffect(() => {
    setRenderRows(rows.rows)
  }, [])
  
  return (
      <TableContainer component={Paper}>

      <Table sx={{borderRadius: '10px', border: '1px solid #BFC5D9'}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Классы</StyledTableCell>
            <StyledTableCell>Всего учащихся (человек)</StyledTableCell>
            <StyledTableCell>Учащиеся общеобразовательных классов</StyledTableCell>
            <StyledTableCell>Лица (дети) с особыми образовательными потребностями, обучающиеся в общеобразовательных классах</StyledTableCell>
            <StyledTableCell>Учащиеся специальных (коррекционных) классов</StyledTableCell>
            <StyledTableCell>Обучающиеся на дому</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRows && renderRows.map((row: RowTablePupilsInterface) => (
            <StyledTableRow key={row.classes}>
              <StyledTableCell sx={{minWidth: '100px'}} component="th" scope="row">{row.classes}</StyledTableCell>
              <StyledTableCell>{row.all_pupils}</StyledTableCell>
              <StyledTableCell>{row.common_pupils}</StyledTableCell>
              <StyledTableCell>{row.special_pupils}</StyledTableCell>
              <StyledTableCell>{row.correction_pupils}</StyledTableCell>
              <StyledTableCell>{row.home_pupils}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PupilsTable;