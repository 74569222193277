import BoxTitle from "../../components/BoxTitle";
import OrganizationDetails from "./components/StudentDetails";
import LoginAndPassword from "./components/LoginAndPassword";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { ModeEditOutline } from "@mui/icons-material";
import { FormikProps, useFormik } from "formik";
import * as yup from "yup";
import { ROLES, form_msg } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectCurrentUser,
  setAllCurrentUserData,
} from "../../store/reducers/currentUserReducer";
import { toast } from "react-toastify";
import {
  editSchoolProfile,
  getCurrentUser,
  updateCredentials,
} from "../../services/useEndpoint";
import RequestButton from "../StudentRequests/components/RequestButton";

export interface DetailsValues {
  official_number: string;
  phone_number: string;
  // official_address: string | null;
  // actual_address: string | null;
  // ceo_fullname: string | null;
  // ceo_official_number: string | null;
  email_address: string;
  // postcode: string | null;
  organization_name: string;
  role_id: number;
}
interface LoginValues {
  login: string;
  password: string;
}

const StudentProfile = (props: unknown) => {
  const [editDetailsMode, setEditDetailsMode] = useState(false);
  const [editLoginDetailsMode, setEditLoginDetailsMode] = useState(false);
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  const setCurrentUSerData = () => {
    getCurrentUser(currentUser.id)
      .then((data) => {
        if (data) {
          dispatch(setAllCurrentUserData(data.data));
        } else {
          console.log("User get data failed. Please check.");
        }
      })
      .catch((error) => {
        console.error("An error occurred during getCurrentUser:", error);
      });
  };

  // navigate("school/declarations");
  const formikOrgDetails = useFormik<DetailsValues>({
    initialValues: {
      official_number: currentUser.official_number || "",
      phone_number: currentUser.phone_number || "",
      // official_address: currentUser.official_address || "",
      // actual_address: currentUser.actual_address || "",
      // ceo_fullname: currentUser.ceo_fullname || "",
      // ceo_official_number: currentUser.ceo_official_number || "",
      email_address: currentUser.email_address || "",
      // postcode: currentUser.postcode || "",
      organization_name: currentUser.official_number || "",
      role_id: ROLES.student.id,
    },
    validationSchema: yup.object().shape({
      role_id: yup.string().required(form_msg.REQUIRED),
      official_number: yup
        .string()
        .required(form_msg.REQUIRED)
        .min(12, form_msg.MIN_LENGTH(12)),
      phone_number: yup.string().required(form_msg.REQUIRED),
      // official_address: yup.string().required(form_msg.REQUIRED),
      // actual_address: yup.string().required(form_msg.REQUIRED),
      // ceo_fullname: yup.string().required(form_msg.REQUIRED),
      // ceo_official_number: yup
      //   .string()
      //   .required(form_msg.REQUIRED)
      //   .min(12, form_msg.MIN_LENGTH(12)),
      email_address: yup
        .string()
        .email(form_msg.EMAIL)
        .required(form_msg.REQUIRED),
      // postcode: yup.string().required(form_msg.REQUIRED),
      organization_name: yup.string().required(form_msg.REQUIRED),
    }),
    onSubmit: (values) => {
      const detailsFormData = new FormData();
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof DetailsValues];
        if (value !== null && value !== undefined) {
          detailsFormData.append(key, value.toString());
        }
      });

      editSchoolProfile(currentUser.id, detailsFormData).then((response) => {
        if (response.success === false) {
          formikOrgDetails.resetForm({
            values: {
              official_number: currentUser.official_number || "",
              phone_number: currentUser.phone_number || "",
              // official_address: currentUser.official_address || "",
              // actual_address: currentUser.actual_address || "",
              // ceo_fullname: currentUser.ceo_fullname || "",
              // ceo_official_number: currentUser.ceo_official_number || "",
              email_address: currentUser.email_address || "",
              // postcode: currentUser.postcode || "",
              role_id: ROLES.student.id,
              organization_name: currentUser.organization_name || "",
            },
          });
        } else {
          setCurrentUSerData();
        }
        setEditDetailsMode(false);
      });
    },
  });

  const formikLoginDetails = useFormik<LoginValues>({
    initialValues: {
      login: currentUser.login,
      password: "",
    },
    validationSchema: yup.object().shape({
      login: yup.string().required(form_msg.REQUIRED),
      password: yup
        .string()
        .required(form_msg.REQUIRED)
        .min(8, form_msg.MIN_LENGTH(8))
        .matches(
          form_msg.PASSWORD_REG_EXP,
          'В числе символов пароля обязательно должны присутствовать буквы в верхнем и нижнем регистрах, цифры и спецсимволы ("№%:?*@#$%^ и другие).',
        ),
    }),
    onSubmit: (values) => {
      const detailsFormData = new FormData();
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof LoginValues];
        if (value !== null && value !== undefined) {
          detailsFormData.append(key, value.toString());
        }
      });
      updateCredentials(currentUser.id, detailsFormData).then((response) => {
        if (response.success === false) {
          formikLoginDetails.resetForm({
            values: {
              login: currentUser.login,
              password: "",
            },
          });
        } else {
          setCurrentUSerData();
        }
        setEditLoginDetailsMode(false);
        formikLoginDetails.setFieldTouched("password", false);
      });
    },
  });

  // function validateAndSubmit<T extends DetailsValues | LoginValues>(
  //   form: FormikProps<T>,
  // ) {
  //   form.validateForm().then((errors) => {
  //     if (Object.keys(errors).length === 0) {
  //       form.handleSubmit();
  //     } else {
  //       console.log(errors);
  //       toast.error("Пожалуйста, заполните все обязательные поля.");
  //       form.setTouched(
  //         Object.keys(form.initialValues).reduce((acc: any, key) => {
  //           acc[key] = true;
  //           return acc;
  //         }, {}),
  //       );
  //     }
  //   });
  // }
  const handlers = {
    changeEditMode: () => {
      if (editDetailsMode)
        formikOrgDetails.resetForm({
          values: {
            official_number: currentUser.official_number || "",
            phone_number: currentUser.phone_number || "",
            // official_address: currentUser.official_address || "",
            // actual_address: currentUser.actual_address || "",
            // ceo_fullname: currentUser.ceo_fullname || "",
            // ceo_official_number: currentUser.ceo_official_number || "",
            email_address: currentUser.email_address || "",
            // postcode: currentUser.postcode || "",
            role_id: ROLES.student.id,
            organization_name: currentUser.organization_name || "",
          },
        });
      setEditDetailsMode(!editDetailsMode);
    },

    validateAndSubmit: <T extends DetailsValues | LoginValues>(
      form: FormikProps<T>,
    ) => {
      form.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          form.handleSubmit();
        } else {
          toast.error(
            "Пожалуйста, заполните все обязательные поля в соответствии с требованиями.",
          );
          form.setTouched(
            Object.keys(form.initialValues).reduce((acc: any, key) => {
              acc[key] = true;
              return acc;
            }, {}),
          );
        }
      });
    },

    // navigateToCreate: () => {
    //   navigate("/school/declarations/create");
    // },
  };

  useEffect(() => {
    if (currentUser) {
      formikOrgDetails.setValues({
        official_number: currentUser.official_number,
        phone_number: currentUser.phone_number,
        // official_address: currentUser.official_address,
        // actual_address: currentUser.actual_address,
        // ceo_fullname: currentUser.ceo_fullname,
        // ceo_official_number: currentUser.ceo_official_number,k
        email_address: currentUser.email_address,
        // postcode: currentUser.postcode,
        role_id: ROLES.student.id,
        organization_name: currentUser.organization_name,
      });

      // formikLoginDetails.setValues({
      //   login: currentUser.login,
      //   password: "",
      // });
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      formikLoginDetails.setValues({
        login: currentUser.login,
        password: "",
      });
    }
  }, [currentUser, formikLoginDetails.setValues]);

  return (
    <>
      <BoxTitle title={"Мой профиль"}>
        {!editDetailsMode ? (
          <>
            <Button onClick={handlers.changeEditMode} variant="outlined">
              <ModeEditOutline />
            </Button>
            <RequestButton />
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                handlers.changeEditMode();
              }}
            >
              Отменить
            </Button>
            <Button
              onClick={() => {
                handlers.validateAndSubmit(formikOrgDetails);
              }}
            >
              Сохранить
            </Button>
          </>
        )}
      </BoxTitle>

      <OrganizationDetails
        values={formikOrgDetails.values}
        touched={formikOrgDetails.touched}
        errors={formikOrgDetails.errors}
        editMode={editDetailsMode}
        handleChange={formikOrgDetails.handleChange}
        //  changeEditMode={handlers.changeEditMode}
      />

      <LoginAndPassword
        values={formikLoginDetails.values}
        touched={formikLoginDetails.touched}
        errors={formikLoginDetails.errors}
        handleChange={formikLoginDetails.handleChange}
        validateAndSubmit={() => handlers.validateAndSubmit(formikLoginDetails)}
        editLoginDetailsMode={editLoginDetailsMode}
        setEditLoginDetailsMode={setEditLoginDetailsMode}
        resetForm={() => {
          formikLoginDetails.resetForm({
            values: {
              login: currentUser.login,
              password: "",
            },
          });
        }}
      />
    </>
  );
};

export default StudentProfile;
