import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";

interface CircularIndeterminateState {
  title: string;
  open: boolean;
  onCancel?(): void;
}

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  px: 4,
  py: 3,
};

export default function LoadingBar({
  title,
  open,
  onCancel,
}: CircularIndeterminateState) {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
          }}
        >
          <CircularProgress />
          <Typography>{title}</Typography>

          {onCancel && (
            <Button
              color="error"
              variant="text"
              size="small"
              onClick={onCancel}
            >
              Отмена
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
