import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Button, Typography, createSvgIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Status } from "../pages/SchoolDeclarations/SchoolDeclarations";
import { getSignersDocument } from "../services/useEndpoint";
import { IApplication } from "../types/application";
import { calculateDaysLeft, downloadFile } from "../utils/helpers";
import NoRowsOverlay from "./NoRowsOverlay";
import Pagination from "./Pagination/Pagination";

export interface RowData {
  id: number;
  type: string;
  name: string;
  date: string;
  status: string;
}

const StyledDataGrid = styled(DataGrid)(() => ({
  height: "auto",
  border: "2px solid #DFE2EC",
  borderRadius: "8px",
  overflow: "hidden",
  maxWidth: "1130px",
  "& .MuiDataGrid-root": {
    border: "2px solid red",
    borderRadius: "8px",
    overflow: "hidden",
    height: "auto",
  },
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-checkboxInput": {
      width: "15px",
      height: "15px",
      "& svg": {
        fill: "#111520",
        width: "15px",
        height: "15px",
        borderRadius: "5px",
      },
    },
  },
  "& .MuiDataGrid-checkboxInput": {
    width: "15px",
    height: "15px",
    "& svg": {
      fill: "#DFE2EC",
      width: "15px",
      height: "15px",
      borderRadius: "5px",
    },
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    backgroundColor: "#FFF",
  },

  "& .Mui-checked": {
    fill: "#DFE2EC",
    width: "15px",
    height: "15px",
    "& svg": {
      fill: "#2C53F5",
    },
  },
  "& .MuiDataGrid-overlay": {
    display: "none",
  },
  // "& .MuiDataGrid-cell": {
  //   fontSize: "14px",
  //   padding: "10px",
  //   backgroundColor: "#FFF",
  //   height: "40px",
  //   whiteSpace: "normal",
  //   overflow: "auto",
  //   textOverflow: "clip",
  // },

  "& .MuiDataGrid-colCellTitle": {
    color: "#111520",
    fontWeight: "medium",
    backgroundColor: "#DFE2EC",
    height: "40px",
  },

  "& .MuiDataGrid-rowSelected": {
    backgroundColor: "red",
    height: "13px",
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    overflow: "auto",
    lineHeight: "120%",
    whiteSpace: "normal",
    height: "100%",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },
}));

interface TableProps {
  page: number;
  perPage: number;
  rows: IApplication[];
  rowCount: number;
  onChangePage(page: number): void;
  onChangePerPage(perPage: number): void;
}

const getStatusColor = (status: Status) => {
  switch (status) {
    case Status.SUCCSESS:
      return "#E9FEEE";
    case Status.REJECTED:
      return "#FBDDDD";
    case Status.REVISION:
      return "#FFF8EB";
    default:
      return "#EAF4FE";
  }
};

const getStatusColorIcon = (status: Status) => {
  switch (status) {
    case Status.SUCCSESS:
      return <CheckCircleIcon style={{ color: "#2DB77B" }} />;
    case Status.REJECTED:
      return <CancelIcon style={{ color: "#EC5654" }} />;
    case Status.REVISION:
      return <CustomCircleIcon />;
    default:
      return <TelegramIcon style={{ color: "#298FF5" }} />;
  }
};

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID заявки",
    headerClassName: "super-app-theme--header",
    width: 100,
    renderCell: (params) => <span>{params.row.id}</span>,
  },
  {
    field: "type",
    headerClassName: "super-app-theme--header",
    headerName: "Тип заявки",
    width: 220,
    editable: true,
    renderCell: (params) =>
      params.row.type === 1 ? "Основной перечень" : "Дополнительный перечень",
  },
  {
    field: "school_name",
    headerName: "Наименование школы",
    headerClassName: "super-app-theme--header",
    width: 180,
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "Дата отправки",
    headerClassName: "super-app-theme--header",
    width: 145,
  },
  {
    field: "displayed_status",
    headerName: "Статус",
    headerClassName: "super-app-theme--header",
    // flex: 1,
    width: 145,
    renderCell: (params) => {
      const { text, color } = params.row.rework_period
        ? calculateDaysLeft(params.row.rework_period, true)
        : { text: "Some default text", color: "Some default color" };

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: getStatusColor(params.row.displayed_status),
                padding: "5px 8px",
                borderRadius: "36px",
                display: "flex",
                alignItems: "center",
                marginRight: "20px",
                height: "25px",
                width: "100%",
              }}
            >
              <span style={{ marginRight: "10px" }}>
                {params.row.displayed_status}
              </span>
              {getStatusColorIcon(params.row.displayed_status)}
            </div>
          </div>
          {params.row.rework_period && (
            <div
              style={{
                whiteSpace: "pre-line",
                marginTop: "3px",
                marginLeft: "5px",
                color: color,
              }}
            >
              <Typography variant="body2">{text}</Typography>
            </div>
          )}
        </div>
      );
    },
  },

  {
    field: "inner_number",
    headerName: "Вх. номер ФЦ",
    headerClassName: "super-app-theme--header",
    width: 105,
  },
  {
    field: "url",
    headerName: "Скачать",
    headerClassName: "super-app-theme--header",
    width: 100,
    renderCell: (params) => {
      let url: string;

      if (params.row.status.id === 4) url = params.row.rework_pdf;
      else if (params.row.status.id === 7) url = params.row.decline_pdf;
      else if (params.row.status.id === 9) url = params.row.return_pdf;
      else url = params.row.pdf;

      return (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              getSignersDocument({ file_path: url })
                .then((data) => {
                  downloadFile(data, "Заявление.pdf", "application/pdf");
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                });
            }}
            disabled={false}
            sx={[
              {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "25px",
                height: "30px",
                backgroundColor: "#FFF",
                transitions: "all 0.5s",
              },
              {
                "&:hover": {
                  backgroundColor: "#A7ADBE",
                  transitions: "all 0.5s",
                  path: {
                    transitions: "all 0.5s",
                    fill: "#FFF",
                  },
                },
              },
            ]}
          >
            <DownloadIcon
              style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
            />
          </Button>
        </div>
      );
    },
  },
];

const TableComponent: React.FC<TableProps> = ({
  page,
  perPage,
  rows,
  rowCount,
  onChangePage,
  onChangePerPage,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleCellClick = (params: any) => {
    if (params.field === "url") {
      // Если это колонка, которую мы хотим игнорировать, ничего не делаем
      return;
    }
    navigate(`${location.pathname}/${params.id}`);
  };

  return (
    <>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        onCellClick={(params) => {
          if (params.field === "displayed_status") {
            return;
          }
          handleCellClick(params);
        }}
        hideFooter
        hideFooterPagination
        pageSizeOptions={[20, 10]}
        rowCount={rowCount}
        slots={{ noRowsOverlay: NoRowsOverlay }}
      />

      <Pagination
        rowCount={rowCount}
        rowsPerPage={perPage}
        currentPage={page}
        onPageChange={(event, page) => onChangePage(page + 1)}
        onRowsPerPageChange={(event) =>
          onChangePerPage(Number(event.target.value))
        }
      />
    </>
  );
};

const CustomCircleIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
  >
    <path
      d="M8.51123 1.80078V4.68077"
      stroke="url(#paint0_linear_1984_7870)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.51123 13.3203V16.2003"
      stroke="url(#paint1_linear_1984_7870)"
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.42139 3.91016L5.45898 5.94775"
      stroke="url(#paint2_linear_1984_7870)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5645 12.0527L13.602 14.0903"
      stroke="url(#paint3_linear_1984_7870)"
      stroke-opacity="0.6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.31152 9H4.19152"
      stroke="url(#paint4_linear_1984_7870)"
      stroke-opacity="0.9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.8315 9H15.7115"
      stroke="url(#paint5_linear_1984_7870)"
      stroke-opacity="0.5"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.42139 14.0903L5.45898 12.0527"
      stroke="url(#paint6_linear_1984_7870)"
      stroke-opacity="0.8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5645 5.94775L13.602 3.91016"
      stroke="url(#paint7_linear_1984_7870)"
      stroke-opacity="0.4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1984_7870"
        x1="-1.98877"
        y1="3.40077"
        x2="7.31221"
        y2="20.8402"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1984_7870"
        x1="-2.48877"
        y1="3.4003"
        x2="7.29301"
        y2="20.8881"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1984_7870"
        x1="3.17842"
        y1="3.40052"
        x2="14.1117"
        y2="14.6005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1984_7870"
        x1="2.91162"
        y1="3.3999"
        x2="13.8449"
        y2="14.5999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1984_7870"
        x1="2.91152"
        y1="-1.5"
        x2="20.12"
        y2="7.45928"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1984_7870"
        x1="2.91153"
        y1="-1.5"
        x2="20.3509"
        y2="7.80098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1984_7870"
        x1="2.91175"
        y1="3.3999"
        x2="14.1117"
        y2="14.5999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1984_7870"
        x1="2.91162"
        y1="3.40052"
        x2="13.8449"
        y2="14.6005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
    </defs>
  </svg>,
  "CustomCircleIcon",
);

export default TableComponent;
