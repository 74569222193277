import { CheckCircle, Telegram } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";

interface TableStatusComponentProps {
  status: string;
}
const TableStatusComponent: React.FC<TableStatusComponentProps> = ({
  status,
}) => {
  const getStatusColorIcon = (status: string) => {
    switch (status) {
      case "done":
        return <CheckCircle style={{ color: "#2DB77B" }} />;
      default:
        return <Telegram style={{ color: "#298FF5" }} />;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        width: "100%",
        justifyContent: "space-between",
        // minWidth:"130px",
        backgroundColor: status === "done" ? "#E9FEEE" : "#EAF4FE",
        borderRadius: "36px",
        px: "10px",
        py: "5px",
      }}
    >
      {status === "done" ? "Выполнен" : "Отправлен"}{" "}
      {getStatusColorIcon(status)}
    </Box>
  );
};
export default TableStatusComponent;
