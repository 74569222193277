import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  DataGrid,
  GridAlignment,
  GridColumnGroup,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { BoardingTableRows } from "../types/tableRowsTypes";
import { useAppSelector } from "../store/hooks";
import {
  selectBoardingPredictiveContingentDataTable,
  selectBoardingSchool,
  selectBoardingStudentsDataTable,
  selectPredictiveContingentDataTable,
  selectStudentsDataTable,
} from "../store/reducers/createDeclaration";
import { useCheckCellsValues } from "../hooks/useCheckCellsValues";

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& *:focus": { outline: "none!important" },
    "& .MuiDataGrid-columnHeadersInner > div": {
      backgroundColor: theme.palette.background.default,
      "&:first-child:has(~ :nth-child(2))": {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
      },
    },

    borderRadius: "10px",
    //borderColor: theme.palette.other.tableBorderColor,

    "& .MuiDataGrid-columnHeaders": {
      borderTopLeftRadius: "9px",
      borderTopRightRadius: "9px",
    },
    //'& .MuiDataGrid-withBorderColor': {borderColor: theme.palette.other.tableBorderColor},
    "& .MuiDataGrid-columnHeader:not(:last-child), .MuiDataGrid-cell:not(:last-child)":
      {
        borderRight: `1px solid #BFC5D9`,
        //borderColor: theme.palette.other.tableBorderColor,
      },
    "& .MuiDataGrid-columnHeaderTitleContainer": { border: "none!important" },
    "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
      borderBottom: "none!important",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-row:not(:last-child) > .MuiDataGrid-cell":
      {
        borderBottom: `1px solid #BFC5D9`,
        //borderColor: theme.palette.other.tableBorderColor,
      },
    "& .MuiDataGrid-iconSeparator": { display: "none" },
    "& .MuiDataGrid-cell": {
      height: "auto",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      textOverflow: "clip",
      overflow: "auto",
      lineHeight: "120%",
      whiteSpace: "normal",
      height: "100%",
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
    },
  };
});

const CustomEditableCell: React.FC<any> = (params) => {
  const { isInputDisabled, onCellValueChange, id, field, value, isCurrent } =
    params;
  const boardingSchool = useAppSelector(selectBoardingSchool);
  const [localValue, setLocalValue] = useState(value);
  const [isValid, setIsValid] = useState(true);

  const checkCellsValues = useCheckCellsValues(isCurrent).checkValues;
  const studentsDataTable = useAppSelector(
    isCurrent ? selectStudentsDataTable : selectPredictiveContingentDataTable,
  );
  const boardingStudentsDataTable = useAppSelector(
    isCurrent
      ? selectBoardingStudentsDataTable
      : selectBoardingPredictiveContingentDataTable,
  );

  const schoolRow =
    studentsDataTable && studentsDataTable.find((row) => row.id === id);
  const boardingRow =
    boardingStudentsDataTable &&
    boardingStudentsDataTable.find((row) => row.id === id);

  const handleCheckValues = () => {
    if (!isInputDisabled && studentsDataTable && boardingStudentsDataTable) {
      if (schoolRow && boardingRow) {
        const validationResults = checkCellsValues(
          schoolRow,
          boardingRow,
          id,
        ).validationResult;

        const fieldKey = field as keyof typeof validationResults;
        if (boardingSchool === "Есть") {
          setIsValid(validationResults[fieldKey]);
        }
      }
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    handleCheckValues();
  }, [studentsDataTable, boardingStudentsDataTable, isInputDisabled]);

  useEffect(() => {
    return () => {
      setIsValid(true);
    };
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const newValue = event.target.value.replace(/[^\d.]/g, "");
    const newValue = event.target.value;
    setLocalValue(newValue);
    onCellValueChange(id, field, newValue);
  };

  const handleBlur = () => {
    if (localValue === "" || value === "0") {
      const newValue = 0;
      setLocalValue("");
      onCellValueChange(id, field, newValue);
    }
  };
  const handleFocus = () => {
    if (
      params.value === 0 &&
      params.field !== "classSummStudents" &&
      params.id !== 4
    ) {
      setLocalValue("");
    }
  };

  const isDisabled =
    //field === "classSchool" ||
    id === 4 || field === "classSummStudents" || isInputDisabled;

  return (
    <input
      value={localValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={{
        height: "100%",
        width: "120%",
        backgroundColor: "rgba(0, 0, 0, 0)",
        padding: "0 10px",
        border: isValid ? "none" : "1px solid red",
        marginLeft: "-10px",
        marginRight: "-10px",
        // display:"flex"
        // border:"none"
      }}
      disabled={isDisabled}
    />
  );
};
const columns = [
  {
    field: "classSchool",
    headerName: " ",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <span>{params.value}</span>;
    },
    sortable: false,
  },
  {
    field: "classSummStudents",
    headerName: " ",
    flex: 1,
    sortable: false,
  },
  {
    field: "normativClassDaily",
    headerName: "Дневные",
    sortable: false,
    flex: 1,
  },
  {
    field: "normativClassBoarding",
    headerName: "Интернат",
    sortable: false,
    flex: 1,
  },
  {
    field: "specialClassDaily",
    headerName: "Дневные",
    sortable: false,
    flex: 1,
  },
  {
    field: "specialClassBoarding",
    headerName: "Интернат",
    sortable: false,
    flex: 1,
  },
  {
    field: "correctiveClassDaily",
    headerName: "Дневные",
    sortable: false,
    flex: 1,
  },
  {
    field: "correctiveClassBoarding",
    headerName: "Интернат",
    sortable: false,
    flex: 1,
  },
  {
    field: "homeSchoolersDaily",
    headerName: "Дневные",
    sortable: false,
    flex: 1,
  },
];

const columnsGroupModel: GridColumnGroup[] = [
  {
    groupId: "classes",
    headerName: "Классы",
    children: [{ field: "classSchool" }],
  },
  {
    groupId: "summStudens",
    headerName: "Всего учащихся (человек)",
    children: [{ field: "classSummStudents" }],
  },
  {
    groupId: "generalEducations",
    headerName: "Учащиеся общеобразовательных классов",
    children: [
      {
        groupId: "normativClass",
        headerName: "Нормотипичные",
        headerAlign: "center" as GridAlignment,
        children: [
          { field: "normativClassDaily" },
          { field: "normativClassBoarding" },
        ],
      },
      {
        groupId: "specialClass",
        headerName: "Учащиеся с особыми образовательными потребностями",
        headerAlign: "center" as GridAlignment,
        children: [
          { field: "specialClassDaily" },
          { field: "specialClassBoarding" },
        ],
      },
    ],
  },
  {
    groupId: "correctiveClassMain",
    headerName: "Учащиеся специальных (коррекционных) классов",
    children: [
      {
        groupId: "correctiveClass",
        headerName: "Учащиеся специальных (коррекционных) классов",
        headerAlign: "center" as GridAlignment,
        children: [
          { field: "correctiveClassDaily" },
          { field: "correctiveClassBoarding" },
        ],
      },
    ],
  },
  {
    groupId: "homeSchoolersMain",
    headerName: "Обучающиеся на дому",
    children: [
      {
        groupId: "homeSchoolers",
        headerName: "Обучающиеся на дому",
        headerAlign: "center" as GridAlignment,
        children: [{ field: "homeSchoolersDaily" }],
      },
    ],
  },
];

interface MagicTableProps {
  rows: BoardingTableRows[];
  setRows: (e: BoardingTableRows[]) => void;
  isInputDisabled: boolean;
  isCurrent: boolean;
}

const MagicTable: React.FC<MagicTableProps> = ({
  rows,
  setRows,
  isInputDisabled,
  isCurrent,
}) => {
  const handleCellValueChange = (id: number, field: string, newValue: any) => {
    if (isInputDisabled) {
      return;
    }
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: newValue };
      }

      return row;
    });
    setRows(updatedRows);
  };

  const calculateTotal = () => {
    type RowKey =
      | "classSummStudents"
      | "normativClassDaily"
      | "normativClassBoarding"
      | "specialClassDaily"
      | "specialClassBoarding"
      | "correctiveClassDaily"
      | "correctiveClassBoarding"
      | "homeSchoolersDaily";

    let isChanged = false;
    const newRow = rows.find((row) => row.id === 4);
    if (!newRow) return;

    let totals: Record<RowKey, number> = {
      classSummStudents: 0,
      normativClassDaily: 0,
      normativClassBoarding: 0,
      specialClassDaily: 0,
      specialClassBoarding: 0,
      correctiveClassDaily: 0,
      correctiveClassBoarding: 0,
      homeSchoolersDaily: 0,
    };

    rows.forEach((row) => {
      if (row.id === 4) return;
      (Object.keys(totals) as RowKey[]).forEach((key) => {
        const value = row[key];
        totals[key] +=
          typeof value === "string" ? parseInt(value, 10) || 0 : value;
      });
    });

    const updatedRows = rows.map((row) => {
      if (row.id !== 4) return row;
      const updatedRow: BoardingTableRows = { ...row };
      (Object.keys(totals) as RowKey[]).forEach((key) => {
        const currentValue = updatedRow[key];
        const newValue = totals[key];
        if (
          typeof currentValue === "string"
            ? parseInt(currentValue, 10) !== newValue
            : currentValue !== newValue
        ) {
          isChanged = true;
          updatedRow[key] = newValue;
        }
      });
      return updatedRow;
    });

    if (isChanged) {
      setRows(updatedRows);
    }
  };

  const calculateTotalSumm = () => {
    let isChanged = false;
    const updatedRows = rows.map((row) => {
      const newSum =
        Number(row.normativClassDaily) +
        Number(row.normativClassBoarding) +
        Number(row.specialClassDaily) +
        Number(row.specialClassBoarding) +
        Number(row.correctiveClassDaily) +
        Number(row.correctiveClassBoarding) +
        Number(row.homeSchoolersDaily);

      if (row.classSummStudents !== newSum) {
        isChanged = true;
      }

      return { ...row, classSummStudents: newSum };
    });

    if (isChanged) {
      setRows(updatedRows);
    }
  };
  // При изменении значений в столбцах, пересчитываем суммы и обновляем строку "Итого"
  useEffect(() => {
    calculateTotalSumm();
    calculateTotal();
  }, [rows]);

  const handleCellClick = (params: any) => {
    if (params.isInputDisabled) {
      return;
    }
    if (
      params.value === 0 &&
      params.field !== "classSummStudents" &&
      params.id !== 4
    ) {
      handleCellValueChange(params.id, params.field, "");
    }
  };

  useEffect(() => {
    // setRowsAll(rows.concat(totalRow));
  }, [
    // totalRow,
    rows,
  ]);
  return (
    <>
      <StyledDataGrid
        // <DataGrid
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        disableVirtualization={true}
        disableColumnFilter={true}
        disableColumnMenu={true}
        checkboxSelection={false}
        hideFooter={true}
        hideFooterPagination={true}
        editMode={"row"}
        rows={
          // rows.concat(totalRow)
          rows
        }
        columns={columns.map((column) => ({
          ...column,
          renderCell: (params) => (
            <CustomEditableCell
              {...params}
              disabled={isInputDisabled}
              onCellValueChange={handleCellValueChange}
              isCurrent={isCurrent}
            />
          ),
        }))}
        columnGroupingModel={columnsGroupModel}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        experimentalFeatures={{
          columnGrouping: true,
        }}
        pageSizeOptions={[5, 10]}
        onCellClick={handleCellClick}
      />
    </>
  );
};
export default MagicTable;
