import { Box, TablePagination } from "@mui/material";
import styled from "@emotion/styled";
import React from "react";
import { fincolors } from "../../styles/theme";
import { LabelDisplayedRows } from "./LabelDisplayedRows";
interface PaginationProps {
  rowCount: number;
  rowsPerPage: number;
  currentPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTablePagination = styled(TablePagination)(() => ({
  "&.MuiTableCell-root.MuiTablePagination-root": {
    border: "none",
  },
  ".MuiTablePagination-toolbar": {
    padding: "0",
  },
  ".MuiTablePagination-select": {
    color: `${fincolors.text.primary}`,
    fontSize: "14px",
    marginRight: "10px",
  },
  ".MuiTablePagination-input": {
    marginRight: "24px",
  },
  ".MuiTablePagination-selectLabel": {
    fontSize: "14px",
  },
  ".MuiTablePagination-selectIcon": {
    fill: "#111520",
  },
}));

const Pagination: React.FC<PaginationProps> = ({
  rowCount,
  rowsPerPage,
  currentPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  // Вычисляем количество страниц
  const totalPages = Math.ceil(rowCount / rowsPerPage);

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <CustomTablePagination
        count={rowCount}
        page={currentPage - 1}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[100, 50, 20]}
        labelRowsPerPage="Показано"
        onRowsPerPageChange={onRowsPerPageChange}
        labelDisplayedRows={() => (
          <LabelDisplayedRows
            currentPage={currentPage}
            totalPages={totalPages}
          />
        )}
      />
    </Box>
  );
};

export default Pagination;
