import styled from "@emotion/styled";
import { Download } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { toast } from "react-toastify";
import NoRowsOverlay from "../../../components/NoRowsOverlay";
import Pagination from "../../../components/Pagination/Pagination";
import { getSignersDocument } from "../../../services/useEndpoint";
import { fincolors } from "../../../styles/theme";
import { calculateDaysLeft, downloadFile } from "../../../utils/helpers";
import { EOutgoingMailType, IOutgoingMail } from "../types";

interface ITable {
  page: number;
  perPage: number;
  rows: IOutgoingMail[];
  rowCount: number;
  onChangePage(page: number): void;
  onChangePerPage(perPage: number): void;
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerClassName: "super-app-theme--header",
    headerName: "Исходящий номер",
    width: 120,
    editable: false,
    renderCell: (params) => params.row.id,
  },
  {
    field: "send_date",
    headerName: "Дата отправления",
    headerClassName: "super-app-theme--header",
    width: 140,
    flex: 1,
    editable: false,
    renderCell: (params) => params.row.send_date,
  },

  {
    field: "organization_name",
    headerName: "Название школы",
    headerClassName: "super-app-theme--header",
    width: 140,
    flex: 1,
    editable: false,
    renderCell: (params) => params.row.organization_name,
  },
  {
    field: "mail_type",
    headerName: "Тема письма",
    headerClassName: "super-app-theme--header",
    width: 140,
    flex: 1,
    editable: false,
    renderCell: (params) => {
      if (params.row.mail_type === EOutgoingMailType.APPROVE)
        return "Согласование";
      if (params.row.mail_type === EOutgoingMailType.REWORK)
        return "Уведомление";
      if (params.row.mail_type === EOutgoingMailType.DECLINE) return "Отказ";
    },
  },
  {
    field: "rework_period",
    headerName: "Срок доработки",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: false,
    renderCell: (params) => {
      const { color, text } = params.row.rework_period
        ? calculateDaysLeft(params.row.rework_period, false)
        : { text: "Some default text", color: "Some default color" };

      return (
        <div style={{ whiteSpace: "pre-line" }}>
          <Typography sx={{ color: fincolors.text.primary, fontSize: 14 }}>
            {params.row.rework_period}
          </Typography>

          {params.row.mail_type !== EOutgoingMailType.DECLINE && (
            <Typography variant="body2" sx={{ color }}>
              {text}
            </Typography>
          )}
        </div>
      );
    },
  },

  {
    field: "pdf",
    headerName: "Документ",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: false,
    renderCell: (params) =>
      !!params.row.pdf ? (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              getSignersDocument({ file_path: params.row.pdf })
                .then((data) => {
                  downloadFile(data, "Исходящее письмо.pdf", "application/pdf");
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                });
            }}
            disabled={false}
            sx={[
              {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "25px",
                height: "30px",
                backgroundColor: "#FFF",
                transitions: "all 0.5s",
              },
              {
                "&:hover": {
                  backgroundColor: "#A7ADBE",
                  transitions: "all 0.5s",
                  path: {
                    transitions: "all 0.5s",
                    fill: "#FFF",
                  },
                },
              },
            ]}
          >
            <Download
              style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
            />
          </Button>
        </Box>
      ) : (
        "Файл не найден"
      ),
  },
];
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  height: "auto",
  border: "2px solid #DFE2EC",
  borderRadius: "8px",
  overflow: "hidden",
  // maxWidth: "1130px",
  "& .MuiDataGrid-root": {
    borderRadius: "8px",
    overflow: "hidden",
    height: "auto",
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    backgroundColor: "#FFF",
  },
  "& .MuiDataGrid-overlay": {
    display: "none",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "14px",
    padding: "10px",
    //backgroundColor: "#FFF",
    height: "auto",
    overflow: "auto",
    textOverflow: "clip",
  },
  "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
    whiteSpace: "normal",
    height: "auto",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-row:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-colCellTitle": {
    color: "#111520",
    fontWeight: "medium",
    backgroundColor: "#DFE2EC",
    height: "40px",
  },

  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    overflow: "auto",
    lineHeight: "120%",
    whiteSpace: "normal",
    height: "100%",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },
}));

const TableMails: React.FC<ITable> = ({
  page,
  perPage,
  rows,
  rowCount,
  onChangePage,
  onChangePerPage,
}) => {
  return (
    <>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
        pageSizeOptions={[20, 10]}
        slots={{ noRowsOverlay: NoRowsOverlay }}
      />

      <Pagination
        rowCount={rowCount}
        rowsPerPage={perPage}
        currentPage={page}
        onPageChange={(event, page) => onChangePage(page + 1)}
        onRowsPerPageChange={(event) =>
          onChangePerPage(Number(event.target.value))
        }
      />
    </>
  );
};

export default TableMails;
