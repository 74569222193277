import { Link, useMatch } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ApartmentOutlined,
  FileCopyOutlined,
  MarkEmailUnreadOutlined,
  ErrorOutline,
  PersonOutlined,
} from "@mui/icons-material";
import React from "react";
import { ROLES } from "../utils/constants";

interface MenuItem {
  path: string;
  text: string;
  icon: React.ReactElement;
}

const menuItems: MenuItem[] = [
  { path: "/profile", text: "Профиль", icon: <ApartmentOutlined /> },
  { path: "/requests", text: "Мои запросы", icon: <FileCopyOutlined /> },
  {
    path: "/notifications",
    text: "Уведомления",
    icon: <MarkEmailUnreadOutlined />,
  },
  { path: "/faq", text: "Справочная информация/ЧАВО", icon: <ErrorOutline /> },
  { path: "/contacts", text: "Контакты", icon: <PersonOutlined /> },
];

const StudentMenuItem: React.FC<MenuItem> = ({ path, text, icon }) => {
  const match = useMatch({
    path: ROLES.student.path + path,
    end: path.length === 1,
  });

  return (
    <ListItem disablePadding>
      <ListItemButton
        //disabled={path !== "/declarations"}
        to={ROLES.student.path + path}
        component={Link}
        selected={Boolean(match)}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

const StudentMenu: React.FC = () => {
  return (
    <List
      sx={{
        width: "310px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {menuItems.map((item) => (
        <StudentMenuItem key={item.path} {...item} />
      ))}
    </List>
  );
};

export default StudentMenu;
