import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from "@mui/material";

interface ModalNotificationsByIdProps {
  open: boolean;
  onClose: () => void;
  id: number;
  fullText: string;
}

const ModalNotificationsById: React.FC<ModalNotificationsByIdProps> = ({
  open,
  onClose,
  fullText,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography>{fullText}</Typography>
        <Button onClick={onClose} color="primary" variant="contained">
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ModalNotificationsById;
