import { AxiosRequestConfig } from "axios";
import anAxiosApi from "../../services/axiosApi";
import { IApplication } from "../../types/application";
import { IDeclarationHistory } from "./types";

export const getDeclarationHistory = async (
  id: IApplication["id"],
  config?: AxiosRequestConfig,
) => {
  const response = await anAxiosApi.get<{ data: IDeclarationHistory }>(
    `/application/${id}/history`,
    config,
  );
  return response.data;
};
