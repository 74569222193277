import { ChangeEvent, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  Stack,
  TextField,
  InputLabel,
  Link,
  FormLabel,
} from "@mui/material";
import {
  Download,
  DownloadDoneOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import { FormikTouched, FormikErrors } from "formik";

import BoxCard from "../../../components/BoxCard";
import ClearIcon from "@mui/icons-material/Clear";
import {toast} from "react-toastify";

interface FormValues {
  signatory_name: string;
  signatory_official_number: string;
  organization_name: string;
  official_number: string;
  official_address: string;
  actual_address: string;
  email_address: string;
  phone_number: string;
  signatory_pdf: null | File | string;
}

interface FormikProp {
  setFieldValue(arg0: string, arg1: any): unknown;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
  values: FormValues;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
}

interface DeclarationStep1Props {
  formik: FormikProp;
  isDisabled: boolean;
  isInputDisabled: boolean;
  signatoryFileUrl: string;
  setSignatoryFileUrl: (url: string) => void;
}

const DeclarationStep1: React.FC<DeclarationStep1Props> = ({
  formik,
  isDisabled,
  isInputDisabled,
  signatoryFileUrl,
  setSignatoryFileUrl,
}) => {
  useEffect(() => {
    if (typeof formik.values.signatory_pdf === "string") {
      setSignatoryFileUrl(formik.values.signatory_pdf);
    }
  }, [formik.values.signatory_pdf]);

  const handleFileChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files?.[0];
    if (file && file.size <= 10 * 1024 * 1024) {
      formik.setFieldValue("signatory_pdf", file);
      const url = URL.createObjectURL(file);
      setSignatoryFileUrl(url);
    } else {
      toast.error("Пожалуйста, загрузите файлы меньше 10МБ");
      formik.setFieldValue("signatory_pdf", null);
      setSignatoryFileUrl("");
    }
  };

  const handleClearFile = () => {
    formik.setFieldValue("signatory_pdf", "");
    URL.revokeObjectURL(signatoryFileUrl);
    setSignatoryFileUrl("");

    // Сброс input элемента
    const input = document.getElementById("file-upload") as HTMLInputElement;
    if (input) input.value = "";
  };

  const handleLabelClick = () => {
    // Сброс input элемента
    const input = document.getElementById("file-upload") as HTMLInputElement;
    if (input) input.value = "";
  };

  //обработчики для полей БИН и ИИН
  const handleNumberChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = evt.target;
    if (/^[0-9]*$/.test(value)) {
      formik.setFieldValue(name, value);
    }
  };

  return (
    <form id="declarations_step_1" onSubmit={formik.handleSubmit}>
      <Stack direction={"column"} spacing={3}>
        <BoxCard title={"Сведения об организации"}>
          <Grid container spacing={2} sx={{ marginBottom: "24px" }}>
            <Grid item xs={4}>
              <TextField
                name="organization_name"
                value={formik.values.organization_name}
                label="Наименование частной организации"
                placeholder="Наименование частной организации"
                error={
                  Boolean(
                    formik?.touched?.organization_name &&
                      formik?.errors?.organization_name,
                  ) || false
                }
                helperText={
                  (formik?.touched?.organization_name &&
                    formik?.errors?.organization_name) ||
                  false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="official_number"
                value={formik.values.official_number}
                label="БИН"
                placeholder="БИН"
                inputProps={{
                  maxLength: 12,
                }}
                error={
                  Boolean(
                    formik?.touched?.official_number &&
                      formik?.errors?.official_number,
                  ) || false
                }
                helperText={
                  (formik?.touched?.official_number &&
                    formik?.errors?.official_number) ||
                  false
                }
                onChange={handleNumberChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                name="official_address"
                value={formik.values.official_address}
                label="Юридический адрес"
                placeholder="Юридический адрес"
                error={
                  Boolean(
                    formik?.touched?.official_address &&
                      formik?.errors?.official_address,
                  ) || false
                }
                helperText={
                  (formik?.touched?.official_address &&
                    formik?.errors?.official_address) ||
                  false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
              />
            </Grid>
            <Grid item xs={4} sx={{ marginBottom: "24px" }}>
              <TextField
                name="actual_address"
                value={formik.values.actual_address}
                label="Почтовый индекс"
                placeholder="Почтовый индекс"
                error={
                  Boolean(
                    formik?.touched?.actual_address &&
                      formik?.errors?.actual_address,
                  ) || false
                }
                helperText={
                  (formik?.touched?.actual_address &&
                    formik?.errors?.actual_address) ||
                  false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={4}>
              <TextField
                name="signatory_name"
                value={formik.values.signatory_name}
                label={
                  <span style={{ whiteSpace: "pre-line" }}>
                    ФИО подписанта (руководитель или представитель по
                    доверенности)
                  </span>
                }
                placeholder="ФИО подписанта"
                error={
                  Boolean(
                    formik?.touched?.signatory_name &&
                      formik?.errors?.signatory_name,
                  ) || false
                }
                helperText={
                  (formik?.touched?.signatory_name &&
                    formik?.errors?.signatory_name) ||
                  false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
                InputProps={{
                  style: {
                    position: "relative",
                  },
                }}
                sx={{
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="signatory_official_number"
                value={formik.values.signatory_official_number}
                label="ИИН подписанта"
                placeholder="ИИН подписанта"
                inputProps={{
                  maxLength: 12,
                }}
                error={
                  Boolean(
                    formik?.touched?.signatory_official_number &&
                      formik?.errors?.signatory_official_number,
                  ) || false
                }
                helperText={
                  (formik?.touched?.signatory_official_number &&
                    formik?.errors?.signatory_official_number) ||
                  false
                }
                onChange={handleNumberChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
                InputProps={{
                  style: {
                    position: "relative",
                  },
                }}
                sx={{
                  "& .MuiFormHelperText-root": {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <FormLabel sx={{ whiteSpace: "pre-line", width:"100%" }}>
                  Прикрепите доверенность, если подписант доверенное лицо
                </FormLabel>
                <Input
                  id="file-upload"
                  name="signatory_pdf"
                  type="file"
                  inputProps={{
                    accept: ".pdf, image/*",
                  }}
                  onChange={handleFileChange}
                  sx={{ display: "none" }}
                  disabled={isInputDisabled}
                />
                {!!formik.values.signatory_pdf || isInputDisabled ? (
                  <>
                    <Button
                      variant="contained"
                      //color="error"
                      // endIcon={<ClearIcon />}
                      // onClick={() => {
                      //   formik.setFieldValue("signatory_pdf", null);
                      // }}
                      // sx={{
                      //   marginTop: "23px",
                      //   height: "36px",
                      //   display: "flex",
                      //   gap: "10px",
                      //   cursor: "default",
                      //   ":hover": {
                      //     backgroundColor: "transparent",
                      //   },
                      // }}
                      sx={{
                        backgroundColor: "#FFF",
                        border: "1px solid #2C53F5",
                        color: "#2C53F5",
                        cursor: "default",
                        ":hover": {
                          backgroundColor: "#DFE2EC",
                        },
                        display: "flex",
                        // color: "#A7ADBE",
                      }}
                    >
                      Доверенность
                      {!!formik.values.signatory_pdf && (
                        <Link
                          href={signatoryFileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#FFF",
                              cursor:"pointer"
                            },
                          }}
                        >
                          <Download />
                        </Link>
                      )}
                      {!isInputDisabled && (
                        <Button
                          onClick={() => {
                            handleClearFile();
                          }}
                          variant="text"
                          sx={{
                            minWidth: "auto",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#FFF",
                              cursor:"pointer"
                            },
                          }}
                        >
                          <ClearIcon />
                        </Button>
                      )}
                    </Button>
                  </>
                ) : (
                  <label htmlFor="file-upload" onClick={handleLabelClick}>
                    <Button
                      variant="contained"
                      component="div"
                      // sx={[
                      //   {
                      //     backgroundColor: "#FFF",
                      //     display: "flex",
                      //     color: "#2C53F5",
                      //     marginTop: "23px",
                      //     border: "1px solid #2C53F5",
                      //     height: "36px",
                      //   },
                      //   {
                      //     "&:hover": {
                      //       color: "#FFF",
                      //     },
                      //   },
                      // ]}
                      sx={{
                        width: "100%",
                        backgroundColor: "#F4F6FA",
                        display: "flex",
                        alignItems:"center",
                        color: "#A7ADBE",
                        "&:hover": {
                          color: "#FFF",
                        },
                      }}
                      disabled={isInputDisabled}
                    >
                      <Box
                        component="div"
                        sx={{ marginLeft: "12px", display: "flex" }}
                      >
                        <Download /> Загрузить (макс 10 МВ)
                      </Box>
                    </Button>
                  </label>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </BoxCard>

        <BoxCard title={"Контактные данные"}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="email_address"
                value={formik.values.email_address}
                label="Электронная почта"
                placeholder="Электронная почта"
                error={
                  Boolean(
                    formik?.touched?.email_address &&
                      formik?.errors?.email_address,
                  ) || false
                }
                helperText={
                  (formik?.touched?.email_address &&
                    formik?.errors?.email_address) ||
                  false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="phone_number"
                value={formik.values.phone_number}
                label="Телефон"
                placeholder="Телефон"
                error={
                  Boolean(
                    formik?.touched?.phone_number &&
                      formik?.errors?.phone_number,
                  ) || false
                }
                helperText={
                  (formik?.touched?.phone_number &&
                    formik?.errors?.phone_number) ||
                  false
                }
                onChange={formik.handleChange}
                // onChange={(event) => {
                //     phoneMask.resolve(event.target.value as string);
                //     formik.setFieldValue("phone_number", phoneMask.value);
                // }}
                onBlur={formik.handleBlur}
                disabled={isInputDisabled}
              />
            </Grid>
          </Grid>
        </BoxCard>
      </Stack>
    </form>
  );
};

export default DeclarationStep1;
