import { Box, Stack, Typography, BoxProps } from "@mui/material";
import React, { ReactNode } from "react";

interface BoxTitleProps extends BoxProps {
  title: string;
  children?: ReactNode;
  downloadButton?: ReactNode;
}

const BoxTitle: React.FC<BoxTitleProps> = ({
  title,
  children,
  downloadButton,
}) => {
  return (
    <Box
      gap={"32px"}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: "32px",
      }}
    >
      <Typography variant="h1">
        {title}
        {downloadButton}
      </Typography>

      <Stack gap={"16px"} direction={"row"}>
        {children}
      </Stack>
    </Box>
  );
};

export default BoxTitle;
