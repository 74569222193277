//@ts-nocheck
import { TimelineOutlined } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BoxTitle from "../../components/BoxTitle";
import DeclarationHistory from "../../components/DeclarationHistory";
import LoadingBar from "../../components/LoadingBar";
import { EDS } from "../../components/Modals/EDS";
import {
  editDeclaration,
  getRevokeApplicationsDocument,
  getSchoolDeclarationsOne,
  getSignersDocument,
  previewSchoolDeclaration,
  revokeApplications,
  signDocument,
  storeApplicationPdf,
  storeReturnPdf,
} from "../../services/useEndpoint";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectBoardingPredictiveContingentDataTable,
  selectBoardingStudentsDataTable,
  selectPredictiveContingentDataTable,
  selectStudentsDataTable,
  setBoardingSchool,
} from "../../store/reducers/createDeclaration";
import { selectCurrentUserId } from "../../store/reducers/currentUserReducer";
import {
  setIsBoardingTouched,
  setIsBoardingValid,
  setIsSchoolTouched,
  setIsSchoolValid,
} from "../../store/reducers/validateCadastralInfoSlice";
import {
  selectInvalidCurrentRows,
  selectInvalidPredictiveRows,
} from "../../store/reducers/validateTablesSlice";
import { fincolors } from "../../styles/theme";
import {
  BREAKPOINT_DATES,
  CADASTRAL_NUMBER_REG_EXP,
} from "../../utils/constants";
import { downloadFile } from "../../utils/helpers";
import { NcaLayer } from "../../utils/nca-layer";
import DeclarationStep1 from "../SchoolDeclarationCreate/components/DeclarationStep1";
import DeclarationStep2, {
  TableRowsMini,
} from "../SchoolDeclarationCreate/components/DeclarationStep2";
import DeclarationStep3 from "../SchoolDeclarationCreate/components/DeclarationStep3";
import DeclarationStep4 from "../SchoolDeclarationCreate/components/DeclarationStep4";
import SuccsessModal from "../SchoolDeclarationCreate/components/SuccessModal";
import {
  validationSchemaStep1,
  validationSchemaStep2,
  validationSchemaStep3,
} from "../SchoolDeclarationCreate/schoolDeclarationYupSchema";

const steps = ["Данные по организации", "Данные по школе", "Документы"];

// TODO: Раздробить компонент
const SchoolTableRows: SchoolTableRows[] = [
  {
    id: 1,
    classes: "1-4",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 2,
    classes: "5-9",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 3,
    classes: "10-11",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 5,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 6,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 7,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
];
const SchoolTableRowsTwo: SchoolTableRows[] = [
  {
    id: 1,
    classes: "1-4",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 2,
    classes: "5-9",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 3,
    classes: "10-11",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 5,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 6,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
  {
    id: 7,
    classes: "",
    all_pupils: 0,
    common_pupils: 0,
    special_pupils: 0,
    correction_pupils: 0,
    home_pupils: 0,
  },
];
const BoardingTableRows: BoardingTableRows[] = [
  {
    id: 1,
    classSchool: "1-4",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 2,
    classSchool: "5-9",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 3,
    classSchool: "10-11",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },

  {
    id: 5,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 6,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 7,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 4,
    classSchool: "Итого",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
];
const BoardingTableRowsTwo: BoardingTableRows[] = [
  {
    id: 1,
    classSchool: "1-4",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 2,
    classSchool: "5-9",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 3,
    classSchool: "10-11",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },

  {
    id: 5,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 6,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 7,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 4,
    classSchool: "Итого",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
];
const rows: TableRowsMini[] = [
  {
    id: 1,
    target_value: "",
    encumbrance_date: "",
    cadastral_number: "",
  },
];

const urlMapping: { [key: number]: string } = {
  4: "rework_pdf",
  // 6: "accept_pdf",
  7: "decline_pdf",
  9: "return_pdf",
};

const statusName: { [key: number]: string } = {
  4: "Уведомление о несоответствии",
  // 6: "Уведомление об принятие",
  7: "Уведомление об отказе",
  9: "Письмо на отзыв",
};
const SchoolDeclarationsOne: React.FC = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [rowsTable, setRowsTable] = useState(SchoolTableRows);
  const [rowsTableAll, setRowsTableAll] = useState(SchoolTableRows);
  const [rowsTableTwo, setRowsTableTwo] = useState(SchoolTableRowsTwo);
  const [rowsTableTwoAll, setRowsTableTwoAll] = useState(SchoolTableRowsTwo);
  const [boardingRowsTable, setBoardingRowsTable] = useState(BoardingTableRows);
  // const [boardingRowsTableAll, setBoardingRowsTableAll] =
  //   useState(BoardingTableRows);
  const [boardingRowsTableTwo, setBoardingRowsTableTwo] =
    useState(BoardingTableRowsTwo);
  // const [boardingRowsTableTwoAll, setBoardingRowsTableTwoAll] =
  //   useState(BoardingTableRowsTwo);
  const [rowsTableMini, setRowsTableMini] = useState(rows);
  const [rowsTableMiniTwo, setRowsTableMiniTwo] = useState(rows);
  const [aCurrentStep, setCurrentStep] = useState<number>(0);
  const [, setIsStep2] = useState(false);
  const [, setIsStep3] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [pdf] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [declarationsData, setDeclarationsData] = useState(null);
  const [declarationsForm, setDeclarationsForm] = useState(null);
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadingPrewiewFile, setLoadingPreviewFile] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [EDSopen, setEDSopen] = useState(false);

  const resetIsSubmitted = () => {
    setIsSubmitted(false);
  };
  const [signatoryFileUrl, setSignatoryFileUrl] = useState("");
  const invalidCurrentRows = useAppSelector(selectInvalidCurrentRows);
  const invalidPredictiveRows = useAppSelector(selectInvalidPredictiveRows);
  const [signersDocumentLoading, setSignersDocumentLoading] = useState(false);
  const [
    signersDocumentLoadingFromStatus,
    setSignersDocumentLoadingFromStatus,
  ] = useState(false);

  const [revokeLoading, setRevokeLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isRevoke, setIsRevoke] = useState(false);

  const formikStep1 = useFormik({
    initialValues: {
      signatory_name: "",
      signatory_official_number: "",
      organization_name: "",
      official_number: "",
      official_address: "",
      actual_address: "",
      email_address: "",
      phone_number: "",
      signatory_pdf: null,
    },
    validationSchema: validationSchemaStep1,
  });

  const formikStep2 = useFormik({
    initialValues: {
      region_id: "",
      locality_id: "",
      area_id: "",
      tuition_fee: 0,
      // region: "",
      // city: "",
      // area: "",
      // district: "",
      region: null,
      area: null,
      district: null,
      locality: null,

      street: "",
      house_number: "",
      school_name: "",
      place_count: 0,
      exploitation_year: "10.10.1998",
      elementary_education: 0,
      basic_secondary_education: 0,
      basic_general_education: 0,
      integrated_education: 0,
      license_number: "",
      national_educational_db_registration: "Нет",
      state_procurements_registration: "Нет",
      boarding_school: "Нет",
      student_contingent: "Городская школа",
      private_organization_exploitation_type: "Строительство",
      private_organization_place_count: 0,
      boarding_school_government_order: 0,
      boarding_school_newly_count: 0,
      // boarding_school_region: "",
      // boarding_school_city: "",
      // boarding_school_area: "",
      // boarding_school_district: "",
      boarding_school_region: null,
      boarding_school_area: null,
      boarding_school_locality: null,
      boarding_school_street: "",
      boarding_school_house_number: "",
      boarding_school_place_count: 0,
      boarding_school_exploitation_year: "10.10.1998",
      boarding_school_exploitation_type: "Строительство",
      boarding_school_registered_immutability_agreement: 0,
      registered_immutability_agreement: 0,
      nobd_id: "",
    },
    validationSchema: validationSchemaStep2,
  });

  const formikStep3 = useFormik({
    initialValues: {
      email: "",
      private_organization_government_order: 1,
      education_licence: null,
      legal_entity_certificate: null,
      bank_certificate: null,
      preliminary_agreement: null,
      immutability_agreement: null,
      real_estate_certificate: null,
      project_documentation: null,
      exploitation_act: null,
      condition_conclusion: null,
      cadastral_information: null,
      tuition_fee_statement: null,
      integrated_education_realization_documents: null,
      status_id: 1,
      anti_terrorism_measures: null,
    },
    validationSchema: validationSchemaStep3,
  });
  useEffect(() => {
    getSchoolDeclarationsOne(id).then(({ data }) => {
      try {
        if (data) {
          setDeclarationsData(data);

          formikStep1.setValues({
            official_number: data.organization.official_number,
            official_address: data.organization.official_address,
            email_address: data.organization.email_address,
            phone_number: data.organization.phone_number,
            actual_address: data.organization.postcode,
            organization_name: data.organization.organization_name,
            signatory_name: data.signatory_name,
            signatory_pdf: data.signatory_pdf,
            signatory_official_number: data.signatory_official_number,
          });
          formikStep2.setValues(
            {
              tuition_fee: data?.tuition_fee,
              region: data?.region,
              city: data?.city,
              area: data?.area,
              district: data?.district,
              street: data?.street,
              house_number: data?.house_number,
              school_name: data?.school_name,
              place_count: data?.place_count,
              exploitation_year: data?.exploitation_year,
              elementary_education: Number(data?.elementary_education),
              basic_secondary_education: Number(
                data?.basic_secondary_education,
              ),
              basic_general_education: Number(data?.basic_general_education),
              integrated_education: Number(data?.integrated_education),
              national_educational_db_registration:
                data?.national_educational_db_registration,
              state_procurements_registration:
                data?.state_procurements_registration,
              boarding_school: data?.boarding_school,
              student_contingent: data?.student_contingent,
              private_organization_exploitation_type:
                data?.private_organization_exploitation_type,
              // private_organization_government_order:
              //   data?.private_organization_government_order,
              private_organization_place_count:
                data?.private_organization_place_count,
              // private_organization_exploitation_year:
              //   data?.private_organization_exploitation_year,
              boarding_school_government_order:
                data?.boarding_school_government_order,
              boarding_school_newly_count: data?.boarding_school_newly_count,
              nobd_id: data?.nobd_id,
              region_id: data?.region.id,
              locality_id: data?.locality.id,
              area_id: data?.area.id,

              boarding_school_region_id: data?.boarding_school_region.id,
              boarding_school_locality_id: data?.boarding_school_locality.id,
              boarding_school_area_id: data?.boarding_school_area.id,
              boarding_school_district_id: data?.boarding_school_district.id,

              boarding_school_street: data?.boarding_school_street,
              boarding_school_house_number: data?.boarding_school_house_number,
              boarding_school_place_count: data?.boarding_school_place_count,
              boarding_school_exploitation_year:
                data?.boarding_school_exploitation_year,
              boarding_school_exploitation_type:
                data?.boarding_school_exploitation_type,
              license_number: data?.license_number,
              registered_immutability_agreement:
                data?.registered_immutability_agreement ? 1 : 0,
              boarding_school_registered_immutability_agreement:
                data?.boarding_school_registered_immutability_agreement ? 1 : 0,
            },
            false,
          );

          dispatch(setBoardingSchool(data?.boarding_school));
          setRowsTable(JSON.parse(data?.students_data_table).splice(0, 3));
          setRowsTableTwo(
            JSON.parse(data?.predictive_contingent_data_table).splice(0, 3),
          );
          setBoardingRowsTable(
            JSON.parse(data?.boarding_school_students_data_table).splice(0, 4),
          );
          setBoardingRowsTableTwo(
            JSON.parse(
              data?.boarding_school_predictive_contingent_data_table,
            ).splice(0, 4),
          );

          data?.cadastral_table &&
            setRowsTableMini(JSON.parse(data?.cadastral_table));
          data?.boarding_school_cadastral_table &&
            setRowsTableMiniTwo(
              JSON.parse(data?.boarding_school_cadastral_table),
            );
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    });
  }, [id]);

  useEffect(() => {
    if (declarationsData) {
      formikStep1.setValues({
        official_number: declarationsData.organization.official_number,
        official_address: declarationsData.organization.official_address,
        email_address: declarationsData.organization.email_address,
        phone_number: declarationsData.organization.phone_number,
        actual_address: declarationsData.organization.postcode,
        organization_name: declarationsData.organization.organization_name,
        signatory_name: declarationsData.signatory_name,
        signatory_pdf: declarationsData.signatory_pdf,
        signatory_official_number: declarationsData.signatory_official_number,
      });
      formikStep2.setValues(
        {
          tuition_fee: declarationsData?.tuition_fee,
          region: declarationsData?.region,
          city: declarationsData?.city,
          area: declarationsData?.area,
          district: declarationsData?.district,
          locality: declarationsData?.locality,
          boarding_school_region: declarationsData?.boarding_school_region,
          boarding_school_area: declarationsData?.boarding_school_area,
          boarding_school_locality: declarationsData?.boarding_school_locality,
          street: declarationsData?.street,
          house_number: declarationsData?.house_number,
          school_name: declarationsData?.school_name,
          place_count: declarationsData?.place_count,
          exploitation_year: declarationsData?.exploitation_year,
          elementary_education: Number(declarationsData?.elementary_education),
          basic_secondary_education: Number(
            declarationsData?.basic_secondary_education,
          ),
          basic_general_education: Number(
            declarationsData?.basic_general_education,
          ),
          integrated_education: Number(declarationsData?.integrated_education),
          national_educational_db_registration:
            declarationsData?.national_educational_db_registration,
          state_procurements_registration:
            declarationsData?.state_procurements_registration,
          boarding_school: declarationsData?.boarding_school,
          student_contingent: declarationsData?.student_contingent,
          private_organization_exploitation_type:
            declarationsData?.private_organization_exploitation_type,
          // private_organization_government_order:
          //   data?.private_organization_government_order,
          private_organization_place_count:
            declarationsData?.private_organization_place_count,
          // private_organization_exploitation_year:
          //   data?.private_organization_exploitation_year,
          boarding_school_government_order:
            declarationsData?.boarding_school_government_order,
          boarding_school_newly_count:
            declarationsData?.boarding_school_newly_count,
          nobd_id: declarationsData?.nobd_id,
          region_id: declarationsData?.region.id,
          locality_id: declarationsData?.locality.id,
          area_id: declarationsData?.area.id,

          boarding_school_region_id:
            declarationsData?.boarding_school_region?.id,
          boarding_school_locality_id:
            declarationsData?.boarding_school_locality?.id,
          boarding_school_area_id: declarationsData?.boarding_school_area?.id,
          boarding_school_district_id:
            declarationsData?.boarding_school_district?.id,

          boarding_school_street: declarationsData?.boarding_school_street,
          boarding_school_house_number:
            declarationsData?.boarding_school_house_number,
          boarding_school_place_count:
            declarationsData?.boarding_school_place_count,
          boarding_school_exploitation_year:
            declarationsData?.boarding_school_exploitation_year,
          boarding_school_exploitation_type:
            declarationsData?.boarding_school_exploitation_type,
          license_number: declarationsData?.license_number,
          registered_immutability_agreement:
            declarationsData?.registered_immutability_agreement ? 1 : 0,
          boarding_school_registered_immutability_agreement:
            declarationsData?.boarding_school_registered_immutability_agreement
              ? 1
              : 0,
        },
        false,
      );

      dispatch(setBoardingSchool(declarationsData?.boarding_school));

      if (
        Number(declarationsData?.elementary_education) &&
        Number(declarationsData?.basic_secondary_education) &&
        Number(declarationsData?.basic_general_education)
      ) {
        setRowsTable(
          JSON.parse(declarationsData?.students_data_table).splice(0, 3),
        );
        setRowsTableTwo(
          JSON.parse(declarationsData?.predictive_contingent_data_table).splice(
            0,
            3,
          ),
        );
      } else if (
        (Number(declarationsData?.elementary_education) &&
          Number(declarationsData?.basic_secondary_education)) ||
        (Number(declarationsData?.basic_general_education) &&
          Number(declarationsData?.basic_secondary_education)) ||
        (Number(declarationsData?.basic_general_education) &&
          Number(declarationsData?.elementary_education))
      ) {
        setRowsTable(
          JSON.parse(declarationsData?.students_data_table).splice(0, 2),
        );
        setRowsTableTwo(
          JSON.parse(declarationsData?.predictive_contingent_data_table).splice(
            0,
            2,
          ),
        );
      } else if (
        Number(declarationsData?.elementary_education) ||
        Number(declarationsData?.basic_secondary_education) ||
        Number(declarationsData?.basic_general_education)
      ) {
        setRowsTable(
          JSON.parse(declarationsData?.students_data_table).splice(0, 1),
        );
        setRowsTableTwo(
          JSON.parse(declarationsData?.predictive_contingent_data_table).splice(
            0,
            1,
          ),
        );
      }

      setBoardingRowsTable(
        JSON.parse(
          declarationsData?.boarding_school_students_data_table,
        ).splice(0, 4),
      );
      setBoardingRowsTableTwo(
        JSON.parse(
          declarationsData?.boarding_school_predictive_contingent_data_table,
        ).splice(0, 4),
      );

      declarationsData?.cadastral_table &&
        setRowsTableMini(JSON.parse(declarationsData?.cadastral_table));
      declarationsData?.boarding_school_cadastral_table &&
        setRowsTableMiniTwo(
          JSON.parse(declarationsData?.boarding_school_cadastral_table),
        );
    }
  }, [declarationsData]);

  const handleClose = () => {
    setOpen(false);
    navigate("/school/declarations");
  };

  const predictiveContingentDataTable = useAppSelector(
    selectPredictiveContingentDataTable,
  );
  const boardingPredictiveContingentDataTable = useAppSelector(
    selectBoardingPredictiveContingentDataTable,
  );
  const studentsDataTable = useAppSelector(selectStudentsDataTable);
  const boardingStudentsDataTable = useAppSelector(
    selectBoardingStudentsDataTable,
  );

  useEffect(() => {
    formikStep3.setValues({
      email: declarationsData?.email,
      private_organization_government_order:
        declarationsData?.private_organization_government_order ? 1 : 0,
      education_licence: declarationsData?.education_licence,
      legal_entity_certificate: declarationsData?.legal_entity_certificate,
      bank_certificate: declarationsData?.bank_certificate,
      preliminary_agreement: declarationsData?.preliminary_agreement,
      anti_terrorism_measures: declarationsData?.anti_terrorism_measures,
      immutability_agreement: declarationsData?.immutability_agreement,
      real_estate_certificate: declarationsData?.real_estate_certificate,
      project_documentation: declarationsData?.project_documentation,
      exploitation_act: declarationsData?.exploitation_act,
      condition_conclusion: declarationsData?.condition_conclusion,
      cadastral_information: declarationsData?.cadastral_information,
      tuition_fee_statement: declarationsData?.tuition_fee_statement,
      integrated_education_realization_documents:
        declarationsData?.integrated_education_realization_documents,
      status_id: 1,
      boarding_school_cadastral_information:
        declarationsData?.boarding_school_cadastral_information,
      boarding_school_immutability_agreement:
        declarationsData?.boarding_school_immutability_agreement,
      boarding_school_project_documentation:
        declarationsData?.boarding_school_project_documentation,
      boarding_school_exploitation_act:
        declarationsData?.boarding_school_exploitation_act,
      boarding_school_condition_conclusion:
        declarationsData?.boarding_school_condition_conclusion,
      boarding_school_real_estate_certificate:
        declarationsData?.boarding_school_real_estate_certificate,
      boarding_school_cadastral_information:
        declarationsData?.boarding_school_cadastral_information,
    });
  }, [declarationsData]);
  //useEffect'ы для сброса значений и состояния touched в таблицах для ячеек с условным отображением

  useEffect(() => {
    formikStep2.setFieldTouched("boarding_school_region", false);
    formikStep2.setFieldTouched("boarding_school_city", false);
    formikStep2.setFieldTouched("boarding_school_area", false);
    formikStep2.setFieldTouched("boarding_school_street", false);
    formikStep2.setFieldTouched("boarding_school_district", false);
    formikStep2.setFieldTouched("boarding_school_house_number", false);

    formikStep2.setFieldTouched("boarding_school_exploitation_year", false);
  }, [formikStep2.values.boarding_school]);

  useEffect(() => {
    formikStep2.setFieldValue("private_organization_place_count", 0);
    formikStep2.setFieldTouched("private_organization_place_count", false);
  }, [formikStep2.values.exploitation_year]);

  useEffect(() => {
    formikStep2.setFieldValue("boarding_school_newly_count", 0);
    formikStep2.setFieldTouched("boarding_school_newly_count", false);
  }, []);

  useEffect(() => {
    const fields = [
      "education_licence",
      "bank_certificate",
      "preliminary_agreement",
      "project_documentation",
      "exploitation_act",
      "condition_conclusion",
      "real_estate_certificate",
      "cadastral_information",
      "integrated_education_realization_documents",
      "anti_terrorism_measures",
    ];
    fields.forEach((field) => {
      formikStep3.setFieldTouched(field, false);
    });

    if (!isInputDisabled) {
      fields.forEach((field) => {
        formikStep3.setFieldValue(field, null);
      });
    }
  }, [formikStep3.values.private_organization_government_order]);

  useEffect(() => {
    if (!isInputDisabled) {
      const fieldsValue = [
        "boarding_school_city",
        "boarding_school_area",
        "boarding_school_district",
        "boarding_school_street",
        "boarding_school_exploitation_year",
        "boarding_school_house_number",
      ];
      fieldsValue.forEach((field) => {
        formikStep2.setFieldValue(field, "");
      });
      formikStep2.setFieldValue("boarding_school_place_count", 0);
      formikStep2.setFieldValue(
        "boarding_school_exploitation_type",
        "Строительство",
      );
      formikStep2.setFieldValue(
        "boarding_school_region",
        "Акмолинская область",
      );

      const fieldsTouched = [
        "boarding_school_region",
        "boarding_school_city",
        "boarding_school_area",
        "boarding_school_street",
        "boarding_school_district",
        "boarding_school_exploitation_year",
        "boarding_school_place_count",
        "boarding_school_exploitation_type",
        "boarding_school_house_number",
      ];
      fieldsTouched.forEach((field) => {
        formikStep2.setFieldTouched(field, false);
      });

      if (formikStep2.values.boarding_school === "Нет") {
        setRowsTableMiniTwo(rows);
        setBoardingRowsTable(BoardingTableRows);
        setBoardingRowsTableTwo(BoardingTableRowsTwo);
      }
    }
  }, [formikStep2.values.boarding_school]);

  useEffect(() => {
    if (
      new Date(formikStep2?.values?.exploitation_year) <
      new Date(BREAKPOINT_DATES.school)
    ) {
      setRowsTableMini(rows);
    }
  }, [formikStep2.values.exploitation_year]);

  useEffect(() => {
    if (
      new Date(formikStep2.values.boarding_school_exploitation_year) <
      new Date(BREAKPOINT_DATES.boarding)
    ) {
      setRowsTableMiniTwo(rows);
      formikStep2.setFieldValue(
        "boarding_school_registered_immutability_agreement",
        0,
      );
    }
  }, [formikStep2.values.boarding_school_exploitation_year]);

  useEffect(() => {
    if (!isInputDisabled) {
      setRowsTableMiniTwo(rows);
      dispatch(setIsBoardingTouched(false));
    }
  }, [formikStep2.values.boarding_school_registered_immutability_agreement]);

  async function handleEDS(base64string: string) {
    try {
      return await NcaLayer.sign(base64string);
    } catch (error) {
      toast.error("При подписании документа произошла ошибка");
    }
  }

  const handleGetSignersDocument = () => {
    setSignersDocumentLoading(true);

    getSignersDocument({ file_path: declarationsData.pdf })
      .then((data) => {
        downloadFile(data, "Заявление.pdf", "application/pdf");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setSignersDocumentLoading(false);
      });
  };

  const handleGetSignersDocumentFromStatus = () => {
    setSignersDocumentLoadingFromStatus(true);

    if (
      statusName[declarationsData?.status?.id] ===
      "Уведомление о несоответствии"
    ) {
      getSignersDocument({ file_path: declarationsData.rework_pdf })
        .then((data) => {
          downloadFile(
            data,
            "Уведомление_о_несоответствии.pdf",
            "application/pdf",
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setSignersDocumentLoadingFromStatus(false);
        });
    }

    if (statusName[declarationsData?.status?.id] === "Уведомление об отказе") {
      getSignersDocument({ file_path: declarationsData.decline_pdf })
        .then((data) => {
          downloadFile(data, "Уведомление_об_отказе.pdf", "application/pdf");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setSignersDocumentLoadingFromStatus(false);
        });
    }
  };

  const handlers = {
    closeCreation: () => {
      if (declarationsData?.status.id === 4 && !isDisabled) {
        const userConfirmed = window.confirm(
          "Все заполненные данные удалятся. Вы уверены, что хотите продолжить?",
        );
        if (!userConfirmed) return false;
      }
      setCurrentStep(0);
      navigate("/school/declarations");
    },
    nextStep: (forceStep?: number) => {
      const step = forceStep ? forceStep : aCurrentStep + 1;
      step >= 3 ? setCurrentStep(3) : setCurrentStep(step);
    },
    touchAllFields: (form) => {
      form.setTouched(
        Object.keys(form.initialValues).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {}),
      );
    },
    validateAndHandleStep: async (currentForm) => {
      const errors = await currentForm.validateForm();

      if (Object.keys(errors).length) {
        toast.error("Пожалуйста, заполните все обязательные поля.");
        handlers.touchAllFields(currentForm);
        setIsSubmitted(!!errors._checkboxGroup);
        return;
      }
      if (currentForm === formikStep1) {
        setIsStep2(true);
        handlers.nextStep();
        return;
      }
      if (currentForm === formikStep2) {
        setIsStep3(true);
        //правка с приоритетом 2
        // if (
        //   currentForm.values.national_educational_db_registration === "Нет" ||
        //   currentForm.values.state_procurements_registration === "Нет" ||
        //   !currentForm.values.license_number
        // ) {
        //   toast.error(
        //     "Без регистрации в НОБД, на веб-портале и без лицензии на образовательную деятельность подать заявление на размещение государственного заказа невозможно!",
        //   );
        //   return
        // }
        if (
          formikStep2.values.national_educational_db_registration === "Нет" ||
          formikStep2.values.state_procurements_registration === "Нет" ||
          !formikStep2.values.nobd_id
        ) {
          toast.error(
            "Без регистрации в НОБД, на веб-портале и без лицензии на образовательную деятельность подать заявление на размещение государственного заказа невозможно!",
          );
          return;
        }
        if (
          Object.keys(invalidCurrentRows).length !== 0 ||
          Object.keys(invalidPredictiveRows).length !== 0
        ) {
          toast.error(
            "Сумма учащихся интернат не может быть больше числа учащихся в образовательном учреждении",
          );
          return;
        }
        if (
          // new Date(BREAKPOINT_DATES.school) <
          // new Date(formikStep2.values.exploitation_year)
          formikStep2.values.registered_immutability_agreement
        ) {
          const filteredRows = rowsTableMini.filter(
            (row) => row.id === 1 || !!row.cadastral_number,
          );
          const invalidRows = filteredRows.filter(
            (row) =>
              !CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) ||
              // &&  row.id === 1
              (CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) &&
                (!row.target_value || !row.encumbrance_date)),
          );
          setRowsTableMini(filteredRows);
          if (invalidRows.length) {
            toast.error("Пожалуйста, заполните обязательные поля.");
            dispatch(setIsSchoolValid(false));
            dispatch(setIsSchoolTouched(true));

            return;
          }
        }

        if (
          // new Date(BREAKPOINT_DATES.boarding) <
          // new Date(formikStep2.values.boarding_school_exploitation_year)
          formikStep2.values.boarding_school_registered_immutability_agreement
        ) {
          const filteredRows = rowsTableMiniTwo.filter(
            (row) => row.id === 1 || !!row.cadastral_number,
          );
          const invalidRows = filteredRows.filter(
            (row) =>
              !CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) ||
              // &&  row.id === 1
              (CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) &&
                (!row.target_value || !row.encumbrance_date)),
          );
          setRowsTableMiniTwo(filteredRows);
          if (invalidRows.length) {
            toast.error("Пожалуйста, заполните обязательные поля.");
            dispatch(setIsBoardingValid(false));
            dispatch(setIsBoardingTouched(true));

            return;
          }
        }

        handlers.nextStep();
        dispatch(setIsSchoolTouched(false));
        dispatch(setIsBoardingTouched(false));
      }
    },
    validateOnStep3: () => {
      formikStep3.validateForm().then((errors) => {
        if (Object.keys(errors).length) {
          toast.error("Пожалуйста, загрузите обязательные файлы.");
          formikStep3.setTouched(
            Object.keys(formikStep3.initialValues).reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {}),
          );
        } else {
          handlers.nextStep();
          const declarationFormData = new FormData();

          console.log("step2", formikStep2);

          //данные с первой формы "Данные по организации", aCurrentStep = 0
          if (formikStep1.values.signatory_pdf) {
            declarationFormData.append(
              "signatory_pdf",
              formikStep1.values.signatory_pdf,
            );
          } else {
            declarationFormData.append("signatory_pdf", "");
          }

          declarationFormData.append(
            "signatory_official_number",
            formikStep1.values.signatory_official_number,
          );
          declarationFormData.append(
            "signatory_name",
            formikStep1.values.signatory_name,
          );
          declarationFormData.append(
            "organization_fields[organization_name]",
            formikStep1.values.organization_name,
          );
          declarationFormData.append(
            "organization_fields[official_number]",
            formikStep1.values.official_number,
          );
          declarationFormData.append(
            "organization_fields[official_address]",
            formikStep1.values.official_address,
          );
          declarationFormData.append(
            "organization_fields[postcode]",
            formikStep1.values.actual_address,
          );
          declarationFormData.append(
            "organization_fields[email_address]",
            formikStep1.values.email_address,
          );
          declarationFormData.append(
            "organization_fields[phone_number]",
            formikStep1.values.phone_number,
          );
          declarationFormData.append("organization_id", currentUserId);
          declarationFormData.append(
            "tuition_fee",
            formikStep2.values.tuition_fee,
          );
          declarationFormData.append("region_id", formikStep2.values.region.id);
          declarationFormData.append("area_id", formikStep2.values.area.id);
          declarationFormData.append(
            "locality_id",
            formikStep2.values.locality.id,
          );
          // declarationFormData.append(
          //   "boarding_school_region_id",
          //   formikStep2.values.region_id,
          // );
          // declarationFormData.append(
          //   "boarding_school_area_id",
          //   formikStep2.values.area_id,
          // );
          // declarationFormData.append(
          //   "boarding_school_locality_id",
          //   formikStep2.values.locality_id,
          // );
          declarationFormData.append("street", formikStep2.values.street);
          declarationFormData.append(
            "house_number",
            formikStep2.values.house_number,
          );

          declarationFormData.append(
            "school_name",
            formikStep2.values.school_name,
          );
          declarationFormData.append(
            "place_count",
            formikStep2.values.place_count,
          );
          declarationFormData.append(
            "exploitation_year",
            formikStep2.values.exploitation_year,
          );
          declarationFormData.append(
            "registered_immutability_agreement",
            formikStep2.values.registered_immutability_agreement,
          );
          declarationFormData.append(
            "elementary_education",
            formikStep2.values.elementary_education,
          );
          declarationFormData.append(
            "basic_secondary_education",
            formikStep2.values.basic_secondary_education,
          );
          declarationFormData.append(
            "basic_general_education",
            formikStep2.values.basic_general_education,
          );
          declarationFormData.append(
            "integrated_education",
            formikStep2.values.integrated_education,
          );
          declarationFormData.append(
            "national_educational_db_registration",
            formikStep2.values.national_educational_db_registration,
          );
          declarationFormData.append(
            "state_procurements_registration",
            formikStep2.values.state_procurements_registration,
          );
          declarationFormData.append(
            "boarding_school",
            formikStep2.values.boarding_school,
          );
          declarationFormData.append(
            "student_contingent",
            formikStep2.values.student_contingent,
          );
          declarationFormData.append(
            "students_data_table",
            JSON.stringify(studentsDataTable),
          );
          declarationFormData.append(
            "predictive_contingent_data_table",
            JSON.stringify(predictiveContingentDataTable),
          );
          // if(formikStep2.values.boarding_school === "Есть"){
          //   declarationFormData.append(
          //     "boarding_school_students_data_table",
          //     JSON.stringify(boardingStudentsDataTable),
          //   );
          //   declarationFormData.append(
          //     "boarding_school_predictive_contingent_data_table",
          //     JSON.stringify(boardingPredictiveContingentDataTable),
          //   );
          // } else {
          //   declarationFormData.append(
          //     "boarding_school_students_data_table",
          //     JSON.stringify(BoardingTableRows),
          //   );
          //   declarationFormData.append(
          //     "boarding_school_students_data_table",
          //     JSON.stringify(BoardingTableRowsTwo),
          //   );
          // }
          declarationFormData.append(
            "boarding_school_students_data_table",
            JSON.stringify(boardingStudentsDataTable),
          );
          declarationFormData.append(
            "boarding_school_predictive_contingent_data_table",
            JSON.stringify(boardingPredictiveContingentDataTable),
          );
          declarationFormData.append(
            "private_organization_exploitation_type",
            formikStep2.values.private_organization_exploitation_type,
          );
          // declarationFormData.append(
          //   "private_organization_government_order",
          //   formikStep2.values.private_organization_government_order,
          // );
          declarationFormData.append(
            "private_organization_place_count",
            formikStep2.values.private_organization_place_count,
          );

          declarationFormData.append(
            "boarding_school_government_order",
            Number(formikStep2.values.boarding_school_government_order),
          );
          declarationFormData.append(
            "boarding_school_newly_count",
            Number(formikStep2.values.boarding_school_newly_count),
          );

          declarationFormData.append(
            "cadastral_table",
            JSON.stringify(rowsTableMini),
          );
          declarationFormData.append(
            "boarding_school_cadastral_table",
            JSON.stringify(rowsTableMiniTwo),
          );

          declarationFormData.append(
            "license_number",
            formikStep2.values.license_number,
          );
          //данные с третьей формы "Документы", aCurrentStep = 2
          declarationFormData.append(
            "private_organization_government_order",
            formikStep3.values.private_organization_government_order,
          );
          declarationFormData.append(
            "education_licence",
            formikStep3.values.education_licence !== null
              ? formikStep3.values.education_licence
              : "",
          ); //file

          declarationFormData.append(
            "legal_entity_certificate",
            formikStep3.values.legal_entity_certificate !== null
              ? formikStep3.values.legal_entity_certificate
              : "",
          ); // file

          declarationFormData.append(
            "bank_certificate",
            formikStep3.values.bank_certificate !== null
              ? formikStep3.values.bank_certificate
              : "",
          ); // file

          declarationFormData.append(
            "anti_terrorism_measures",
            formikStep3.values.anti_terrorism_measures !== null
              ? formikStep3.values.anti_terrorism_measures
              : "",
          ); //file

          declarationFormData.append(
            "preliminary_agreement",
            formikStep3.values.preliminary_agreement !== null
              ? formikStep3.values.preliminary_agreement
              : "",
          ); // file

          declarationFormData.append(
            "boarding_school_cadastral_information",
            formikStep3.values.boarding_school_cadastral_information !== null
              ? formikStep3.values.boarding_school_cadastral_information
              : "",
          ); // file

          declarationFormData.append(
            "immutability_agreement",
            formikStep3.values.immutability_agreement !== null
              ? formikStep3.values.immutability_agreement
              : "",
          ); // file

          declarationFormData.append(
            "boarding_school_immutability_agreement",
            formikStep3.values.boarding_school_immutability_agreement !== null
              ? formikStep3.values.boarding_school_immutability_agreement
              : "",
          ); // file

          declarationFormData.append(
            "real_estate_certificate",
            formikStep3.values.real_estate_certificate != null
              ? formikStep3.values.real_estate_certificate
              : "",
          ); // file

          declarationFormData.append(
            "boarding_school_real_estate_certificate",
            formikStep3.values.boarding_school_real_estate_certificate != null
              ? formikStep3.values.boarding_school_real_estate_certificate
              : "",
          ); // file

          declarationFormData.append(
            "project_documentation",
            formikStep3.values.project_documentation !== null
              ? formikStep3.values.project_documentation
              : "",
          ); // file

          declarationFormData.append(
            "boarding_school_project_documentation",
            formikStep3.values.boarding_school_project_documentation !== null
              ? formikStep3.values.boarding_school_project_documentation
              : "",
          ); // file

          declarationFormData.append(
            "exploitation_act",
            formikStep3.values.exploitation_act !== null
              ? formikStep3.values.exploitation_act
              : "",
          ); //file

          declarationFormData.append(
            "boarding_school_exploitation_act",
            formikStep3.values.boarding_school_exploitation_act !== null
              ? formikStep3.values.boarding_school_exploitation_act
              : "",
          ); //file

          declarationFormData.append(
            "condition_conclusion",
            formikStep3.values.condition_conclusion !== null
              ? formikStep3.values.condition_conclusion
              : "",
          ); //file

          declarationFormData.append(
            "boarding_school_condition_conclusion",
            formikStep3.values.boarding_school_condition_conclusion !== null
              ? formikStep3.values.boarding_school_condition_conclusion
              : "",
          ); //file

          declarationFormData.append(
            "cadastral_information",
            formikStep3.values.cadastral_information !== null
              ? formikStep3.values.cadastral_information
              : "",
          ); //file

          declarationFormData.append(
            "boarding_school_cadastral_information",
            formikStep3.values.boarding_school_cadastral_information !== null
              ? formikStep3.values.boarding_school_cadastral_information
              : "",
          ); //file

          declarationFormData.append(
            "tuition_fee_statement",
            formikStep3.values.tuition_fee_statement !== null
              ? formikStep3.values.tuition_fee_statement
              : "",
          ); //file
          if (
            formikStep3.values?.integrated_education_realization_documents &&
            formikStep3.values?.integrated_education_realization_documents
              ?.length &&
            typeof formikStep3.values
              ?.integrated_education_realization_documents !== "string"
          ) {
            Array.from(
              formikStep3.values.integrated_education_realization_documents,
            ).forEach((el) => {
              declarationFormData.append(
                "integrated_education_realization_documents[]",
                el,
              );
            });
          }

          declarationFormData.append("status_id", formikStep3.values.status_id);
          if (formikStep2.values.boarding_school_region_id) {
            declarationFormData.append(
              "boarding_school_region",
              formikStep2.values.boarding_school_region_id,
            );
          } else {
            declarationFormData.append("boarding_school_region_id", "");
          }

          if (formikStep2.values.boarding_school_area_id) {
            declarationFormData.append(
              "boarding_school_area_id",
              formikStep2.values.boarding_school_area_id,
            );
          } else {
            declarationFormData.append("boarding_school_area_id", "");
          }
          if (formikStep2.values.boarding_school_district_id) {
            declarationFormData.append(
              "boarding_school_district_id",
              formikStep2.values.boarding_school_district_id,
            );
          } else {
            declarationFormData.append("boarding_school_district_id", "");
          }
          if (formikStep2.values.boarding_school_locality_id) {
            declarationFormData.append(
              "boarding_school_locality_id",
              formikStep2.values.boarding_school_locality_id,
            );
          } else {
            declarationFormData.append("boarding_school_locality_id", "");
          }

          if (formikStep2.values.boarding_school_street) {
            declarationFormData.append(
              "boarding_school_street",
              formikStep2.values.boarding_school_street,
            );
          } else {
            declarationFormData.append("boarding_school_street", "");
          }

          if (formikStep2.values.boarding_school_house_number) {
            declarationFormData.append(
              "boarding_school_house_number",
              formikStep2.values.boarding_school_house_number,
            );
          } else {
            declarationFormData.append("boarding_school_house_number", "");
          }
          declarationFormData.append(
            "boarding_school_place_count",
            formikStep2.values.boarding_school_place_count,
          );

          if (formikStep2.values.boarding_school_exploitation_year) {
            declarationFormData.append(
              "boarding_school_exploitation_year",
              formikStep2.values.boarding_school_exploitation_year,
            );
          } else {
            declarationFormData.append("boarding_school_exploitation_year", "");
          }
          // declarationFormData.append(
          //   "boarding_school_exploitation_year",
          //   formikStep2.values.boarding_school_exploitation_year,
          // );
          declarationFormData.append(
            "boarding_school_exploitation_type",
            formikStep2.values.boarding_school_exploitation_type,
          );
          declarationFormData.append(
            "boarding_school_registered_immutability_agreement",
            formikStep2.values
              .boarding_school_registered_immutability_agreement,
          );
          declarationFormData.append("nobd_id", formikStep2.values.nobd_id);
          setLoadingPreviewFile(true);
          setDeclarationsForm(declarationFormData);

          try {
            console.log("declarationFormData", declarationFormData);
            previewSchoolDeclaration(declarationFormData).then((res) => {
              if (res) {
                toast.info(
                  "Если PDF файл не отображается, проверьте параметры отображения файлов в настройках браузера",
                );
                const data = new Blob([res], { type: "application/pdf" });
                setPdfUrl(window.URL.createObjectURL(data));

                setLoadingPreviewFile(false);
              } else {
                console.log("Data send failed. Please check your credentials.");
              }
            });
          } catch (error) {
            console.log(error);
            setLoadingPreviewFile(false);
          }
          // setEDSopen(true);
        }
      });
    },
    validateStep: async (formik, errorMessage) => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length) {
        toast.error(errorMessage);
        formik.setTouched(
          Object.keys(formik.values).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {}),
        );
        setIsSubmitted(!!errors._checkboxGroup);
        return false;
      }
      return true;
    },
    validateOnStep4: async () => {
      const isStep1Valid = await handlers.validateStep(
        formikStep1,
        'Пожалуйста, заполните обязательные поля в разделе "Данные по организации"',
      );

      const isTablesValid =
        Object.keys(invalidCurrentRows)?.length === 0 &&
        Object.keys(invalidPredictiveRows)?.length === 0;

      if (!isTablesValid) {
        toast.error(
          "Сумма учащихся интернат не может быть больше числа учащихся в образовательном учреждении",
        );
        return;
      }

      const isStep2Valid = await handlers.validateStep(
        formikStep2,
        'Пожалуйста, заполните обязательные поля в разделе "Данные по школе"',
      );

      if (
        // new Date(BREAKPOINT_DATES.school) <
        // new Date(formikStep2.values.exploitation_year)
        formikStep2.values.registered_immutability_agreement
      ) {
        const filteredRows = rowsTableMini.filter(
          (row) => row.id === 1 || !!row.cadastral_number,
        );
        const invalidRows = filteredRows.filter(
          (row) =>
            !CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) ||
            // &&  row.id === 1
            (CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) &&
              (!row.target_value || !row.encumbrance_date)),
        );
        setRowsTableMini(filteredRows);
        if (invalidRows.length) {
          toast.error(
            "Пожалуйста, заполните обязательные поля с кадастровой информацией по школе.",
          );
          dispatch(setIsSchoolValid(false));
          dispatch(setIsSchoolTouched(true));

          return;
        }
      }
      if (
        // new Date(BREAKPOINT_DATES.boarding) <
        // new Date(formikStep2.values.boarding_school_exploitation_year)
        formikStep2.values.boarding_school_registered_immutability_agreement
      ) {
        const filteredRows = rowsTableMiniTwo.filter(
          (row) => row.id === 1 || !!row.cadastral_number,
        );
        const invalidRows = filteredRows.filter(
          (row) =>
            !CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) ||
            // &&  row.id === 1
            (CADASTRAL_NUMBER_REG_EXP.test(row.cadastral_number) &&
              (!row.target_value || !row.encumbrance_date)),
        );
        setRowsTableMiniTwo(filteredRows);
        if (invalidRows.length) {
          toast.error(
            "Пожалуйста, заполните обязательные поля с кадастровой информацией по интернат.",
          );
          dispatch(setIsBoardingValid(false));
          dispatch(setIsBoardingTouched(true));

          return;
        }
      }

      if (isStep1Valid && isStep2Valid) {
        updateApplication();
      }
    },
    onClickCurrentPage: (index) => {
      if (isDisabled === true) {
        setCurrentStep(index);
      }
      setCurrentStep(index);
    },
  };

  const postRevokeApplications = async (id: number) => {
    try {
      setRevokeLoading(true);
      const response = await storeReturnPdf(id);
      const signature = await handleEDS(response.base64_content);
      if (!signature) return;

      await signDocument(signature, response.file_path);
      await revokeApplications(id, { return_pdf: response.file_path });
      toast.success("Заявления успешно отозвано");
      navigate("/school/declarations");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setRevokeLoading(false);
    }
  };

  const onClickRevokeApplication = (id: number) => {
    try {
      getRevokeApplicationsDocument(id).then((res) => {
        if (res) {
          toast.info(
            "Если PDF файл не отображается, проверьте параметры отображения файлов в настройках браузера",
          );
          const data1 = new Blob([res], { type: "application/pdf" });
          setIsRevoke(true);
          setPdfUrl(window.URL.createObjectURL(data1));
          setCurrentStep(3);
        } else {
          console.log("Data send failed. Please check your credentials.");
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingPreviewFile(false);
    }
  };

  const updateApplication = async () => {
    if (!declarationsForm) return;

    setIsLoading(true);

    try {
      const newData = new FormData();

      for (let [key, value] of declarationsForm.entries())
        newData.append(key, value);

      const response = await storeApplicationPdf(newData);
      declarationsForm.append("pdf", response.file_path);
      const signature = await handleEDS(response.base64_content);

      if (!signature) return;

      await signDocument(signature, response.file_path);
      await editDeclaration(declarationsData?.id, declarationsForm);
      toast.success("Заявление успешно доработано");
      navigate("/school/declarations");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          "Произошла ошибка при доработке заявки",
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let newSchoolRows = SchoolTableRows;
    let newSchoolRowsTwo = SchoolTableRowsTwo;
    let newBoardingSchoolRows = BoardingTableRows;
    let newBoardingSchoolRowsTwo = BoardingTableRowsTwo;
    if (formikStep2.values.elementary_education === 0) {
      newSchoolRows = newSchoolRows.filter((elem) => elem.id !== 1);
      newSchoolRowsTwo = newSchoolRowsTwo.filter((elem) => elem.id !== 1);
      newBoardingSchoolRows = newBoardingSchoolRows.filter(
        (elem) => elem.id !== 1,
      );
      newBoardingSchoolRowsTwo = newBoardingSchoolRowsTwo.filter(
        (elem) => elem.id !== 1,
      );
    }
    if (formikStep2.values.basic_secondary_education === 0) {
      newSchoolRows = newSchoolRows.filter((elem) => elem.id !== 2);
      newSchoolRowsTwo = newSchoolRowsTwo.filter((elem) => elem.id !== 2);
      newBoardingSchoolRows = newBoardingSchoolRows.filter(
        (elem) => elem.id !== 2,
      );
      newBoardingSchoolRowsTwo = newBoardingSchoolRowsTwo.filter(
        (elem) => elem.id !== 2,
      );
    }
    if (formikStep2.values.basic_general_education === 0) {
      newSchoolRows = newSchoolRows.filter((elem) => elem.id !== 3);
      newSchoolRowsTwo = newSchoolRowsTwo.filter((elem) => elem.id !== 3);
      newBoardingSchoolRows = newBoardingSchoolRows.filter(
        (elem) => elem.id !== 3,
      );
      newBoardingSchoolRowsTwo = newBoardingSchoolRowsTwo.filter(
        (elem) => elem.id !== 3,
      );
    }

    if (formikStep2.values.integrated_education === 0) {
      newSchoolRows = newSchoolRows.filter(
        (elem) => elem.id !== 5 && elem.id !== 6 && elem.id !== 7,
      );
      newSchoolRowsTwo = newSchoolRowsTwo.filter(
        (elem) => elem.id !== 5 && elem.id !== 6 && elem.id !== 7,
      );
      newBoardingSchoolRows = newBoardingSchoolRows.filter(
        (elem) => elem.id !== 5 && elem.id !== 6 && elem.id !== 7,
      );
      newBoardingSchoolRowsTwo = newBoardingSchoolRowsTwo.filter(
        (elem) => elem.id !== 5 && elem.id !== 6 && elem.id !== 7,
      );
    }
    setRowsTable(newSchoolRows);
    setRowsTableAll(newSchoolRows);
    setRowsTableTwo(newSchoolRowsTwo);
    setRowsTableTwoAll(newSchoolRowsTwo);
    setBoardingRowsTable(newBoardingSchoolRows);
    setBoardingRowsTableTwo(newBoardingSchoolRowsTwo);
  }, [
    formikStep2.values.elementary_education,
    formikStep2.values.basic_secondary_education,
    formikStep2.values.basic_general_education,
    formikStep2.values.boarding_school,
    formikStep2.values.integrated_education,
  ]);

  return (
    <>
      <Box>
        <Button
          variant="text"
          startIcon={<span>{"<"}</span>}
          onClick={() => handlers.closeCreation()}
        >
          Назад к заявлениям
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <BoxTitle
          title={
            "Заявление на размещение гос. образовательного заказа на среднее образование"
          }
          downloadButton={
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "flex-start",
                flexDirection: "column",
                mt: 2,
              }}
            >
              <Button
                variant={"outlined"}
                size={"small"}
                onClick={handleGetSignersDocument}
                disabled={signersDocumentLoading}
                startIcon={
                  <DownloadIcon
                    style={{ color: "#A7ADBE", width: "20px", height: "20px" }}
                  />
                }
              >
                Подписанное заявление
              </Button>

              <DeclarationHistory applicationId={declarationsData?.id}>
                {(open) => (
                  <Button
                    variant={"outlined"}
                    size={"small"}
                    onClick={open}
                    startIcon={
                      <TimelineOutlined
                        style={{
                          color: "#A7ADBE",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    }
                  >
                    История
                  </Button>
                )}
              </DeclarationHistory>

              {urlMapping[declarationsData?.status?.id] ? (
                <Button
                  variant={"outlined"}
                  size={"small"}
                  startIcon={
                    <DownloadIcon
                      style={{
                        color: "#A7ADBE",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  }
                  onClick={handleGetSignersDocumentFromStatus}
                  disabled={signersDocumentLoadingFromStatus}
                >
                  {statusName[declarationsData?.status?.id]}
                </Button>
              ) : null}
            </Box>
          }
        >
          {declarationsData?.status.id &&
          declarationsData?.status.id === 4 &&
          isDisabled === true ? (
            <Button
              sx={{ minWidth: "200px" }}
              onClick={() => {
                setIsDisabled(false);
                setIsInputDisabled(false);
              }}
            >
              Доработать
            </Button>
          ) : (
            ""
          )}
          {!isDisabled && (
            <>
              {(aCurrentStep === 0 || aCurrentStep === 1) && (
                <Button
                  sx={{ minWidth: "200px" }}
                  form={`declarations_step_${1 + aCurrentStep}`}
                  type="submit"
                  onClick={() => {
                    if (aCurrentStep === 0) {
                      handlers.validateAndHandleStep(formikStep1);
                    }
                    if (aCurrentStep === 1) {
                      handlers.validateAndHandleStep(formikStep2);
                    }
                  }}
                >
                  Продолжить
                </Button>
              )}

              {aCurrentStep === 2 && (
                <Button
                  sx={{ minWidth: "200px", whiteSpace: "nowrap" }}
                  form="declarations_step_3"
                  //type="submit"
                  onClick={() => {
                    handlers.validateOnStep3();
                  }}
                >
                  Сформировать заявление
                </Button>
              )}

              {aCurrentStep === 3 && (
                <Button
                  sx={{ minWidth: "200px", whiteSpace: "nowrap" }}
                  form="declarations_step_3"
                  // type="submit"
                  onClick={() => handlers.validateOnStep4()}
                  disabled={loadingPrewiewFile}
                >
                  Отправить
                </Button>
              )}
            </>
          )}

          {isRevoke && (
            <Button
              sx={{ minWidth: "200px" }}
              disabled={revokeLoading}
              onClick={() => postRevokeApplications(declarationsData?.id)}
            >
              Подписать
            </Button>
          )}

          {!declarationsData?.reworked &&
            !declarationsData?.been_returned &&
            declarationsData?.status.id === 1 &&
            !isRevoke && (
              <Button
                sx={{ minWidth: "200px" }}
                onClick={() => onClickRevokeApplication(declarationsData?.id)}
              >
                Отозвать заявление
              </Button>
            )}
        </BoxTitle>
        {/*<Box*/}
        {/*  sx={{ display: "flex", justifyContent: "end", mb: "20px", mt: -5 }}*/}
        {/*>*/}
        {/*  <SidebarHistoryDeclarations />*/}
        {/*</Box>*/}
      </Box>

      {/* <Box
        component="div"
        sx={{
          border: "1px solid #298FF5",
          backgroundColor: "#EAF4FE",
          padding: "12px 16px",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoIcon color="primary" />
        <Box component="div" sx={{ marginLeft: "12px" }}>
          Вы можете подавать только, если вы ранее не подали в первичный прием
          либо есть изменения к ранее поданной заявке, требующие корректировок в
          перечень.
        </Box>
      </Box> */}

      <Stack
        direction={"row"}
        sx={{
          background: fincolors.background.paper,
          // ":hover": "",
          borderRadius: "10px",
        }}
      >
        {steps.map((label, index) => {
          const isMe = index === +aCurrentStep;
          return (
            <Button
              key={index}
              sx={{
                width: "calc(100% / 3)",
                color: isMe ? "" : fincolors.text.primary,
                background: isMe ? "" : fincolors.background.paper,
                fontWeight: isMe ? 500 : 400,
              }}
              onClick={() => handlers.onClickCurrentPage(index)}
            >
              {label}
            </Button>
          );
        })}
      </Stack>

      {aCurrentStep === 0 ? (
        <DeclarationStep1
          formik={formikStep1}
          isDisabled={isDisabled}
          isInputDisabled={isInputDisabled}
          setSignatoryFileUrl={setSignatoryFileUrl}
          signatoryFileUrl={signatoryFileUrl}
        />
      ) : aCurrentStep === 1 ? (
        <DeclarationStep2
          formik={formikStep2}
          rowsTable={rowsTable}
          setRowsTable={setRowsTable}
          rowsTableAll={rowsTableAll}
          setRowsTableAll={setRowsTableAll}
          rowsTableTwo={rowsTableTwo}
          setRowsTableTwo={setRowsTableTwo}
          rowsTableTwoAll={rowsTableTwoAll}
          setRowsTableTwoAll={setRowsTableTwoAll}
          boardingRowsTable={boardingRowsTable}
          setBoardingRowsTable={setBoardingRowsTable}
          // boardingRowsTableAll={boardingRowsTableAll}
          // setBoardingRowsTableAll={setBoardingRowsTableAll}
          boardingRowsTableTwo={boardingRowsTableTwo}
          setBoardingRowsTableTwo={setBoardingRowsTableTwo}
          // boardingRowsTableTwoAll={boardingRowsTableTwoAll}
          // setBoardingRowsTableTwoAll={setBoardingRowsTableTwoAll}
          rowsTableMini={rowsTableMini}
          setRowsTableMini={setRowsTableMini}
          rowsTableMiniTwo={rowsTableMiniTwo}
          setRowsTableMiniTwo={setRowsTableMiniTwo}
          isDisabled={isDisabled}
          isInputDisabled={isInputDisabled}
          resetIsSubmitted={resetIsSubmitted}
          isSubmitted={isSubmitted}
        />
      ) : aCurrentStep === 2 ? (
        <DeclarationStep3
          formik={formikStep3}
          formik2Values={formikStep2.values}
          isDisabled={isDisabled}
          isInputDisabled={isInputDisabled}
          isCreation={false}
        />
      ) : aCurrentStep === 3 ? (
        <DeclarationStep4 isLoading={loadingPrewiewFile} pdfUrl={pdfUrl} />
      ) : null}
      <BoxTitle>
        {declarationsData?.status.id &&
        declarationsData?.status.id === 4 &&
        isDisabled === true ? (
          <Button
            sx={{ minWidth: "200px" }}
            onClick={() => {
              setIsDisabled(false);
              setIsInputDisabled(false);
            }}
          >
            Доработать
          </Button>
        ) : (
          ""
        )}
        {!isDisabled && (
          <>
            {(aCurrentStep === 0 || aCurrentStep === 1) && (
              <Button
                sx={{ minWidth: "200px" }}
                form={`declarations_step_${1 + aCurrentStep}`}
                type="submit"
                onClick={() => {
                  if (aCurrentStep === 0) {
                    handlers.validateAndHandleStep(formikStep1);
                  }
                  if (aCurrentStep === 1) {
                    handlers.validateAndHandleStep(formikStep2);
                  }
                }}
              >
                Продолжить
              </Button>
            )}
            {aCurrentStep === 2 && (
              <Button
                sx={{ minWidth: "200px", whiteSpace: "nowrap" }}
                form="declarations_step_3"
                //type="submit"
                onClick={() => {
                  handlers.validateOnStep3();
                }}
              >
                Сформировать заявление
              </Button>
            )}
            {aCurrentStep === 3 && (
              <Button
                sx={{ minWidth: "200px", whiteSpace: "nowrap" }}
                form="declarations_step_3"
                // type="submit"
                onClick={() => handlers.validateOnStep4()}
                disabled={loadingPrewiewFile}
              >
                Отправить
              </Button>
            )}
          </>
        )}
      </BoxTitle>

      <SuccsessModal open={open} pdf={pdf} onClose={handleClose} />

      <EDS open={EDSopen} submit={() => {}} onClose={() => setEDSopen(false)} />

      <LoadingBar
        open={isLoading}
        title={"Подождите, идет загрузка..."}
        onCancel={() => setIsLoading(false)}
      />
    </>
  );
};

export default SchoolDeclarationsOne;
