import { Button, Popover, Stack, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, useEffect } from "react";
import {
  getNotifications,
  postViewNotifications,
} from "../../../services/useEndpoint";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectNewNotificationsCount,
  selectShowAllNotifications,
  setShowAllNotifications,
} from "../../../store/reducers/notificationsSlice";
import theme, { fincolors } from "../../../styles/theme";
import NotificationsList from "./schools/NotificationsList";
interface SchoolNotificationsModalProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
}
const NotificationsModal: FC<SchoolNotificationsModalProps> = ({
  anchorEl,
  setAnchorEl,
}) => {
  const dispatch = useAppDispatch();
  const showAllNotifications = useAppSelector(selectShowAllNotifications);
  const newNotificationsCount = useAppSelector(selectNewNotificationsCount);
  const { data } = useQuery(["notificationsQuery"], () =>
    getNotifications({ page: 1 }),
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover1" : undefined;
  const mutation = useMutation({
    mutationFn: postViewNotifications,
  });
  useEffect(() => {
    if (data) {
      const requestData = {
        ids: [],
      };
      data?.notifications?.data?.map((el: any) => {
        if (el?.id) {
          // @ts-ignore
          requestData.ids.push(el?.id);
        }
      });
      // @ts-ignore
      requestData.ids.length && mutation.mutateAsync(requestData);
    }
  }, [showAllNotifications]);
  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      dispatch(setShowAllNotifications(false));
    }, 100);
  };

  const handleClick = () => {
    dispatch(setShowAllNotifications(true));
  };

  return (
    <Popover
      disablePortal={true}
      id={id}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      //   aria-labelledby="alert-dialog-title"
      //   aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "394px",
        }}
      >
        <Stack direction="row" gap="8px" padding="16px">
          <Typography sx={{ fontSize: theme.typography.h3.fontSize }}>
            Уведомления
          </Typography>
          {newNotificationsCount !== 0 && (
            <Typography
              sx={{
                color: fincolors.primary.contrastText,
                background: fincolors.error.main,
                fontSize: theme.typography.h5,
                borderRadius: "12px",
                paddingLeft: "8px",
                paddingRight: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              +{newNotificationsCount} новых
            </Typography>
          )}
        </Stack>

        <NotificationsList />

        {newNotificationsCount > 0 && (
          <Button
            variant="outlined"
            sx={{
              display: "flex",
              height: "36px",
              marginX: "26px",
              marginY: "25px ",
            }}
            onClick={() => {
              showAllNotifications ? handleClose() : handleClick();
            }}
          >
            {showAllNotifications ? "Закрыть" : "Посмотреть все"}
          </Button>
        )}
      </Stack>
    </Popover>
  );
};

export default NotificationsModal;
