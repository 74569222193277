import { getRemarkTypes } from "../services/useEndpoint";
import { fincolors } from "../styles/theme";
import { IApplication } from "../types/application";
import { remarkType } from "../types/modalTypes";
import { DEPARTMENT_DIRECTOR_CODE, ROLES, STATUS_ID } from "./constants";

/**
 * downloadFile
 * @param file
 * @param name
 * @param type
 */
export function downloadFile(
  file: Blob,
  name: string,
  type: BlobPropertyBag["type"],
) {
  const url = URL.createObjectURL(new Blob([file], { type }));
  const link = document.createElement("a");

  link.href = url;
  link.download = name;
  link.click();
  URL.revokeObjectURL(url);
}

/**
 * getRandomString
 * @param length
 * @returns string
 */
export function getRandomString(
  length: number,
  includeSpecialCharacters = false,
): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const specialCharacters = "!@#$%^&*()-_=+[]{}|;:,.<>?";
  const availableCharacters = includeSpecialCharacters
    ? characters + specialCharacters
    : characters;

  return Array.from(
    crypto.getRandomValues(new Uint32Array(length)),
    (value) => availableCharacters[value % availableCharacters.length],
  ).join("");
}

export const redirectByRole = (roleId: number) => {
  switch (roleId) {
    case ROLES.school.id:
      return ROLES.school.path;

    //АПД
    case ROLES.directorApd.id:
      return ROLES.directorApd.path;
    case ROLES.mainSpecialistApd.id:
      return ROLES.mainSpecialistApd.path;
    case ROLES.specialistApd.id:
      return ROLES.specialistApd.path;

    //ДБФ
    case ROLES.directorDbf.id:
      return ROLES.directorDbf.path;
    case ROLES.mainSpecialistDbf.id:
      return ROLES.mainSpecialistDbf.path;
    case ROLES.specialistDbf.id:
      return ROLES.specialistDbf.path;

    //инвестор
    case ROLES.investor.id:
      return ROLES.investor.path;

    //студент
    case ROLES.student.id:
      return ROLES.student.path;
    default:
      return "/auth/sign-in";
  }
};
export const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File(
    [theBlob as any], // cast as any
    fileName,
    {
      lastModified: new Date().getTime(),
      type: "application/pdf",
    },
  );
};

//helpers  для полей таблиц с заявлениями
export const getDayWord = (days: number) => {
  if (days === 1) {
    return "день";
  } else if (days >= 2 && days <= 4) {
    return "дня";
  } else {
    return "дней";
  }
};

export const calculateDaysLeft = (
  expiryDateString: string,
  isFirstTime: boolean,
) => {
  const expiryDate = new Date(expiryDateString.split(".").reverse().join("-"));

  const currentDate = new Date();
  const timeDiff = expiryDate.getTime() - currentDate.getTime();
  const daysLeft = timeDiff > 0 ? Math.ceil(timeDiff / (1000 * 3600 * 24)) : 0;
  const dayWord = getDayWord(daysLeft);
  const color = daysLeft <= 1 ? "red" : "inherit";

  return {
    text: `осталось ${daysLeft} к. ${dayWord}`,
    color: color,
  };
};

//для опеределения текущего департамента специалиста
export const getCurrentDepartment = (departmentCode: string) =>
  departmentCode === DEPARTMENT_DIRECTOR_CODE.apd ? "apd" : "dbf";

//для отображения типов замечаний на страницах заявок
export const displayCommentTypes = async (commentTypes: Array<number>) => {
  let types: remarkType[] = [];
  try {
    types = await getRemarkTypes();
  } catch (error) {
    console.log(error);
  }
  const selectedTypes = new Set(commentTypes);
  const typeNames = types.reduce((acc: Array<string>, type: remarkType) => {
    if (selectedTypes.has(type.id)) {
      acc.push(type.short_text);
    }
    return acc;
  }, []);

  return typeNames;
};

export const getUnassignedStyle = (row: IApplication) => {
  const isUnassigned =
    !row.current_executor?.organization_name &&
    row.status.id !== STATUS_ID.approved &&
    row.status.id !== STATUS_ID.rejected &&
    row.status.id !== STATUS_ID.onExecutorRework;

  return isUnassigned ? { fontWeight: "900" } : {};
};

export const getStatusColor = (statusId: number) => {
  switch (statusId) {
    case STATUS_ID.approved:
      return "#2DB77B";
    case STATUS_ID.rejected:
      return fincolors.error.main;
    case STATUS_ID.onSchoolRework:
      return "#FE8C1B";
    case STATUS_ID.new:
      return "#0E70C7";
    default:
      return "transparent";
  }
};

export function extractFileNameAndExtension(url: string): {
  name: string;
  extension: string;
} {
  const matches = url.match(/\/([^\\/]+)\.([a-zA-Z0-9]+)$/);
  if (!matches) {
    throw new Error("Invalid URL format");
  }
  return { name: matches[1], extension: matches[2] };
}
