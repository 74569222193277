import { FC, useEffect, useState } from "react";
import TableComponentNotifications from "../../components/TableComponentNotifications";

import { Box } from "@mui/material";
import BoxTitle from "../../components/BoxTitle";
import useNotificationData from "../../hooks/useNotificationsData";

export type SchoolNotificationsState = {
  id: number;
  checkbox: boolean;
  application_id: number;
  short_text: string;
  created_at: string;
  full_text: string;
};

const SchoolNotifications: FC = () => {
  // const { data } = useQuery(["notificationsQuery"], () => getNotifications(1));
  const { data, totalCount, lastPage } = useNotificationData();
  const [rows, setRows] = useState<SchoolNotificationsState[]>([]);

  useEffect(() => {
    if (data && data.length > 0) {
      // @ts-ignore
      const rowsData: SchoolNotificationsState[] = data?.map((obj: any) => ({
        id: obj.id,
        // checkbox: false,
        application_id: obj.application_id,
        short_text: obj.short_text,
        created_at: obj.created_at,
        full_text: obj.full_text,
      }));
      console.log("rowsData = ", rowsData);
      setRows(rowsData);
    }
  }, [totalCount, data]);

  console.log("data.notifications = ", rows);

  return (
    <div style={{ padding: "40px 20px", maxWidth: "1130px" }}>
      <BoxTitle title={"Уведомления"}></BoxTitle>

      <Box>
        <TableComponentNotifications rows={rows} rowCount={totalCount} />
      </Box>
    </div>
  );
};

export default SchoolNotifications;
