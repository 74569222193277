import { AxiosRequestConfig } from "axios";
import anAxiosApi from "../../services/axiosApi";
import { IApplication } from "../../types/application";
import {
  ICreateApplicationEnumeration,
  ISignApplicationEnumeration,
} from "./types";

export const getApplicationsEnumeration = async (
  config?: AxiosRequestConfig,
) => {
  const response = await anAxiosApi.get("/application-list", config);
  return response.data;
};

export const createApplicationEnumeration = async (
  data: ICreateApplicationEnumeration,
) => {
  const response = await anAxiosApi.post("/application-list", data);
  return response.data;
};

export const signApplicationEnumeration = async (
  id: IApplication["id"],
  data: ISignApplicationEnumeration,
) => {
  const response = await anAxiosApi.post(`/application-list/${id}/sign`, data);
  return response.data;
};

export const deleteApplicationEnumeration = async (id: IApplication["id"]) => {
  const response = await anAxiosApi.delete(`/application-list/${id}`);
  return response.data;
};
