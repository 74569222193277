import { ChangeEvent, FC, useState } from "react";
import {  Button, Stack, TextField, Typography } from "@mui/material";
import BoxCard from "../../../components/BoxCard";
import {
  ModeEditOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

interface Props {
  values: {
    login: string;
    password: string;
  };
  touched: {
    login?: boolean;
    password?: boolean;
  };
  errors: {
    login?: string;
    password?: string;
  };
  // editMode: number;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  validateAndSubmit: () => void;
  resetForm: () => void;
  editLoginDetailsMode: boolean;
  setEditLoginDetailsMode: (mode: boolean) => void;
}

const LoginAndPassword: FC<Props> = ({
  values,
  touched,
  errors,
  // editMode,
  handleChange,
  validateAndSubmit,
  editLoginDetailsMode,
  setEditLoginDetailsMode,
  resetForm,
}) => {
  const [isPasswordView, setIsPasswordView] = useState<boolean>(false);
  const handlers = {
    changeEditMode: () => {
      if (editLoginDetailsMode) resetForm();
      setEditLoginDetailsMode(!editLoginDetailsMode);
    },
    saveNewLoginDetails: () => {
      validateAndSubmit();
    },
  };

  return (
    <>
      <BoxCard>
        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              textTransform: "uppercase",
            }}
          >
            Логин и пароль
          </Typography>
          {!editLoginDetailsMode && (
            <Button
              variant="outlined"
              sx={{ height: "36px" }}
              onClick={handlers.changeEditMode}
            >
              <ModeEditOutline /> Изменить пароль
            </Button>
          )}
        </Stack>

        <Stack direction={"row"} gap={2} sx={{ marginBottom: "24px" }}>
          <TextField
            name="login"
            value={values.login}
            label="Логин"
            placeholder="Введите логин"
            error={Boolean(touched.login && errors.login) || false}
            helperText={(touched.login && errors.login) || false}
            onChange={handleChange}
            disabled={!editLoginDetailsMode}
          />

          <TextField
            name="password"
            value={values.password}
            type={isPasswordView ? "text" : "password"}
            label="Пароль"
            placeholder="Введите пароль"
            error={Boolean(touched.password && errors.password) || false}
            helperText={(touched.password && errors.password) || false}
            onChange={handleChange}
            disabled={!editLoginDetailsMode}
            InputProps={{
              endAdornment: (
                <Button
                  onClick={() => setIsPasswordView(!isPasswordView)}
                  variant="text"
                >
                  {!isPasswordView ? <Visibility /> : <VisibilityOff />}
                </Button>
              ),
            }}
          />
        </Stack>

        {editLoginDetailsMode && (
          <Stack direction={"row"} gap={2}>
            <Button
              variant="outlined"
              onClick={handlers.changeEditMode}
              sx={{ height: "36px", width: "208px" }}
            >
              Отменить
            </Button>
            <Button
              onClick={() => {
                handlers.saveNewLoginDetails();
              }}
              sx={{ height: "36px", width: "208px" }}
            >
              Сохранить
            </Button>
          </Stack>
        )}
      </BoxCard>
    </>
  );
};

export default LoginAndPassword;
