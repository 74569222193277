import { useEffect, useState, useRef } from "react";
import { debounce } from "lodash";

export interface DebouncedProps<TValue> {
  value: TValue;
  delay?: number;
  onChange(debouncedValue: TValue): void;
  children(value: TValue, onChange: (value: TValue) => void): React.ReactNode;
}

const Debounced = <TValue,>({
  value,
  delay = 500,
  onChange,
  children,
}: DebouncedProps<TValue>) => {
  const [data, setData] = useState(value);

  const debouncedOnChangeRef = useRef(
    debounce((nextValue: TValue) => onChange(nextValue), delay),
  );

  useEffect(() => {
    debouncedOnChangeRef.current = debounce(
      (nextValue: TValue) => onChange(nextValue),
      delay,
    );
    return () => {
      debouncedOnChangeRef.current.cancel();
    };
  }, [onChange, delay]);

  const handleChange = (nextValue: TValue) => {
    setData(nextValue);
    debouncedOnChangeRef.current(nextValue);
  };

  useEffect(() => {
    setData(value);
  }, [value]);

  return <>{children(data, handleChange)}</>;
};

export default Debounced;
