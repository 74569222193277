import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { BoardingTableRows, SchoolTableRows } from "../../types/tableRowsTypes";

export interface SchoolPupilsData {
  classes: string;
  all_pupils: string;
  common_pupils: string;
  special_pupils: string;
  correction_pupils: string;
  home_pupils: string;
}

interface SchoolDectarationInterface {
  organization_id: number | null; //need
  signatory_pdf: any | null;
  tuition_fee: number | null;
  elementary_education: number | null;
  basic_secondary_education: number | null;
  basic_general_education: number | null;
  national_educational_db_registration: string | null;
  state_procurements_registration: string | null;
  boarding_school: string | null;
  student_contingent: string | null;
  students_data_table: SchoolTableRows[] | null; //need
  boarding_students_data_table: BoardingTableRows[] | null; //need
  predictive_contingent_data_table: SchoolTableRows[] | null; //need
  boarding_predictive_contingent_data_table: BoardingTableRows[] | null; //need
  // region: string | null;
  // city: string | null;
  // area: string | null;
  // street: string | null;
  region_id: null | number;
  area_id: null | number;
  locality_id: null | number;

  school_name: string | null;
  place_count: number | string | null;
  exploitation_year: string | number | null;
  private_organization_place_count: number | string | null;
  private_organization_exploitation_type: string | null;
  private_organization_place_live_count: number | string | null;
  private_organization_exploitation_live_type: string | null;
  // private_organization_exploitation_year: string | number | null;
  // private_organization_government_order: boolean | string | null; //need
  education_licence: any | File;
  legal_entity_certificate: any | File;
  bank_certificate: any;
  anti_terrorism_measures: any | File;
  preliminary_agreement: any | File;
  immutability_agreement: any | File;
  real_estate_certificate: any | File;
  status_id: number | string | null;
  integrated_education: boolean | null; //need
  target_value: string | null; //need
  encumbrance_date: string | null; //need
  cadastral_number: string; //need
  boarding_target_value: string | null; //need
  boarding_encumbrance_date: string | null; //need
  boarding_cadastral_number: string; //need
  private_organization_government_order: number | boolean;
}
// "access_token": "36|V0e2pJZvr3ZUv7nHhlkYUof5horJ3hbVmJXfcyxR"
// }

const initialState = {
  schoolDeclaration: {
    //дополнительные строки, которых нет и которые надо добавить
    organization_id: null,
    students_data_table: null,
    boarding_students_data_table: null,
    boarding_predictive_contingent_data_table: null,
    predictive_contingent_data_table: null,

    // private_organization_government_order: null, // = done
    integrated_education: null, // = done
    target_value: null,
    encumbrance_date: null,
    cadastral_number: "",
    boarding_target_value: null,
    boarding_encumbrance_date: null,
    boarding_cadastral_number: "",
    region_id: null,
    area_id: null,
    locality_id: null,
    // signer_fullname: '',
    // signer_official_number: '',
    // organization_name: '',
    // official_number: '',
    // official_address: '',
    // actual_address: '',
    // email_address: '',
    // phone_number: '',
    signatory_pdf: null, //done
    tuition_fee: 0, //done
    // organization_name: 'fsssssssssssssssssssm',
    // region: "", //done
    // city: "", //done
    // area: "", //done
    // street: "", //done

    school_name: "", //done
    place_count: 0, //done
    exploitation_year: "", //done
    elementary_education: null, //done
    basic_secondary_education: null, //done
    basic_general_education: null, //done
    // isAccreditationEducation: false,
    national_educational_db_registration: "Нет", //done
    state_procurements_registration: "Нет", //done
    boarding_school: "Нет", //done
    student_contingent: "Городская школа", //done
    private_organization_exploitation_type: "Строительство", //done
    private_organization_exploitation_live_type: "Не размещался", //done
    // educational_order: 'Нет',
    private_organization_place_count: 0, //done
    private_organization_place_live_count: 0,
    // private_organization_exploitation_year: "", //done
    // email: '',
    // passwordTEST: '',
    private_organization_government_order: 1,
    education_licence: null, //done
    legal_entity_certificate: null, //done
    bank_certificate: null, //done
    preliminary_agreement: null, //done
    immutability_agreement: null, //done
    real_estate_certificate: null, //done
    status_id: 1, //done
  } as SchoolDectarationInterface,
};

export const createDeclarationSlice = createSlice({
  name: "createDeclarationSlice",
  initialState,
  reducers: {
    setAllSchoolDeclarationData: (state, action) => {
      state.schoolDeclaration = action.payload;
    },
    setCadastralNumber: (state, action) => {
      state.schoolDeclaration.cadastral_number = action.payload;
    },
    setEncumbranceDate: (state, action) => {
      state.schoolDeclaration.encumbrance_date = action.payload;
    },
    setTargetValue: (state, action) => {
      state.schoolDeclaration.target_value = action.payload;
    },
    setBoardingCadastralNumber: (state, action) => {
      state.schoolDeclaration.boarding_cadastral_number = action.payload;
    },
    setBoardingEncumbranceDate: (state, action) => {
      state.schoolDeclaration.boarding_encumbrance_date = action.payload;
    },
    setBoardingTargetValue: (state, action) => {
      state.schoolDeclaration.boarding_target_value = action.payload;
    },
    setPredictiveContingentDataTable: (state, action) => {
      state.schoolDeclaration.predictive_contingent_data_table = action.payload;
    },
    setStudentsDataTable: (state, action) => {
      state.schoolDeclaration.students_data_table = action.payload;
    },
    setBoardingStudentsDataTable: (state, action) => {
      state.schoolDeclaration.boarding_students_data_table = action.payload;
    },
    setBoardingPredictiveContingentDataTable: (state, action) => {
      state.schoolDeclaration.boarding_predictive_contingent_data_table =
        action.payload;
    },
    setBoardingSchool: (state, action) => {
      state.schoolDeclaration.boarding_school = action.payload;
    },
  },
});

export const selectCadastralNumber = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration.cadastral_number;
export const selectEncumbranceDate = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration.encumbrance_date;
export const selectTargetValue = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration.target_value;
export const selectBoardingCadastralNumber = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration.boarding_cadastral_number;
export const selectBoardingEncumbranceDate = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration.boarding_encumbrance_date;
export const selectBoardingTargetValue = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration.boarding_target_value;
export const selectPredictiveContingentDataTable = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration
    .predictive_contingent_data_table;
export const selectStudentsDataTable = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration.students_data_table;
export const selectBoardingPredictiveContingentDataTable = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration
    .boarding_predictive_contingent_data_table;
export const selectBoardingStudentsDataTable = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration.boarding_students_data_table;
export const selectBoardingSchool = (state: RootState) =>
  state.createDeclarationSlice.schoolDeclaration.boarding_school;

export const {
  setAllSchoolDeclarationData,
  setCadastralNumber,
  setEncumbranceDate,
  setTargetValue,
  setBoardingCadastralNumber,
  setBoardingEncumbranceDate,
  setBoardingTargetValue,
  setPredictiveContingentDataTable,
  setStudentsDataTable,
  setBoardingStudentsDataTable,
  setBoardingPredictiveContingentDataTable,
  setBoardingSchool,
} = createDeclarationSlice.actions;

export default createDeclarationSlice.reducer;
