import { useState } from "react";
import { toast } from "react-toastify";
import ConfirmDialog, {
  ConfirmDialogProps,
} from "../../../components/ConfirmDialog";
import { deleteApplicationEnumeration } from "../api";
import { IApplicationEnumeration } from "../types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface DeleteEnumerationActionProps
  extends Omit<ConfirmDialogProps, "onClose" | "open" | "children"> {
  enumerationId: IApplicationEnumeration["id"];
  children(onOpen: () => void): React.ReactNode;
  onDelete?(): void;
}

const DeleteEnumerationAction = ({
  enumerationId,
  children,
  onDelete,
  ...props
}: DeleteEnumerationActionProps) => {
  const [opened, setOpened] = useState(false);
  const queryClient = useQueryClient();

  const deleteEnumerationMutation = useMutation({
    mutationKey: ["deleteEnumeration"],
    mutationFn: deleteApplicationEnumeration,
    onSuccess: () => {
      toast.success("Перечень успешно удален");
      onDelete?.();

      queryClient.invalidateQueries(["declarations-enumeration"]);
      setOpened(false);
    },
    onError: () => {
      toast.error("Не удалось удалить перечень");
    },
  });

  return (
    <>
      {children(() => setOpened(true))}

      <ConfirmDialog
        {...props}
        open={opened}
        disabled={deleteEnumerationMutation.isLoading}
        onClose={() => setOpened(false)}
        confirmText="Вы действительно хотите удалить перечень?"
        onConfirm={() => deleteEnumerationMutation.mutate(enumerationId)}
      />
    </>
  );
};

export default DeleteEnumerationAction;
