import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import { createPortal } from "react-dom";

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth:"620px",
  maxWidth: '1000px',
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
   p: 4,
};

interface RequestModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  setIsEDSOpen:(value:boolean)=>void
}
const RequestPortal: React.FC<RequestModalProps> = ({
  open,
  onClose,
  title,
  setIsEDSOpen
}) => {
  if (open) {
    return createPortal(
      <Modal  open={open} onClose={onClose}>
        <Box sx={style}>
          <Typography sx={{
            fontSize:"20px",
            fontWeight:500,
            textAlign:"center"
          }}>{title}</Typography>
          <Stack direction="row" 
          justifyContent="space-between"
          sx={{
            pt:4
          }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "45%",
                // color: '#2C53F5',
                // border: '1px solid #2C53F5',
                // backgroundColor: '#FFF',
              }}
              onClick={onClose}
            >
              Отмена
            </Button>
            <Button
              sx={{
                width: "45%",
                // color: '#2C53F5',
                // border: '1px solid #2C53F5',
                // backgroundColor: '#FFF',
              }}
               onClick={()=>{setIsEDSOpen(true)}}
            >
              Получить
            </Button>
          </Stack>
        </Box>
      </Modal>,
      document.body,
    );
  }
  return null;
};

export default RequestPortal;
