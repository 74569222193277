// @ts-ignore
import { NCALayerClient } from "ncalayer-js-client";

const ncalayerClient = new NCALayerClient();
const catchError = (error: any) =>
  error.message ? { data: error } : { data: { message: error } };

export class NcaLayer {
  private static async connect() {
    if (ncalayerClient.wsConnection) return;

    try {
      await ncalayerClient.connect();
    } catch (error) {
      throw catchError("Не удалось подключиться к NCALayer: " + error);
    }
  }

  static TestConnect() {
    if (ncalayerClient.wsConnection) return;

    try {
      ncalayerClient.connect();
    } catch (error) {
      throw catchError("Не удалось подключиться к NCALayer: " + error);
    }
  }

  private static async getActiveTokens(): Promise<string[]> {
    try {
      return await ncalayerClient.getActiveTokens();
    } catch (error) {
      throw catchError(error);
    }
  }

  static async sign(data: string): Promise<string> {
    try {
      await this.connect();
      const activeTokens = await this.getActiveTokens();
      const storageType =
        activeTokens[0] || (NCALayerClient.fileStorageType as string);
      return await ncalayerClient.createCAdESFromBase64(storageType, data);
    } catch (error) {
      throw catchError(error);
    }
  }

  static async getInfo(): Promise<{
    certNotAfter: string;
    certNotBefore: string;
  }> {
    await this.connect();

    const activeTokens = await this.getActiveTokens();
    const storageType =
      activeTokens[0] || (NCALayerClient.fileStorageType as string);

    try {
      return await ncalayerClient.getKeyInfo(storageType);
    } catch (error) {
      throw catchError(error);
    }
  }
}
