import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { STUDENT_REQUEST_TYPES } from "../../../utils/constants";
import RequestPortal from "../../../components/Modals/studentRequestModal/RequestPortal";
import EDSPortal from "../../../components/Modals/studentRequestModal/EDSPortal";
import { NcaLayer } from "../../../utils/nca-layer";
import { toast } from "react-toastify";
import { getDocumentForSign } from "../../../services/useEndpoint";

export default function RequestButton() {
  const [selectValue, setSelectValue] = React.useState<string>("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState<string>("");
  const [isEDSOpen, setIsEDSOpen] = React.useState(false);

  async function handleEDS() {
    try {
      const base64string = "";
      const signature = await NcaLayer.sign(base64string);
      console.log("sign in handle", signature);
      return signature;
    } catch (error) {
      return false;
    }
  }

  const sendEDS = async () => {
    try {
      const signature = await handleEDS();
      if (signature) {
        setIsEDSOpen(false);
        setIsModalOpen(false);
        toast.success("Запрос отправлен");
        // if (declarationsData) {
        //   const newData = new FormData();
        //   for (let [key, value] of declarationsData.entries()) {
        //     newData.append(key, value);
        //   }
        //   newData.append("xml", signature);
        //   postSchoolDeclarations(newData);
        //   toast.success("Заявление направлено исполнителю");
        //   setDeclarationsData(null);
        //   // navigate("school/declarations/");
        // } else {
        //   toast.error("Ошибка подписания ЭЦП");
        // }
      } else {
        toast.error("Отмена подписания");
        setIsEDSOpen(false);
        // navigate("/school/declarations");
      }
    } catch (error) {
      console.error("Ошибка в sendEDS: ", error);
      toast.error("Произошла ошибка при отправке данных");
    }
  };

  return (
    <>
      <FormControl fullWidth>
        {
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectValue}
            displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return "Получить информацию";
              }
              let displayText = "";
              switch (selected) {
                case STUDENT_REQUEST_TYPES.loanDebt:
                  displayText = "Получить справку об отсутствии задолженности";
                  break;
                case STUDENT_REQUEST_TYPES.workOff:
                  displayText = "Узнать статус по отработке";
                  break;
                case STUDENT_REQUEST_TYPES.debtStatus:
                  displayText = "Узнать статус по долгу";
                  break;
                case STUDENT_REQUEST_TYPES.creditStatus:
                  displayText = "Узнать статус по задолженности по кредиту";
                  break;
                default:
                  displayText = "Получить информацию";
              }
              return displayText;
            }}
            onChange={(evt) => {
              setSelectValue(evt.target.value);
              setIsModalOpen(true);
              switch (evt.target.value) {
                case STUDENT_REQUEST_TYPES.loanDebt:
                  return setModalTitle(
                    "Получение справки об отсутствии задолженности",
                  );
                case STUDENT_REQUEST_TYPES.workOff:
                  return setModalTitle("Проверка статуса по отработке");
                case STUDENT_REQUEST_TYPES.debtStatus:
                  return setModalTitle("Проверка статуса по долгу");
                case STUDENT_REQUEST_TYPES.creditStatus:
                  return setModalTitle("Проверка статуса по кредиту");
                default:
                  return setModalTitle("");
              }
            }}
            sx={{
              width: "300px",
              backgroundColor: "#2C53F5",
              display: "flex",
              color: "#FFF",
              fill: "#FFF",
              ".MuiOutlinedInput-notchedOutline": {
                //borderColor: "rgba(228, 219, 233, 0.25)",
                borderColor: "transparent !important",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                // borderColor: "rgba(228, 219, 233, 0.25)",
                borderColor: "transparent !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                // borderColor: "rgba(228, 219, 233, 0.25)",
                borderColor: "transparent !important",
              },
              ".MuiSvgIcon-root ": {
                fill: "white !important",
              },
              ".MuiInputBase-input": {
                justifyContent: "center",
                whiteSpace: "pre-line",
              },
            }}
          >
            <MenuItem value={STUDENT_REQUEST_TYPES.loanDebt}>
              Получить справку об отсутствии задолженности
            </MenuItem>
            <MenuItem value={STUDENT_REQUEST_TYPES.workOff}>
              Узнать статус по отработке
            </MenuItem>
            <MenuItem
              value={STUDENT_REQUEST_TYPES.debtStatus}
              // onClick={handleMenuItemClick}
            >
              Узнать статус по долгу
            </MenuItem>
            <MenuItem
              value={STUDENT_REQUEST_TYPES.creditStatus}
              // onClick={handleMenuItemClick}
            >
              Узнать статус по задолженности по кредиту
            </MenuItem>
          </Select>
        }
      </FormControl>
      <RequestPortal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalTitle}
        setIsEDSOpen={setIsEDSOpen}
      />
      <EDSPortal
        open={isEDSOpen}
        onClose={() => setIsEDSOpen(false)}
        submit={sendEDS}
      />
    </>
  );
}
