import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export interface RoleInterface {
  id: number,
  name: string,
  code: string
}

export interface UserInterface {
  id: number,
  login: string,
  role: RoleInterface,
  organization_name: string,
  official_number: string,
  official_address: string | null,
  actual_address: string | null,
  ceo_fullname: string | null,
  ceo_official_number: string | null,
  email_address: string,
  phone_number: string,
  postcode: string | null,
  created_at: string
}
// "access_token": "36|V0e2pJZvr3ZUv7nHhlkYUof5horJ3hbVmJXfcyxR"
// }

const initialState = {
  currentUser: {
    id: 0,
    login: '',
    role: {
      id:0,
      name:'',
      code:''
    },
    organization_name: '',
    official_number: '',
    official_address: '',
    actual_address: '',
    ceo_fullname: '',
    ceo_official_number: '',
    email_address: '',
    phone_number: '',
    postcode: '',
    created_at: '',
  } as UserInterface,
  isAuthorized: false
};

export const currentUserSlice = createSlice({
  name: "constructorSlice",
  initialState,
  reducers: {
    setAllCurrentUserData: (state, action) => {
      state.currentUser = action.payload;
    },
    resetAllCurrentUserData: (state) => {
      state.currentUser = {
        id: 0,
        login: '',
        role: {
          id:0,
          name:'',
          code:''
        },
        organization_name: '',
        official_number: '',
        official_address: '',
        actual_address: '',
        ceo_fullname: '',
        ceo_official_number: '',
        email_address: '',
        phone_number: '',
        postcode: '',
        created_at: '',
      }
    },
    setAuthorized: (state) => {
      state.isAuthorized = true;
    },
    setUnAuthorized: (state) => {
      state.isAuthorized = false;
    },
  },
});

export const selectCurrentUser = (state: RootState) =>
  state.currentUserSlice.currentUser;
export const selectCurrentUserId = (state: RootState) =>
  state.currentUserSlice.currentUser.id;
export const selectRole = (state: RootState) =>
  state.currentUserSlice.currentUser.role;
export const selectAuthorization = (state: RootState) =>
  state.currentUserSlice.isAuthorized;



export const { 
  resetAllCurrentUserData, 
  setAllCurrentUserData, 
  setAuthorized, 
  setUnAuthorized
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
