import React, { useEffect } from "react";
import { useState } from "react";
import {
  DataGrid,
  GridAlignment,
  GridColumnGroup,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { BoardingTableRows } from "../types/tableRowsTypes";

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& *:focus": { outline: "none!important" },
    "& .MuiDataGrid-columnHeadersInner > div": {
      backgroundColor: theme.palette.background.default,
      "&:first-child:has(~ :nth-child(2))": {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
      },
    },

    borderRadius: "10px",
    //borderColor: theme.palette.other.tableBorderColor,

    "& .MuiDataGrid-columnHeaders": {
      borderTopLeftRadius: "9px",
      borderTopRightRadius: "9px",
    },
    //'& .MuiDataGrid-withBorderColor': {borderColor: theme.palette.other.tableBorderColor},
    "& .MuiDataGrid-columnHeader:not(:last-child), .MuiDataGrid-cell:not(:last-child)":
      {
        borderRight: `1px solid #BFC5D9`,
        //borderColor: theme.palette.other.tableBorderColor,
      },
    "& .MuiDataGrid-columnHeaderTitleContainer": { border: "none!important" },
    "& .MuiDataGrid-row:last-child > .MuiDataGrid-cell": {
      borderBottom: "none!important",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-row:not(:last-child) > .MuiDataGrid-cell":
      {
        borderBottom: `1px solid #BFC5D9`,
        //borderColor: theme.palette.other.tableBorderColor,
      },
    "& .MuiDataGrid-iconSeparator": { display: "none" },
    "& .MuiDataGrid-cell": {
      height: "auto",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      textOverflow: "clip",
      overflow: "auto",
      lineHeight: "120%",
      whiteSpace: "normal",
      height: "100%",
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
    },
  };
});

const columns = [
  {
    field: "classSchool",
    headerName: " ",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <span>{params.value}</span>;
    },
    sortable: false,
  },
  {
    field: "classSummStudents",
    headerName: " ",
    flex: 1,
    sortable: false,
  },
  {
    field: "normativClassDaily",
    headerName: "Дневные",
    sortable: false,
    flex: 1,
  },
  {
    field: "normativClassBoarding",
    headerName: "Интернат",
    sortable: false,
    flex: 1,
  },
  {
    field: "specialClassDaily",
    headerName: "Дневные",
    sortable: false,
    flex: 1,
  },
  {
    field: "specialClassBoarding",
    headerName: "Интернат",
    sortable: false,
    flex: 1,
  },
  {
    field: "correctiveClassDaily",
    headerName: "Дневные",
    sortable: false,
    flex: 1,
  },
  {
    field: "correctiveClassBoarding",
    headerName: "Интернат",
    sortable: false,
    flex: 1,
  },
  {
    field: "homeSchoolersDaily",
    headerName: "Дневные",
    sortable: false,
    flex: 1,
  },
];

const columnsGroupModel: GridColumnGroup[] = [
  {
    groupId: "classes",
    headerName: "Классы",
    children: [{ field: "classSchool" }],
  },
  {
    groupId: "summStudens",
    headerName: "Всего учащихся (человек)",
    children: [{ field: "classSummStudents" }],
  },
  {
    groupId: "generalEducations",
    headerName: "Учащиеся общеобразовательных классов",
    children: [
      {
        groupId: "normativClass",
        headerName: "Нормотипичные",
        headerAlign: "center" as GridAlignment,
        children: [
          { field: "normativClassDaily" },
          { field: "normativClassBoarding" },
        ],
      },
      {
        groupId: "specialClass",
        headerName: "Учащиеся с особыми образовательными потребностями",
        headerAlign: "center" as GridAlignment,
        children: [
          { field: "specialClassDaily" },
          { field: "specialClassBoarding" },
        ],
      },
    ],
  },
  {
    groupId: "correctiveClassMain",
    headerName: "Учащиеся специальных (коррекционных) классов",
    children: [
      {
        groupId: "correctiveClass",
        headerName: "Учащиеся специальных (коррекционных) классов",
        headerAlign: "center" as GridAlignment,
        children: [
          { field: "correctiveClassDaily" },
          { field: "correctiveClassBoarding" },
        ],
      },
    ],
  },
  {
    groupId: "homeSchoolersMain",
    headerName: "Обучающиеся на дому",
    children: [
      {
        groupId: "homeSchoolers",
        headerName: "Обучающиеся на дому",
        headerAlign: "center" as GridAlignment,
        children: [{ field: "homeSchoolersDaily" }],
      },
    ],
  },
];

interface MagicTableProps {
  rows: BoardingTableRows[];
}

const MagicTable: React.FC<MagicTableProps> = ({ rows }) => {
  return (
    <>
      <StyledDataGrid
        // <DataGrid
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        disableVirtualization={true}
        disableColumnFilter={true}
        disableColumnMenu={true}
        checkboxSelection={false}
        hideFooter={true}
        hideFooterPagination={true}
        editMode={"row"}
        rows={rows}
        columns={columns}
        columnGroupingModel={columnsGroupModel}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        experimentalFeatures={{
          columnGrouping: true,
        }}
        pageSizeOptions={[5, 10]}
      />
    </>
  );
};
export default MagicTable;
