import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import React, { useEffect, useRef } from "react";
import IMask, { InputMask } from "imask/esm/index";
import {
  selectIsBoardingTouched,
  selectIsBoardingValid,
  selectIsSchoolTouched,
  selectIsSchoolValid,
  setIsBoardingTouched,
  setIsBoardingValid,
  setIsSchoolTouched,
  setIsSchoolValid,
} from "../../../../store/reducers/validateCadastralInfoSlice";

const CustomEditableCell: React.FC<any> = (params) => {
  const dispatch = useAppDispatch();
  const numberFieldRef = useRef<HTMLInputElement>(null);
  const maskRef = useRef<
    InputMask<{
      mask: string;
      blocks: { "00:000:000:000:0": { mask: string } };
      definitions: { "0": RegExp };
    }>
  >();
  const [value, setValue] = React.useState<string>(params.value);
  const {
    isSchoolTable,
    id,
    field,
    onCellValueChange,
    invalidRows,
    setInvalidRows,
  } = params;
  const [isError, setIsError] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);
  const isValid = useAppSelector(
    isSchoolTable ? selectIsSchoolValid : selectIsBoardingValid,
  );
  const isTouchedAfterSubmit = useAppSelector(
    isSchoolTable ? selectIsSchoolTouched : selectIsBoardingTouched,
  );

  useEffect(() => {
    if (params.field === "cadastral_number" && numberFieldRef.current) {
      // Удаляем использование IMask и оставляем поле ввода без маски
      const inputField = numberFieldRef.current as HTMLInputElement;

      const handleChange = (event: Event) => {
        const { value } = event.target as HTMLInputElement;
        setValue(value);
      };

      inputField.addEventListener("input", handleChange);

      return () => {
        inputField.removeEventListener("input", handleChange);
      };
    }
  }, [numberFieldRef]);

  useEffect(() => {
    //   setIsError(false);
    updateInvalidRows(id, field, false);
    handleValidateValues(value);
    return () => {
      // setIsError(false);
      isSchoolTable
        ? dispatch(setIsSchoolValid(true))
        : dispatch(setIsBoardingValid(true));
    };
  }, [value, isValid, isTouched, isTouchedAfterSubmit]);

  const handleChange = (
    // event: React.ChangeEvent<HTMLInputElement>
    value: string,
  ) => {
    setValue(value);
    onCellValueChange(id, field, value);
    handleValidateValues(value);
    //   isSchoolTable
    //     ? dispatch(setIsSchoolTouched(true))
    //     : dispatch(setIsBoardingTouched(true));
  };
  const handleBlur = () => {
    onCellValueChange(id, field, value);
    handleValidateValues(value);
    setIsTouched(true);
    // if (field === "cadastral_number") {
    //   if (maskRef.current) {
    //     const isFieldValid = maskRef.current.masked.isComplete;
    //     setIsError(!isFieldValid);
    //   //  updateInvalidRows(id, field, !isFieldValid)

    //   }
    // }
    //   isSchoolTable
    //     ? dispatch(setIsSchoolTouched(true))
    //     : dispatch(setIsBoardingTouched(true));
  };
  const updateInvalidRows = (id: number, field: string, isError: boolean) => {
    setInvalidRows((prevRows: Array<{ field: string; id: number }>) => {
      const index = prevRows.findIndex(
        (item: { field: string; id: number }) =>
          item.id === id && item.field === field,
      );
      if (isError) {
        if (index === -1) {
          return [...prevRows, { id, field }];
        }
        return prevRows;
      } else {
        if (index > -1) {
          return prevRows.filter(
            (_: { field: string; id: number }, i: number) => i !== index,
          );
        }
        return prevRows;
      }
    });
  };
  const handleValidateValues = (value: string) => {
    if ((isTouched || isTouchedAfterSubmit) && field === "cadastral_number") {
      if (maskRef.current) {
        const isFieldValid = maskRef.current.masked.isComplete;
        //   setIsError(!isFieldValid);
        if (!isFieldValid) {
          isSchoolTable
            ? dispatch(setIsSchoolValid(false))
            : dispatch(setIsBoardingValid(false));
          updateInvalidRows(id, field, !isFieldValid);
        }
      }
    } else {
      if ((isTouched || isTouchedAfterSubmit) && !value) {
        //   setIsError(true);
        isSchoolTable
          ? dispatch(setIsSchoolValid(false))
          : dispatch(setIsBoardingValid(false));
        updateInvalidRows(id, field, true);
      } else {
        if (field === "cadastral_number" && params.id !== 1 && !value)
          // setIsError(false);
          isSchoolTable
            ? dispatch(setIsSchoolValid(true))
            : dispatch(setIsBoardingValid(true));
        updateInvalidRows(id, field, false);
      }
    }
  };

  if (params.field === "encumbrance_date") {
    return (
      <TextField
        value={value}
        placeholder="Дата обременения"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.value)
        }
        onBlur={handleBlur}
        style={{
          border: invalidRows.find(
            (row: { field: string; id: number }) =>
              row.id === params.id && row.field === field,
          )
            ? "1px solid red"
            : "none",
        }}
        sx={{
          // "&.MuiDataGrid-root .MuiDataGrid-cell": {
          //   border: isError? "1px" : "none",
          //   borderColor:isError? "red" : '',
          // },
          "& .MuiOutlinedInput-root": {
            border: "none",
            borderRadius: 0,
            fontSize: "14px",
            fontWeight: "400",
            width: "100%",

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0)",
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-disabled": {
              "& .MuiInputBase-input": {
                color: "#111520",
                WebkitTextFillColor: "#111520",
              },
              background: "transparent",
            },
          },
        }}
        InputProps={{
          sx: {
            "&::before": {
              borderBottom: "none !important",
            },
            "&::after": {
              borderBottom: "none !important",
            },
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              color: "#111520 !important",
            },
          },
        }}
        disabled={params.disabled}
      />
    );
  }
  if (params.field === "cadastral_number") {
    return (
      <TextField
        inputRef={numberFieldRef}
        type="text"
        value={value}
        onBlur={handleBlur}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(e.target.value);
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        onKeyUp={(event) => {}}
        style={{
          border: invalidRows.find(
            (row: { field: string; id: number }) =>
              row.id === params.id && row.field === field,
          )
            ? "1px solid red"
            : "none",
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none",
            borderRadius: 0,
            fontSize: "14px",
            fontWeight: "400",
            width: "100%",

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0)",
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-disabled": {
              "& .MuiInputBase-input": {
                color: "#111520",
                WebkitTextFillColor: "#111520",
              },
              background: "transparent",
            },
          },
        }}
        InputProps={{
          sx: {
            "&::before": {
              borderBottom: "none !important",
            },
            "&::after": {
              borderBottom: "none !important",
            },
          },
        }}
        placeholder={params.placeholder[`${params.field}`]}
        disabled={params.disabled}
      />
    );
  }
  return (
    <TextField
      type="text"
      value={value}
      onBlur={handleBlur}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleChange(e.target.value)
      }
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
      style={{
        border: invalidRows.find(
          (row: { field: string; id: number }) =>
            row.id === params.id && row.field === params.field,
        )
          ? "1px solid red"
          : "none",
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          border: "none",
          borderRadius: 0,
          fontSize: "14px",
          fontWeight: "400",
          width: "100%",

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0)",
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-disabled": {
            "& .MuiInputBase-input": {
              color: "#111520",
              WebkitTextFillColor: "#111520",
            },
            background: "transparent",
          },
        },
      }}
      InputProps={{
        sx: {
          "&::before": {
            borderBottom: "none !important",
          },
          "&::after": {
            borderBottom: "none !important",
          },
        },
      }}
      placeholder={params.placeholder[`${params.field}`]}
      disabled={params.disabled}
    />
  );
};

export default CustomEditableCell;
