import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { StyledTextarea } from "./StyledTextArea";
import {
  CommentInterface,
  ReworkModalProps,
  remarkType,
} from "../../types/modalTypes";
import { getRemarkTypes } from "../../services/useEndpoint";
import { COMMENTS_TYPES } from "../../utils/constants";

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  onClose: () => void;
  submit: (id: number, comment: string, type: Array<number>) => void;
  id: number;
  isReworked: boolean;
  commentData: CommentInterface[];
};
const selectRenderValueStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "98%",
};
export default function GenerateMessage({
  open,
  onClose,
  id,
  submit,
  isReworked,
  commentData,
}: Props) {
  const [comment, setComment] = useState("");
  const [selectedOptionsIds, setSelectedOptionsIds] = useState<number[]>([]);
  const [remarkTypes, setRemarkTypes] = useState<remarkType[]>();

  const placeholderText = !!isReworked
    ? "Уважаемый пользователь, вам отказано в заявке по причине..."
    : "Уважаемый пользователь, вам необходимо доработать заявку по причине...";

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const value = event.target.value;
    setSelectedOptionsIds(
      typeof value === "string"
        ? value.split(",").map((str) => parseInt(str, 10))
        : value,
    );
  };
  useEffect(() => {
    if (commentData && commentData.length > 0) {
      const currentData = commentData[0];
      setComment(currentData.comment || "");
      setSelectedOptionsIds(
        currentData.remark_type_ids
          ? currentData.remark_type_ids?.map((remarkType) => remarkType.id)
          : [],
      );
    }
  }, [commentData]);

  useEffect(() => {
    if (!open) return;
    // setSelectedOptionsIds(commentData?.remark_type_ids);
    try {
      getRemarkTypes()
        .then((data) => {
          if (data) {
            setRemarkTypes(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [open]);

  // useEffect(() => {
  //   setSelectedOptionsIds(commentData?.remark_type_ids);
  // }, [commentData?.remark_type_ids, ]);

  // useEffect(() => {
  //   setComment(commentData?.comment);
  // }, [commentData?.comment]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center", marginBottom: "32px" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Сформировать письмо
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              width: "45%",
            }}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            disabled={!selectedOptionsIds?.length}
            onClick={() => submit(id, comment, selectedOptionsIds)}
            sx={{
              width: "45%",
            }}
          >
            Отправить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
