import { Box, Typography } from "@mui/material";
import { fincolors } from "../../styles/theme";

interface LabelDisplayedRowsProps {
  currentPage: number;
  totalPages: number;
}
export const LabelDisplayedRows: React.FC<LabelDisplayedRowsProps> = ({
  currentPage,
  totalPages,
}) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Typography
      sx={{
        color: fincolors.primary.main,
        backgroundColor: "white",
        width: 36,
        height: 36,
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "14px",
      }}
      variant="h5"
    >
      {currentPage}
    </Typography>
    <Typography variant="h5">из</Typography>
    <Typography
      sx={{
        color: fincolors.text.primary,
        width: 36,
        height: 36,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      variant="h5"
    >
      {totalPages}
    </Typography>
  </Box>
);
