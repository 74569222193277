export enum EDeclarationHistoryStatus {
  "Новое" = "Новое",
  "Принято" = "Принято",
  "Отправлено на доработку" = "Отправлено на доработку",
  "Отклонено" = "Отклонено",
  "Отозвано" = "Отозвано",
  "На исполнении" = "На исполнении",
  "На согласовании" = "На согласовании",
  "На доработке у исполнителя" = "На доработке у исполнителя",
}
