import {GlobalStyles, alpha} from '@mui/material';
import theme from './theme';

const BaseStyles = ()=>{
	return (
		<GlobalStyles
			styles={{
				'body, #root, .body': {width: '100%', minHeight: '100vh'},

				'body, * >': {
					'&::-webkit-scrollbar': {width: 4, height: 4},
					'&::-webkit-scrollbar-track': {backgroundColor: 'transparent'},
					'&::-webkit-scrollbar-thumb': {backgroundColor: 'transparent'},

					'&:hover::-webkit-scrollbar-track, &::-webkit-scrollbar-track:hover': {backgroundColor: alpha(theme.palette.background.paper, 1)},
					'&:hover::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:hover': {backgroundColor: alpha(theme.palette.primary.main, 1)},			
				},
                '.MuiInputBase-root input:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    WebkitTextFillColor: '#000', 
                    transition: 'background-color 5000s ease-in-out 0s',
                },

                '.MuiInputBase-root input:-moz-autofill': {
                    boxShadow: '0 0 0 1000px white inset',
                    textFillColor: '#000', 
                    transition: 'background-color 5000s ease-in-out 0s',
                },

                '.MuiInputBase-root input:-ms-input-placeholder': {
                    boxShadow: '0 0 0 1000px white inset',
                    color: '#000', 
                },
			}}
		/>
	);
};

export default BaseStyles;

