import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getDocumentForSign } from "../../../services/useEndpoint";
import { NcaLayer } from "../../../utils/nca-layer";
import { signApplicationEnumeration } from "../api";
import { IApplicationEnumeration } from "../types";

export interface SignEnumerationProps {
  enumerationId: IApplicationEnumeration["id"];
  filePath: string;
  children(mutationResult: UseSignEnumerationResult): React.ReactNode;
  onSign?(): void;
}

const SignEnumeration = ({
  enumerationId,
  filePath,
  onSign,
  children,
}: SignEnumerationProps) => {
  const signEnumerationMutation = useSignEnumeration(
    enumerationId,
    filePath,
    onSign,
  );

  return <>{children(signEnumerationMutation)}</>;
};

type UseSignEnumerationResult = ReturnType<typeof useSignEnumeration>;
function useSignEnumeration(
  enumerationId: IApplicationEnumeration["id"],
  filePath: string,
  onSign?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["signEnumeration"],
    mutationFn: async () => {
      const base64string = await getDocumentForSign({ filePath });
      const signature = await NcaLayer.sign(base64string);
      if (!signature) return;

      return await signApplicationEnumeration(enumerationId, {
        file_path: filePath,
        signature,
      });
    },
    onSuccess: () => {
      toast.success("Перечень успешно подписан");
      onSign?.();

      queryClient.invalidateQueries(["declarations-enumeration"]);
    },
    onError: () => {
      toast.error("Не удалось подписать перечень");
    },
  });
}

export default SignEnumeration;
