import styled from "@emotion/styled";
import { Circle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fincolors } from "../styles/theme";
import { IApplication } from "../types/application";
import { STATUS_ID } from "../utils/constants";
import { calculateDaysLeft } from "../utils/helpers";
import NoRowsOverlay from "./NoRowsOverlay";
import Pagination from "./Pagination/Pagination";
import { TableDateTime } from "./TableDateTime";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  height: "auto",
  border: "2px solid #DFE2EC",
  borderRadius: "8px",
  overflow: "hidden",
  "& .MuiDataGrid-root": {
    border: "2px solid red",
    borderRadius: "8px",
    overflow: "hidden",
    height: "auto",
  },
  "& .MuiDataGrid-overlay": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60px",
  },
  "& .MuiDataGrid-overlayWrapper": {
    display: "inline-block",
    height: "60px",
  },
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-checkboxInput": {
      width: "15px",
      height: "15px",
      "& svg": {
        fill: "#111520",
        width: "15px",
        height: "15px",
        borderRadius: "5px",
      },
    },
  },
  "& .MuiDataGrid-checkboxInput": {
    width: "15px",
    height: "15px",
    "& svg": {
      fill: "#DFE2EC",
      width: "15px",
      height: "15px",
      borderRadius: "5px",
    },
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    backgroundColor: "#FFF",
  },

  "& .Mui-checked": {
    fill: "#DFE2EC",
    width: "15px",
    height: "15px",
    "& svg": {
      fill: "#2C53F5",
    },
  },
  "& .MuiDataGrid-cell": {
    fontSize: "14px",
    padding: "10px",
    backgroundColor: "#FFF",
    height: "40px",
    whiteSpace: "normal",
    overflow: "auto",
    textOverflow: "clip",
  },

  "& .MuiDataGrid-colCellTitle": {
    color: "#111520",
    fontWeight: "medium",
    backgroundColor: "#DFE2EC",
    height: "40px",
  },

  "& .MuiDataGrid-rowSelected": {
    backgroundColor: "red",
    height: "13px",
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    overflow: "auto",
    lineHeight: "120%",
    whiteSpace: "normal",
    height: "100%",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiDataGrid-footerContainer": {
    display: "none",
  },
}));

const getStatusColor = (status: number) => {
  switch (status) {
    case STATUS_ID.onExecutorRework:
      return "#FE8C1B";
    case STATUS_ID.onExecution:
      return "#0E70C7";
    case STATUS_ID.onApproval:
      return "#0E70C7";
    default:
      return "#0E70C7";
  }
};

const columns: GridColDef[] = [
  {
    field: "id",
    headerClassName: "super-app-theme--header",
    headerName: "№ ID заявки",
    width: 120,
    editable: false,
    renderCell: (params) => `№ ${params.row.id}`,
  },
  {
    field: "inner_number",
    headerClassName: "super-app-theme--header",
    headerName: "Вх. номер ФЦ",
    width: 120,
    editable: false,
  },
  {
    field: "school_name",
    headerName: "Название школы",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: true,
  },
  {
    field: "created_at",
    headerName: "Дата поступления",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: true,
    renderCell: (params) => (
      <TableDateTime
        dateTimeString={params.row.created_at}
        params={params.row}
      />
    ),
  },
  {
    field: "review_period",
    headerName: "Срок рассмотрения",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: true,
    renderCell: (params) => {
      const { text, color } = calculateDaysLeft(params.row.review_period, true);

      return (
        <div style={{ whiteSpace: "pre-line" }}>
          <Typography sx={{ color: color, fontSize: 14 }}>
            {params.row.review_period}
          </Typography>

          {[
            STATUS_ID.approved,
            STATUS_ID.rejected,
            STATUS_ID.returned,
          ].includes(params.row.status.id) ? null : (
            <Typography variant="body2"> {text}</Typography>
          )}
        </div>
      );
    },
  },
  {
    field: "organization_fields",
    headerName: "Отправитель / БИН",
    headerClassName: "super-app-theme--header",
    width: 130,
    editable: true,
    renderCell: (params) => (
      <Box>
        <Typography sx={{ color: fincolors.text.primary, fontSize: 14 }}>
          {params.row.organization_fields.organization_name}
        </Typography>
        <Typography sx={{ color: fincolors.text.secondary, fontSize: 12 }}>
          {params.row.organization_fields.official_number}
        </Typography>
      </Box>
    ),
  },
  {
    field: "displayed_status",
    headerName: "Статус",
    headerClassName: "super-app-theme--header",
    width: 250,
    editable: true,
    renderCell: (params) => (
      <div
        style={{
          borderRadius: "36px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          <Circle
            sx={{
              width: "12px",
              height: "12px",
              color: getStatusColor(params.row.status.id),
              marginRight: "8px",
            }}
          />
          <Typography
            sx={{
              display: "flex",
              whiteSpace: "pre-line",
              fontSize: 12,
              width: 165,
            }}
          >
            {params.row?.displayed_status}
          </Typography>
        </span>
        <Typography
          sx={{
            display: "flex",
            whiteSpace: "pre-line",
            fontSize: 12,
            marginLeft: "20px",
          }}
        >
          {params?.row.status.id === STATUS_ID.onSchoolRework ||
          params?.row.status.id === STATUS_ID.rejected ? null : (
            <span>{params.row.current_executor?.organization_name}</span>
          )}
        </Typography>
      </div>
    ),
  },
  {
    field: "type",
    headerClassName: "super-app-theme--header",
    headerName: "Тип заявки",
    width: 220,
    editable: true,
    renderCell: (params) =>
      params.row.type === 1 ? "Основной перечень" : "Дополнительный перечень",
  },
];

interface TableState {
  page: number;
  perPage: number;
  rows: IApplication[];
  rowCount: number;
  onChangePage(page: number): void;
  onChangePerPage(perPage: number): void;
}

const TableDeclarations: React.FC<TableState> = ({
  page,
  perPage,
  rows,
  rowCount,
  onChangePage,
  onChangePerPage,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleRowClick = (params: any) => {
    navigate(`${location.pathname}/${params.id}`);
  };

  return (
    <>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
        pageSizeOptions={[20, 10]}
        slots={{ noRowsOverlay: NoRowsOverlay }}
        onRowClick={handleRowClick}
      />

      <Pagination
        rowCount={rowCount}
        rowsPerPage={perPage}
        currentPage={page}
        onPageChange={(event, page) => onChangePage(page + 1)}
        onRowsPerPageChange={(event) =>
          onChangePerPage(Number(event.target.value))
        }
      />
    </>
  );
};

export default TableDeclarations;
