import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  SearchOutlined,
  NotificationsOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";

import logotype from "../../assets/svg/logotype.svg";

import { useGlobalContext } from "../../contexts/GlobalContext";
import { useModalContext } from "../../contexts/ModalContext";
import { SelectChangeEvent } from "@mui/material/Select";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  resetAllCurrentUserData,
  selectCurrentUser,
  selectCurrentUserId,
  setUnAuthorized,
} from "../../store/reducers/currentUserReducer";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { setClearFilter } from "../../store/reducers/filterSlice";
import {
  setCurrentPage,
  setMailsCurrentPage,
} from "../../store/reducers/currentPageSlice";
import NotificationsModal from "../Modals/notificationsModal/NotificationsModal";
import { fincolors } from "../../styles/theme";
import {
  selectNewNotificationsCount,
  setNewNotificationsCount,
  setNotifications,
} from "../../store/reducers/notificationsSlice";
import { getNotifications } from "../../services/useEndpoint";
import { ROLES } from "../../utils/constants";

const Header = () => {
  const {
    state: { isAuthorized, langSelected },
    dispatch,
  } = useGlobalContext();
  const navigate = useNavigate();
  const { modalOpen } = useModalContext();
  const reduxDispatch = useAppDispatch();
  const queryClient = useQueryClient();
  // const [user, setUser] = useState({ email: undefined, name: undefined });

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null); // todo type
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<HTMLElement | null>(null);
  const user = useAppSelector(selectCurrentUser);
  const isMenuOpen = Boolean(anchorEl);

  const newNotificationsCount = useAppSelector(selectNewNotificationsCount);
  const { data, isLoading } = useQuery(
    ["notificationsQuery"],
    getNotifications,
  );
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    dispatch({ type: "SET_LANGUAGE", payload: event.target.value as string });
  };

  useEffect(() => {
    if (data) {
      reduxDispatch(setNewNotificationsCount(data?.new_count));
      dispatch(setNotifications(data.notifications));
    }
  }, [data, isLoading]);

  const isAuth = !!Cookies.get("token");
  const id = Cookies.get("currentUserId");
  const role = Number(Cookies.get("role"));
  const handleMenuTrigger = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget as HTMLDivElement); // todo: pass type suda
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    Cookies.remove("role");
    Cookies.remove("token");
    navigate("/auth/sign-in");
    queryClient.clear();
    reduxDispatch(resetAllCurrentUserData());
    reduxDispatch(setUnAuthorized());
    reduxDispatch(setClearFilter());
    reduxDispatch(setCurrentPage(0));
    reduxDispatch(setMailsCurrentPage(0));
  };

  return (
    <AppBar position="sticky" elevation={0}>
      <Container>
        <Toolbar disableGutters>
          {/*<NavLink to="/school/declarations" style={{ display: "flex" }}>*/}
          <Box
            component="img"
            sx={{ height: 20, userSelect: "none" }}
            alt="Finance center"
            src={logotype}
          />
          {/*</NavLink>*/}

          <Box sx={{ flexGrow: 1 }} />

          {role === ROLES.school.id ? (
            <Box sx={{ marginRight: 5 }}>
              <Typography>Телефон Службы поддержки</Typography>
              <Typography>+7 775 128 61 88</Typography>
            </Box>
          ) : null}

          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            {/* <IconButton color="inherit">
              <SearchOutlined />
            </IconButton> */}

            <IconButton
              color="inherit"
              onClick={(evt: React.MouseEvent<HTMLElement>) => {
                setNotificationAnchorEl(evt.currentTarget);
              }}
            >
              <Badge
                badgeContent={newNotificationsCount || 0}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: fincolors.error.main,
                    color: fincolors.primary.contrastText,
                  },
                }}
              >
                <NotificationsOutlined />
              </Badge>
            </IconButton>
            <NotificationsModal
              anchorEl={notificationAnchorEl}
              setAnchorEl={setNotificationAnchorEl}
            />

            <Select
              sx={{
                color: "inherit",
                minWidth: 70,
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: "none" },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  { border: "none" },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { border: "none" },
              }}
              value={langSelected}
              onChange={handleLanguageChange}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"ru-RU"}>RU</MenuItem>
              <MenuItem value={"kk-KZ"}>KZ</MenuItem>
              <MenuItem value={"en-US"}>EN</MenuItem>
            </Select>

            {isAuth ? (
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  onClick={handleMenuTrigger}
                >
                  <Avatar
                    alt={user.organization_name}
                    src={
                      "https://www.google.com/images/branding/googlelogo/1x/googlelogo_light_color_272x92dp.png"
                    }
                    sx={{
                      border: "solid black 1px",
                      width: 36,
                      height: 36,
                      background: "#13131313",
                    }}
                  />
                  <Typography sx={{ fontWeight: "medium", userSelect: "none" }}>
                    {user.email_address}
                  </Typography>
                  <ArrowDropDownOutlined
                    sx={{
                      transform: isMenuOpen ? "rotate(180deg)" : "rotate(0)",
                    }}
                  />
                </Box>

                <Menu
                  sx={{ mt: "45px" }}
                  anchorEl={anchorEl}
                  keepMounted
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    key="logout"
                    onClick={() => {
                      logout();
                    }}
                  >
                    <Typography textAlign="center">Выйти</Typography>
                  </MenuItem>
                  {/* settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleMenuClose}>
                      <Typography textAlign='center'>{setting}</Typography>
                    </MenuItem>
                  )) */}
                </Menu>
              </Box>
            ) : (
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" onClick={() => modalOpen("login")}>
                  Вход
                </Button>
                <Button onClick={() => modalOpen("register")}>
                  Регистрация
                </Button>
              </Stack>
            )}
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default React.memo(Header);
