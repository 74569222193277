import { CheckCircle, Create } from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { fincolors } from "../styles/theme";

interface CommentsProps {
  title: string;
  remarkTypes?: Array<string> | null;
  comment?: string;
  type?: number;
  isMainSpecialist?: boolean;
}

const CommentsConclusion: React.FC<CommentsProps> = ({
  title,
  remarkTypes,
  comment,
  type,
  isMainSpecialist,
}) => {
  return (
    <Box sx={{ width: "auto", minHeight: "50px", maxWidth: "912px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          bgcolor: isMainSpecialist
            ? "rgba(115,112,115,0.25)"
            : "rgba(182,162,162,0.25)",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <Create
          width={3}
          sx={{ mr: 1, color: isMainSpecialist ? "#211f21" : "#181414" }}
        />
        <Typography fontWeight={500}>{title}</Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: fincolors.primary.contrastText,
          p: 2,
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderColor: fincolors.other.tableBorderColor,
          borderWidth: "1px",
          borderTopWidth: 0,
          borderStyle: "solid",
        }}
      >
        {remarkTypes?.length ? (
          <>
            <Typography sx={{ color: fincolors.other.tableBorderColor }}>
              Замечания:
            </Typography>

            <Box
              sx={{
                borderColor: fincolors.other.tableBorderColor,
                borderRadius: "10px",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              {remarkTypes &&
                remarkTypes.map((type, index) => (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ p: 2 }}
                    >
                      <Typography key={index} sx={{ fontWeight: 500 }}>
                        {type}
                      </Typography>

                      <CheckCircle
                        sx={{
                          mr: 1,
                          color: isMainSpecialist ? "#4d0064" : "#800000",
                        }}
                      />
                    </Stack>

                    {index !== remarkTypes.length - 1 && (
                      <Divider
                        sx={{ borderColor: fincolors.other.tableBorderColor }}
                      />
                    )}
                  </>
                ))}
            </Box>
          </>
        ) : null}

        {type && (
          <Typography>
            {type === 1 ? "Соответствует" : "Не соответствует"}
          </Typography>
        )}

        {comment && (
          <>
            <Typography
              sx={{
                mt: remarkTypes?.length ? 2 : "",
                color: fincolors.other.tableBorderColor,
              }}
            >
              Комментарий:
            </Typography>

            <Typography>{comment}</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CommentsConclusion;
