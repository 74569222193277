import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import React, { ChangeEvent, useState } from "react";
import BoxCard from "../../../components/BoxCard";
import FileDownloadField from "../../../components/FileDownloadField";
import { toast } from "react-toastify";
import * as yup from "yup";

interface FormValues {
  private_organization_government_order: number;
  boarding_school_government_order: number;
  education_licence: null | File;
  legal_entity_certificate: null | File;
  bank_certificate: null | File;
  preliminary_agreement: null | File;
  immutability_agreement: null | File;
  real_estate_certificate: null | File;
  project_documentation: null | File;
  exploitation_act: null | File;
  condition_conclusion: null | File;
  cadastral_information: null | File;
  tuition_fee_statement: null | File;
  integrated_education_realization_documents: null | File;
  boarding_school_registered_immutability_agreement: number;
  private_organization_exploitation_type: string;

  boarding_school_preliminary_agreement: null | File;
  boarding_school_immutability_agreement: null | File;
  boarding_school_project_documentation: null | File;
  boarding_school_exploitation_act: null | File;
  boarding_school_condition_conclusion: null | File;
  boarding_school_real_estate_certificate: null | File;
  boarding_school_cadastral_information: null | File;

  anti_terrorism_measures: null | File;
}

interface FormikProp {
  setFieldValue(arg0: string, arg1: any): unknown;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
  values: FormValues;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
}

interface DeclarationStep3Props {
  formik: FormikProp;
  formik2Values: FormikValues;
  isDisabled: boolean;
  isInputDisabled: boolean;
  isCreation?: boolean;
}
const CustomUploadIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
  >
    <path
      d="M3.99935 6.33333V11.3333H8.99935V6.33333H12.3327L6.49935 0.5L0.666016 6.33333H3.99935ZM6.49935 2.83333L8.33268 4.66667H7.33268V9.66667H5.66602V4.66667H4.66602L6.49935 2.83333ZM12.3327 13H0.666016V14.6667H12.3327V13Z"
      fill="#A7ADBE"
    />
  </svg>,
  "CustomUpload",
);

const StyledLabel = styled(InputLabel)({
  overflow: "auto",
  whiteSpace: "normal",
  textOverflow: "clip",
  fontSize: "14px",
  display: "inline",
});

const DeclarationStep3: React.FC<DeclarationStep3Props> = ({
  formik,
  formik2Values,
  isInputDisabled,
  isCreation,
}) => {
  const [fileAll, setFileAll] = useState<any>([]);

  return (
    <form
      encType="multipart/form-data"
      id="declarations_step_3"
      onSubmit={formik.handleSubmit}
    >
      <Stack direction={"column"} spacing={3}>
        <BoxCard
          title={"прикрепите документы в PDF-формате"}
          sx={{ marginBottom: "10px" }}
        >
          <Stack direction={"column"} spacing={3}>
            {
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <StyledLabel
                    id="demo-simple-select-label"
                    sx={{ fontWeight: "bold" }}
                  >
                    Ранее государственный образовательный заказ:
                  </StyledLabel>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        formik?.values.private_organization_government_order
                          ? 1
                          : 0
                      }
                      label="Ранее государственный образовательный заказ:"
                      onChange={(event) => {
                        const { value } = event.target;
                        formik.setFieldValue(
                          "private_organization_government_order",
                          value,
                        );
                      }}
                      disabled={isInputDisabled}
                    >
                      <MenuItem value={1}>Размещался</MenuItem>
                      <MenuItem value={0}>Не размещался</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            }
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <StyledLabel
                    id="demo-simple-select-label"
                    sx={{ fontWeight: "500" }}
                  >
                    Справка о государственной регистрации (перерегистрации)
                    юридического лица
                  </StyledLabel>
                </Grid>
                <Grid item xs={3}>
                  {!isInputDisabled ? (
                    <FormControl fullWidth>
                      <Input
                        id="legal_entity_certificate"
                        name="legal_entity_certificate"
                        type="file"
                        inputProps={{
                          accept: ".pdf",
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          const file = event.target.files?.[0];
                          if (file && file.size <= 10 * 1024 * 1024) {
                            formik.setFieldValue(
                              "legal_entity_certificate",
                              file,
                            );
                          } else {
                            toast.error(
                              "Пожалуйста, загрузите файлы меньше 10МБ",
                            );
                            formik.setFieldValue(
                              "legal_entity_certificate",
                              null,
                            );
                          }
                        }}
                        sx={{ display: "none" }}
                        disabled={isInputDisabled}
                        error={
                          Boolean(
                            formik?.touched?.legal_entity_certificate &&
                              formik?.errors?.legal_entity_certificate,
                          ) || false
                        }
                      />

                      {formik.values.legal_entity_certificate && (
                        <Button
                          variant="outlined"
                          color="error"
                          endIcon={<ClearIcon />}
                          onClick={() => {
                            formik.setFieldValue(
                              "legal_entity_certificate",
                              null,
                            );
                          }}
                          disabled={isInputDisabled}
                        >
                          <Box
                            component="span"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {formik.values.legal_entity_certificate.name
                              ? formik.values.legal_entity_certificate.name
                              : "Справка о гос. регистрация"}
                          </Box>
                        </Button>
                      )}
                      {!formik.values.legal_entity_certificate && (
                        <label htmlFor="legal_entity_certificate">
                          <Button
                            variant="contained"
                            component="div"
                            sx={{
                              backgroundColor: "#F4F6FA",
                              display: "flex",
                              color: "#A7ADBE",
                            }}
                            disabled={isInputDisabled}
                          >
                            <CustomUploadIcon
                              sx={{ width: "12px", height: "15px" }}
                            />
                            <Box component="div" sx={{ marginLeft: "12px" }}>
                              Загрузить (макс 10 МВ)
                            </Box>
                          </Button>
                        </label>
                      )}
                      <FormHelperText>
                        {formik?.touched?.legal_entity_certificate &&
                          formik?.errors?.legal_entity_certificate}
                      </FormHelperText>
                    </FormControl>
                  ) : (
                    !!formik.values.legal_entity_certificate && (
                      <FileDownloadField
                        fileName={
                          formik.values.legal_entity_certificate?.name
                            ? formik.values.legal_entity_certificate.name
                            : "Справка о гос. регистрация"
                        }
                        fileUrl={
                          !!formik.values.legal_entity_certificate &&
                          typeof formik.values.legal_entity_certificate ===
                            "string"
                            ? formik.values.legal_entity_certificate
                            : ""
                        }
                      />
                    )
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <StyledLabel
                    id="demo-simple-select-label"
                    sx={{ fontWeight: "500" }}
                  >
                    Копия приказа руководителя частной организации образования
                    или лица исполняющего его обязанности об утверждении размера
                    родительской платы за обучение в частной организации
                    образования (при наличии родительской оплаты)
                  </StyledLabel>
                </Grid>
                <Grid item xs={3}>
                  {!isInputDisabled ? (
                    <FormControl fullWidth>
                      <Input
                        id="tuition_fee_statement"
                        name="tuition_fee_statement"
                        type="file"
                        inputProps={{
                          accept: ".pdf",
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          const file = event.target.files?.[0];
                          if (file && file.size <= 10 * 1024 * 1024) {
                            formik.setFieldValue("tuition_fee_statement", file);
                          } else {
                            toast.error(
                              "Пожалуйста, загрузите файлы меньше 10МБ",
                            );
                            formik.setFieldValue("tuition_fee_statement", null);
                          }
                        }}
                        sx={{ display: "none" }}
                      />
                      {formik.values.tuition_fee_statement && (
                        <Button
                          variant="outlined"
                          color="error"
                          endIcon={<ClearIcon />}
                          onClick={() => {
                            formik.setFieldValue("tuition_fee_statement", null);
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {formik.values.tuition_fee_statement.name
                              ? formik.values.tuition_fee_statement.name
                              : "5)Копия приказа руководителя частной организации образования или лица исполняющего его обязанности об утверждении размера родительской платы за обучение в частной организации образования (при наличии родительской оплаты)"}
                          </Box>
                        </Button>
                      )}
                      {!formik.values.tuition_fee_statement && (
                        <label htmlFor="tuition_fee_statement">
                          <Button
                            variant="contained"
                            component="div"
                            sx={{
                              backgroundColor: "#F4F6FA",
                              display: "flex",
                              color: "#A7ADBE",
                            }}
                          >
                            <CustomUploadIcon
                              sx={{ width: "12px", height: "15px" }}
                            />
                            <Box component="div" sx={{ marginLeft: "12px" }}>
                              Загрузить (макс 10 МВ)
                            </Box>
                          </Button>
                        </label>
                      )}
                      <FormHelperText>
                        {formik?.touched?.tuition_fee_statement &&
                          formik?.errors?.tuition_fee_statement}
                      </FormHelperText>
                    </FormControl>
                  ) : (
                    !!formik.values.tuition_fee_statement && (
                      <FileDownloadField
                        fileName={
                          formik.values.tuition_fee_statement?.name
                            ? formik.values.tuition_fee_statement.name
                            : "Справка о гос. регистрация"
                        }
                        fileUrl={
                          !!formik.values.tuition_fee_statement &&
                          typeof formik.values.tuition_fee_statement ===
                            "string"
                            ? formik.values.tuition_fee_statement
                            : ""
                        }
                      />
                    )
                  )}
                </Grid>
              </Grid>

              {!!formik2Values.integrated_education &&
                formik?.values.private_organization_government_order === 1 && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <StyledLabel
                        id="demo-simple-select-label"
                        sx={{ marginTop: "24px", fontWeight: "500" }}
                      >
                        Документы, подтверждающие реализацию самостоятельно
                        разработанных интегрированных образовательных программ,
                        прохождение авторизации в Организации Международного
                        Бакалавриата или международную институциональную
                        аккредитацию - для частных организаций образования,
                        реализующих самостоятельно разработанные интегрированные
                        образовательные программы, прошедших авторизацию в
                        Организации Международного Бакалавриата или
                        международную институциональную аккредитацию
                      </StyledLabel>
                    </Grid>
                    <Grid item xs={3}>
                      {!isInputDisabled ? (
                        <FormControl fullWidth>
                          <Input
                            id="integrated_education_realization_documents"
                            name="integrated_education_realization_documents"
                            type="file"
                            inputProps={{
                              accept: ".pdf",
                              // multiple: true,
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const files = event.target.files;
                              if (files) {
                                const newFiles = Array.from(files);
                                if (
                                  newFiles.some(
                                    (file) => file.size > 10 * 1024 * 1024,
                                  )
                                ) {
                                  toast.error(
                                    "Пожалуйста, загрузите файлы меньше 10МБ",
                                  );
                                  formik.setFieldValue(
                                    "integrated_education_realization_documents",
                                    null,
                                  );
                                } else {
                                  formik.setFieldValue(
                                    "integrated_education_realization_documents",
                                    newFiles,
                                  );
                                }
                                setFileAll(newFiles);
                              }
                            }}
                            sx={{ display: "none" }}
                          />
                          {formik.values
                            .integrated_education_realization_documents && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue(
                                  "integrated_education_realization_documents",
                                  null,
                                );
                                setFileAll([]);
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values
                                  .integrated_education_realization_documents
                                  .name
                                  ? formik.values
                                      .integrated_education_realization_documents
                                      .name
                                  : "Документы, подтверждающие реализацию самостоятельно разработанных интегрированных образовательных программ, прохождение авторизации в Организации Международного Бакалавриата или международную институциональную аккредитацию - для частных организаций образования, реализующих самостоятельно разработанные интегрированные образовательные программы, прошедших авторизацию в Организации Международного Бакалавриата или международную институциональную аккредитацию (при наличии)"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values
                            .integrated_education_realization_documents && (
                            <label htmlFor="integrated_education_realization_documents">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 10 МВ)
                                </Box>
                              </Button>
                            </label>
                          )}
                          <FormHelperText>
                            {formik?.touched
                              ?.integrated_education_realization_documents &&
                              formik?.errors
                                ?.integrated_education_realization_documents}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        formik.values.private_organization_government_order ===
                          1 &&
                        !!formik.values.legal_entity_certificate &&
                        !!formik.values
                          .integrated_education_realization_documents &&
                        typeof formik.values
                          .integrated_education_realization_documents ===
                          "string" &&
                        JSON.parse(
                          formik.values
                            .integrated_education_realization_documents,
                        ).map((item: string, i: number) => (
                          <FileDownloadField
                            key={i}
                            fileName={"Сведения самостоят. разраб. ОП"}
                            fileDownloadName={"Сведения_самостоят_разраб_ОП"}
                            fileUrl={item}
                          />
                        ))
                      )}
                    </Grid>
                  </Grid>
                )}
              {(!isInputDisabled || formik?.values.education_licence) &&
                formik?.values.private_organization_government_order === 0 && (
                  <Grid container spacing={1}>
                    {
                      // (!isInputDisabled || formik?.values.education_licence) &&
                      //   formik?.values.is_hosted === 0 &&
                      <Grid item xs={12}>
                        <StyledLabel
                          id="demo-simple-select-label"
                          sx={{ fontWeight: "500" }}
                        >
                          Копия лицензии на занятие образовательной
                          деятельностью по общеобразовательным учебным
                          программам начального, основного среднего, общего
                          среднего образования, выданной по форме, утвержденной
                          приказом Министра национальной экономики Республики
                          Казахстан от 6 января 2015 года № 3.
                        </StyledLabel>
                      </Grid>
                    }
                    <Grid item xs={3}>
                      {!isInputDisabled &&
                      formik?.values.private_organization_government_order ===
                        0 ? (
                        <FormControl fullWidth>
                          <Input
                            id="education_licence"
                            name="education_licence"
                            type="file"
                            inputProps={{
                              accept: ".pdf",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const file = event.target.files?.[0];
                              if (file && file.size <= 10 * 1024 * 1024) {
                                formik.setFieldValue("education_licence", file);
                              } else {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 10МБ",
                                );
                                formik.setFieldValue("education_licence", null);
                              }
                            }}
                            sx={{ display: "none" }}
                            disabled={isInputDisabled}
                          />
                          {formik.values.education_licence && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue("education_licence", null);
                              }}
                              disabled={isInputDisabled}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values.education_licence.name
                                  ? formik.values.education_licence.name
                                  : "Копия лицензия"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values.education_licence && (
                            <label htmlFor="education_licence">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                                disabled={isInputDisabled}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 10 МВ)
                                </Box>
                              </Button>
                            </label>
                          )}
                          <FormHelperText>
                            {formik?.touched?.education_licence &&
                              formik?.errors?.education_licence}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        !!formik.values.education_licence && (
                          <FileDownloadField
                            fileName={
                              formik.values.education_licence?.name
                                ? formik.values.education_licence.name
                                : "Акт приемки"
                            }
                            fileUrl={
                              !!formik.values.education_licence &&
                              typeof formik.values.education_licence ===
                                "string"
                                ? formik.values.education_licence
                                : ""
                            }
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                )}

              {(!isInputDisabled || formik?.values.bank_certificate) &&
                formik?.values.private_organization_government_order === 0 && (
                  <Grid container spacing={1}>
                    {
                      // (!isInputDisabled || formik?.values.bank_certificate) &&
                      formik?.values.private_organization_government_order ===
                        0 && (
                        <Grid item xs={12}>
                          <StyledLabel
                            id="demo-simple-select-label"
                            sx={{ fontWeight: "500" }}
                          >
                            Справка о наличии счета в банке второго уровня
                            Республики Казахстан
                          </StyledLabel>
                        </Grid>
                      )
                    }
                    <Grid item xs={3}>
                      {!isInputDisabled &&
                      formik?.values.private_organization_government_order ===
                        0 ? (
                        <FormControl fullWidth>
                          <Input
                            id="bank_certificate"
                            name="bank_certificate"
                            type="file"
                            inputProps={{
                              accept: ".pdf",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const file = event.target.files?.[0];
                              if (file && file.size <= 10 * 1024 * 1024) {
                                formik.setFieldValue("bank_certificate", file);
                              } else {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 10МБ",
                                );
                                formik.setFieldValue("bank_certificate", null);
                              }
                            }}
                            error={
                              Boolean(
                                formik?.touched?.legal_entity_certificate &&
                                  formik?.errors?.legal_entity_certificate,
                              ) || false
                            }
                            sx={{ display: "none" }}
                            disabled={isInputDisabled}
                          />
                          {formik.values.bank_certificate && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue("bank_certificate", null);
                              }}
                              //disabled={isInputDisabled}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values.bank_certificate.name
                                  ? formik.values.bank_certificate.name
                                  : "Справка о наличии счета"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values.bank_certificate && (
                            <label htmlFor="bank_certificate">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                                //disabled={isInputDisabled}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 10 МВ)
                                </Box>
                              </Button>
                            </label>
                          )}
                          <FormHelperText>
                            {formik?.touched?.bank_certificate &&
                              formik?.errors?.bank_certificate}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        !!formik.values.bank_certificate &&
                        formik?.values.private_organization_government_order ===
                          0 && (
                          <FileDownloadField
                            fileName={
                              formik.values.bank_certificate.name
                                ? formik.values.bank_certificate.name
                                : "Справка о наличии счета"
                            }
                            fileUrl={
                              !!formik.values.bank_certificate &&
                              typeof formik.values.bank_certificate === "string"
                                ? formik.values.bank_certificate
                                : ""
                            }
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                )}

              {(!isInputDisabled || formik?.values.anti_terrorism_measures) &&
                formik?.values.private_organization_government_order === 0 && (
                  <Grid container spacing={1}>
                    {formik?.values.private_organization_government_order ===
                      0 && (
                      <Grid item xs={12}>
                        <StyledLabel
                          id="demo-simple-select-label"
                          sx={{ fontWeight: "500" }}
                        >
                          Документ, подтверждающий соблюдение требований
                          антитеррористической защиты в соответствии с
                          Инструкцией
                        </StyledLabel>
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      {!isInputDisabled &&
                      formik?.values.private_organization_government_order ===
                        0 ? (
                        <FormControl fullWidth>
                          <Input
                            id="anti_terrorism_measures"
                            name="anti_terrorism_measures"
                            type="file"
                            inputProps={{
                              accept: ".pdf",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const file = event.target.files?.[0];
                              if (file && file.size <= 10 * 1024 * 1024) {
                                formik.setFieldValue(
                                  "anti_terrorism_measures",
                                  file,
                                );
                              } else {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 10МБ",
                                );
                                formik.setFieldValue(
                                  "anti_terrorism_measures",
                                  null,
                                );
                              }
                            }}
                            error={
                              Boolean(
                                formik?.touched?.anti_terrorism_measures &&
                                  formik?.errors?.anti_terrorism_measures,
                              ) || false
                            }
                            sx={{ display: "none" }}
                            disabled={isInputDisabled}
                          />
                          {formik.values.anti_terrorism_measures && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue(
                                  "anti_terrorism_measures",
                                  null,
                                );
                              }}
                              //disabled={isInputDisabled}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values.anti_terrorism_measures.name
                                  ? formik.values.anti_terrorism_measures.name
                                  : "Документ, подтверждающий соблюдение требований антитеррористической защиты в соответствии с Инструкцией"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values.anti_terrorism_measures && (
                            <label htmlFor="anti_terrorism_measures">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                                //disabled={isInputDisabled}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 10 МВ)
                                </Box>
                              </Button>
                            </label>
                          )}
                          <FormHelperText>
                            {formik?.touched?.anti_terrorism_measures &&
                              formik?.errors?.anti_terrorism_measures}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        !!formik.values.anti_terrorism_measures &&
                        formik?.values.private_organization_government_order ===
                          0 && (
                          <FileDownloadField
                            fileName={
                              formik.values.anti_terrorism_measures.name
                                ? formik.values.anti_terrorism_measures.name
                                : "Документ, подтверждающий соблюдение требований антитеррористической защиты в соответствии с Инструкцией"
                            }
                            fileUrl={
                              !!formik.values.anti_terrorism_measures &&
                              typeof formik.values.anti_terrorism_measures ===
                                "string"
                                ? formik.values.anti_terrorism_measures
                                : ""
                            }
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                )}
              {(!isInputDisabled ||
                formik.values.integrated_education_realization_documents) &&
              formik.values.private_organization_government_order === 0 &&
              !!formik2Values.integrated_education ? (
                // <Grid container spacing={1}>
                //   {(!isInputDisabled ||
                //     formik.values.integrated_education_realization_documents) &&
                //     formik.values.private_organization_government_order ===
                //       0 && (
                //       <Grid item xs={12}>
                //         <StyledLabel
                //           id="demo-simple-select-label"
                //           sx={{ marginTop: "24px", fontWeight: "500" }}
                //         >
                //           Документы, подтверждающие реализацию самостоятельно
                //           разработанных интегрированных образовательных
                //           программ, прохождение авторизации в Организации
                //           Международного Бакалавриата или международную
                //           институциональную аккредитацию - для частных
                //           организаций образования, реализующих самостоятельно
                //           разработанные интегрированные образовательные
                //           программы, прошедших авторизацию в Организации
                //           Международного Бакалавриата или международную
                //           институциональную аккредитацию
                //         </StyledLabel>
                //       </Grid>
                //     )}
                //   <Grid item xs={3}>
                //     {!isInputDisabled &&
                //     formik.values.private_organization_government_order ===
                //       0 ? (
                //       <FormControl fullWidth>
                //         <Input
                //           id="integrated_education_realization_documents"
                //           name="integrated_education_realization_documents"
                //           type="file"
                //           inputProps={{
                //             accept: ".pdf",
                //             multiple: true,
                //           }}
                //           onChange={(
                //             event: React.ChangeEvent<HTMLInputElement>,
                //           ) => {
                //             const files = event.target.files;
                //             if (files) {
                //               const newFiles = Array.from(files);
                //               if (
                //                 newFiles.some(
                //                   (file) => file.size > 10 * 1024 * 1024,
                //                 )
                //               ) {
                //                 toast.error(
                //                   "Пожалуйста, загрузите файлы меньше 10МБ",
                //                 );
                //                 formik.setFieldValue(
                //                   "integrated_education_realization_documents",
                //                   null,
                //                 );
                //               } else {
                //                 formik.setFieldValue(
                //                   "integrated_education_realization_documents",
                //                   newFiles,
                //                 );
                //               }
                //               setFileAll(newFiles);
                //             }
                //           }}
                //           sx={{ display: "none" }}
                //         />
                //         {formik.values
                //           .integrated_education_realization_documents && (
                //           <Button
                //             variant="outlined"
                //             color="error"
                //             endIcon={<ClearIcon />}
                //             onClick={() => {
                //               formik.setFieldValue(
                //                 "integrated_education_realization_documents",
                //                 null,
                //               );
                //               setFileAll([]);
                //             }}
                //           >
                //             <Box
                //               component="span"
                //               sx={{
                //                 whiteSpace: "nowrap",
                //                 overflow: "hidden",
                //                 textOverflow: "ellipsis",
                //               }}
                //             >
                //               {formik.values
                //                 .integrated_education_realization_documents.name
                //                 ? formik.values
                //                     .integrated_education_realization_documents
                //                     .name
                //                 : "6)Документы, подтверждающие реализацию самостоятельно разработанных интегрированных образовательных программ, прохождение авторизации в Организации Международного Бакалавриата или международную институциональную аккредитацию - для частных организаций образования, реализующих самостоятельно разработанные интегрированные образовательные программы, прошедших авторизацию в Организации Международного Бакалавриата или международную институциональную аккредитацию (при наличии)"}
                //             </Box>
                //           </Button>
                //         )}
                //
                //         {!formik.values
                //           .integrated_education_realization_documents && (
                //           <label htmlFor="integrated_education_realization_documents">
                //             <Button
                //               variant="contained"
                //               component="div"
                //               sx={{
                //                 backgroundColor: "#F4F6FA",
                //                 display: "flex",
                //                 color: "#A7ADBE",
                //               }}
                //             >
                //               <CustomUploadIcon
                //                 sx={{ width: "12px", height: "15px" }}
                //               />
                //               <Box component="div" sx={{ marginLeft: "12px" }}>
                //                 Загрузить (макс 10 МВ)
                //               </Box>
                //             </Button>
                //           </label>
                //         )}
                //         <FormHelperText>
                //           {formik?.touched
                //             ?.integrated_education_realization_documents &&
                //             formik?.errors
                //               ?.integrated_education_realization_documents}
                //         </FormHelperText>
                //         {fileAll && !!fileAll?.length && (
                //           <Box sx={{ mt: "8px" }}>
                //             {fileAll.map((file: any, index: number) => {
                //               const url = URL.createObjectURL(file);
                //               return (
                //                 <FileDownloadField
                //                   key={index}
                //                   fileName={
                //                     file.name
                //                       ? file.name
                //                       : `${index}. Документы, подтверждающие реализацию`
                //                   }
                //                   fileUrl={url}
                //                 />
                //               );
                //             })}
                //           </Box>
                //         )}
                //       </FormControl>
                //     ) : (
                //       formik.values.private_organization_government_order ===
                //         0 &&
                //       !!formik.values
                //         .integrated_education_realization_documents &&
                //       typeof formik.values
                //         .integrated_education_realization_documents ===
                //         "string" &&
                //       JSON.parse(
                //         formik.values
                //           .integrated_education_realization_documents,
                //       ).map((item: string, i: number) => (
                //         <FileDownloadField
                //           key={i}
                //           fileName={"Документ"}
                //           fileUrl={item}
                //         />
                //       ))
                //     )}
                //   </Grid>
                // </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <StyledLabel
                      id="demo-simple-select-label"
                      sx={{ marginTop: "24px", fontWeight: "500" }}
                    >
                      Документы, подтверждающие реализацию самостоятельно
                      разработанных интегрированных образовательных программ,
                      прохождение авторизации в Организации Международного
                      Бакалавриата или международную институциональную
                      аккредитацию - для частных организаций образования,
                      реализующих самостоятельно разработанные интегрированные
                      образовательные программы, прошедших авторизацию в
                      Организации Международного Бакалавриата или международную
                      институциональную аккредитацию
                    </StyledLabel>
                  </Grid>
                  <Grid item xs={3}>
                    {!isInputDisabled ? (
                      <FormControl fullWidth>
                        <Input
                          id="integrated_education_realization_documents"
                          name="integrated_education_realization_documents"
                          type="file"
                          inputProps={{
                            accept: ".pdf",
                            // multiple: true,
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const files = event.target.files;
                            if (files) {
                              const newFiles = Array.from(files);
                              if (
                                newFiles.some(
                                  (file) => file.size > 10 * 1024 * 1024,
                                )
                              ) {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 10МБ",
                                );
                                formik.setFieldValue(
                                  "integrated_education_realization_documents",
                                  null,
                                );
                              } else {
                                formik.setFieldValue(
                                  "integrated_education_realization_documents",
                                  newFiles,
                                );
                              }
                              setFileAll(newFiles);
                            }
                          }}
                          sx={{ display: "none" }}
                        />
                        {formik.values
                          .integrated_education_realization_documents && (
                          <Button
                            variant="outlined"
                            color="error"
                            endIcon={<ClearIcon />}
                            onClick={() => {
                              formik.setFieldValue(
                                "integrated_education_realization_documents",
                                null,
                              );
                              setFileAll([]);
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {formik.values
                                .integrated_education_realization_documents.name
                                ? formik.values
                                    .integrated_education_realization_documents
                                    .name
                                : "Документы, подтверждающие реализацию самостоятельно разработанных интегрированных образовательных программ, прохождение авторизации в Организации Международного Бакалавриата или международную институциональную аккредитацию - для частных организаций образования, реализующих самостоятельно разработанные интегрированные образовательные программы, прошедших авторизацию в Организации Международного Бакалавриата или международную институциональную аккредитацию (при наличии)"}
                            </Box>
                          </Button>
                        )}
                        {!formik.values
                          .integrated_education_realization_documents && (
                          <label htmlFor="integrated_education_realization_documents">
                            <Button
                              variant="contained"
                              component="div"
                              sx={{
                                backgroundColor: "#F4F6FA",
                                display: "flex",
                                color: "#A7ADBE",
                              }}
                            >
                              <CustomUploadIcon
                                sx={{ width: "12px", height: "15px" }}
                              />
                              <Box component="div" sx={{ marginLeft: "12px" }}>
                                Загрузить (макс 10 МВ)
                              </Box>
                            </Button>
                          </label>
                        )}
                        <FormHelperText>
                          {formik?.touched
                            ?.integrated_education_realization_documents &&
                            formik?.errors
                              ?.integrated_education_realization_documents}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      formik.values.private_organization_government_order ===
                        0 &&
                      !!formik.values.legal_entity_certificate &&
                      !!formik.values
                        .integrated_education_realization_documents &&
                      typeof formik.values
                        .integrated_education_realization_documents ===
                        "string" &&
                      JSON.parse(
                        formik.values
                          .integrated_education_realization_documents,
                      ).map((item: string, i: number) => (
                        <FileDownloadField
                          key={i}
                          fileName={"Сведения самостоят. разраб. ОП"}
                          fileDownloadName={"Сведения_самостоят_разраб_ОП"}
                          fileUrl={item}
                        />
                      ))
                    )}
                  </Grid>
                </Grid>
              ) : null}
              {/* {
              formik2Values.private_organization_exploitation_type ===
              "Строительство/реконструкция" ? ( */}
              <Grid container spacing={1}>
                {formik2Values.registered_immutability_agreement &&
                formik.values.private_organization_government_order === 0 &&
                (!isInputDisabled ||
                  formik?.values.preliminary_agreement ||
                  formik.values.project_documentation ||
                  formik.values.exploitation_act ||
                  formik.values.condition_conclusion ||
                  formik.values.real_estate_certificate ||
                  formik.values.cadastral_information) &&
                formik?.values.private_organization_government_order === 0 ? (
                  <>
                    <Typography
                      sx={{
                        marginBottom: "16px",
                        fontSize: "14px",
                        paddingLeft: "8px",
                        paddingTop: "8px",
                        lineHeight: "1.5rem",
                        fontWeight: "500",
                      }}
                    >
                      Для частных организаций образования с вновь введенными
                      ученическими местами и (или) местами для проживания в
                      объектах частных организаций среднего образования,
                      предусмотренными Методикой. При этом предельный срок
                      эксплуатации здания с вновь введенными путем реконструкции
                      ученическими местами в объектах частных организаций
                      среднего образования, предусмотренными Методикой,
                      составляет 30 (тридцать) лет на дату первоначальной подачи
                      заявления на данный объект:
                    </Typography>

                    <Grid container justifyContent="center">
                      <Typography variant={"h2"}>ОБЪЕКТ «ШКОЛА»</Typography>
                    </Grid>

                    {(!isInputDisabled ||
                      formik?.values.preliminary_agreement) && (
                      <Grid item xs={12}>
                        <StyledLabel id="demo-simple-select-label">
                          Копия действующего предварительного договора,
                          заключенного между оператором и частной организацией
                          образования по утвержденной оператором форме
                        </StyledLabel>
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      {(!isInputDisabled ||
                        formik?.values.preliminary_agreement) && (
                        <Grid item xs={12}>
                          <StyledLabel id="demo-simple-select-label">
                            Пред договор
                          </StyledLabel>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {!isInputDisabled ? (
                          <FormControl fullWidth>
                            <Input
                              id="preliminary_agreement"
                              name="preliminary_agreement"
                              type="file"
                              inputProps={{
                                accept: ".pdf",
                              }}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                const file = event.target.files?.[0];
                                if (file && file.size <= 10 * 1024 * 1024) {
                                  formik.setFieldValue(
                                    "preliminary_agreement",
                                    file,
                                  );
                                } else {
                                  toast.error(
                                    "Пожалуйста, загрузите файлы меньше 10МБ",
                                  );
                                  formik.setFieldValue(
                                    "preliminary_agreement",
                                    null,
                                  );
                                }
                              }}
                              sx={{ display: "none" }}
                              disabled={isInputDisabled}
                            />
                            {formik.values.preliminary_agreement && (
                              <Button
                                variant="outlined"
                                color="error"
                                endIcon={<ClearIcon />}
                                onClick={() => {
                                  formik.setFieldValue(
                                    "preliminary_agreement",
                                    null,
                                  );
                                }}
                                // disabled={isInputDisabled}
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {formik.values.preliminary_agreement.name
                                    ? formik.values.preliminary_agreement.name
                                    : "Пред договор"}
                                </Box>
                              </Button>
                            )}
                            {!formik.values.preliminary_agreement && (
                              <label htmlFor="preliminary_agreement">
                                <Button
                                  variant="contained"
                                  component="div"
                                  sx={{
                                    backgroundColor: "#F4F6FA",
                                    display: "flex",
                                    color: "#A7ADBE",
                                  }}
                                  // disabled={isInputDisabled}
                                >
                                  <CustomUploadIcon
                                    sx={{ width: "12px", height: "15px" }}
                                  />
                                  <Box
                                    component="div"
                                    sx={{ marginLeft: "12px" }}
                                  >
                                    Загрузить (макс 10 МВ)
                                  </Box>
                                </Button>
                              </label>
                            )}
                            <FormHelperText>
                              {formik?.touched?.preliminary_agreement &&
                                formik?.errors?.preliminary_agreement}
                            </FormHelperText>
                          </FormControl>
                        ) : (
                          !!formik.values.preliminary_agreement && (
                            <FileDownloadField
                              fileName={
                                formik.values.preliminary_agreement?.name
                                  ? formik.values.preliminary_agreement.name
                                  : "Пред договор"
                              }
                              fileUrl={
                                !!formik.values.preliminary_agreement &&
                                typeof formik.values.preliminary_agreement ===
                                  "string"
                                  ? formik.values.preliminary_agreement
                                  : ""
                              }
                            />
                          )
                        )}
                      </Grid>
                    </Grid>
                    {formik.values.private_organization_government_order ===
                      0 && (
                      <Grid item xs={6}>
                        {(!isInputDisabled ||
                          formik?.values.immutability_agreement) && (
                          <Grid item xs={12}>
                            <StyledLabel id="demo-simple-select-label">
                              Соглашение о неизменности
                            </StyledLabel>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          {!isInputDisabled ? (
                            <FormControl fullWidth>
                              <Input
                                id="immutability_agreement"
                                name="immutability_agreement"
                                type="file"
                                inputProps={{
                                  accept: ".pdf",
                                }}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const file = event.target.files?.[0];
                                  if (file && file.size <= 10 * 1024 * 1024) {
                                    formik.setFieldValue(
                                      "immutability_agreement",
                                      file,
                                    );
                                  } else {
                                    toast.error(
                                      "Пожалуйста, загрузите файлы меньше 10МБ",
                                    );
                                    formik.setFieldValue(
                                      "immutability_agreement",
                                      null,
                                    );
                                  }
                                }}
                                required
                                sx={{ display: "none" }}
                                disabled={isInputDisabled}
                              />
                              {formik.values.immutability_agreement && (
                                <Button
                                  variant="outlined"
                                  color="error"
                                  endIcon={<ClearIcon />}
                                  onClick={() => {
                                    formik.setFieldValue(
                                      "immutability_agreement",
                                      null,
                                    );
                                  }}
                                >
                                  <Box
                                    component="span"
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {formik.values.immutability_agreement.name
                                      ? formik.values.immutability_agreement
                                          .name
                                      : "Соглашение о неизменности"}
                                  </Box>
                                </Button>
                              )}
                              {!formik.values.immutability_agreement && (
                                <label htmlFor="immutability_agreement">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    sx={{
                                      backgroundColor: "#F4F6FA",
                                      display: "flex",
                                      color: "#A7ADBE",
                                    }}
                                  >
                                    <CustomUploadIcon
                                      sx={{ width: "12px", height: "15px" }}
                                    />
                                    <Box
                                      component="div"
                                      sx={{ marginLeft: "12px" }}
                                    >
                                      Загрузить (макс 10 МВ)
                                    </Box>
                                  </Button>
                                </label>
                              )}
                              {/* Вывод сообщения об ошибке, если файл не загружен */}
                              {!formik.values.immutability_agreement && (
                                <FormHelperText error>
                                  {formik.touched.immutability_agreement &&
                                    !formik.values.immutability_agreement &&
                                    "Пожалуйста, загрузите файл"}
                                </FormHelperText>
                              )}
                            </FormControl>
                          ) : (
                            // Обработка, если файл уже загружен
                            !!formik.values.immutability_agreement && (
                              <FileDownloadField
                                fileName={
                                  formik.values.immutability_agreement?.name
                                    ? formik.values.immutability_agreement.name
                                    : "Соглашение о неизменности"
                                }
                                fileUrl={
                                  !!formik.values.immutability_agreement &&
                                  typeof formik.values
                                    .immutability_agreement === "string"
                                    ? formik.values.immutability_agreement
                                    : ""
                                }
                              />
                            )
                          )}
                        </Grid>
                      </Grid>
                    )}

                    {(!isInputDisabled ||
                      formik?.values.project_documentation) && (
                      <Grid item xs={10}>
                        <StyledLabel id="demo-simple-select-label">
                          Проектная (проектно-сметная) документация на объект
                          частной организации образования, разработанная и
                          утвержденная в соответствии со статьей 60 Закона об
                          архитектурной деятельности, либо судебный акт о праве
                          собственности на объект
                        </StyledLabel>
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      {!isInputDisabled ? (
                        <FormControl fullWidth>
                          <Input
                            id="project_documentation"
                            name="project_documentation"
                            type="file"
                            inputProps={{
                              accept: ".zip",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const file = event.target.files?.[0];
                              if (file && file.size <= 50 * 1024 * 1024) {
                                formik.setFieldValue(
                                  "project_documentation",
                                  file,
                                );
                              } else {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 50МБ",
                                );
                                formik.setFieldValue(
                                  "project_documentation",
                                  null,
                                );
                              }
                            }}
                            sx={{ display: "none" }}
                            disabled={isInputDisabled}
                          />
                          {formik.values.project_documentation && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue(
                                  "project_documentation",
                                  null,
                                );
                              }}
                              // disabled={isInputDisabled}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values.project_documentation.name
                                  ? formik.values.project_documentation.name
                                  : "Проектная (проектно-сметная) документация на объект частной организации образования, разработанная и утвержденная в соответствии со статьей 60 Закона об архитектурной деятельности, либо судебный акт о праве собственности на объект"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values.project_documentation && (
                            <label htmlFor="project_documentation">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 50 МВ)
                                </Box>
                              </Button>
                            </label>
                          )}
                          <FormHelperText>
                            {formik?.touched?.project_documentation &&
                              formik?.errors?.project_documentation}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        !!formik.values.project_documentation && (
                          <FileDownloadField
                            fileName={
                              formik.values.project_documentation?.name
                                ? formik.values.project_documentation.name
                                : "Проектная (проектно-сметная) документация"
                            }
                            fileUrl={
                              !!formik.values.project_documentation &&
                              typeof formik.values.project_documentation ===
                                "string"
                                ? formik.values.project_documentation
                                : ""
                            }
                          />
                        )
                      )}
                    </Grid>
                    {(!isInputDisabled || formik?.values.exploitation_act) && (
                      <Grid item xs={10}>
                        <StyledLabel id="demo-simple-select-label">
                          Акт приемки построенного объекта в эксплуатацию,
                          подтверждающий дату ввода объекта в эксплуатацию в
                          соответствии с пунктом 4 статьи 73 Закона об
                          архитектурной деятельности
                        </StyledLabel>
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      {!isInputDisabled ? (
                        <FormControl fullWidth>
                          <Input
                            id="exploitation_act"
                            name="exploitation_act"
                            type="file"
                            inputProps={{
                              accept: ".pdf",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const file = event.target.files?.[0];
                              if (file && file.size <= 10 * 1024 * 1024) {
                                formik.setFieldValue("exploitation_act", file);
                              } else {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 10МБ",
                                );
                                formik.setFieldValue("exploitation_act", null);
                              }
                            }}
                            sx={{ display: "none" }}
                          />
                          {formik.values.exploitation_act && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue("exploitation_act", null);
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values.exploitation_act.name
                                  ? formik.values.exploitation_act.name
                                  : "Акт приемки построенного объекта в эксплуатацию, подтверждающий дату ввода объекта в эксплуатацию в соответствии с пунктом 4 статьи 73 Закона об архитектурной деятельности"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values.exploitation_act && (
                            <label htmlFor="exploitation_act">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 10 МВ)
                                </Box>
                              </Button>
                            </label>
                          )}
                          <FormHelperText>
                            {formik?.touched?.exploitation_act &&
                              formik?.errors?.exploitation_act}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        !!formik.values.exploitation_act && (
                          <FileDownloadField
                            fileName={
                              formik.values.exploitation_act?.name
                                ? formik.values.exploitation_act.name
                                : "Акт приемки"
                            }
                            fileUrl={
                              !!formik.values.exploitation_act &&
                              typeof formik.values.exploitation_act === "string"
                                ? formik.values.exploitation_act
                                : ""
                            }
                          />
                        )
                      )}
                    </Grid>
                    {!!formik2Values.registered_immutability_agreement &&
                      formik2Values.private_organization_exploitation_type ===
                        "Реконструкция" && (
                        <>
                          {(!isInputDisabled ||
                            formik?.values.condition_conclusion) && (
                            <Grid item xs={10}>
                              <StyledLabel id="demo-simple-select-label">
                                Заключение о состоянии подлежащего реконструкции
                                здания в соответствии с пунктом 4 статьей 34-4
                                Закона об архитектурной деятельности, при
                                реконструкции
                              </StyledLabel>
                            </Grid>
                          )}
                          <Grid item xs={3}>
                            {!isInputDisabled ? (
                              <FormControl fullWidth>
                                <Input
                                  id="condition_conclusion"
                                  name="condition_conclusion"
                                  type="file"
                                  inputProps={{
                                    accept: ".pdf",
                                  }}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    const file = event.target.files?.[0];
                                    if (file && file.size <= 10 * 1024 * 1024) {
                                      formik.setFieldValue(
                                        "condition_conclusion",
                                        file,
                                      );
                                    } else {
                                      toast.error(
                                        "Пожалуйста, загрузите файлы меньше 10МБ",
                                      );
                                      formik.setFieldValue(
                                        "condition_conclusion",
                                        null,
                                      );
                                    }
                                  }}
                                  sx={{ display: "none" }}
                                />
                                {formik.values.condition_conclusion && (
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    endIcon={<ClearIcon />}
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "condition_conclusion",
                                        null,
                                      );
                                    }}
                                  >
                                    <Box
                                      component="span"
                                      sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {formik.values.condition_conclusion.name
                                        ? formik.values.condition_conclusion
                                            .name
                                        : "Заключение о состоянии подлежащего реконструкции здания в соответствии с пунктом 4 статьей 34-4 Закона об архитектурной деятельности, при реконструкции"}
                                    </Box>
                                  </Button>
                                )}
                                {!formik.values.condition_conclusion && (
                                  <label htmlFor="condition_conclusion">
                                    <Button
                                      variant="contained"
                                      component="div"
                                      sx={{
                                        backgroundColor: "#F4F6FA",
                                        display: "flex",
                                        color: "#A7ADBE",
                                      }}
                                    >
                                      <CustomUploadIcon
                                        sx={{ width: "12px", height: "15px" }}
                                      />
                                      <Box
                                        component="div"
                                        sx={{ marginLeft: "12px" }}
                                      >
                                        Загрузить (макс 10 МВ)
                                      </Box>
                                    </Button>
                                  </label>
                                )}
                                <FormHelperText>
                                  {formik?.touched?.condition_conclusion &&
                                    formik?.errors?.condition_conclusion}
                                </FormHelperText>
                              </FormControl>
                            ) : (
                              !!formik.values.condition_conclusion && (
                                <FileDownloadField
                                  fileName={
                                    formik.values.condition_conclusion?.name
                                      ? formik.values.condition_conclusion.name
                                      : "Заключение"
                                  }
                                  fileUrl={
                                    !!formik.values.condition_conclusion &&
                                    typeof formik.values
                                      .condition_conclusion === "string"
                                      ? formik.values.condition_conclusion
                                      : ""
                                  }
                                />
                              )
                            )}
                          </Grid>
                        </>
                      )}
                    {(!isInputDisabled ||
                      formik?.values.real_estate_certificate) && (
                      <Grid item xs={10}>
                        <StyledLabel id="demo-simple-select-label">
                          Сведения о зарегистрированных правах (обременениях) на
                          недвижимое имущество и его технических характеристиках
                          с зарегистрированным обременением права на изменение
                          целевого назначения объекта частной организации
                          среднего образования, указанного в заявлении и
                          сведениях об интернатной организации (при наличии), на
                          срок не менее двадцати лет
                        </StyledLabel>
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      {!isInputDisabled ? (
                        <FormControl fullWidth>
                          <Input
                            id="real_estate_certificate"
                            name="real_estate_certificate"
                            type="file"
                            inputProps={{
                              accept: ".pdf",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const file = event.target.files?.[0];
                              if (file && file.size <= 10 * 1024 * 1024) {
                                formik.setFieldValue(
                                  "real_estate_certificate",
                                  file,
                                );
                              } else {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 10МБ",
                                );
                                formik.setFieldValue(
                                  "real_estate_certificate",
                                  null,
                                );
                              }
                            }}
                            sx={{ display: "none" }}
                          />
                          {formik.values.real_estate_certificate && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue(
                                  "real_estate_certificate",
                                  null,
                                );
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values.real_estate_certificate.name
                                  ? formik.values.real_estate_certificate.name
                                  : "Сведения о зарегистрированных правах (обременениях) на недвижимое имущество и его технических характеристиках с зарегистрированным обременением права на изменение целевого назначения объекта частной организации среднего образования, указанного в заявлении и сведениях об интернатной организации (при наличии), на срок не менее двадцати лет"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values.real_estate_certificate && (
                            <label htmlFor="real_estate_certificate">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 10 МВ)
                                </Box>
                              </Button>
                            </label>
                          )}
                          <FormHelperText>
                            {formik?.touched?.real_estate_certificate &&
                              formik?.errors?.real_estate_certificate}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        !!formik.values.real_estate_certificate && (
                          <FileDownloadField
                            fileName={
                              formik.values.real_estate_certificate?.name
                                ? formik.values.real_estate_certificate.name
                                : "Сведения о зарегистрированных правах "
                            }
                            fileUrl={
                              !!formik.values.real_estate_certificate &&
                              typeof formik.values.real_estate_certificate ===
                                "string"
                                ? formik.values.real_estate_certificate
                                : ""
                            }
                          />
                        )
                      )}
                    </Grid>

                    <React.Fragment>
                      {!isInputDisabled && (
                        <Grid item xs={10}>
                          <StyledLabel id="demo-simple-select-label">
                            Сведения из правового кадастра о наличии либо
                            отсутствии зарегистрированных прав на объект частной
                            организации образования, указанный в заявлении и
                            (или) сведениях об интернатной организации (при
                            наличии), в том числе о виде права, правообладателе,
                            основании возникновения права, а также об основании
                            прекращения права (при наличии)
                          </StyledLabel>
                        </Grid>
                      )}
                      <Grid item xs={3} marginBottom={"24px"}>
                        {!isInputDisabled ? (
                          <FormControl fullWidth>
                            <Input
                              id="cadastral_information"
                              name="cadastral_information"
                              type="file"
                              inputProps={{
                                accept: ".pdf",
                              }}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                const file = event.target.files?.[0];
                                if (file && file.size <= 10 * 1024 * 1024) {
                                  formik.setFieldValue(
                                    "cadastral_information",
                                    file,
                                  );
                                } else {
                                  toast.error(
                                    "Пожалуйста, загрузите файлы меньше 10МБ",
                                  );
                                  formik.setFieldValue(
                                    "cadastral_information",
                                    null,
                                  );
                                }
                              }}
                              sx={{ display: "none" }}
                            />
                            {formik.values.cadastral_information && (
                              <Button
                                variant="outlined"
                                color="error"
                                endIcon={<ClearIcon />}
                                onClick={() => {
                                  formik.setFieldValue(
                                    "cadastral_information",
                                    null,
                                  );
                                }}
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {formik.values.cadastral_information.name
                                    ? formik.values.cadastral_information.name
                                    : "Сведения из правового кадастра о наличии либо отсутствии зарегистрированных прав на объект частной организации образования, указанный в заявлении и (или) сведениях об интернатной организации (при наличии), в том числе о виде права, правообладателе, основании возникновения права, а также об основании прекращения права (при наличии)"}
                                </Box>
                              </Button>
                            )}
                            {!formik.values.cadastral_information && (
                              <label htmlFor="cadastral_information">
                                <Button
                                  variant="contained"
                                  component="div"
                                  sx={{
                                    backgroundColor: "#F4F6FA",
                                    display: "flex",
                                    color: "#A7ADBE",
                                  }}
                                >
                                  <CustomUploadIcon
                                    sx={{ width: "12px", height: "15px" }}
                                  />
                                  <Box
                                    component="div"
                                    sx={{ marginLeft: "12px" }}
                                  >
                                    Загрузить (макс 10 МВ)
                                  </Box>
                                </Button>
                              </label>
                            )}
                            <FormHelperText>
                              {formik?.touched?.cadastral_information &&
                                formik?.errors?.cadastral_information}
                            </FormHelperText>
                          </FormControl>
                        ) : (
                          !!formik.values.cadastral_information && (
                            <FileDownloadField
                              fileName={
                                formik.values.cadastral_information?.name
                                  ? formik.values.cadastral_information.name
                                  : "Сведения из правового кадастра"
                              }
                              fileUrl={
                                !!formik.values.cadastral_information &&
                                typeof formik.values.cadastral_information ===
                                  "string"
                                  ? formik.values.cadastral_information
                                  : ""
                              }
                            />
                          )
                        )}
                      </Grid>
                    </React.Fragment>
                  </>
                ) : null}
              </Grid>
            </>
            {formik2Values.boarding_school_registered_immutability_agreement &&
            formik?.values.private_organization_government_order === 0 &&
            formik2Values.boarding_school === "Есть" ? (
              <>
                <Typography
                  variant={"h2"}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  ОБЪЕКТ «ИНТЕРНАТ»
                </Typography>
                <Grid container spacing={1}>
                  {(!isInputDisabled ||
                    formik?.values.boarding_school_preliminary_agreement) && (
                    <Grid item xs={12}>
                      <StyledLabel id="demo-simple-select-label">
                        Копия действующего предварительного договора,
                        заключенного между оператором и частной организацией
                        образования по утвержденной оператором форме
                      </StyledLabel>
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    {(!isInputDisabled ||
                      formik?.values.boarding_school_preliminary_agreement) && (
                      <Grid item xs={12}>
                        <StyledLabel id="demo-simple-select-label">
                          Пред договор
                        </StyledLabel>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {!isInputDisabled ? (
                        <FormControl fullWidth>
                          <Input
                            id="boarding_school_preliminary_agreement"
                            name="boarding_school_preliminary_agreement"
                            type="file"
                            inputProps={{
                              accept: ".pdf",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const file = event.target.files?.[0];
                              if (file && file.size <= 10 * 1024 * 1024) {
                                formik.setFieldValue(
                                  "boarding_school_preliminary_agreement",
                                  file,
                                );
                              } else {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 10МБ",
                                );
                                formik.setFieldValue(
                                  "boarding_school_preliminary_agreement",
                                  null,
                                );
                              }
                            }}
                            sx={{ display: "none" }}
                            disabled={isInputDisabled}
                          />
                          {formik.values
                            .boarding_school_preliminary_agreement && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue(
                                  "boarding_school_preliminary_agreement",
                                  null,
                                );
                              }}
                              // disabled={isInputDisabled}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values
                                  .boarding_school_preliminary_agreement.name
                                  ? formik.values
                                      .boarding_school_preliminary_agreement
                                      .name
                                  : "Пред договор"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values
                            .boarding_school_preliminary_agreement && (
                            <label htmlFor="boarding_school_preliminary_agreement">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                                // disabled={isInputDisabled}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 10 МВ)
                                </Box>
                              </Button>
                            </label>
                          )}
                          <FormHelperText>
                            {formik?.touched
                              ?.boarding_school_preliminary_agreement &&
                              formik?.errors
                                ?.boarding_school_preliminary_agreement}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        !!formik.values
                          .boarding_school_preliminary_agreement && (
                          <FileDownloadField
                            fileName={
                              formik.values
                                .boarding_school_preliminary_agreement?.name
                                ? formik.values
                                    .boarding_school_preliminary_agreement.name
                                : "Пред договор"
                            }
                            fileUrl={
                              !!formik.values
                                .boarding_school_preliminary_agreement &&
                              typeof formik.values
                                .boarding_school_preliminary_agreement ===
                                "string"
                                ? formik.values
                                    .boarding_school_preliminary_agreement
                                : ""
                            }
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <StyledLabel id="demo-simple-select-label">
                        Соглашение о неизменности
                      </StyledLabel>
                    </Grid>

                    <Grid item xs={12}>
                      {!isInputDisabled ? (
                        <FormControl fullWidth>
                          <Input
                            id="boarding_school_immutability_agreement"
                            name="boarding_school_immutability_agreement"
                            type="file"
                            inputProps={{
                              accept: ".pdf",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const file = event.target.files?.[0];
                              if (file && file.size <= 10 * 1024 * 1024) {
                                formik.setFieldValue(
                                  "boarding_school_immutability_agreement",
                                  file,
                                );
                              } else {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 10МБ",
                                );
                                formik.setFieldValue(
                                  "boarding_school_immutability_agreement",
                                  null,
                                );
                              }
                            }}
                            sx={{ display: "none" }}
                            disabled={isInputDisabled}
                          />
                          {formik.values
                            .boarding_school_immutability_agreement && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue(
                                  "boarding_school_immutability_agreement",
                                  null,
                                );
                              }}
                              // disabled={isInputDisabled}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values
                                  .boarding_school_immutability_agreement.name
                                  ? formik.values
                                      .boarding_school_immutability_agreement
                                      .name
                                  : "Соглашение о неизменности"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values
                            .boarding_school_immutability_agreement && (
                            <label htmlFor="boarding_school_immutability_agreement">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                                // disabled={isInputDisabled}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 10 МВ)
                                </Box>
                              </Button>
                              <FormHelperText>
                                {formik?.touched
                                  ?.boarding_school_immutability_agreement &&
                                  formik?.errors
                                    ?.boarding_school_immutability_agreement}
                              </FormHelperText>
                            </label>
                          )}
                        </FormControl>
                      ) : (
                        !!formik.values
                          .boarding_school_immutability_agreement && (
                          <FileDownloadField
                            fileName={
                              formik.values
                                .boarding_school_immutability_agreement?.name
                                ? formik.values
                                    .boarding_school_immutability_agreement.name
                                : "Соглашение о неизменности"
                            }
                            fileUrl={
                              !!formik.values
                                .boarding_school_immutability_agreement &&
                              typeof formik.values
                                .boarding_school_immutability_agreement ===
                                "string"
                                ? formik.values
                                    .boarding_school_immutability_agreement
                                : ""
                            }
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                  {(!isInputDisabled ||
                    formik?.values.boarding_school_project_documentation) && (
                    <Grid item xs={10}>
                      <StyledLabel id="demo-simple-select-label">
                        Проектная (проектно-сметная) документация на объект
                        частной организации образования, разработанная и
                        утвержденная в соответствии со статьей 60 Закона об
                        архитектурной деятельности, либо судебный акт о праве
                        собственности на объект
                      </StyledLabel>
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    {!isInputDisabled ? (
                      <FormControl fullWidth>
                        <Input
                          id="boarding_school_project_documentation"
                          name="boarding_school_project_documentation"
                          type="file"
                          inputProps={{
                            accept: ".zip",
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const file = event.target.files?.[0];
                            if (file && file.size <= 50 * 1024 * 1024) {
                              formik.setFieldValue(
                                "boarding_school_project_documentation",
                                file,
                              );
                            } else {
                              toast.error(
                                "Пожалуйста, загрузите файлы меньше 50МБ",
                              );
                              formik.setFieldValue(
                                "boarding_school_project_documentation",
                                null,
                              );
                            }
                          }}
                          sx={{ display: "none" }}
                          disabled={isInputDisabled}
                        />
                        {formik.values
                          .boarding_school_project_documentation && (
                          <Button
                            variant="outlined"
                            color="error"
                            endIcon={<ClearIcon />}
                            onClick={() => {
                              formik.setFieldValue(
                                "boarding_school_project_documentation",
                                null,
                              );
                            }}
                            // disabled={isInputDisabled}
                          >
                            <Box
                              component="span"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {formik.values
                                .boarding_school_project_documentation.name
                                ? formik.values
                                    .boarding_school_project_documentation.name
                                : "Проектная (проектно-сметная) документация на объект частной организации образования, разработанная и утвержденная в соответствии со статьей 60 Закона об архитектурной деятельности, либо судебный акт о праве собственности на объект"}
                            </Box>
                          </Button>
                        )}
                        {!formik.values
                          .boarding_school_project_documentation && (
                          <label htmlFor="boarding_school_project_documentation">
                            <Button
                              variant="contained"
                              component="div"
                              sx={{
                                backgroundColor: "#F4F6FA",
                                display: "flex",
                                color: "#A7ADBE",
                              }}
                            >
                              <CustomUploadIcon
                                sx={{ width: "12px", height: "15px" }}
                              />
                              <Box component="div" sx={{ marginLeft: "12px" }}>
                                Загрузить (макс 50 МВ)
                              </Box>
                            </Button>
                          </label>
                        )}
                        <FormHelperText>
                          {formik?.touched
                            ?.boarding_school_project_documentation &&
                            formik?.errors
                              ?.boarding_school_project_documentation}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      !!formik.values.boarding_school_project_documentation && (
                        <FileDownloadField
                          fileName={
                            formik.values.boarding_school_project_documentation
                              ?.name
                              ? formik.values
                                  .boarding_school_project_documentation.name
                              : "Проектная (проектно-сметная) документация"
                          }
                          fileUrl={
                            !!formik.values
                              .boarding_school_project_documentation &&
                            typeof formik.values
                              .boarding_school_project_documentation ===
                              "string"
                              ? formik.values
                                  .boarding_school_project_documentation
                              : ""
                          }
                        />
                      )
                    )}
                  </Grid>
                  {(!isInputDisabled ||
                    formik?.values.boarding_school_exploitation_act) && (
                    <Grid item xs={10}>
                      <StyledLabel id="demo-simple-select-label">
                        Акт приемки построенного объекта в эксплуатацию,
                        подтверждающий дату ввода объекта в эксплуатацию в
                        соответствии с пунктом 4 статьи 73 Закона об
                        архитектурной деятельности
                      </StyledLabel>
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    {!isInputDisabled ? (
                      <FormControl fullWidth>
                        <Input
                          id="boarding_school_exploitation_act"
                          name="boarding_school_exploitation_act"
                          type="file"
                          inputProps={{
                            accept: ".pdf",
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const file = event.target.files?.[0];
                            if (file && file.size <= 10 * 1024 * 1024) {
                              formik.setFieldValue(
                                "boarding_school_exploitation_act",
                                file,
                              );
                            } else {
                              toast.error(
                                "Пожалуйста, загрузите файлы меньше 10МБ",
                              );
                              formik.setFieldValue(
                                "boarding_school_exploitation_act",
                                null,
                              );
                            }
                          }}
                          sx={{ display: "none" }}
                        />
                        {formik.values.boarding_school_exploitation_act && (
                          <Button
                            variant="outlined"
                            color="error"
                            endIcon={<ClearIcon />}
                            onClick={() => {
                              formik.setFieldValue(
                                "boarding_school_exploitation_act",
                                null,
                              );
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {formik.values.boarding_school_exploitation_act
                                .name
                                ? formik.values.boarding_school_exploitation_act
                                    .name
                                : "Акт приемки построенного объекта в эксплуатацию, подтверждающий дату ввода объекта в эксплуатацию в соответствии с пунктом 4 статьи 73 Закона об архитектурной деятельности"}
                            </Box>
                          </Button>
                        )}
                        {!formik.values.boarding_school_exploitation_act && (
                          <label htmlFor="boarding_school_exploitation_act">
                            <Button
                              variant="contained"
                              component="div"
                              sx={{
                                backgroundColor: "#F4F6FA",
                                display: "flex",
                                color: "#A7ADBE",
                              }}
                            >
                              <CustomUploadIcon
                                sx={{ width: "12px", height: "15px" }}
                              />
                              <Box component="div" sx={{ marginLeft: "12px" }}>
                                Загрузить (макс 10 МВ)
                              </Box>
                            </Button>
                          </label>
                        )}
                        <FormHelperText>
                          {formik?.touched?.boarding_school_exploitation_act &&
                            formik?.errors?.boarding_school_exploitation_act}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      !!formik.values.boarding_school_exploitation_act && (
                        <FileDownloadField
                          fileName={
                            formik.values.boarding_school_exploitation_act?.name
                              ? formik.values.boarding_school_exploitation_act
                                  .name
                              : "Акт приемки"
                          }
                          fileUrl={
                            !!formik.values.boarding_school_exploitation_act &&
                            typeof formik.values
                              .boarding_school_exploitation_act === "string"
                              ? formik.values.boarding_school_exploitation_act
                              : ""
                          }
                        />
                      )
                    )}
                  </Grid>
                  {!!formik2Values.boarding_school_registered_immutability_agreement &&
                    formik2Values.boarding_school_government_order === 1 && (
                      <>
                        {!isInputDisabled && (
                          <Grid item xs={10}>
                            <StyledLabel id="demo-simple-select-label">
                              Заключение о состоянии подлежащего реконструкции
                              здания в соответствии с пунктом 4 статьей 34-4
                              Закона об архитектурной деятельности, при
                              реконструкции
                            </StyledLabel>
                          </Grid>
                        )}
                        <Grid item xs={3}>
                          {!isInputDisabled ? (
                            <FormControl fullWidth>
                              <Input
                                id="boarding_school_condition_conclusion"
                                name="boarding_school_condition_conclusion"
                                type="file"
                                inputProps={{
                                  accept: ".pdf",
                                }}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const file = event.target.files?.[0];
                                  if (file && file.size <= 10 * 1024 * 1024) {
                                    formik.setFieldValue(
                                      "boarding_school_condition_conclusion",
                                      file,
                                    );
                                  } else {
                                    toast.error(
                                      "Пожалуйста, загрузите файлы меньше 10МБ",
                                    );
                                    formik.setFieldValue(
                                      "boarding_school_condition_conclusion",
                                      null,
                                    );
                                  }
                                }}
                                sx={{ display: "none" }}
                              />
                              {formik.values
                                .boarding_school_condition_conclusion && (
                                <Button
                                  variant="outlined"
                                  color="error"
                                  endIcon={<ClearIcon />}
                                  onClick={() => {
                                    formik.setFieldValue(
                                      "boarding_school_condition_conclusion",
                                      null,
                                    );
                                  }}
                                >
                                  <Box
                                    component="span"
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {formik.values
                                      .boarding_school_condition_conclusion.name
                                      ? formik.values
                                          .boarding_school_condition_conclusion
                                          .name
                                      : "Заключение о состоянии подлежащего реконструкции здания в соответствии с пунктом 4 статьей 34-4 Закона об архитектурной деятельности, при реконструкции"}
                                  </Box>
                                </Button>
                              )}
                              {!formik.values
                                .boarding_school_condition_conclusion && (
                                <label htmlFor="boarding_school_condition_conclusion">
                                  <Button
                                    variant="contained"
                                    component="div"
                                    sx={{
                                      backgroundColor: "#F4F6FA",
                                      display: "flex",
                                      color: "#A7ADBE",
                                    }}
                                  >
                                    <CustomUploadIcon
                                      sx={{ width: "12px", height: "15px" }}
                                    />
                                    <Box
                                      component="div"
                                      sx={{ marginLeft: "12px" }}
                                    >
                                      Загрузить (макс 10 МВ)
                                    </Box>
                                  </Button>
                                </label>
                              )}
                              <FormHelperText>
                                {formik?.touched
                                  ?.boarding_school_condition_conclusion &&
                                  formik?.errors
                                    ?.boarding_school_condition_conclusion}
                              </FormHelperText>
                            </FormControl>
                          ) : (
                            !!formik.values
                              .boarding_school_condition_conclusion && (
                              <FileDownloadField
                                fileName={
                                  formik.values
                                    .boarding_school_condition_conclusion?.name
                                    ? formik.values
                                        .boarding_school_condition_conclusion
                                        .name
                                    : "Заключение"
                                }
                                fileUrl={
                                  !!formik.values
                                    .boarding_school_condition_conclusion &&
                                  typeof formik.values
                                    .boarding_school_condition_conclusion ===
                                    "string"
                                    ? formik.values
                                        .boarding_school_condition_conclusion
                                    : ""
                                }
                              />
                            )
                          )}
                        </Grid>
                      </>
                    )}
                  {(!isInputDisabled ||
                    formik?.values.boarding_school_real_estate_certificate) && (
                    <Grid item xs={10}>
                      <StyledLabel id="demo-simple-select-label">
                        Сведения о зарегистрированных правах (обременениях) на
                        недвижимое имущество и его технических характеристиках с
                        зарегистрированным обременением права на изменение
                        целевого назначения объекта частной организации среднего
                        образования, указанного в заявлении и сведениях об
                        интернатной организации (при наличии), на срок не менее
                        двадцати лет
                      </StyledLabel>
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    {!isInputDisabled ? (
                      <FormControl fullWidth>
                        <Input
                          id="boarding_school_real_estate_certificate"
                          name="boarding_school_real_estate_certificate"
                          type="file"
                          inputProps={{
                            accept: ".pdf",
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const file = event.target.files?.[0];
                            if (file && file.size <= 10 * 1024 * 1024) {
                              formik.setFieldValue(
                                "boarding_school_real_estate_certificate",
                                file,
                              );
                            } else {
                              toast.error(
                                "Пожалуйста, загрузите файлы меньше 10МБ",
                              );
                              formik.setFieldValue(
                                "boarding_school_real_estate_certificate",
                                null,
                              );
                            }
                          }}
                          sx={{ display: "none" }}
                        />
                        {formik.values
                          .boarding_school_real_estate_certificate && (
                          <Button
                            variant="outlined"
                            color="error"
                            endIcon={<ClearIcon />}
                            onClick={() => {
                              formik.setFieldValue(
                                "boarding_school_real_estate_certificate",
                                null,
                              );
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {formik.values
                                .boarding_school_real_estate_certificate.name
                                ? formik.values
                                    .boarding_school_real_estate_certificate
                                    .name
                                : "Сведения о зарегистрированных правах (обременениях) на недвижимое имущество и его технических характеристиках с зарегистрированным обременением права на изменение целевого назначения объекта частной организации среднего образования, указанного в заявлении и сведениях об интернатной организации (при наличии), на срок не менее двадцати лет"}
                            </Box>
                          </Button>
                        )}
                        {!formik.values
                          .boarding_school_real_estate_certificate && (
                          <label htmlFor="boarding_school_real_estate_certificate">
                            <Button
                              variant="contained"
                              component="div"
                              sx={{
                                backgroundColor: "#F4F6FA",
                                display: "flex",
                                color: "#A7ADBE",
                              }}
                            >
                              <CustomUploadIcon
                                sx={{ width: "12px", height: "15px" }}
                              />
                              <Box component="div" sx={{ marginLeft: "12px" }}>
                                Загрузить (макс 10 МВ)
                              </Box>
                            </Button>
                          </label>
                        )}
                        <FormHelperText>
                          {formik?.touched
                            ?.boarding_school_real_estate_certificate &&
                            formik?.errors
                              ?.boarding_school_real_estate_certificate}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      !!formik.values
                        .boarding_school_real_estate_certificate && (
                        <FileDownloadField
                          fileName={
                            formik.values
                              .boarding_school_real_estate_certificate?.name
                              ? formik.values
                                  .boarding_school_real_estate_certificate.name
                              : "Сведения о зарегистрированных правах "
                          }
                          fileUrl={
                            !!formik.values
                              .boarding_school_real_estate_certificate &&
                            typeof formik.values
                              .boarding_school_real_estate_certificate ===
                              "string"
                              ? formik.values
                                  .boarding_school_real_estate_certificate
                              : ""
                          }
                        />
                      )
                    )}
                  </Grid>

                  <React.Fragment>
                    {!isInputDisabled && (
                      <Grid item xs={10}>
                        <StyledLabel id="demo-simple-select-label">
                          Сведения из правового кадастра о наличии либо
                          отсутствии зарегистрированных прав на объект частной
                          организации образования, указанный в заявлении и (или)
                          сведениях об интернатной организации (при наличии), в
                          том числе о виде права, правообладателе, основании
                          возникновения права, а также об основании прекращения
                          права (при наличии)
                        </StyledLabel>
                      </Grid>
                    )}
                    <Grid item xs={3} marginBottom={"24px"}>
                      {!isInputDisabled ? (
                        <FormControl fullWidth>
                          <Input
                            id="boarding_school_cadastral_information"
                            name="boarding_school_cadastral_information"
                            type="file"
                            inputProps={{
                              accept: ".pdf",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const file = event.target.files?.[0];
                              if (file && file.size <= 10 * 1024 * 1024) {
                                formik.setFieldValue(
                                  "boarding_school_cadastral_information",
                                  file,
                                );
                              } else {
                                toast.error(
                                  "Пожалуйста, загрузите файлы меньше 10МБ",
                                );
                                formik.setFieldValue(
                                  "boarding_school_cadastral_information",
                                  null,
                                );
                              }
                            }}
                            sx={{ display: "none" }}
                          />
                          {formik.values
                            .boarding_school_cadastral_information && (
                            <Button
                              variant="outlined"
                              color="error"
                              endIcon={<ClearIcon />}
                              onClick={() => {
                                formik.setFieldValue(
                                  "boarding_school_cadastral_information",
                                  null,
                                );
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formik.values
                                  .boarding_school_cadastral_information.name
                                  ? formik.values
                                      .boarding_school_cadastral_information
                                      .name
                                  : "Сведения из правового кадастра о наличии либо отсутствии зарегистрированных прав на объект частной организации образования, указанный в заявлении и (или) сведениях об интернатной организации (при наличии), в том числе о виде права, правообладателе, основании возникновения права, а также об основании прекращения права (при наличии)"}
                              </Box>
                            </Button>
                          )}
                          {!formik.values
                            .boarding_school_cadastral_information && (
                            <label htmlFor="boarding_school_cadastral_information">
                              <Button
                                variant="contained"
                                component="div"
                                sx={{
                                  backgroundColor: "#F4F6FA",
                                  display: "flex",
                                  color: "#A7ADBE",
                                }}
                              >
                                <CustomUploadIcon
                                  sx={{ width: "12px", height: "15px" }}
                                />
                                <Box
                                  component="div"
                                  sx={{ marginLeft: "12px" }}
                                >
                                  Загрузить (макс 10 МВ)
                                </Box>
                              </Button>
                            </label>
                          )}
                          <FormHelperText>
                            {formik?.touched
                              ?.boarding_school_cadastral_information &&
                              formik?.errors
                                ?.boarding_school_cadastral_information}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        !!formik.values
                          .boarding_school_cadastral_information && (
                          <FileDownloadField
                            fileName={
                              formik.values
                                .boarding_school_cadastral_information?.name
                                ? formik.values
                                    .boarding_school_cadastral_information.name
                                : "Сведения из правового кадастра"
                            }
                            fileUrl={
                              !!formik.values
                                .boarding_school_cadastral_information &&
                              typeof formik.values
                                .boarding_school_cadastral_information ===
                                "string"
                                ? formik.values
                                    .boarding_school_cadastral_information
                                : ""
                            }
                          />
                        )
                      )}
                    </Grid>
                  </React.Fragment>
                </Grid>
              </>
            ) : null}
          </Stack>
        </BoxCard>
      </Stack>
    </form>
  );
};

export default DeclarationStep3;
