import { Box, Typography } from "@mui/material";
import { fincolors } from "../styles/theme";
import { IApplication } from "../types/application";
import { getUnassignedStyle } from "../utils/helpers";

export const TableDateTime = ({
  dateTimeString,
  params,
}: {
  params: IApplication;
  dateTimeString: string;
}) => {
  const [date, time] = dateTimeString.split(" ");

  return (
    <Box>
      <Typography
        sx={{
          color: fincolors.text.primary,
          fontSize: 14,
          fontWeight: getUnassignedStyle(params),
        }}
      >
        {date}
      </Typography>

      <Typography
        sx={{
          color: fincolors.text.secondary,
          fontSize: 12,
          fontWeight: getUnassignedStyle(params),
        }}
      >
        {time}
      </Typography>
    </Box>
  );
};
