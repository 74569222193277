import React, { useState, useEffect } from "react";
import { Box, Button, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Cookies from "js-cookie";
import { form_msg } from "../../../utils/constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginCall, loginCallByEDS } from "../../../services/useEndpoint";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../../contexts/ModalContext";
import { useAppDispatch } from "../../../store/hooks";
import { setAllCurrentUserData } from "../../../store/reducers/currentUserReducer";
import { NcaLayer } from "../../../utils/nca-layer";

interface LoginFormProps {
  handlers: {
    nextStep: (step: string) => void;
  };
}

const LoginForm: React.FC<LoginFormProps> = ({ handlers }) => {
  const [aLoginType, setLoginType] = useState<string>("loginpass");
  const [isPasswordView, setPasswordView] = useState<boolean>(true);
  const { setOpen } = useModalContext();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      login: yup.string().required(form_msg.REQUIRED),
      password: yup.string().required(form_msg.REQUIRED),
    }),
    async onSubmit(values) {
      try {
        const { login, password } = values;
        loginCall(login, password)
          .then((data) => {
            if (data) {
              //@ts-ignore
              Cookies.set("token", "Bearer " + data.access_token);
              //@ts-ignore
              Cookies.set("currentUserId", data.user.id);
              //@ts-ignore
              dispatch(setAllCurrentUserData(data.user));
              //@ts-ignore
              Cookies.set("role", data.user.role.id);
              //@ts-ignore
              setOpen(false);
              navigate("/school/declarations");
            } else {
              // Handle login failure, e.g., show an error message
              console.log("Login failed. Please check your credentials.");
            }
          })
          // .then(() => {
          //   navigate('/school/');
          // })
          .catch((error) => {
            // Handle any errors that occurred during the login process
            console.error("An error occurred during login:", error);
          });
      } catch (error) {
        // Handle any errors that occurred during the login process
        console.error("An error occurred during login:", error);
      }
    },
  });

  async function handleLoginCallByEDS() {
    try {
      const signature = await NcaLayer.sign("base64string");
      const data = await loginCallByEDS(signature);

      console.log(data);
    } catch (error) {}
  }

  return (
    <>
      <Typography variant="h2" component="h2">
        Вход в систему
      </Typography>

      <Typography
        component="span"
        sx={{ mt: 2, mb: 4, padding: "0 16px", textAlign: "center" }}
      >
        Выберите способ входа в Финансовый центр
      </Typography>

      <Box sx={{ width: "100%", mb: 4 }}>
        <Tabs
          value={aLoginType}
          onChange={(e, v) => setLoginType(v)}
          variant="fullWidth"
          aria-label="wrapped label tabs example"
        >
          <Tab value="loginpass" label="Логин и пароль" />
          <Tab value="byfile" label="ЭЦП" />
        </Tabs>
      </Box>

      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {aLoginType === "loginpass" ? (
          <form onSubmit={handleSubmit}>
            <TextField
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginBottom: "24px",
              }}
              name="login"
              value={values.login}
              label="Логин"
              placeholder="Введите логин"
              error={Boolean(touched.login && errors.login) || false}
              helperText={(touched.login && errors.login) || false}
              onChange={handleChange}
            />

            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: "32px",
              }}
            >
              <TextField
                name="password"
                value={values.password}
                label="Пароль"
                placeholder="Введите пароль"
                type={!isPasswordView ? "text" : "password"}
                error={Boolean(touched.password && errors.password) || false}
                helperText={(touched.password && errors.password) || false}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={() => setPasswordView(!isPasswordView)}
                      variant="text"
                    >
                      {!isPasswordView ? <Visibility /> : <VisibilityOff />}
                    </Button>
                  ),
                }}
              />

              <Button
                sx={{ marginTop: "12px" }}
                variant="text"
                onClick={() => handlers.nextStep("passrecovery")}
              >
                Забыли пароль?
              </Button>
            </Box>

            <Button
              sx={{ flex: 1 }}
              type="submit"
              disabled={!values.login && !values.password}
            >
              Войти
            </Button>
          </form>
        ) : (
          <Button variant="contained" onClick={handleLoginCallByEDS}>
            Выбрать сертификат
          </Button>
        )}
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontSize: "13px" }}>
            Телефон Службы поддержки
          </Typography>
          <Typography sx={{ fontSize: "13px" }}>+7 775 128 61 88</Typography>
        </Box>
      </Box>
    </>
  );
};

export default LoginForm;
