import { Check, Send } from "@mui/icons-material";
import { EApplicationEnumerationStatus } from "./enums";

export const getStatusColor = (status: EApplicationEnumerationStatus) => {
  switch (status) {
    case EApplicationEnumerationStatus.FORMED:
      return "#FFF8EB";
    case EApplicationEnumerationStatus.SIGNED:
      return "#EAF4FE";
    default:
      return "#EAF4FE";
  }
};

export const getStatusIcon = (status: EApplicationEnumerationStatus) => {
  switch (status) {
    case EApplicationEnumerationStatus.FORMED:
      return Check;
    case EApplicationEnumerationStatus.SIGNED:
      return Send;
  }
};
