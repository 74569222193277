
import styled from "@emotion/styled";
import { TextareaAutosize } from "@mui/material";

export const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
      width: 100%;
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 400;
      line-height: 1.5;
      padding: 12px;
      border-radius: 10px;
      border: 1px solid #DFE2EC;
      resize: none;
      font-size:16px
      // firefox
      &:focus-visible {
        outline: 0;
      }
    `,
  );