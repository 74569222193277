interface BoardingTableRows {
  id: number;
  classSchool: string;
  classSummStudents: number;
  normativClassDaily: number;
  normativClassBoarding: number;
  specialClassDaily: number;
  specialClassBoarding: number;
  correctiveClassDaily: number;
  correctiveClassBoarding: number;
  homeSchoolersDaily: number;
}

export const BoardingTableRows: BoardingTableRows[] = [
  {
    id: 1,
    classSchool: "1-4",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 2,
    classSchool: "5-9",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 3,
    classSchool: "10-11",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 5,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 6,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 7,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 4,
    classSchool: "Итого",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
];
export const BoardingTableRowsTwo: BoardingTableRows[] = [
  {
    id: 1,
    classSchool: "1-4",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 2,
    classSchool: "5-9",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 3,
    classSchool: "10-11",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 5,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 6,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 7,
    classSchool: "",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
  {
    id: 4,
    classSchool: "Итого",
    classSummStudents: 0,
    normativClassDaily: 0,
    normativClassBoarding: 0,
    specialClassDaily: 0,
    specialClassBoarding: 0,
    correctiveClassDaily: 0,
    correctiveClassBoarding: 0,
    homeSchoolersDaily: 0,
  },
];
