import { useCallback, useState } from "react";
import { useDebounce } from "react-use";

import { UseAsyncAutocompleteReturnProps, UseQueryFn } from "./types";

function useAsyncAutocomplete<Config, Value, Result = Config>(
  useQueryProps: UseQueryFn<Config, Value, Result>,
): UseAsyncAutocompleteReturnProps<Config, Value, Result> {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const [debouncedQuery, setDebouncedQuery] = useState<string | null>(null);

  const handleSearch = useCallback(() => {
    setDebouncedQuery(query);
  }, [query]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setQuery(null);
    setOpen(false);
  }, []);

  useDebounce(handleSearch, 500, [query]);

  return {
    useQueryProps: useQueryProps({ open, search: debouncedQuery || "" }),
    open,
    debouncedQuery,
    setQuery,
    handleOpen,
    handleClose,
  };
}

export default useAsyncAutocomplete;
