import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";
import BoxTitle from "../../components/BoxTitle";
import { getApplicationsEnumeration } from "./api";
import CreateEnumerationDialog from "./components/CreateEnumeration";
import ApplicationsEnumerationTable from "./components/Table";

const DeclarationsEnumerationPage = () => {
  const [{ page, per_page }, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    per_page: withDefault(NumberParam, 10),
  });

  const {
    data: applicationsEnumeration,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["declarations-enumeration"],
    queryFn: getApplicationsEnumeration,
  });

  return (
    <Box sx={{ py: 5, px: 2.5, maxWidth: "1130px" }}>
      <Stack direction="row" gap={2} sx={{ mb: 2.5 }}>
        <BoxTitle
          title={
            "Перечень частных организаций образования для размещения государственного образовательного заказа на среднее образование на 2023-2024 учебный год"
          }
        />

        <CreateEnumerationDialog>
          {(onOpen, createEnumerationResult) => (
            <Button
              onClick={onOpen}
              disabled={createEnumerationResult.isLoading}
            >
              Сформировать
            </Button>
          )}
        </CreateEnumerationDialog>
      </Stack>

      {isLoading && (
        <Box sx={{ display: "grid", placeItems: "center", p: 5 }}>
          <CircularProgress />
        </Box>
      )}

      {isSuccess && (
        <ApplicationsEnumerationTable
          rows={applicationsEnumeration.data}
          rowCount={applicationsEnumeration.meta.total}
          page={page}
          perPage={per_page}
          onChangePage={(page) => setQuery({ page })}
          onChangePerPage={(per_page) => setQuery({ per_page, page: 1 })}
        />
      )}
    </Box>
  );
};

export default DeclarationsEnumerationPage;
