import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { NotificationDate } from "../../types/modalTypes";

interface Notifications {
  notifications: Array<any>;
  newNotificationsCount: number;
  showAllNotifications: boolean;
}

const initialState: Notifications = {
  notifications: [],
  newNotificationsCount: 0,
  showAllNotifications: false,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setNewNotificationsCount: (state, action) => {
      state.newNotificationsCount = action.payload;
    },
    setShowAllNotifications: (state, action) => {
      state.showAllNotifications = action.payload;
    },
  },
});

export const selectNewNotificationsCount = (state: RootState) =>
  state.notificationsReducer.newNotificationsCount;
export const selectNotifications = (state: RootState) =>
  state.notificationsReducer.notifications;
export const selectShowAllNotifications = (state: RootState) =>
  state.notificationsReducer.showAllNotifications;

export const {
  setNotifications,
  setNewNotificationsCount,
  setShowAllNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
