import { CloseOutlined, FilterAltOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Stack,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { identity, pickBy } from "lodash";
import { useState } from "react";

import BoxTitle from "../../components/BoxTitle";
import Debounced from "../../components/Debounced";
import DeclarationsFilters from "../../components/DeclarationsFilters";
import TableDeclarations from "../../components/TableDeclarations";
import { useApplicationsQueryParams } from "../../hooks/useApplicationsQueryParams";
import { getApplications } from "../SpecialistDeclarations/api";

export enum SpecialistDeclarationsStatus {
  ONEXECUTION = "На исполнении",
  TOBEAGREED = "На согласовании",
}

const MainSpecialistDeclarations = () => {
  const [
    {
      search,
      page,
      per_page,
      date,
      id,
      official_number,
      review_days_count,
      status,
      overdue,
      current_executor,
      type,
    },
    setQuery,
  ] = useApplicationsQueryParams();

  const {
    data: applications,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: [
      "applications",
      search,
      page,
      per_page,
      date,
      id,
      official_number,
      review_days_count,
      status,
      current_executor?.id,
      overdue,
      type,
    ].filter(Boolean),
    queryFn: ({ signal }) =>
      getApplications({
        signal,
        params: pickBy(
          {
            search,
            page,
            per_page,
            date: date ? dayjs(date).format("YYYY-MM-DD") : null,
            id,
            official_number,
            review_days_count,
            status_id: status,
            overdue,
            current_executor_id: current_executor?.id,
            type,
          },
          identity,
        ),
      }),
  });

  const [expanded, setExpanded] = useState(false);

  return (
    <Stack sx={{ gap: 2.5, py: 5, px: 2.5, maxWidth: "1130px" }}>
      <BoxTitle title={"Заявления"} />

      <Debounced
        value={search}
        onChange={(search) => setQuery({ search, page: 1 })}
      >
        {(value, onChange) => (
          <TextField
            type="search"
            placeholder="Поиск по наименованию, ИИН/БИН и тд."
            value={value}
            variant="outlined"
            sx={{ maxWidth: 375 }}
            onChange={(event) => onChange(event.target.value)}
          />
        )}
      </Debounced>

      <Button
        sx={{ alignSelf: "flex-start" }}
        variant="outlined"
        onClick={() => setExpanded(!expanded)}
        startIcon={expanded ? <CloseOutlined /> : <FilterAltOutlined />}
      >
        Фильтр
      </Button>

      <Collapse in={expanded}>
        <DeclarationsFilters disabled={isLoading} />
      </Collapse>

      {isLoading && (
        <Box sx={{ display: "grid", placeItems: "center", p: 5 }}>
          <CircularProgress />
        </Box>
      )}

      {isSuccess && (
        <TableDeclarations
          rows={applications.data}
          rowCount={applications.meta.total}
          page={page}
          perPage={per_page}
          onChangePage={(page) => setQuery({ page })}
          onChangePerPage={(per_page) => setQuery({ per_page, page: 1 })}
        />
      )}
    </Stack>
  );
};

export default MainSpecialistDeclarations;
