import { Box, Button, InputLabel, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { ReworkModalProps } from "../../types/modalTypes";
import { StyledTextarea } from "./StyledTextArea";

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 620,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export default function ExecutorReworkModal({
  id,
  open,
  onClose,
  submit,
}: ReworkModalProps) {
  const [comment, setComment] = useState("");

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center", marginBottom: "32px" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          На доработку исполнителю
        </Typography>
        <Box sx={{ marginBottom: "32px" }}>
          <Box>
            <InputLabel id="demo-simple-select-label">
              Причина доработки<span style={{ color: "#EC5654" }}>*</span>
            </InputLabel>

            <StyledTextarea
              aria-label="Причина доработки *"
              minRows={3}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Уважаемый пользователь вам необходимо доработать заявку по причине..."
              style={{ fontSize: "16px" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              width: "45%",
            }}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            disabled={!comment}
            onClick={() => submit(id, comment, [])}
            sx={{
              width: "45%",
            }}
          >
            Отправить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
