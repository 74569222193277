import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Box, Button, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Modal from "@mui/material/Modal";

interface RegistrationCodeState {
  open: boolean;
  emailAddress: string;
  changePhoneNumber: () => void;
  restartRegistrationCode: () => void;
  sendRequestToServer: (code: number) => void;
}
const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const RegistrationCode: React.FC<RegistrationCodeState> = ({
  open,
  emailAddress,
  changePhoneNumber,
  restartRegistrationCode,
  sendRequestToServer,
}) => {
  const [code, setCode] = useState(["", "", "", "", ""]);
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updatedCode = [...code];
    const value = e.target.value;

    if (value.length <= 1) {
      updatedCode[index] = value;
      setCode(updatedCode);

      if (index < 4 && value !== "") {
        inputRefs[index + 1].current?.focus();
      }
    }
  };
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number,
  ) => {
    if (e.key === "Backspace" && code[index].length === 0 && index > 0) {
      inputRefs[index - 1].current?.focus();
    }
  };
  useEffect(() => {
    const isCodeComplete = code.every((char) => char.length === 1);
    console.log(Number(code.join("")));

    if (isCodeComplete) {
      sendRequestToServer(Number(code.join("")));
    }
  }, [code]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h2"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              Введите код из SMS
            </Typography>
            <Box sx={{ mt: 2, mb: 2, textAlign: "center" }}>
              Код подтверждения отправлен на почту <br /> {emailAddress}
            </Box>
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  height: "58px",
                }}
              >
                {code.map((char, index) => (
                  <TextField
                    key={index}
                    variant="outlined"
                    value={char}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, index)
                    }
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    inputProps={{ maxLength: 1 }}
                    inputRef={inputRefs[index]}
                    sx={{
                      width: "64px",
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        borderRadius: "10px",
                        width: "64px",
                        height: "100%",
                        backgroundColor: "#DFE2EC",
                        "& .MuiInputBase-input": {
                          textAlign: "center",
                          width: "64px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0)",
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                    }}
                  />
                ))}
              </Box>
              <Button
                variant="text"
                sx={{
                  textAlign: "center",
                  mt: "15px",
                }}
                onClick={() => restartRegistrationCode()}
              >
                Отправить код повторно
              </Button>
              <Button
                variant="text"
                sx={{
                  textAlign: "center",
                  mt: "15px",
                }}
                onClick={() => changePhoneNumber()}
              >
                Ввести другую электронную почту
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RegistrationCode;
