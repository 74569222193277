import { FC } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

type Specialists = {
  actual_address: null | string;
  ceo_fullname: null | string;
  ceo_official_number: null | string;
  created_at: Date | string;
  email_address: string;
  id: number;
  login: string;
  official_address: null | string;
  official_number: string;
  organization_name: string;
  phone_number: string;
  postcode: null | string;
};

interface AutoWorkerSelectProps {
  id: string;
  index:number;
  specialists: Specialists[];
  specialistId: number | null;
  mainSpecialistId: number | null;
  mainSpecialists: Specialists[];
  handleDelete: () => void;
  handleSpecialistChange: (id: string, specialistId: number) => void;
  handleMainSpecialistChange: (id: string, mainSpecialistId: number) => void;
 

}
const AutoWorkerSelect: FC<AutoWorkerSelectProps> = ({
  id,
index,
  specialists,
  mainSpecialists,
  handleDelete,
  handleSpecialistChange,
  handleMainSpecialistChange,
  specialistId,
  mainSpecialistId,
}) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ marginTop: "30px" }} fullWidth>
        <InputLabel id="specialist-select">Специалист</InputLabel>
        <Select
          labelId="specialist-select"
          id="specialist-select"
          value={specialistId !== null ? specialistId.toString() : ""}
          label="Specialist"
          onChange={(evt) =>
            handleSpecialistChange(id, Number(evt.target.value))
          }
        >
          {specialists &&
            specialists.map((item) => {
              return (
                <MenuItem value={item.id} key={item.id}>
                  {item.organization_name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <FormControl sx={{ marginTop: "30px" }} fullWidth>
        <InputLabel id="main-specialist-select">
          Главный специалист (не обязательно)
        </InputLabel>
        <Select
          labelId="main-specialist-select"
          id="main-spetialist-select"
          value={
            mainSpecialistId !== null && mainSpecialistId !== undefined
              ? mainSpecialistId.toString()
              : ""
          }
          label="Main Specialist"
          onChange={(evt) =>
            handleMainSpecialistChange(id, Number(evt.target.value))
          }
        >
          {mainSpecialists &&
            mainSpecialists.map((item) => {
              return (
                <MenuItem value={item.id} key={item.id}>
                  {item.organization_name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

     {index > 0 &&   <Button
        variant="text"
        sx={{ color: "red", alignItems: "center", marginTop: "16px" }}
        onClick={handleDelete}
      >
        <CloseOutlined sx={{ fontSize: "16px" }} /> Удалить исполнителей
      </Button>}
    </Box>
  );
};

export default AutoWorkerSelect;
