import { AxiosRequestConfig } from "axios";
import anAxiosApi from "../../services/axiosApi";
import { Meta } from "../../types/types";
import { IIncomingMail } from "./types";

export const getIncomingMails = async (config?: AxiosRequestConfig) => {
  const { data } = await anAxiosApi.get<{ data: IIncomingMail[]; meta: Meta }>(
    "/application/incoming-mails",
    config,
  );
  return data;
};
