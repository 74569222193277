import React, { useEffect } from "react";
import BoxTitle from "../../components/BoxTitle";
import RequestButton from "./components/RequestButton";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { STUDENT_REQUEST_TYPES } from "../../utils/constants";
import { Link } from "@mui/material";
import styled from "@emotion/styled";
import { useAppDispatch } from "../../store/hooks";
import { setCurrentPage } from "../../store/reducers/currentPageSlice";
import Pagination from "../../components/Pagination/Pagination";
import TableStatusComponent from "./components/TableStatusComponent";
import { DescriptionOutlined } from "@mui/icons-material";
import { fincolors } from "../../styles/theme";
import StudentStatusModal from "../../components/Modals/studentStatusModal/StudentStatusModal";

interface RequestItem {
  id: number;
  type: string;
  date: string;
  status: string;
  pdf: string | null;
}
const mockRequests = [
  {
    id: 1,
    type: "loan_debt",
    date: "23.06.2023",
    status: "done",
    pdf: "https://api.financecenter.sailet.pro//storage/application-accept-pdfs/1707820596.pdf",
  },
  {
    id: 2,
    type: "work_off",
    date: "23.06.2023",
    status: "not_done",
    pdf: null,
  },
  {
    id: 3,
    type: "loan_debt",
    date: "23.06.2023",
    status: "done",
    pdf: "https://api.financecenter.sailet.pro//storage/application-accept-pdfs/1707820596.pdf",
  },
  {
    id: 4,
    type: "debt_status",
    date: "23.06.2023",
    status: "not_done",
    pdf: null,
  },
  {
    id: 5,
    type: "credit_status",
    date: "23.06.2023",
    status: "done",
    pdf: "https://api.financecenter.sailet.pro//storage/application-accept-pdfs/1707820596.pdf",
  },
  {
    id: 6,
    type: "loan_debt",
    date: "23.06.2023",
    status: "done",
    pdf: "https://api.financecenter.sailet.pro//storage/application-accept-pdfs/1707820596.pdf",
  },
  {
    id: 7,
    type: "loan_debt",
    date: "23.06.2023",
    status: "done",
    pdf: "https://api.financecenter.sailet.pro//storage/application-accept-pdfs/1707820596.pdf",
  },
];

const columns: GridColDef[] = [
  {
    field: "number",
    headerClassName: "super-app-theme--header",
    headerName: "Номер",
    width: 120,
    editable: false,
    renderCell: (params) => `№ ${params.row.number}`,
  },
  {
    field: "requestType",
    headerName: "Вид запроса",
    headerClassName: "super-app-theme--header",
    flex: 1,
    width: 140,
    editable: false,
    renderCell: (params) => {
      switch (params.row.requestType) {
        case STUDENT_REQUEST_TYPES.loanDebt:
          return "Получение справки об отсутствии задолженности";
        case STUDENT_REQUEST_TYPES.workOff:
          return "Проверка статуса по отработке";
        case STUDENT_REQUEST_TYPES.debtStatus:
          return "Проверка статуса по долгу";
        case STUDENT_REQUEST_TYPES.creditStatus:
          return "Проверка статуса по кредиту";
        default:
          return "";
      }
    },
  },
  {
    field: "date",
    headerName: "Дата запроса",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: false,
  },
  {
    field: "status",
    headerName: "Статус",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: false,
    renderCell: (params) => <TableStatusComponent status={params.row.status} />,
  },
  {
    field: "documentUrl",
    headerName: "Ответ",
    headerClassName: "super-app-theme--header",
    width: 140,
    editable: false,
    renderCell: (params) =>
      !!params.row.documentUrl ? (
        <Link
          href={params.row.documentUrl}
          target="_blank"
          sx={{
            display: "flex",
            alignItems: "flex-end",
            color: fincolors.primary.main,
            fontWeight: 500,
            textDecoration: "none",
            gap: "6px",
          }}
        >
          <DescriptionOutlined /> Посмотреть
        </Link>
      ) : null,
  },
];

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  height: "auto",
  border: "2px solid #DFE2EC",
  borderRadius: "8px",
  overflow: "hidden",
  // maxWidth: "1130px",
  "& .MuiDataGrid-root": {
    borderRadius: "8px",
    overflow: "hidden",
    height: "auto",
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    backgroundColor: "#FFF",
  },

  "& .MuiDataGrid-overlay": {
    display: "none",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "14px",
    padding: "10px",
    //backgroundColor: "#FFF",
    height: "auto",
    overflow: "auto",
    textOverflow: "clip",
  },
  "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
    whiteSpace: "normal",
    height: "auto",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-row:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-colCellTitle": {
    color: "#111520",
    fontWeight: "medium",
    backgroundColor: "#DFE2EC",
    height: "40px",
  },

  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    height: "100%",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    overflow: "auto",
    lineHeight: "120%",
    whiteSpace: "normal",
    height: "100%",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },
}));

export default function StudentRequests() {
  const [requestRows, setRequestRows] = React.useState<
    Array<{
      id: number;
      number: number;
      date: string;
      requestType: string;
      status: string;
      documentUrl: string | null;
    }>
  >([]);
  const [isModalStatusOpen, setIsModalStatusOpen] = React.useState(false);
  const handleCellClick = (params: any) => {
    if (params.field !== "documentUrl") {
      setIsModalStatusOpen(true);
    }
  };
  const dispatch = useAppDispatch();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 20,
    page: 0,
  });

  React.useEffect(() => {
    dispatch(setCurrentPage(paginationModel.page));
  }, [paginationModel.page]);

  useEffect(() => {
    const sortedRows = mockRequests.map((requestItem: RequestItem) => ({
      id: requestItem.id,
      number: requestItem.id,
      date: requestItem.date,
      requestType: requestItem.type,
      status: requestItem.status,
      documentUrl: requestItem.pdf,
    }));
    setRequestRows(sortedRows);
  }, [mockRequests]);

  return (
    <>
      <BoxTitle title={"Мои запросы"}>
        <RequestButton />
      </BoxTitle>
      <StyledDataGrid
        columns={columns}
        rows={requestRows}
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
        disableColumnMenu
        onCellClick={handleCellClick}
      />
      <Pagination
        rowCount={mockRequests.length}
        rowsPerPage={paginationModel.pageSize}
        currentPage={paginationModel.page + 1}
        onPageChange={(evt, page) =>
          setPaginationModel({ ...paginationModel, page: page })
        }
        onRowsPerPageChange={(evt) => {
          setPaginationModel({
            ...paginationModel,
            pageSize: Number(evt.target.value),
          });
        }}
      />
      <StudentStatusModal
        open={isModalStatusOpen}
        onClose={() => setIsModalStatusOpen(false)}
      />
    </>
  );
}
