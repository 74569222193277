import React from "react";
import Routers from "./basic/Routers";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { getCurrentUser } from "./services/useEndpoint";

import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  setAllCurrentUserData,
  setAuthorized,
  selectAuthorization,
  selectCurrentUserId,
} from "./store/reducers/currentUserReducer";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const isAuthorizesRTK = useAppSelector(selectAuthorization);
  const userId = useAppSelector(selectCurrentUserId);

  // if (isLoading) {
  //   return <div></div>;
  // }

  useEffect(() => {
    const token = !!Cookies.get("token");
    const id = Cookies.get("currentUserId");
    if (!token) return;
    if (id) {
      getCurrentUser(+id)
        .then((data) => {
          if (data) {
            dispatch(setAllCurrentUserData(data.data));
            dispatch(setAuthorized());
          } else {
            console.log("User get data failed. Please check.");
          }
        })
        .catch((error) => {
          console.error("An error occurred during getCurrentUser:", error);
        });
    }
  }, [userId]);

  return <Routers />;
};

export default App;
