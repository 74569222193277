import { GridRowId, GridRowSelectionModel } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IApplication } from "../../../types/application";
import AutoConfirmModal from "./AutoConfirmModal";
import DirectorDataGridArchieve from "./DirectorDataGridArchieve";

interface TableState {
  page: number;
  perPage: number;
  rows: IApplication[];
  rowCount: number;
  onChangePage(page: number): void;
  onChangePerPage(perPage: number): void;
}

const TableDirectorDeclarationsArchieve: React.FC<TableState> = ({
  page,
  perPage,
  rows,
  rowCount,
  onChangePage,
  onChangePerPage,
}) => {
  const [isBoxShow, setIsBoxShow] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [isChecked, setIsChecked] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleCellClick = (params: any) => {
    if (params.field === "__check__") {
      return;
    }
    navigate(`${location.pathname}/${params.id}`);
  };

  const handleSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel,
  ) => {
    setSelectedRows(rowSelectionModel);

    if (rowSelectionModel.length > 0) {
      setIsBoxShow(true);
    } else {
      setIsBoxShow(false);
    }
  };

  const handleClickSelectExecutors = () => {
    setIsModalOpen(true);
  };

  const onCheckboxChange = () => {
    setIsChecked(!isChecked);

    const allDeclarationIds: GridRowId[] = [];
    rows.forEach((item) => allDeclarationIds.push(item.id));

    isChecked ? setSelectedRows([]) : setSelectedRows(allDeclarationIds);
  };

  const handleBoxAreaClose = () => {
    setIsBoxShow(false);
    setSelectedRows([]);
  };

  return (
    <>
      <DirectorDataGridArchieve
        rows={rows}
        handleSelectionModelChange={handleSelectionModelChange}
        selectedRows={selectedRows}
        isChecked={isChecked}
        handleCellClick={handleCellClick}
        isBoxShow={isBoxShow}
        onCheckboxChange={onCheckboxChange}
        handleBoxAreaClose={handleBoxAreaClose}
        rowCount={rowCount}
        handleClickSelectExecutors={handleClickSelectExecutors}
        page={page}
        perPage={perPage}
        onChangePage={onChangePage}
        onChangePerPage={onChangePerPage}
      />

      <AutoConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        applicationIds={selectedRows}
      />
    </>
  );
};

export default TableDirectorDeclarationsArchieve;
