import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Outlet, useOutletContext } from "react-router-dom";
import { Box, Container } from "@mui/material";

import Header from "../components/Header/Header";

import { useState } from "react";
import AdminMenu from "../components/AdminMenu";

type ContextType = { hasSideBar: boolean | null };

const AdminLayout = () => {
  const [sideBarState, setSideBarState] = useState(1);
  const [hasSideBar, setHasSideBar] = useState(true);

  const contentOffsetOfMenu =
    sideBarState === 0 ? "0px" : sideBarState === 1 ? "310px" : "50px";

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/director/declarations/current") {
      setSideBarState(0);
      setHasSideBar(false);
    } else if (location.pathname !== "/director/declarations/current") {
      setSideBarState(1);
      setHasSideBar(true);
    }
  }, [location]);

  // useEffect(() => {
  // 	console.log(location.pathname)
  // 	console.log('внутри adminlayout hasSideBar', hasSideBar)
  // }, [])

  return (
    <>
      <Header />

      <Container
        disableGutters={true}
        component="main"
        sx={{ display: "flex", minHeight: "100vh" }}
      >
        {/* <Container sx={{display: 'flex'}}>	 */}
        {!sideBarState ? null : <AdminMenu state={sideBarState} />}

        <Box sx={{ width: "100%" }}>
          <Outlet context={{ hasSideBar } satisfies ContextType} />
        </Box>
        {/* </Container> */}
      </Container>
    </>
  );
};

export function useHasSidebar() {
  return useOutletContext<ContextType>();
}

export default AdminLayout;
