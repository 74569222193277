import { Cancel, CheckCircle, Telegram } from "@mui/icons-material";
import { Box, Modal, Stack, Typography, createSvgIcon } from "@mui/material";
import React from "react";
import { fincolors } from "../../../styles/theme";
const CustomCircleIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
  >
    <path
      d="M8.51123 1.80078V4.68077"
      stroke="url(#paint0_linear_1984_7870)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.51123 13.3203V16.2003"
      stroke="url(#paint1_linear_1984_7870)"
      stroke-opacity="0.7"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.42139 3.91016L5.45898 5.94775"
      stroke="url(#paint2_linear_1984_7870)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5645 12.0527L13.602 14.0903"
      stroke="url(#paint3_linear_1984_7870)"
      stroke-opacity="0.6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.31152 9H4.19152"
      stroke="url(#paint4_linear_1984_7870)"
      stroke-opacity="0.9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.8315 9H15.7115"
      stroke="url(#paint5_linear_1984_7870)"
      stroke-opacity="0.5"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.42139 14.0903L5.45898 12.0527"
      stroke="url(#paint6_linear_1984_7870)"
      stroke-opacity="0.8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5645 5.94775L13.602 3.91016"
      stroke="url(#paint7_linear_1984_7870)"
      stroke-opacity="0.4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1984_7870"
        x1="-1.98877"
        y1="3.40077"
        x2="7.31221"
        y2="20.8402"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1984_7870"
        x1="-2.48877"
        y1="3.4003"
        x2="7.29301"
        y2="20.8881"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1984_7870"
        x1="3.17842"
        y1="3.40052"
        x2="14.1117"
        y2="14.6005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1984_7870"
        x1="2.91162"
        y1="3.3999"
        x2="13.8449"
        y2="14.5999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1984_7870"
        x1="2.91152"
        y1="-1.5"
        x2="20.12"
        y2="7.45928"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1984_7870"
        x1="2.91153"
        y1="-1.5"
        x2="20.3509"
        y2="7.80098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1984_7870"
        x1="2.91175"
        y1="3.3999"
        x2="14.1117"
        y2="14.5999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1984_7870"
        x1="2.91162"
        y1="3.40052"
        x2="13.8449"
        y2="14.6005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC50D" />
        <stop offset="1" stop-color="#F57A35" />
      </linearGradient>
    </defs>
  </svg>,
  "CustomCircleIcon",
);

const style = {
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "700px",
  maxWidth: "1000px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const titleStyle = {
  fontSize: "14px",
  fontWeight: 600,
};
const boxStyle = {
  borderRadius: "10px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: fincolors.other.borderColor,
  px: "16px",
  py: "12px",
  lineHeight: "24px",
};
const getStatusColor = (status: string) => {
  switch (status) {
    case "":
      return "#E9FEEE";
    case "1":
      return "#FBDDDD";
    case "2":
      return "#FFF8EB";
    default:
      return "#EAF4FE";
  }
};
const getStatusColorIcon = (status: string) => {
  switch (status) {
    case "":
      return <CheckCircle style={{ color: "#2DB77B" }} />;
    case "1":
      return <Cancel style={{ color: "#EC5654" }} />;
    case "2":
      return <CustomCircleIcon />;
    default:
      return <Telegram style={{ color: "#298FF5" }} />;
  }
};

interface StudentStatusModalProps {
  open: boolean;
  onClose: (value: boolean) => void;
}
const StudentStatusModal: React.FC<StudentStatusModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center", fontSize: "20px", fontWeight: 500 }}
        >
          Проверка cтатуса
        </Typography>

        <Box sx={{mt:"32px"}}>
          <Typography sx={titleStyle}>Статус</Typography>
          <Typography
            sx={{
              display: "inline-flex",
              alignItems: "center",
              gap: "10px",
              backgroundColor: "#FBDDDD",
              borderRadius: "36px",
              fontSize: "12px",
              px: "8px",
              py: "5px",
              mt: "10px",
            }}
          >
            Не прибыл
            <Cancel
              sx={{
                color: fincolors.error.main,
              }}
            />
          </Typography>
        </Box>
        <Stack sx={{ gap: "10px", mt: "24px" }}>
          <Typography sx={titleStyle}>Памятка</Typography>
          <Box sx={boxStyle}>
            <Typography sx={{ color: fincolors.text.secondary }}>
              В этой связи, Вам необходимо представить документы, подтверждающие
              Ваше трудоустройство, либо в случае неисполнения обязанности по
              отработке возместить бюджетные расходы, связанные с Вашим
              обучением.
              <br />
              <br />
              Для более детальной информации Вам необходимо обратиться по
              следующим номерам: +7(7172)-695-032, 695-034, 695-047, 695-056,
              695-057; Эл. Почта почта для отправки документов: 2023dm@mail.ru.
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ gap: "10px", mt: "24px" }}>
          <Typography sx={titleStyle}>
            Остаток задолженности и сроки погашения по образовательному кредиту
            на 05.08.2023:
          </Typography>
          <Box sx={boxStyle}>
            <Typography sx={{ color: fincolors.error.main, fontWeight: 600 }}>
              Остаток задолженности: 2 545 000 тг.
            </Typography>
            <Typography sx={{ color: fincolors.error.main, fontWeight: 600 }}>
              Срок погашения: 23.05.2026г.
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
export default StudentStatusModal;
